import React from 'react'
import "./SocialImpact.css"

const SocialImpact = (props) => {
    const { content = {} } = props
    const { tagLine,heading = '',description='',ctaLink,ctaText,data = [] } = content

  return (
    <div className='container'>
    <div className='social-impact-container'>
    <div className='social-impact-header-wrp'>
       <div className='social-impact-container-wrp'>
            <div className='social-impact-subheading'>{tagLine}</div>
            <div className='social-impact-desc' dangerouslySetInnerHTML={{ __html: (heading) }} />
            <div className='social-impact-description' dangerouslySetInnerHTML={{ __html: (description) }} />
       </div>
       <div className='social-impact-btn-wrp'>
          <a className='social-impact-btn' href={ctaLink}>{ctaText}</a>
       </div>
    </div>
   

    <div className='social-impact-cards'>
        {data && data.length > 0 && data.map((item, index) => {
            return  <div className='social-impact-card' key={index}>
                <div className='social-impact-card-bottom-num'>
                    <img src={item.image}/>
                </div>
                <div className='social-impact-card-content'>
                    <div className='social-impact-card-title'>{item.title}</div>
                    <div className='social-impact-card-desc'>{item.desc}</div>
                </div>
          </div>
        })}
        </div>

</div>
</div>
  )
}

export default SocialImpact