import React, { useEffect } from 'react';
import "./StarRating.css"
import iOSStoreIcon from "../../../../../images/newDesignV2/ios_app_store_icon.png"
const StarRating = ({
    rating='4.9',ratingLavel=''
}) => {

    useEffect(()=>{
        let starRate  = (rating >= 4.6 && rating < 5) ? 4.65 :  rating;
        rateStyle(starRate, 'starRating');
        function rateStyle(num, divID) {
            var ratingRounded = Math.floor(num);
            var starArray = document.getElementById(divID).querySelectorAll(".star-over");
          for (var i = 0; i < ratingRounded; i++) {
                starArray[i].classList.add("star-visible");
            }
            var finalStar = Math.round((num-ratingRounded)*100);
            if (finalStar != 0) {
                starArray[ratingRounded].classList.add("star-visible");
                starArray[ratingRounded].style.width=finalStar+"%";
            }
        }
    },[]);

    return (
        <div className='ratting-with-app-store-icons'>
        <div className='app-store-icon-area'>
            <a href="https://apps.apple.com/us/app/avibra/id1449843334" target="_blank">
            <img src={iOSStoreIcon} className='ratting-ios-store-img' />
            </a>
        </div>
        <div id="starRating" className="avibra-app-start-rating">
            <div style={{float:'left'}}>
            <i className="star star-under far fa-star">
                <i className="star star-over fa fa-star"></i>
            </i>
            <i className="star star-under far fa-star">
                <i className="star star-over fa fa-star"></i>
            </i>
            <i className="star star-under far fa-star">
                <i className="star star-over fa fa-star"></i>
            </i>
            <i className="star star-under far fa-star">
                <i className="star star-over fa fa-star"></i>
            </i>
            <i className="star star-under far fa-star">
                <i className="star star-over fa fa-star"></i>
            </i>
            </div>
            {ratingLavel ? <div className="app-start-rating-text">{ratingLavel} &nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
</svg></div> : null}
        </div> 
        </div>
    )
}

export default StarRating;
