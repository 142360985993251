import React, { useEffect } from "react";
import { setLogEvent } from "../../../../utils/helpers";
import { Link } from "react-router";
import "./Jupiter.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

const Jupiter = ({
  data: {
    image,
    orientation,
    bgColor,
    heading,
    button,
    renderType,
    title,
    subtitle,
    subImage,
    link,
    index,
  },
}) => {
  useEffect(() => {
    if (localStorage.getItem("scrollTo")) {
      document
        .getElementById(localStorage.getItem("scrollTo"))
        .scrollIntoView();
      localStorage.removeItem("scrollTo");
    }
  }, []);

  const jupiterButtonClick = (data) => {
    if (window.document.documentMode) {
      localStorage.setItem("scrollTo", `index-${data?.index}`);
    }

    setLogEvent("User Clicked On " + data?.heading + " " + data?.button, data);
  };

  return (
    <>
      <div className={`jupiter-card`} id={`index-${index}`}>
        {/* <div className="container">
          <div
            className="jupiter__card__main"
            style={{ backgroundColor: bgColor }}
          >
            <div
              className={`row align-items-center ${orientation === "left" && "flex-Card-reverse"
                }`}
            >
              <div className="col-lg-6  order-two">
                <div className="section-content">
                  <div className="section__main">
                    <p className="m-0">{heading}</p>
                  </div>
                  <div className="section__content__main">
                    <h5> {title}</h5>
                  </div>
                  <div className="content__card">
                    <div dangerouslySetInnerHTML={ { __html: (subtitle) } }></div>
                  </div>
                  <div className={`section__button ${renderType === "light" && "light__mode"}`}>
                    <Link to={`/${link}`} onClick={() => jupiterButtonClick({ heading, title, subtitle, button, link, index })}>
                      {button}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-one">
                <div className="section-content-img">
                  <LazyLoadImage src={image} alt={title} className="img-fluid"  effect="opacity"/>
                  <div className="graph__area">
                    <LazyLoadImage src={subImage} alt="" className="img-fluid"  effect="opacity" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="jupiter__card__main-container">
          <div
            className="jupiter__card__main"
            style={{ backgroundColor: bgColor }}>

            <div className="row m-0">

              <div className="col-lg-6 p-0">
                <div className="section-content-img">
                  <LazyLoadImage src={image} alt={title.replace(/<[^>]*>?/gm, '')} className="img-fluid" effect="opacity" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="section-content">
                  {heading ? <div className="section__main">
                    <p className="m-0">{heading}</p>
                  </div> : null}
                  {title ? <div className={`section__content__main ${link?link:'' }`}>
                    <div className="heading-h5" dangerouslySetInnerHTML={{ __html: (title) }}></div>
                  </div> : null}
                  <div className="content__card">
                    <div dangerouslySetInnerHTML={{ __html: (subtitle) }}></div>
                  </div>
                  <div className={`section__button ${renderType === "light" && "light__mode"}`}>
                    <Link to={`/${link}`} onClick={() => jupiterButtonClick({ heading, title, subtitle, button, link, index })}>
                      {button}
                    </Link>
                  </div>
                </div>
              </div>

            </div>
          </div>
          {/* <div className="main__blog__wrp">
              <div className="slider__content">
                  <div className="slider__items">
                    <div className="slider__img">
                    <div className="section-content-img">
                      <LazyLoadImage src={image} alt={title} className="img-fluid" effect="opacity" />
                    </div>
                    <div className="slider__main__content">
                    <div className="section-content">
                  <div className="section__main">
                    <p className="m-0">{heading}</p>
                  </div>
                  <div className="section__content__main">
                    <h5> {title}</h5>
                  </div>
                  <div className="content__card">
                    <div dangerouslySetInnerHTML={{ __html: (subtitle) }}></div>
                  </div>
                  <div className={`section__button ${renderType === "light" && "light__mode"}`}>
                    <Link to={`/${link}`} onClick={() => jupiterButtonClick({ heading, title, subtitle, button, link, index })}>
                      {button}
                    </Link>
                  </div>
                </div>
              </div>
                    </div>
                    </div>
                  </div>
              </div> */}
          </div>
        </div>
    </>
  );
};

export default Jupiter;
