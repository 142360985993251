import React from "react";
import "./Beneficiary.css";
import FooterSection from "./FooterSection";
import queryString from "query-string"

const StaticContentSlide = (props) => {
  const {pageData: {title='', description='', footer }, lifeadndInsuranceProvider='', slideNo='', policyData={} } = props;
  const addProviderInfo = (description) =>{
    if(description.indexOf("##lifeadndInsuranceProviderInfo##") != -1){
    switch (lifeadndInsuranceProvider) {
      case "Amalgamated_Amalgamated":
        return description.replace("##lifeadndInsuranceProviderInfo##", "Your Life and AD&D insurance is underwritten by Amalgamated Life Insurance Company") 
      case "Amalgamated_Prudential":
        return description.replace("##lifeadndInsuranceProviderInfo##", "Your Life  insurance is underwritten by Amalgamated Life Insurance Company, AD&D insurance is underwritten by Prudential Insurance Company")
      case "Prudential_Amalgamated":
        return description.replace("##lifeadndInsuranceProviderInfo##", "Your Life  insurance is underwritten by Prudential Insurance Company, AD&D insurance is underwritten by Amalgamated Life Insurance Company")
      case "Prudential_Prudential":
        return description.replace("##lifeadndInsuranceProviderInfo##", "Your Life and AD&D insurance is underwritten by Prudential Insurance Company")
      default:
        return description.replace("##lifeadndInsuranceProviderInfo##", " ");
    }
  } else {
    return description
  }
  }
  const number_ordinal_suffix_of = (i) => {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
  }
  let updatedDescription = (lifeadndInsuranceProvider && description)  ? addProviderInfo(description) : description;
  console.log("StaticContentSlide ===>", title)
  const {adndTerminationAge=''} = policyData
  const adndAgeNumber = adndTerminationAge ? adndTerminationAge : '62';
  const ageNextBirthday = Number(adndAgeNumber) + 1;
  updatedDescription = updatedDescription.replace('{##adndTerminationAge##}', adndAgeNumber);
  updatedDescription = updatedDescription.replace('{##adndTerminationAgeBirthdayStart##}', number_ordinal_suffix_of(ageNextBirthday));

  let qsparam = queryString.parse(window.location.search.toLowerCase());
  let partnerKey = qsparam['_partnerkey_'] ? qsparam['_partnerkey_'] : '';
  let campaignName = qsparam['utm_campaign'] ? qsparam['utm_campaign'] : ''; 
  let lifeInsuranceProvider = lifeadndInsuranceProvider.split("_");

  //console.log("StaticContentSlide ===>", lifeInsuranceProvider, partnerKey, campaignName);
  if(partnerKey=='acorns' && campaignName=='10kinsurance') {
    if(lifeInsuranceProvider[0] && lifeInsuranceProvider[0].toLowerCase()=='amalgamated') {
      updatedDescription = updatedDescription.replace('sixty-one (61) days', 'thirty (30) days');
    }
    if(lifeInsuranceProvider[0] && lifeInsuranceProvider[0].toLowerCase()=='prudential') {
      updatedDescription = updatedDescription.replace('There is a sixty-one (61) days waiting period', 'Immediately applied');
    }
  }
  return (
    <>
      <div className="card-guide">
        <div className="container h-100 p-0">
          <div className="main__color Benefi-ciary">
            {title ? <div className="Benefi-ciary__title">
                <h1>{title}</h1>
            </div> : null}
            <div className="beneficiary__main">
                <div class="static-slide-container">
                  <div className="static_content_data">
                  <div dangerouslySetInnerHTML={ { __html: (updatedDescription) } }></div>
                  </div>
                </div>
              </div>
            <FooterSection slideName={footer} slideNo={slideNo} />
          </div>
        </div>
      </div>
    </>
  );
};

export default StaticContentSlide;
