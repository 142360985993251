import React from 'react'
import "./FullWidthImageBannerHeaderSection.css"
import { setLogEvent } from '../../../utils/helpers';
import GetStartedButton from "../Common/GetStartedButton/GetStartedButton";

const FullWidthImageBannerHeaderSection = (props) => {
    const { content = {} } = props
    const { heading = '',description='',bannerImage={},buttonText='',buttonLink=''} = content;
    const {desktop='',mobile='',tablet=''} = bannerImage
    const onDownloadClick = () => {
        setLogEvent("User Clicked On Get Started Button");
    }
    return (<>
        <div className="full-width-banner-header-img-section">
            <div className="full-width-banner-header-img-area container" style={{backgroundImage:`url(${desktop})`}}>
                <div className="header_area header_area_new header-section ">     
                    <div className="row">   
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="left" >
                                <div className="full-width-banner-header-text-area">
                                    {heading && <div className='title' dangerouslySetInnerHTML={{ __html: (heading) }} />}
                                    {description && <div className='sub-title'>{description}</div>}
                                    {buttonText ? <GetStartedButton
                                        bgColor={'#774AD9'}
                                        onClick={() => onDownloadClick()}
                                        text={buttonText}
                                        link={buttonLink}
                                        target={'_blank'}
                                        padding={'15px 45px'}
                                    /> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default FullWidthImageBannerHeaderSection