import React from 'react';
import "./OurMission.css"
import { Container,Row,Col } from 'reactstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const OurMission = ({
    data = {}
}) => {
    const { heading='',title='', description='', logoImage='',leftImage='' } = data
    return (
            <div className='container'>
                <div>
                    <div className='our-mission-heading-v2'>{heading}</div>
                </div>
                <div>
                    <div className='divider-line-full-width-our-mission'></div>
                </div>
                <Row className='row'>
                    <Col xs={12} md={4} lg={5} xl={5}>
                        <h2 className='our-mission-heading'>{title}</h2>
                        {logoImage && <div className="our__mission_left_img_area">
                            <LazyLoadImage src={logoImage} alt={title || 'Avibra logo'} className="img-fluid" effect="opacity"/>
                        </div>}
                    </Col>
                    <Col xs={12} md={8} lg={7} xl={7}>
                        <div className="our-mission-description"  dangerouslySetInnerHTML={{__html: (description)}} />
                    </Col>
                </Row>
            </div>
            )
};

export default OurMission;





