
import '../styles/index.css'
import Routes from './routes'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { createRoot } from 'react-dom/client'
import * as React from 'react';
//import * as ReactDOM from 'react-dom';

import { GetEnvVar } from './utils/AppConfig';
const NV = GetEnvVar("NODE_ENV")
const isProd =  NV && NV.trim() === 'production';
const isPreProd = NV && NV.trim() === 'preprod';
if (isProd || isPreProd) {
    console.log = () => {};
    console.debug = () => {};
    console.info = () => {};
    console.warn = () => {};
}

createRoot(document.getElementById('view')).render(
    <Routes />
)

// const avibra_web = {
//     components: {
//         Routes:Routes
// 		//custom component can be added as a property eg. componentName : require("./dataGrid.js")
//     }
// }

// /**
//  *3rd party libraries
//  *@namespace avibra_web.external
//  */
// avibra_web.external = {
//     react: React,
//     reactDom: ReactDOM,
//     reactDomClient: createRoot
// }

// export default avibra_web // can be imported using import statement

// window.avibra_web = avibra_web



