import React from 'react'
import "./AvibraCare.css"

const AvibraCare = (props) => {
    const { content = {} } = props
    const { heading = '', imageWeb, imageMobile, data = [] } = content

    return (
        <div className='container'>
            <div className='avibra-care-container container'>
                <div className='avibra-care-header-wrp'>
                    <div className='avibra-care-heading' dangerouslySetInnerHTML={{ __html: (heading) }} />
                    <div className='avibra-care-cards'>
                        {data && data.length > 0 && data.map((item, index) => {
                            return <div className='avibra-care-card' key={index}>
                                <div className='avibra-care-card-content'>
                                    <div className='avibra-care-card-title'>{item.title}</div>
                                    <div className='avibra-care-card-desc'>{item.desc}</div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
                {/* <div className='avibra-care-bottom-img-wrp avibra-care-bottom-img-desktop'>
                    <img src={imageWeb} />
                </div>
                <div className='avibra-care-bottom-img-wrp avibra-care-bottom-img-mobile'>
                    <img src={imageMobile} />
                </div> */}

            </div>
        </div>

    )
}

export default AvibraCare