import React from 'react'
import "./FooterSection.css"

const FooterSection = ({slideName='',slideNo=''}) => {
    if(!slideName && !slideNo)
        return null;
    return (
            <div className="footer__main">
                <div className="left__side__footer">
                    <h6>{slideName|| ''}</h6>
                </div>
                <div className="right__side__footer">
                    <h6>{slideNo || ''}</h6>
                </div>
            </div>
            )
}
export default FooterSection