import React, { useState, useEffect, useCallback } from 'react'
import {
	Container,
	Row,
	Col,Form, FormGroup, Label, Input,Button,Spinner
} from 'reactstrap';
import Header from './../../../components/NewDesignV2/Common/Header/Header';
import Footer from './../../../components/NewDesignV2/Common/Footer/Footer';
import SeoMetaTags from '../../../components/NewDesignV2/Common/SeoMetaTags/SeoMetaTags';
import './../../../../styles/NewDesignV2/main-page.css'
import './../../../../styles/NewDesignV2/main-page-responsive.css';
import NewDesignV2LandingPageData from "./../../../../constant/NewDesignV2LandingPageData.json";
import NewDesignPressPage from "./../../../../constant/NewDesignPressPage.json";

import { connect } from 'react-redux';
import { getFeparamsFilteredApi } from '../../../api/policyDocumentApi';
import { feparamsAction} from '../../../actions/feparamsAction';
import './Press.css';

import { setLogEvent,setAmplitudeInit } from '../../../utils/helpers';
import PressReleases from '../../../components/NewDesignV2/Common/PressReleases/PressReleases';
import InTheNews from '../../../components/NewDesignV2/Common/InTheNews/InTheNews';
const Press = (props) => {
	const [isOpen, setIsOpen] = useState(false); 
    const [loader, setLoader] = useState(false)
	//const [selectedMyHabitsIndex, setSelectedMyHabitsIndex] = useState(0)
	const [stickyHeaderClass, setStickyHeaderClass] = useState('row topbar');
    const toggleSticky = useCallback(
        () => {

            const scrollY = window.scrollY ? window.scrollY : document.body.scrollTop
			const scrollHeight = (window.innerWidth <= 768) ? 10 : 100
			scrollY >= scrollHeight ? 
				setStickyHeaderClass(`row topbar fixed-header ${(window.innerWidth <= 768) ? '' : 'animated fadeInDown'}`)
                :
                setStickyHeaderClass("row topbar")
        },
        []
    );

	useEffect(()=>{
		window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
		setLoader(true)
		setTimeout(() => {
		  setLoader(false)	
		}, 300)
	},[])
    useEffect(() => {
        document.getElementById("main-wrapper").focus();
		const {appConfig={}}=props
		if(!Object.keys(appConfig).length) {
        getFeparamsFilteredApi().then(res => {
            const { data = {} } = res;
            props.feparamsAction(data);
            let { amplitudeKey = "" } = data;
            setAmplitudeInit(amplitudeKey);
            setLogEvent("News Page loaded", {
                currentUrl: window.location.href,
            })
        }).catch(err => {
            console.log(err);
        });
      }else setLogEvent("News Page loaded", {
        currentUrl: window.location.href,
    })

		const handleScroll = () => {
            toggleSticky();
        };

        if (window.addEventListener) {
            window.addEventListener("scroll", handleScroll, { capture: true });
        } else {
            window.attachEvent("onscroll", handleScroll);
        }

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
	}, [])

	const toggle = () => setIsOpen(!isOpen);
	

    const { mainSection={},contactSection={}, footerSection={}, seoMetaData={} } = NewDesignV2LandingPageData
	const { inTheNewsData={}, ourPressStories={}, pressContactUs={} } = NewDesignPressPage
    
    const { download_href } = mainSection.data.headerSection;
	const { mainPageAppDownLoadLink = download_href } = props.appConfig;

	return (<>{loader?<div style={{ textAlign: 'center', marginTop: '20px'}}> 
	    <Spinner style={{ width: '2rem', height: '2rem',color:'#787878',marginBottom:'25px' }} /></div>:
		<Container fluid className="main__container " id="main-wrapper">
			<SeoMetaTags seoData={seoMetaData.pressPage} />
            <Row className={`topbar ${stickyHeaderClass}`}>
                <Col className="p-0">
                    <Header toggle={toggle} isOpen={isOpen} content={mainSection.data.headerSection} downloadLink={mainPageAppDownLoadLink} />
                </Col>
            </Row>
			<Row className="press_page_wrap">
				<Col className="p-0">
					<InTheNews inTheNewsData={inTheNewsData} />
				</Col>
			</Row>
			<Row>
				<Col className="p-0">
					<PressReleases pageData={ourPressStories} />
				</Col>
			</Row>

			<Row className="press_contact_wrap">
				<Col className="p-0">
				<Container className="press_contact_area">
					<Row className="press_contact_container">
						<Col sm="12" md="12" lg="6" xl="6" >
							<div className='press_contact_heading'>{pressContactUs.title}</div>
						</Col>
						<Col sm="12" md="12" lg="6" xl="6" className="press_contact_value">
							<a href={`mailto:${pressContactUs.email}`} >{pressContactUs.email}</a>
						</Col>
					</Row>
				</Container>
				</Col>
			</Row>
			<Row>
                <Col className="p-0">
                    <Footer content={footerSection} />
                </Col>
            </Row>
        </Container>
        }</>
	);
}

const mapStateToProps = state => {
    const { appConfig } = state.feparamsReducer;
    return { appConfig };
}
export default connect(mapStateToProps, { feparamsAction })(Press);