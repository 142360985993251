import React, { useState, useEffect, useCallback } from 'react'
import {
    Container,
    Row,
    Col,
    Spinner
} from 'reactstrap';
import { connect } from 'react-redux';
import { setLogEvent, setAmplitudeInit } from '../../../utils/helpers';
import Header from '../../../components/NewDesignV2/Common/Header/Header';
import Footer from '../../../components/NewDesignV2/Common/Footer/Footer';
import NewDesignV2LandingPageData from "../../../../constant/NewDesignV2LandingPageData.json";
import NewDesignV2CaseStudy from "../../../../constant/NewDesignV2CaseStudy.json";
import '../../../../styles/NewDesignV2/main-page.css'
import '../../../../styles/NewDesignV2/main-page-responsive.css';
import "./CaseStudies.css"
import { getFeparamsFilteredApi } from '../../../api/policyDocumentApi';
import { feparamsAction,UpdateFeparamsInfo,getFeparamsSelectedApi } from '../../../actions/feparamsAction';
import SeoMetaTags from '../../../components/NewDesign/Common/SeoMetaTags/SeoMetaTags';
import CaseStudyHeaderSection from '../../../components/NewDesignV2/CaseStudyHeaderSection/CaseStudyHeaderSection';
import CustomerStories from '../../../components/NewDesignV2/CustomerStories/CustomerStories';
import CustomerStoriesGrid from '../../../components/NewDesignV2/CustomerStoriesGrid/CustomerStoriesGrid';

const CaseStudy = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [loader, setLoader] = useState(false)
    const [stickyHeaderClass, setStickyHeaderClass] = useState('row topbar');
    const toggleSticky = useCallback(
        () => {

            const scrollY = window.scrollY ? window.scrollY : document.body.scrollTop
            const scrollHeight = (window.innerWidth <= 768) ? 1 : 100
            scrollY >= scrollHeight ?
                setStickyHeaderClass(`row topbar fixed-header ${(window.innerWidth <= 768) ? '' : 'animated fadeInDown'}`)
                :
                setStickyHeaderClass("row topbar")
        },
        []
    );

    useEffect(() => {
        window.scrollTo(0, 0);
        setLoader(true)
        setTimeout(() => {
            setLoader(false)
        }, 50)
    }, [])

   useEffect(()=>{
     if(!props?.mainSiteCaseStudies)
        getFeparamsSelectedApi(['mainSiteCaseStudies']).then(res => {
            const { data = {} } = res;
            props.UpdateFeparamsInfo({ prop:'mainSiteCaseStudies', value: data.mainSiteCaseStudies });
        })
        .catch(err => {
                console.log(err);
        });
   },[])

    useEffect(() => {
        const { appConfig = {} } = props
        if (!Object.keys(appConfig).length) {
            getFeparamsFilteredApi().then(res => {
                const { data = {} } = res;
                props.feparamsAction(data);
                let { amplitudeKey = "" } = data;
                setAmplitudeInit(amplitudeKey);
                setLogEvent("Case Studies Page loaded", {
                    currentUrl: window.location.href,
                })
            })
                .catch(err => {
                    console.log(err);
                });
        } else setLogEvent("Case Studies Page loaded", {
            currentUrl: window.location.href,
        })
        const handleScroll = () => {
            toggleSticky();
        };

        if (window.addEventListener) {
            window.addEventListener("scroll", handleScroll, { capture: true });
        } else {
            window.attachEvent("onscroll", handleScroll);
        }

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [])

    const toggle = () => setIsOpen(!isOpen);
    const { mainSection, footerSection,seoMetaData = {}} = NewDesignV2LandingPageData
    const { caseStudyHeaderSectionData = {}, lovedBy = {},customerStoriesGridData={} } = NewDesignV2CaseStudy
    const { download_href } = mainSection.data.headerSection;

    const { mainPageAppDownLoadLink = download_href } = props.appConfig;
    // const {mainSiteCaseStudies}=props
    let mainSiteCaseStudies = props?.mainSiteCaseStudies  ? JSON.parse(unescape(props.mainSiteCaseStudies )) : {};
    console.log('mainSiteCaseStudies',mainSiteCaseStudies)
    return (<> {loader  || Object.keys(mainSiteCaseStudies).length==0? <div style={{ textAlign: 'center', marginTop: '20px' }}> <Spinner style={{ width: '2rem', height: '2rem', color: '#787878', marginBottom: '25px' }} /></div> : <Container fluid className="main__container " id="main-wrapper">
        <SeoMetaTags seoData={seoMetaData.homePage} />
        <Row className={`header-menu-topbar-v2 light-purple-navbar topbar ${stickyHeaderClass}`}>
            <Col className="p-0">
                <Header toggle={toggle} isOpen={isOpen} content={mainSection.data.headerSection} downloadLink={mainPageAppDownLoadLink} extraClassName="light-purple-navbar" />
            </Col>
        </Row>
        <Row>
            <Col className="section-area p-0 home-page-header-section-v3 light-purple-bg">
                <CaseStudyHeaderSection content={caseStudyHeaderSectionData} appLinkUrl={'/book-a-demo'} target="" />
            </Col>
        </Row>

        <Row>
            <Col className="case-study-loved-by-section light-purple-bg">
                <div className="loved-by case-study-loved-by">
                    <div className="container p-0">
                        {lovedBy && <div className='header-partner-logo-content'>
                            <div className='header-partner-logo-items'>
                                {lovedBy.data?.length &&
                                    lovedBy.data.map((item, index) =>
                                        <img className={`header-partner-logo-item item-logo-${index}`} src={item.image} />)}
                            </div>
                        </div>}
                    </div>
                </div>
            </Col>
        </Row>

        <Row>
          <Col className='p-0 light-purple-bg'>
             <CustomerStories content={mainSiteCaseStudies.customerStoriesGridData} />
          </Col>
        </Row>
        <Row>
          <Col className='p-0 light-purple-bg'>
             <CustomerStoriesGrid content={mainSiteCaseStudies.customerStoriesGridData} />
          </Col>
        </Row>
        <Row>
            <Col className="p-0">
                <Footer content={footerSection} />
            </Col>
        </Row>
    </Container>}

    </>


    );
}
const mapStateToProps = state => {
    const { appConfig,mainSiteCaseStudies } = state.feparamsReducer;
    return { appConfig,mainSiteCaseStudies };
}

export default connect(mapStateToProps, { feparamsAction,UpdateFeparamsInfo,getFeparamsSelectedApi })(CaseStudy);
