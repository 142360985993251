import React ,{useEffect , useRef, useState } from 'react'

// import 'swiper/css/swiper.css';
import "./NewMembershipGuide.css"
import StartMember from "../../components/NewMembershipGuide/StartMember"
import MembershipDetails from "../../components/NewMembershipGuide/MembershipDetails"
import WelcomMember from '../../components/NewMembershipGuide/WelcomMember';
import RenewalProcess from '../../components/NewMembershipGuide/RenewalProcess';
import Livemembership from '../../components/NewMembershipGuide/Livemembership';
import Comparison from '../../components/NewMembershipGuide/Comparison';
import MemberBenefits from '../../components/NewMembershipGuide/MemberBenefits';
import Beneficiary from '../../components/NewMembershipGuide/Beneficiary';
import StaticContentSlide from '../../components/NewMembershipGuide/StaticContentSlide';
import BenefitsStore from '../../components/NewMembershipGuide/BenefitsStore';
import CardMain1 from '../../components/NewMembershipGuide/CardMain1';
import CardMain2 from '../../components/NewMembershipGuide/CardMain2';
import ADDBD from '../../components/NewMembershipGuide/AD&DBenefits';
import BenefitsHighlight from '../../components/NewMembershipGuide/BenefitsHighlight';
import SampleSaving from '../../components/NewMembershipGuide/SampleSaving';
import DobsDrillDownDetails from '../../components/NewMembershipGuide/DobsDrillDownDetails';
import NewMembershipGuideData from "../../../constant/NewMembershipGuideData.json"
import ClaimInformation from '../../components/NewMembershipGuide/ClaimInformation';
import MedicalInsurance from '../../components/NewMembershipGuide/MedicalInsurance';
import Sources from '../../components/NewMembershipGuide/Sources';
import html2pdf from "html2pdf.js";
//import jsPDF from 'jspdf';
import moment from "moment";
import { isMobile } from '../../utils/helpers';
import _ from 'lodash';
//import PDFMerger from 'pdf-merger-js/browser';
//import { createPDF,pdfArrayToBlob, mergePDF } from "pdf-actions";
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
// import PDFDocument from 'pdfkit';

// window.PDFDocument = PDFDocument

// import 'swiper/swiper.scss';

function NewMembershipGuide(props) {
    const {
        policy,
        beneficiary = [],
        wellbeing = {},
        plusPlanDetails,
        plusplanHeader,
        userPlans,
        userInfo,
        planMeta,
        insurance,
        waitingPeriod = "",
        insuranceDisclaimer,
        accidentalInsurance,
        membershipState = "Inactive",
        insuranceCoverage,
        isDollarStore,
        dynamicPdfFileName = "",
        lifeadndInsuranceProvider="",
        dollarCludData={}
      }  = props;
    let {
        adnddefaultfacevalue,
        reactivationdate,
        issuedate,
        products,
    } = accidentalInsurance;

    const [getLoaderForPDF, setLoaderForPDF] = useState(false)
    const [downloaded, setDownloaded] = useState(false)
    const { startMember={}, memberDetails={}, welcomeMember={}, renewalProcess={}, liveVibrant={}, comparision = {}, staticContentSlideData=[], beneficiaryContingent={}, beneficiaryPrimary={}, activeBenefits={}, samplesaving={}, sourcesData={}, footNote="" } = NewMembershipGuideData
    // useEffect(() => {
    //     document.getElementsByTagName('body')[0].setAttribute('class','membership-guide')
    // }, [])
    console.log('dollarCludData===>', dollarCludData,Object.keys(dollarCludData).length>0 && dollarCludData?.heading,"userInfo",userInfo)
    const {addonsPlanList = {}, purchasedDobsPlan={}, profileid='' } = props;
    //let { reactivationdate, issuedate } = accidentalInsurance
    let { isplus = "", isdollarstore } = userInfo;
    let lifeValue = 0;
    let adndValue = 0;
    let ameValue = 0;
    let ciValue = 0;
    const newInsurance = insuranceCoverage && insuranceCoverage.map((data) => {
        if(data.productname === 'life')
        {
          data.productname = 'Life Insurance'
        
        }
        else if(data.productname === 'adnd')
        {
          data.productname = 'AD&D'
        
        }
        else if(data.productname === 'ame')
        {
          data.productname = 'Accidental Medical Expense'
        
        }
        return data
      })
    
      const newPolicy = products && products.map((data) => {
        
        if(data.productname === 'life')
        {
          lifeValue = data.earnedfacevalue + data.planearnedfacevalue + data.planinitialearnedfacevalue;
        }
        else if(data.productname === 'adnd')
        {
          adndValue = data.earnedfacevalue + data.planearnedfacevalue + data.planinitialearnedfacevalue;
        }
        else if(data.productname === 'ame')
        {
          ameValue = data.earnedfacevalue + data.planearnedfacevalue + data.planinitialearnedfacevalue;
        }
        else if(data.productname === 'ci')
        {
          ciValue = data.earnedfacevalue + data.planearnedfacevalue + data.planinitialearnedfacevalue;
        }
        return data
      })
      let rightData = [
        {
          title: "Name",
          desc: policy?.profilenam ? policy.profilenam : '',
        },
        {
          title: "Date of Birth:",
          desc: policy?.dob ? moment(policy.dob).format("MMM D, YYYY") : '',
        },
        {
          title: "Membership ID:",
          desc: userInfo?.customerid,
        },
        {
          title: (membershipState && membershipState.toLowerCase() == 'active' && !policy?.inactivedate) ? "Membership Activation Date:" : "Membership Inactivation Date:",
          desc: (membershipState && membershipState.toLowerCase() == 'active' && !policy?.inactivedate) ?  moment((reactivationdate !== null ? reactivationdate : issuedate)).format("MMM D, YYYY") : policy?.inactivedate ? moment((policy?.inactivedate)).format("MMM D, YYYY") : '',
        },
        {
          title: "Life Insurance Benefit Amount:",
          desc: `$${policy?.earnedamount}`,
        },
        {
          title: "Membership Plan:",
          desc: `${isplus ? Object.keys(dollarCludData).length>0 && dollarCludData?.heading  : "Core"}`,
        },
        {
          title: "Membership Status:",
          desc: `${membershipState}`,
        },
      ]
      // if(ameValue){
      //   let ameElement = {
      //     title: "Accident Medical Benefit Amount:",
      //     desc: `$${ameValue}`,
      //   }
      //   rightData.splice((rightData.length-2), 0, ameElement);
      // }
    
      if (adndValue) {
        rightData = [
          {
            title: "Name",
            desc: policy?.profilenam ? policy.profilenam : '',
          },
          {
            title: "Date of Birth:",
            desc: policy?.dob ? moment(policy.dob).format("MMM D, YYYY") : '',
          },
          {
            title: "Membership ID:",
            desc: userInfo?.customerid,
          },
          {
            title: (membershipState && membershipState.toLowerCase() == 'active' && !policy?.inactivedate) ? "Membership Activation Date:" : "Membership Inactivation Date:",
            desc: (membershipState && membershipState.toLowerCase() == 'active' && !policy?.inactivedate) ?  moment((reactivationdate !== null ? reactivationdate : issuedate)).format("MMM D, YYYY") : policy?.inactivedate ? moment((policy?.inactivedate)).format("MMM D, YYYY") : '',
          },
          {
            title: "Life Insurance Benefit Amount:",
            desc: `$${lifeValue}`,
          },
          {
            title: "AD&D Insurance Benefit Amount:",
            desc: `$${adndValue}`,
          },
          // {
          //   title: "Accident Medical Benefit Amount:",
          //   desc: `$${ameValue}`,
          // },
          {
            title: "Membership Plan:",
            desc: `${isplus ? Object.keys(dollarCludData).length>0 && dollarCludData?.heading : "Core"}`,
          },
          {
            title: "Membership Status:",
            desc: `${membershipState}`,
          }
        ]
        // if(ciValue){
        //   let ciElement = {
        //     title: "Critical Illness Benefit Amount:",
        //     desc: `$${ciValue}`,
        //   }
        //   rightData.splice((rightData.length-2), 0, ciElement);
        // }
      }
    // console.log("purchaed dollar plan", purchasedDobsPlan)
    // console.log("addons plan list===>", addonsPlanList)
    // const {addonsPlanList = {},profileid=''} = props;
    console.log("policyData", policy)
    console.log("userInfoData", userInfo)
    let pageCountValue = 0
    let plansFilterList = [];
    let drillDownDetailPageUrls = [];
    let drillDownDetailData= [];
    let drillDownCount = 0;
    let tempPlanList = [];
    let firstThreePlans = [];
    let lastFourPlans = [];
    let filteredTempPlanList =[], filteredTempPlanList2=[], filteredTempPlanList3=[]
    if(addonsPlanList && addonsPlanList.plans){
        addonsPlanList.plans.map((planList,index)=>{
            console.log("drillDownDetailPageUrls ===>",planList.heading,"-->", planList.ignoreOnPayload,"-->", planList.subscribedPlanLevel, "-->", index,"-->", planList.requestapi+profileid+'/'+planList.name)
            if(!(planList.ignoreOnPayload)){
                plansFilterList.push(planList)
            }
            if(planList.requestapi && planList.name && profileid!=''){
                let arrIndex = drillDownCount++;
                drillDownDetailPageUrls[arrIndex] = planList.requestapi+profileid+'/'+planList.name;
                drillDownDetailData[arrIndex] = planList;
            }
        })
        tempPlanList = plansFilterList; //addonsPlanList.plans;
        if(tempPlanList && tempPlanList.length >= 3){
            firstThreePlans = tempPlanList.slice(0, 3);
        }
        filteredTempPlanList = _.difference(tempPlanList, firstThreePlans);
        console.log("plansFilterList ===>", tempPlanList, firstThreePlans, filteredTempPlanList)
        /*if (filteredTempPlanList && filteredTempPlanList.length >= 4) {
            lastFourPlans = filteredTempPlanList.slice((filteredTempPlanList.length - 4), filteredTempPlanList.length)
        }
        filteredTempPlanList2 = _.difference(filteredTempPlanList, lastFourPlans);*/
        filteredTempPlanList2 = filteredTempPlanList;
        if(filteredTempPlanList2 && filteredTempPlanList2.length > 6 ) { 
            let perChunk = 6 // items per chunk
            filteredTempPlanList3 = filteredTempPlanList2.reduce((resultArray, item, index) => { 
            const chunkIndex = Math.floor(index/perChunk)

            if(!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
            }
            resultArray[chunkIndex].push(item)
            return resultArray
            }, [])
        } else {
            filteredTempPlanList3[0] = filteredTempPlanList2;
        }
        
    }

    //purchasedDobsPlan
    let filteredDobsList2 = [];
    if(purchasedDobsPlan) {
        if(purchasedDobsPlan && purchasedDobsPlan.length > 6 ) {
            let perChunk = 6 // items per chunk
            filteredDobsList2 = purchasedDobsPlan.reduce((resultArray, item, index) => { 
            const chunkIndex = Math.floor(index/perChunk)

            if(!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
            }
            resultArray[chunkIndex].push(item)
            return resultArray
            }, [])
        } else {
            filteredDobsList2[0] = purchasedDobsPlan;
        }
    }
    
    let beneficiaryPrimaryArr = []; // beneficiary table data
    let beneficiaryContingentArr = [];
    if(beneficiary.length){
      beneficiaryPrimaryArr = beneficiary.filter((e)=>e.beneficiaryType == 'primary');
      beneficiaryContingentArr = beneficiary.filter((e)=>e.beneficiaryType == 'contingent');
      if(!beneficiaryContingentArr.length){
        beneficiaryPrimaryArr = beneficiary;
      }
    }

    // console.log("NewMembershipGuide tempPlanList ===>", tempPlanList, firstThreePlans, lastFourPlans, filteredTempPlanList3)
    let sourcesData1=[];
    if(sourcesData.sourcesData){
        if(sourcesData.sourcesData && sourcesData.sourcesData.length > 6 ) {
            let perChunk = 6 // items per chunk
            sourcesData1 = sourcesData.sourcesData.reduce((resultArray, item, index) => { 
            const chunkIndex = Math.floor(index/perChunk)

            if(!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
            }
            resultArray[chunkIndex].push(item)
            return resultArray
            }, [])
        } else {
            sourcesData1[0] = sourcesData.sourcesData;
        }
    }
    const onClickSave = async () => {
        window.scroll(0, 0)
        setLoaderForPDF(true)

        let quotes = document.getElementById("print-pdf")
        // let breakSec = document.getElementById("break")
        // let membership2 = document.getElementById("membership2")
        // let sidebar = document.getElementById("membership1")
        let screenSize = window.matchMedia("(max-width: 798)")
        // let logoPrint = document.getElementById("logo-print")
        // logoPrint.style.display = "block"
        // quotes.style.boxShadow = "none"
        // quotes.style.width = "100%"
        // membershipInfo.style.display = "none"
        // membership2.prepend(sidebar)
        // let shareAndCare = document.getElementById("share-and-care")
        // let mainSide = document.getElementById("main-side")
        // let myRiskChart = document.getElementById("my-risk-chart")
        // let wellBeingChart = document.getElementById("well-being-chart")
        // shareAndCare.style.boxShadow = "none"
        // myRiskChart.style.boxShadow = "none"
        // wellBeingChart.style.boxShadow = "none"
        quotes.style.lineHeight = screenSize.matches ? "unset" : "21.2999999999px"
        // console.log(quotes, 'quotes')
        // setLoadingPdf(false)
        // return
        var opt = {
          margin: 1, //screenSize.matches ? [10, 10] : [15,15],
          filename: `AvibraPolicyDocument-${moment().valueOf()}`,
          image: { type: "jpeg", quality: 0.9 },
          html2canvas: { scale: 1, letterRendering: true, dpi: 96, useCORS: true, logging: true, allowTaint : false },
        //   jsPDF:        { unit: 'pt', format: 'letter', orientation: 'portrait' },
        jsPDF: {unit: 'pt', format: 'a4', orientation: 'landscape'},
          pagebreak: { mode: ['avoid-all', 'css'] }
        }
        /*var worker = new html2pdf()
        await worker
          .set(opt)
          .from(quotes)
          .save()
          .then(_ => {
            // membershipInfo.style.display = "none"
            // shareAndCare.style.boxShadow = "0 2px 6px 0 rgba(57, 73, 96, 0.2)"
            // myRiskChart.style.boxShadow = "0 2px 6px 0 rgba(57, 73, 96, 0.2)"
            // wellBeingChart.style.boxShadow = "0 2px 6px 0 rgba(57, 73, 96, 0.2)"
            // membershipInfo.style.boxShadow = "0 2px 6px 0 rgba(57, 73, 96, 0.2)"
            // membershipInfo.style.display= "none"
            // membershipInfo.style.maxWidth = "340px"
            //quotes.style.lineHeight = "unset"
            // let elements = membership2.getElementsByTagName("section")
            quotes.style.lineHeight = "unset"
            // insertAfter(elements[0], mainSide)
            setLoaderForPDF(false)
            setDownloaded(true)
          })*/
            let domPages = []
            let indexOfLastPage = 0;

            document.querySelectorAll('.slide__pdf').forEach(function(obj) {
                
                const pattern = /(Dollar Benefits Store)\d+/g
                if(pattern.test(obj.textContent) && indexOfLastPage === 0){                    
                    indexOfLastPage = obj.textContent.match(pattern)[0].match(/\d+/g)[0];
                }
                if(indexOfLastPage === 0){
                    domPages.push('<div class="slide__pdf">'+ obj.innerHTML.replace(/(.+)/, (_, s) => `<div>${s}</div>`)+ '</div>');
                }
                
            });

            let worker = html2pdf().set(opt);
            domPages.forEach((page, index) => {
                /*if(index > 64){ 
                    //return true;
                } else*/ 
                {
                worker = worker
                    .from(page)
                    .toContainer(index + 1)
                    .toCanvas()
                    .toPdf()
                    .get('pdf')
                    .then((pdf) => {
                    if (index < domPages.length - 1) {
                        pdf.addPage();
                    }
                });
                }
            });         
            //return worker.save().then(_ => { setLoaderForPDF(false); })        
            
        return   worker.outputPdf('arraybuffer').then(async (result) => {
                let documents =[result]
                //console.log("dynamicPdfFileName Before If ==>", dynamicPdfFileName)
                if(dynamicPdfFileName){
                    //console.log("dynamicPdfFileName If ==>", `https://avibra.com/${dynamicPdfFileName}`)
                //const existingPdfBytes = await fetch(`https://api.allorigins.win/raw?url=https://lifexwebuiprod.s3.amazonaws.com/${dynamicPdfFileName}`).then(res => res.arrayBuffer()).catch((e)=>{})
                const existingPdfBytes = await fetch(`https://avibra.com/pdfs/${dynamicPdfFileName}`,
                {
                  mode: 'cors',
                  method: 'GET',
                  headers: {'Accept':'application/pdf'}
                }).then(res => res.arrayBuffer()).catch((e)=>{
                    console.log("PDF  arraybuffer error===",e)
                });
                documents =[result,existingPdfBytes]
                }

                const mergedPdf = await PDFDocument.create();

                for (let document of documents) {
                    document = await PDFDocument.load(document);
            
                    const copiedPages = await mergedPdf.copyPages(document, document.getPageIndices());
                    copiedPages.forEach((page) => mergedPdf.addPage(page));    
                }
                
                   const pdfBytes = await mergedPdf.save();

                   var blob = new Blob([pdfBytes], {type: "application/pdf"});
                   var link = document.createElement('a');
                   link.href = window.URL.createObjectURL(blob);
                   var fileName = `AvibraPolicyDocument-${Date.now()}`;
                   link.download = fileName;
                   link.click();
                   setLoaderForPDF(false)
                   setDownloaded(true)
                
            }).catch((e)=>{
                console.log("result error===",e)
            });
            // return worker.save().then(_ => {
            //     setLoaderForPDF(false)
            // });
      }
    return (
        <>
        <div className="new__membership__guide">
            
            <section id="print-pdf">
            <section id="membership2"></section>
          
                <div className="slide__pdf" id="membership-info">
                    <StartMember pageData = {startMember}/>
                </div>
                <div className="slide__pdf" id="main-side">
                    <MembershipDetails  pageData ={memberDetails} rightPolicyData={rightData} slideNo={2}/>
                </div>
                <div className="slide__pdf" id="share-and-care">
                    <WelcomMember pageData = {welcomeMember} slideNo={3}/>
                </div>
                <div className="slide__pdf" id="share-and-care">
                    <RenewalProcess pageData = {renewalProcess} slideNo={4}/>
                </div>
                <div className="slide__pdf" id="my-risk-chart">
                    <Livemembership pageData = {liveVibrant} slideNo={5}/>
                </div>
                <div className="slide__pdf" id="well-being-chart">
                    <Comparison pageData ={comparision} plusPlanDetails={plusPlanDetails} userPlans={userPlans} userInfo={userInfo} slideNo={6}/> 
                </div>
                {staticContentSlideData && staticContentSlideData.length>0  ? 
                staticContentSlideData.map((slideData, index) => {
                    return (
                    <div className="slide__pdf" id={`static-content-${(index+1)}`}>
                        <StaticContentSlide pageData ={slideData} lifeadndInsuranceProvider={lifeadndInsuranceProvider}  slideNo={(7+index)} policyData={policy}/> 
                    </div> 
                    )
                }) : null }
                {filteredDobsList2 && filteredDobsList2.length ? 
                filteredDobsList2.map((activeList, index) => {
                    return (
                    <div className="slide__pdf" id="logo-print-old">
                        <MemberBenefits pageData = {activeBenefits} benefitsList={activeList} slideNo={(10+index)}/> 
                    </div>
                    )
                }) : null }
                <div className="slide__pdf">
                    <Beneficiary  pageData = {beneficiaryPrimary} primary = {beneficiaryPrimaryArr} slideNo={(10+filteredDobsList2.length)}/> 
                </div> 
                {beneficiaryContingentArr.length>0 &&(
                    <div className="slide__pdf" id="membership1">
                        <Beneficiary pageData = {beneficiaryContingent} contingent = {beneficiaryContingentArr} slideNo={(11+filteredDobsList2.length)}/> 
                    </div> 
                )}
                {firstThreePlans && firstThreePlans.length <= 3? 
                <div className="slide__pdf">
                    <BenefitsStore addonsPlanList={firstThreePlans} title="Dollar Benefits Store" desc="Welcome to our Dollar Benefits Store, where you can buy peace of mind for just $1/week. Purchase one benefit or several—just pay $1/week for each one you choose." slideNo={((beneficiaryContingentArr.length>0?12:11)+filteredDobsList2.length)} footNote={footNote}/> 
                </div> : null}
                {filteredTempPlanList3 && filteredTempPlanList3.length ?
                    filteredTempPlanList3.map((planList,index)=>{
                        // console.log("filteredTempPlanList3 planList==>", planList)
                        return(<div className="slide__pdf" key={`slide_cardmain_${index}`}>
                            <CardMain1  addonsPlanList={planList} footerText={"Dollar Benefits Store"} slideNo={((beneficiaryContingentArr.length>0?13:12)+filteredDobsList2.length+index)}/> 
                        </div>) 
                    })
                : null}
                {drillDownDetailPageUrls && drillDownDetailPageUrls.length ? 
                    drillDownDetailPageUrls.map((requestUrl,index)=>{
                        pageCountValue =  ((beneficiaryContingentArr.length>0?13:12)+filteredDobsList2.length+filteredTempPlanList3.length)
                        if(index>0) pageCountValue = pageCountValue + (5*index);
                        return <DobsDrillDownDetails listItemData={drillDownDetailData[index]} requestUrl={requestUrl+'?index='+index} activeBenefits={activeBenefits} samplesaving={samplesaving} slideNo={pageCountValue}/> 
                    })
                : null}
                {/*sourcesData1 && sourcesData1.length ?
                sourcesData1.map((activeList, index) => {
                    let countValue = (pageCountValue+5+index)
                    return(<div className="slide__pdf">
                        <Sources pageData={sourcesData} sourcesDataPage={activeList} footerText={"Sources"} slideNo={countValue} /> 
                </div> 
                )})
                 : null
                  */}
                  </section>
                  <div className={isMobile() ? 'mobile-browser' : 'desktop-browser'}>{
                      getLoaderForPDF ? (
                        <div className="policy-document">
                            <span>
                                <i className="fas fa-circle-notch fa-spin fa-icon"></i>
                            </span>
                        </div>
                      ) : downloaded ? (
                        <div className="policy-document">
                            <span>
                                <i className="fas fa-check fa-icon"></i>
                            </span>
                        </div>
                      ) : (
                        <div className="policy-document" onClick={onClickSave}>
                            <span>
                                <i className="fas fa-download fa-icon"></i>
                            </span>
                        </div>
                      )
                  }</div>
        </div>
        </>
    )
}

export default NewMembershipGuide
