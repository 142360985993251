import React from 'react';
import { Container,Row,Col } from 'reactstrap';
import "./Investors.css";

const Investors = ({ pageData = {} }) => {

    const { logos = [], heading = "" } = pageData;
    
    return ( <div className="investors-logo-wrp">
                <div className='container'>
                    <div className='world-class-investors-container'>
                        <div className='world-class-investors-heading'>
                            {heading}
                        </div>
                        <div className='row justify-content-md-center justify-content-xl-center'>
                            {logos.map((logo, i) => (
                                <div className='col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3'>
                                    <div className='invester-logo-area'>
                                        <div className='invester-logo-container'>
                                            <img src={logo} alt={`${'Investor '+(i+1)}`} key={i} className="img-fluid"/>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
};

export default Investors;
