import React,{useEffect, useState} from 'react'
import { Link } from 'react-router'
import {
    Container,
    Row,
    Col
} from 'reactstrap';
import { connect } from 'react-redux';
import { UpdateLifeInsuranceInfo } from '../../../../actions/lifeInsuranceAction';
import {setLogEvent} from '../../../../utils/helpers';
import "./Footer.css"
import whiteLogo from '../../../../../images/footer-white-log.png'
import fbPartner from '../../../../../images/fb-partner.png'
import intsaPartner from '../../../../../images/insta-partner.png'
import twitterPartner from '../../../../../images/twitter-partner.png'
import linkedIn from '../../../../../images/linkedin.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { appleAppLink, googleAppLink, fbPageLink, instaPageLink, twitterPageLink, tikTokLink, linkedInLink, privacyLink, blogLink, licensesLink, termsLink, currentYear } from '../../../../../constant/constant'
import 'react-lazy-load-image-component/src/effects/opacity.css';
import closeIcon from "../../../../../images/close_modal.png";
import seal2 from "../../../../../images/compliance-seal1.png";
import GetStartedButton from '../GetStartedButton/GetStartedButton'

const hasSectionBeenShown = localStorage.getItem('sectionShown');

const Footer = (props) => {

    const [showSection, setShowSection] = useState(false);
    let pagePath = window.location.pathname;
    let { content, hideFooterLink = '',showGetStartedButton=true,hideFooterTopTextSection=false,footerHeadingData={} }=props
    const { beforeFooterText='' } = content
    const {heading='',subHeading=''} = footerHeadingData
    let getLink = (link) => {
        return link.href.includes("http") ?
            (<a href={link.href} target="_blank">{link.title}</a>)
            :
            (<Link to={link.href}>{link.title}</Link>)
    }

    useEffect(()=>{

       console.log('hasSectionBeenShown', hasSectionBeenShown,props.fixedFooterShown)
        if (!hasSectionBeenShown && !props.fixedFooterShown && window.location.pathname=='/') {
       console.log('hasSectionBeenShown1', )
          setShowSection(true);
          localStorage.setItem('sectionShown', 'true');
        }

    },[])

    const FixedContentClickHandler =()=>{
        props.UpdateLifeInsuranceInfo({ prop:'fixedFooterShown', value: true })
        setShowSection(false)
    }
    const onBookADemoClick = ()=>{
        setLogEvent("User Clicked On Book A Demo Button");
    }
    const footerHeadingText = heading || beforeFooterText;
    return (<div className="footer-cont footer-cont-v2"><Container>
        <Row>
            <Col>
            <div className={`main-footer-container-area footer-v2 ${hideFooterTopTextSection?'hide-top-footer-area':''}`}>
            {!hideFooterTopTextSection ? <div className='main-footer-extra-text-section'>
                <div className='heading-area-v2 center'>
                {footerHeadingText && <div className="heading-v2 font-54" dangerouslySetInnerHTML={{ __html: footerHeadingText }} ></div>}
                {/* {subHeading && <div className="footer sub-heading-v2">{subHeading}</div>} */}
                {showGetStartedButton ? <GetStartedButton 
                    text="BOOK A DEMO"
                    link={'/book-a-demo'}
                    onClick={()=>onBookADemoClick()}
                    bgColor={'#774AD9'}
                /> : null}
                </div>
            </div> : null}
                <div className="footer-cont-padding-v2">
                    <div>
                        {hideFooterLink == true ? null : <div>
                            <div className="footer-cont-section-1 row">
                                <div className="col-md-12 col-lg-4 col-xl-4 p-0">
                                    <div className="left">
                                        <div className="logo-container">
                                            <img src={whiteLogo} height="38" alt="Avibra logo" />
                                        </div>
                                    </div>
                                </div>
                                {pagePath != '/disclosures' && <div className="col-md-12 col-lg-8 col-xl-8 footer-menu-area">
                                    <div className="right">
                                        <div className="nav-links">
                                            <div className="first-right-menu benefits-nav">
                                                <div className="footer-nav-heading">Platform</div>
                                                {
                                                    content.footerNav.leftProducts.map((link, index) => <span key={index}>{getLink(link)}</span>)
                                                }
                                            </div>

                                            <div className="first-right-menu benefits-nav">
                                                <div className="footer-nav-heading">Industries</div>
                                                {
                                                    content.footerNav.leftIndustries.map((link, index) => <span key={index}>{getLink(link)}</span>)
                                                }
                                            </div>
                                            <div className="first-right-menu benefits-nav">
                                                <div className="footer-nav-heading">Resources</div>
                                                {
                                                    content.footerNav.leftResources.map((link, index) => <span key={index}>{getLink(link)}</span>)
                                                }
                                            </div>
                                           
                                            <div className="first-right-menu pl-40">
                                                {
                                                    content.footerNav.leftSide.map((link, index) => <span key={index}>{getLink(link)}</span>)
                                                }
                                               
                                            </div>
                                            <div className="second-right-menu">
                                                {
                                                    content.footerNav.rightSide.map((link, index) => <span key={index}><a href={link.href} target="_blank">{link.title}</a></span>)
                                                }
                                            </div>

                                            {/* <div className="second-right-menu-mobile">
                                
                                    </div> */}
                                        </div>
                                    </div>
                                    {/* <div className="right">
                                        <div className="nav-links">
                                            <div className="second-right-menu tablet-only">
                                                {
                                                    content.footerNav.rightSide.map((link, index) => <span key={index}><a href={link.href} target="_blank">{link.title}</a></span>)
                                                }
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="social-icon-container-new mobile-social">
                                        <a href={fbPageLink} target="_blank"><LazyLoadImage src={fbPartner} className="facebook-icon" effect="opacity" /></a>
                                        <a href={instaPageLink} target="_blank"><LazyLoadImage src={intsaPartner} className="instagram-icon" effect="opacity" /></a>
                                        <a href={twitterPageLink} target="_blank"><LazyLoadImage src={twitterPartner} className="twitter-icon" effect="opacity" /></a>
                                        <a href={linkedInLink} target="_blank"><LazyLoadImage src={linkedIn} className="linkedin-icon" effect="opacity" /></a>
                                        {/* <a href={tikTokLink} target="_blank"><LazyLoadImage src={Tiktok} className="twitter-icon" effect="opacity"/></a> */}
                                    </div>
                                </div>}
                            </div>

                        </div>}
                        <div className='footer-bottom-img-wrp row'>
                        <div className='col-md-12 col-lg-4 col-xl-4 seal-img-wrp'>
                            <div className='seal1-img-wrp'>
                            <a href="https://www.bbb.org/us/nj/iselin/profile/group-benefits/avibra-inc-0221-90222248/#sealclick" target="_blank" rel="nofollow"><img src="https://seal-newjersey.bbb.org/seals/blue-seal-200-42-whitetxt-bbb-90222248.png" style={{border:0}} alt="Avibra, Inc. BBB Business Review" /></a>
                            </div>
                            <div className='seal2-img-wrp'>
                                <a href='https://www.aicpa.org/soc4so' target="_blank" >
                                <img src={seal2}/>
                                </a>
                            </div>
                            </div>
                           <div className='col-md-12 col-lg-6 col-xl-6 social-icon-wrp-desktop'>
                           <div className="social-icon-container-new-v2">
                                        <a href={fbPageLink} target="_blank"><LazyLoadImage src={fbPartner} className="facebook-icon" effect="opacity" /></a>
                                        <a href={instaPageLink} target="_blank"><LazyLoadImage src={intsaPartner} className="instagram-icon" effect="opacity" /></a>
                                        <a href={twitterPageLink} target="_blank"><LazyLoadImage src={twitterPartner} className="twitter-icon" effect="opacity" /></a>
                                        <a href={linkedInLink} target="_blank"><LazyLoadImage src={linkedIn} className="linkedin-icon" effect="opacity" /></a>
                                        {/* <a href={tikTokLink} target="_blank"><LazyLoadImage src={Tiktok} className="twitter-icon" effect="opacity"/></a> */}
                                        {/* <a href="https://www.bbb.org/us/nj/iselin/profile/group-benefits/avibra-inc-0221-90222248/#sealclick" target="_blank" rel="nofollow"><img src="https://seal-newjersey.bbb.org/seals/blue-seal-200-42-whitetxt-bbb-90222248.png" style={{border:0,width:'220px'}} alt="Avibra, Inc. BBB Business Review" /></a> */}
                            </div>
                           </div>
                          

                        </div>
                        <div className={`footer-bottom ${showSection ? 'footer-popup-section':''}`}>
                            <div className="line"></div>
                            <div className="row">
                                <div className="col-md-5 col-lg-4 col-xl-4 footer-copyrights bb-logo">
                                    <div>
                                        {content.email}
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: (content.trademarks) }}></div>
                                    <div>
                                        © {currentYear} {content.rights}
                                    </div>
                                    
                                </div>
                                <div className="col-md-7 col-lg-8 col-xl-8 p-0">
                                    {/* <div className="footer-disclaimer" id='point-one' dangerouslySetInnerHTML={{ __html: (content.disclaimer) }}>
                                        </div> */}
                                    <div className="footer-disclaimer" id='point-one'>
                                        <div style={{ fontSize: "16px" }}><sup>+</sup></div> {content.disclaimer}
                                    </div>
                                </div>
                                

                            </div>
                           
                        </div>
                    </div>
                </div>
                </div>
            </Col>
        </Row>
    </Container>
        {showSection && <div className='footer-fixed-section'>
            <div className='footer-close-icon' >
                <img src={closeIcon}  onClick={()=>FixedContentClickHandler()}/>
            </div>
           <div className='footer-fixed-content' dangerouslySetInnerHTML={{ __html: (content.fixedFooterContent) }} />
        </div>}
    </div>
    )
}


const mapStateToProps = state =>{
	const {fixedFooterShown}  = state.lifeInsuranceReducer;
	return { fixedFooterShown };
}

export default connect(mapStateToProps, {UpdateLifeInsuranceInfo})(Footer);
