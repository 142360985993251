import React from 'react'
import "./UseCases.css"

const UseCases = (props) => {

    const { content = {} } = props
    const { tagLine = '', heading = '', data = [] } = content

    return (
         <div className='container'>
         <div className='use-cases-container'>
         <div className='use-cases-heading' dangerouslySetInnerHTML={ { __html: (heading) } } />
         <div className='use-cases-desc'>{content.description}</div>

         <div className='use-cases-items'>
             {data && data.length > 0 && data.map((item, index) => {
                 return <div className={`use-cases-item item-${(index+1)} ${item.extraClassName}`} key={index}>
                    <div className='use-cases-item-content'>
                     <div className='use-cases-title'>{item.title}</div>
                     <div className='use-cases-item-desc'>{item.desc}</div>
                     {item.ctaText && <div className='use-cases-btn-wrp'>
                         <a className='use-cases-btn' href={item.ctaLink}>{item.ctaText}</a>
                         </div>}
                    </div>
                     <div className='use-cases-img-wrp'>
                         <div className='img-desktop-v2'>
                             <img src={item.image} className='img-fluid'/>
                         </div>
                         <div className='img-tablet-v2'>
                             <img src={item.imageTablet ? item.imageTablet : item.image} className='img-fluid'/>
                         </div>
                         <div className='img-mobile-v2'>
                             <img src={item.imageMobile} className='img-fluid'/>
                         </div>  
                     </div>

                 </div>
             })}

         </div>


     </div>
     </div>
    )
}

export default UseCases