/*import CryptoJS from "crypto-js";
const sk = '123AVI3RA';
const dcrpt = ( cipherText ) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, sk )
    const plainText = bytes.toString(CryptoJS.enc.Utf8)
    return plainText
}*/
const data =  {
    NODE_ENV: import.meta.env.VITE_NODE_ENV || 'development',
    API_URL: import.meta.env.VITE_API_URL || '',
    UserPoolId: import.meta.env.VITE_UserPoolId || '',
    ClientId: import.meta.env.VITE_ClientId || '',
    POOL_IDENTITY: import.meta.env.VITE_POOL_IDENTITY || '',
    PROVIDER_NAME: import.meta.env.VITE_PROVIDER_NAME || '',
    REGION: import.meta.env.VITE_REGION || '',
    REG_URL: import.meta.env.VITE_REG_URL || '',
    BUILD_FOR: import.meta.env.VITE_BUILD_FOR || '',
    MEETING_LINK: import.meta.env.VITE_MEETING_LINK || '',
    BRAZE_API_KEY: import.meta.env.VITE_BRAZE_API_KEY || '',
    BRAZE_BASE_URL: import.meta.env.VITE_BRAZE_BASE_URL || '',
    GOOGLE_CAPTCHA_KEY:import.meta.env.VITE_GOOGLE_CAPTCHA_KEY || '',
    MIXPANEL_INFO: import.meta.env.VITE_MIXPANEL_INFO || '',
    AUTH_API_URL:import.meta.env.VITE_AUTH_API_URL || '',
    AUTH_API_KEY: import.meta.env.VITE_AUTH_API_KEY || '',
    VITE_SK:import.meta.env.VITE_SK || ''
};

export const GetEnvVar = (key)=>{
    return data[key]
}
