import React,{useEffect} from 'react'

const GoogleTranslate = (props) => {
    const {langCode = ''} = props;
    useEffect(() => {
        var addScript = document.createElement("script");
        addScript.setAttribute(
          "src",
          "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
        
        console.log("google_translate_element langCode ==>", langCode)
        if(langCode && langCode != 'en'){
          setTimeout(function() {
            var selectElement = document.querySelector('#google_translate_element select');
            selectElement.value = langCode;
            selectElement.dispatchEvent(new Event('change'));
          }, 1500);
        }

      }, []);
 
      
    const googleTranslateElementInit = () => {
        const userBrowserLanguage = window.navigator.userLanguage || window.navigator.language;
        
        // Define a list of supported languages on your website
        const supportedLanguages = ['en','es']; // English and Spanish
        console.log("userBrowserLanguage ==>", userBrowserLanguage, supportedLanguages )
        // Set the default language based on the user's browser language
        const defaultLanguage = supportedLanguages.includes(userBrowserLanguage) ? userBrowserLanguage : 'en';
        
        new google.translate.TranslateElement({
        pageLanguage: defaultLanguage, // Default language based on user's browser
        includedLanguages: supportedLanguages.join(','), // List of supported languages
        defaultLanguage: defaultLanguage // Default translation language
        }, 'google_translate_element');
      };

  return (
    <div className="google_translate_area" style={{width:'100%',height:'1px',visibility:'hidden'}}>
        <div id="google_translate_element"></div>
    </div>
  )
}

export default GoogleTranslate