import React from "react";
import "./InfoCard.css"

const InfoCard = ({ data: { title, description } }) => {
    return (<div className="box__offer">
        <h3>{title}</h3>
        <p>{description}</p>
    </div>);
}

export default InfoCard;