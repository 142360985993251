import { SET_PARTNER_DATA, SET_PARTNER_ACCESS_TOKEN, SET_PARTNER_MARKETPLACE_INBOX, SET_PARTNER_MARKETPLACE_DETAILS_DATA, UNAUTHORIZED_PARTNER, MARKETPLACE_SHOW_LOADER, MARKETPLACE_HIDE_LOADER,UPDATE_PARTNERDATA_INFO  } from "../types";

const INITIAL_STATE = {
    partnerDetails: {}, partnerAccessToken:"",marketPlaceInbox:{},unauthorizedPartner:false,mainCardArrayList:[]
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case UPDATE_PARTNERDATA_INFO:
            return { ...state , [action.payload.prop]: action.payload.value}
        case SET_PARTNER_DATA:
            return { ...state , partnerDetails: action.payload }
        case SET_PARTNER_ACCESS_TOKEN:
            return { ...state , partnerAccessToken: action.payload }
        case SET_PARTNER_MARKETPLACE_INBOX:
            return { ...state , marketPlaceInbox: action.payload }
        case SET_PARTNER_MARKETPLACE_DETAILS_DATA:
            return { ...state , productDetailsData: action.payload }
        case UNAUTHORIZED_PARTNER:
            return { ...state , unauthorizedPartner: action.payload }
        case MARKETPLACE_SHOW_LOADER:
            return { ...state, loading: true}
        case MARKETPLACE_HIDE_LOADER:
            return { ...state, loading: false}
        default:
            return state;
    }
}