import React from 'react'
import "./SocialPlatfomHeader.css"
const SocialPlatfomHeader = (props) => {
    const { content = {} } = props
    const { tagLine,heading = '',desc='',imageWeb='',imageTablet='',imageMobile=''} = content

    return (
        <div className='container'>
            <div className='social-platform-header-container'>
                <div className='social-platform-header-tagline'>{tagLine}</div>
                <div className='social-platform-header-heading' dangerouslySetInnerHTML={{ __html: (heading) }} />
                <div className='social-platform-header-desc'>{desc}</div>
                <div className='social-platform-header-image-wrp social-platform-header-img-desktop'>
                    <img src={imageWeb} className='img-fluid'/>
                </div>
                <div className='social-platform-header-image-wrp social-platform-header-img-tablet'>
                    <img src={imageTablet ? imageTablet : imageWeb} className='img-fluid'/>
                </div>
                <div className='social-platform-header-image-wrp social-platform-header-img-mobile'>
                    <img src={imageMobile ? imageMobile : imageWeb} className='img-fluid'/>
                </div>
            </div>
        </div>
    )
}

export default SocialPlatfomHeader