import React from 'react'
import "./PowersWorld.css"
import StarRating from '../../../components/NewDesignV2/Common/StarRating/StarRating'
const PowersWorld = (props) => {
    const {content,contentTheme='',showRating=false}=props
    const {title='',powersWorldItem={}}=content
  
  if(contentTheme == 'leftHeading') {
    return (
      <div className='powers-world-container container'>
          <div className='row' style={{width:'100%',display:'flex',alignItems:'center'}}>
            <div className='col-lg-4 col-md-4 col-sm-12 left-side-heading-area'>
              {title && <div className='powers-world-heading'>{title}</div>}
              {showRating ? <StarRating rating={'4.9'} ratingLavel="7,100+ ratings" /> : null}
            </div>
            <div className='col-lg-8 col-md-8 col-sm-12 right-side-logo-area'>
              <div className='powers-world-items'>
                  <div className='powers-world-item'>
                      {powersWorldItem.data && powersWorldItem.data.length>0 && powersWorldItem.data.map((item,index)=>{
                          return <img src={item.image} key={item.name+index}/>
                      })}
                  </div>
              </div>
            </div>
          </div>
      </div>
    )
  } else {
    return (
      <div className='powers-world-container container'>
          <div className='powers-world-heading'>{title}</div>
          <div className='powers-world-items'>
              <div className='powers-world-item'>
                  {powersWorldItem.data && powersWorldItem.data.length>0 && powersWorldItem.data.map((item,index)=>{
                      return <img src={item.image} key={item.name+index}/>
                  })}
              </div>
          </div>
      </div>
    )
  }
}

export default PowersWorld