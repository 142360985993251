import React from 'react'
import "./StartMember.css"
import avibraLogo from '../../../images/avibra-logo.svg'

const StartMember = ({pageData: {title, mainTitle, description}}) => {
    return (
        <>
              <div className="MembershipDetails__main fix-space StartMember__main">
            <div className="container main__color main__box__bg">
                
            <div className="logo">
        <img src={avibraLogo} alt="" srcset="" />
    </div>
    <div className="line__start"></div>
    <div className="content__start">
        <div className="slide__main__sub">
            <p>{title}</p>
        </div>
        <div className="start__title">
            <h1 dangerouslySetInnerHTML = {{__html:(mainTitle)}}></h1>
        </div>
        <div className="start__para__member">
            <p>{description}</p>
        </div>
    </div>
            </div>
        </div>
        </>
    )
}

export default StartMember
