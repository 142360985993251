import React from "react";
import "./Comparison.css";
import FooterSection from "./FooterSection";

const data = [
    {
      title: "Membership Benefits",
      coMember: "Core Membership",
      dobMember: "Dollar Club Membership",
    },
    {
      title: "Life Insurance",
      coMember: "$5,000",
      dobMember: "$5,000",
    },
    {
      title: "Accidental Death & Dismemberment Insurance",
      coMember: "$5,000",
      dobMember: "$10,000",
    },
    {
      title: "Waived Monthly App Check In Requirement",
      coMember: "$5,000",
      dobMember: "$10,000",
    },
    {
      title: "2x Earned Rewards",
      coMember: "$5,000",
      dobMember: "$10,000",
    },
    {
      title: "Well-Being Advisor",
      coMember: "$5,000",
      dobMember: "$10,000",
    },
    {
      title: "Access to the Dollar Benefits Store",
      coMember: "$5,000",
      dobMember: "$10,000",
    },
];

const Comparison = ({pageData: {title,headingData='', data, footer, index}, slideNo='', plusPlanDetails={}, userPlans={}, userInfo={} }) => {
  
  const renderText = (key, condition = () => false, fallbackvalue) => {
    let data = userInfo[key]
    let value = key
    if (typeof data == "number") value = `$${abbreviate(data, 1)}`
    else if (key == "adndcoverage") {
      value = `$${abbreviate(userInfo.plusmax - userInfo.plusmaxcap)}`
    }
  
    if (key == "adndmax") {
      if (condition()) return fallbackvalue
      return `$${abbreviate(adnddefaultfacevalue, 1)}`
    }
  
    return value == "$NaN" ? "$0" : value
  }
  return (
    <>
      {/* <div className="MembershipDetails__main fix-space">
        <div className="container main__color">
          <div className="welcome__main">
            <div className="welcome__row comparison">
              <div className="welcome__title">
                <h1>
                  Membership <span>Comparison</span>
                </h1>
              </div>
              <div className="data__welcome__table">
                <table class="table">
                  {data?.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>{item.title}</td>
                          <td>{item.coMember}</td>
                          <td>{item.dobMember}</td>
                        </tr>
                      </>
                    );
                  })}
                </table>
              </div>
            </div>
            <div className="footer__main">
              <div className="left__side__footer">
                <h6>Live A VIBRAnt Life</h6>
              </div>
              <div className="right__side__footer">
                <h6>3</h6>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="card-guide">
        <div className="container h-100 p-0">
          <div className="main__color Comparison">
            <div className="Comparison__title">
                <h1 dangerouslySetInnerHTML = {{__html:(title)}}></h1>
            </div>
            <div className="data__welcome__table">
                <table class="table">
                {headingData ? <tr>
                  <td>{headingData.title}</td>
                  <td>{headingData.coMember}</td>
                  <td>{headingData.dobMember}</td>
                </tr> : null}
                {plusPlanDetails.map((data, index) => {
                  if(index<4)
                  return (
                    <tr key={index} id="page2el">
                    <td>{data.displayName}</td>
                    {
                        userPlans && userPlans.map((plan,i)=>{
                           return(
                            <td key={`${i}-${index}planmap`} >
                                {data[plan] =="Y" || data[plan] =="N" ?
                                    <span>{
                                    data[plan] == "Y" ? <span style={styles.bulleted} className="dd_lighlights_bulleted"> ✓ </span> : '--'
                                    }</span>
                                    :
                                    renderText(data[plan], true)
                                }
                            </td>
                           ) 
                        })
                    }
                    </tr>
                  )
                  else
                  return null;
                })}
                  {data?.map((item, index) => {
                    return (
                        <tr>
                          <td>{item.title}</td>
                          <td>{item.coMember || '--'}</td>
                          <td>{item.dobMember ||
                          <span style={styles.bulleted} className="dd_lighlights_bulleted">
                              ✓
                          </span> }</td>
                        </tr>
                    );
                  })}
                </table>
            </div>
            <FooterSection slideName={footer} slideNo={slideNo} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Comparison;

const styles = {
  bulleted: {
      width:'30px',
      height:'30px',
      borderRadius:'15px',
      backgroundColor:"#FD7350",
      justifyContent: "center",
      alignItems: "center",
      marginRight:'15px',
      marginLeft:'0px',
      marginTop:'5px',
      padding:'5px',
      textAlign:'center',
      fontSize:'15px',
      display:'inline-block',
      color:'#fff'
  }
}