import React from 'react'
import "./SampleSaving.css"
import FooterSection from "./FooterSection";

function SampleSaving({savingsTableData={},footerText='',slideNo='' }) {

  const { title='', propertyData={} } = savingsTableData;
  console.log("savingsTableData ==>", savingsTableData)
  const renderSavingTable = (tableData) => {
      let data = tableData
      return (
          <div className={`data__welcome__table ${data && data.length > 7 ? 'large_table_content' : ''}`}>
            <table className="table" border="0" style={{ width: '100%' }}><tbody>
              {
                  data.map((rowData, index) => {
                      if (rowData.type === "heading") {
                          return (
                                  <tr>
                                  {
                                      rowData.list.map((item, i) => {
                                          if(i<3)
                                          return (
                                              <td width="33%">
                                                  <div>{item != "" && item}</div>
                                              </td>
                                          )
                                          else
                                          return null
                                      })
                                  }
                                  </tr>
                                )
                      }
                      if (rowData.type === "value") {
                          return (
                              <tr>
                                  {
                                      rowData.list.map((item, i) => {
                                        if(i<3)
                                          return (
                                              <td width="25%">
                                                  <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                                                      {/*<div className={i === 2 ? "theme_color" : "normal_color" } style={{
                                                          color: i === 2 ? "#774AD9" : "#292C4E",
                                                          fontWeight: i === 0 ? "600" : "400",
                                                          fontSize: i === 0 ? '12px' : '14px',
                                                          textAlign: 'center'
                                                      }}> 
                                                     </div>*/}
                                                    {item}
                                                  </div>
                                              </td>
                                            )
                                        else
                                          return null
                                      })
                                  }
                              </tr>
                          )
                      }
                  })
              }
            </tbody></table>
          </div>
      )
  }

    const tableData2 = propertyData[propertyData.dataType]
    return (
       <>
       {savingsTableData && (title || (propertyData && propertyData?.dataType && propertyData[propertyData?.dataType])) ? 
        <div className="slide__pdf sample_saving">
        <div className="card-guide">
        <div className="container h-100 p-0">
          <div className="main__color Sample__Saving">
            <div className="Sample__Saving__title">
                <h1>{title}</h1>
            </div>
            <div>
              { tableData2 && renderSavingTable(tableData2) }
            </div>
            {/*<div className="data__welcome__table">
                <table class="table">
                  {TableData?.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>{item.title}</td>
                          <td>{item.coMember}</td>
                          <td>{item.dobMember}</td>
                        </tr>
                      </>
                    );
                  })}
                </table>
            </div>
            <div class="sample__details" style={{marginTop:(tableData2 && tableData2.length > 7) ? '15px' : '30px' }}> 
              <p>* Average health insurance out-of-pocket maximum in the US in 2021</p>
              <p>** 2020 national averages of weekly child care costs</p>
              <p>*** National average cost of professionally installed wheelchair ramp</p>
            </div>*/}
            
            <FooterSection slideName={footerText} slideNo={slideNo} />
          </div>
        </div>
      </div>
      </div>
      : null}
      </>
    )
}

export default SampleSaving
