import React, { useState } from "react";
import "./Pillar.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';


const Pillar = ({ data: { image, imageText, title, subtitle } }) => {

  const [isShow, setIsShow] = useState(false);

  const handleAfterLoad = () => {
    setIsShow(true);
  }

  return (
    <div className="tag__card ">
      <div className="card__img order-two">
        <LazyLoadImage src={image} alt={title} className="img-fluid" effect="opacity" afterLoad={() => handleAfterLoad()} />
        {
          isShow ?
            <div className={`design__card ${title.toLowerCase()}`}>
              {
                imageText.map((ele) => (
                  <div className="tag__of__card">
                    <div className="tag__card">
                      <div className="tag__is">{ele}</div>
                    </div>
                  </div>
                ))
              }
            </div>
            : null
        }
      </div>
      <div className="card__content order-one">
        <h3>{title}</h3>
        <p>{subtitle}</p>
      </div>
    </div>
  );
};

export default Pillar;
