import React,{useEffect, useState} from 'react'
import { Link } from 'react-router'
import {
    Container,
    Row,
    Col
} from 'reactstrap';
import whiteLogo from '../../../../../images/footer-white-log.png'
import fbPartner from '../../../../../images/fb-partner.png'
import intsaPartner from '../../../../../images/insta-partner.png'
import twitterPartner from '../../../../../images/twitter-partner.png'
import Tiktok from '../../../../../images/tiktok.png'
import linkedIn from '../../../../../images/linkedin.png'
import apple from '../../../../../images/new-apple.png'
import googlePlay from '../../../../../images/new-google.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { appleAppLink, googleAppLink, fbPageLink, instaPageLink, twitterPageLink, tikTokLink, linkedInLink, privacyLink, blogLink, licensesLink, termsLink, currentYear } from '../../../../../constant/constant'
import 'react-lazy-load-image-component/src/effects/opacity.css';
import closeIcon from "../../../../../images/close_modal.png";
import { connect } from 'react-redux';
import { UpdateLifeInsuranceInfo } from '../../../../actions/lifeInsuranceAction';

const hasSectionBeenShown = localStorage.getItem('sectionShown');

const Footer = (props) => {

    const [showSection, setShowSection] = useState(false);
    let pagePath = window.location.pathname;
    let { content, hideFooterLink = '' }=props
    let getLink = (link) => {
        return link.href.includes("http") ?
            (<a href={link.href} target="_blank">{link.title}</a>)
            :
            (<Link to={link.href}>{link.title}</Link>)
    }

    useEffect(()=>{

       console.log('hasSectionBeenShown', hasSectionBeenShown,props.fixedFooterShown)
        if (!hasSectionBeenShown && !props.fixedFooterShown && window.location.pathname=='/') {
       console.log('hasSectionBeenShown1', )
          setShowSection(true);
          localStorage.setItem('sectionShown', 'true');
        }

    },[])

    const FixedContentClickHandler =()=>{
        props.UpdateLifeInsuranceInfo({ prop:'fixedFooterShown', value: true })
        setShowSection(false)
    }

    return (<div className="footer-cont"><Container>
        <Row>
            <Col className="p-0">
                <div className="footer-cont-padding">
                    <div>
                        {hideFooterLink == true ? null : <div>
                            <div className="footer-cont-section-1 row">
                                <div className="col-md-12 col-lg-3 col-xl-3 p-0">
                                    <div className="left">
                                        <div className="logo-container">
                                            <img src={whiteLogo} height="38" alt="Avibra logo" />
                                        </div>
                                    </div>
                                </div>
                                {pagePath != '/disclosures' && <div className="col-md-12 col-lg-9 col-xl-9 footer-menu-area">
                                    <div className="right">
                                        <div className="nav-links">
                                            <div className="first-right-menu benefits-nav">
                                                <div className="footer-nav-heading">Benefits</div>
                                                {
                                                    content.footerNav.leftProducts.map((link, index) => <span key={index}>{getLink(link)}</span>)
                                                }
                                            </div>
                                            <div className="first-right-menu solutions-nav">
                                                <div className="footer-nav-heading">Solutions</div>
                                                {
                                                    content.footerNav.leftSolutions.map((link, index) => <span key={index}>{getLink(link)}</span>)
                                                }
                                                <div className="social-icon-container-new desktop-social">
                                                    <a href={fbPageLink} target="_blank"><LazyLoadImage src={fbPartner} className="facebook-icon" effect="opacity" /></a>
                                                    <a href={instaPageLink} target="_blank"><LazyLoadImage src={intsaPartner} className="instagram-icon" effect="opacity" /></a>
                                                    <a href={twitterPageLink} target="_blank"><LazyLoadImage src={twitterPartner} className="twitter-icon" effect="opacity" /></a>
                                                    <a href={linkedInLink} target="_blank"><LazyLoadImage src={linkedIn} className="linkedin-icon" effect="opacity" /></a>
                                                    {/* <a href={tikTokLink} target="_blank"><LazyLoadImage src={Tiktok} className="twitter-icon" effect="opacity"/></a> */}
                                                </div>
                                            </div>
                                            <div className="first-right-menu pl-40">
                                                {
                                                    content.footerNav.leftSide.map((link, index) => <span key={index}>{getLink(link)}</span>)
                                                }
                                                {/* <div className="main__inner__footer">
                                        <div className="menu__left">
                                        {
                                            content.footerNav.leftSide.map((link, index) => <span key={index}>{getLink(link)}</span>)
                                        }
                                        </div>
                                        <div className="menu__right">
                                        {
                                            content.footerNav.rightSide.map((link, index) => <span key={index}>{getLink(link)}</span>)
                                        }
                                        </div>
                                        </div> */}
                                            </div>
                                            <div className="second-right-menu">
                                                {
                                                    content.footerNav.rightSide.map((link, index) => <span key={index}><a href={link.href} target="_blank">{link.title}</a></span>)
                                                }
                                            </div>

                                            {/* <div className="second-right-menu-mobile">
                                
                                    </div> */}
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className="nav-links">
                                            <div className="second-right-menu tablet-only">
                                                {
                                                    content.footerNav.rightSide.map((link, index) => <span key={index}><a href={link.href} target="_blank">{link.title}</a></span>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="social-icon-container-new mobile-social">
                                        <a href={fbPageLink} target="_blank"><LazyLoadImage src={fbPartner} className="facebook-icon" effect="opacity" /></a>
                                        <a href={instaPageLink} target="_blank"><LazyLoadImage src={intsaPartner} className="instagram-icon" effect="opacity" /></a>
                                        <a href={twitterPageLink} target="_blank"><LazyLoadImage src={twitterPartner} className="twitter-icon" effect="opacity" /></a>
                                        <a href={linkedInLink} target="_blank"><LazyLoadImage src={linkedIn} className="linkedin-icon" effect="opacity" /></a>
                                        {/* <a href={tikTokLink} target="_blank"><LazyLoadImage src={Tiktok} className="twitter-icon" effect="opacity"/></a> */}
                                    </div>
                                </div>}
                            </div>

                        </div>}
                        <div className={`footer-bottom ${showSection ? 'footer-popup-section':''}`}>
                            <div className="line"></div>
                            <div className="row">
                                <div className="col-md-5 col-lg-5 col-xl-5 footer-copyrights">
                                    <div>
                                        {content.email}
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: (content.trademarks) }}></div>

                                    <div>
                                        © {currentYear} {content.rights}
                                    </div>
                                </div>
                               
                                <div className="col-md-4 col-lg-4 col-xl-4">
                                    <div className="social-icon-container-new tablet-social">
                                        <a href={fbPageLink} target="_blank"><LazyLoadImage src={fbPartner} className="facebook-icon" effect="opacity" /></a>
                                        <a href={instaPageLink} target="_blank"><LazyLoadImage src={intsaPartner} className="instagram-icon" effect="opacity" /></a>
                                        <a href={twitterPageLink} target="_blank"><LazyLoadImage src={twitterPartner} className="twitter-icon" effect="opacity" /></a>
                                        <a href={linkedInLink} target="_blank"><LazyLoadImage src={linkedIn} className="linkedin-icon" effect="opacity" /></a>
                                        {/* <a href={tikTokLink} target="_blank"><LazyLoadImage src={Tiktok} className="twitter-icon" effect="opacity"/></a> */}
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-5 col-lg-5 col-xl-5 footer-copyrights bb-logo">
                                <a href="https://www.bbb.org/us/nj/iselin/profile/group-benefits/avibra-inc-0221-90222248/#sealclick" target="_blank" rel="nofollow"><img src="https://seal-newjersey.bbb.org/seals/blue-seal-200-42-whitetxt-bbb-90222248.png" style={{border:0}} alt="Avibra, Inc. BBB Business Review" /></a>
                                </div>
                                <div className="col-12 col-md-7 col-lg-7 col-xl-7 p-0">
                                    {/* <div className="footer-disclaimer" id='point-one' dangerouslySetInnerHTML={{ __html: (content.disclaimer) }}>
                                        </div> */}
                                    <div className="footer-disclaimer" id='point-one'>
                                        <div style={{ fontSize: "16px" }}><sup>+</sup></div> {content.disclaimer}
                                    </div>
                                </div>
                                

                            </div>
                           
                        </div>
                    </div>
                </div>

            </Col>
        </Row>
    </Container>
        {showSection && <div className='footer-fixed-section'>
            <div className='footer-close-icon' >
                <img src={closeIcon}  onClick={()=>FixedContentClickHandler()}/>
            </div>
           <div className='footer-fixed-content' dangerouslySetInnerHTML={{ __html: (content.fixedFooterContent) }} />
        </div>}
    </div>
    )
}


const mapStateToProps = state =>{
	const {fixedFooterShown}  = state.lifeInsuranceReducer;
	return { fixedFooterShown };
}

export default connect(mapStateToProps, {UpdateLifeInsuranceInfo})(Footer);
