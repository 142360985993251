import React from 'react'
import "./FullWidthBannerImage.css"

const FullWidthBannerImage = ({bannerImageData={},hideBannerImage=false}) => {
  const {bannerImagePath='',bannerImageMobile='',bannerImageTablet=''} = bannerImageData
  return (
    <div>
    {(hideBannerImage || !bannerImagePath) ? null : 
    <section className='product-banner'>
        <div className='product-banner-section'>
            <div className='container'>
                <div className='product-banner-img banner-for-desktop'>
                    <img className='img-fluid' src={bannerImagePath} />
                </div>
                <div className='product-banner-img banner-for-teblet'>
                    <img className='img-fluid' src={bannerImageTablet? bannerImageTablet : bannerImagePath} />
                </div>
                <div className='product-banner-img banner-for-mobile'>
                    <img className='img-fluid' src={bannerImageMobile ? bannerImageMobile : bannerImagePath} />
                </div>
            </div>
        </div>
    </section>}
</div>
  )
}

export default FullWidthBannerImage