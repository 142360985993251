import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import "./CustomerStories.css"
import { useNavigate } from "react-router";
import plusIcon from "../../../../images/new-plus-icon.png"

const CustomerStories = (props) => {
    const {content={}}=props
    const {partnerData={}}=content

    const {title,subtitle}=content
     const [storyData, setStoryData] = useState({})
      const navigate = useNavigate();
    console.log('content', content)
    
      useEffect(()=>{
        if(Object.keys(partnerData).length){
          let mergedArray =[]
          mergedArray=Object.values(partnerData).flat();
          let primaryData=mergedArray.find((item)=>item.primaryKey==true)
          console.log('mergedArray', mergedArray)

          setStoryData(primaryData?primaryData:{})
        }
      },[props.content])
     
      const onClickHandler=(item)=>{
        let url=`/case-study/${item.partnerKey}`
        navigate({pathname:url})
      }
  return (
    Object.keys(storyData).length>0?
    <div className="container customer-story-case-study">
      <div className="customer-story-case-study-header-wrp">
          <h2>{title}</h2>
          <p>
            {subtitle}
          </p>
      </div>
      <Row className="justify-content-center customer-story-case-study-card">
        <Col lg={11} md={11} sm={12}>
          <div className="customer-story-case-study-card-outer">
            <Row className="align-items-center customer-story-case-study-card-inner">
              <Col md={6} className="customer-story-case-study-card-inner-left">
               <div className="customer-story-logo-wrp">
               <img className="customer-story-logo" src={storyData.logo}></img> 
               {storyData.secondPartnerlogo && <img className="customer-story-plus" src={plusIcon} />}
               {storyData.secondPartnerlogo && <img className="customer-story-logo" src={storyData.secondPartnerlogo}></img>}
               </div>
                <h3 className="customer-story-card-heading">{storyData.title}</h3>
                <p className="customer-story-card-desc">{storyData.description}</p>
                <div className="customer-story-card-btn">
                   <div className="customer-story-right-arrow" onClick={()=>onClickHandler(storyData)}>
                   <i class="fas fa-arrow-right"></i>
                   </div>
                  <span className="me-2" onClick={()=>onClickHandler(storyData)}>{storyData.ctaText}</span>
                </div>
              </Col>

              <Col md={6} className="customer-story-card-image">
                <img
                  src={storyData.cardImage}
                  className="img-fluid"
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>:null
  );
};

export default CustomerStories;
