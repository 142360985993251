import React from 'react'
import { Routes, Route } from "react-router"
//const AlertDetail = React.lazy(() => import(`../../views/AlertDetail/AlertDetail`));
//const PartnerPage = React.lazy(() => import(`../../views/PartnerPage/PartnerPage`));
const PolicyDocument = React.lazy(() => import(`../../views/PolicyDocument/PolicyDocument`));

import MemberShip from "../../views/Membership/index.js"
import MemberShipGuide from "../../views/MembershipGuide/index.js"

//const ContactUs = React.lazy(() => import(`../../views/NewMainLaningPage/ContactUs`));

//const MarketingPage = React.lazy(() => import(`../../views/MarketingPage/MarketingPage`));

const ValidateParams = React.lazy(() => import(`../../views/ValidateParams/ValidateParams`));

import Join from '../../views/Join'

/*const LifePDFListing = React.lazy(() => import(`../../views/LifePage/LifePDFListing`));
const LifeInsurancePayment = React.lazy(() => import(`../../views/LifeInsurancePayment/LifeInsurancePayment`));
const FeLifePDFListing = React.lazy(() => import(`../../views/LifePage/FeLifePDFListing`));

import Redirect from '../../views/Redirect'

const Enterprise = React.lazy(() => import(`../../views/NewMainLaningPage/Enterprise/`));

const About = React.lazy(() => import(`../../views/NewMainLaningPage/About`));

const MembershipPlans = React.lazy(() => import(`../../views/NewMainLaningPage/Membership`));

const NoCostInsurance = React.lazy(() => import(`../../views/NewMainLaningPage/NoCostInsurance`));

const DollarBenefitStore = React.lazy(() => import(`../../views/NewMainLaningPage/DollarBenefitStore`));

const VibrantLife = React.lazy(() => import(`../../views/NewMainLaningPage/VibrantLife`));

const Marketplace = React.lazy(() => import(`../../views/NewMainLaningPage/Marketplace`));


const Press = React.lazy(() => import(`../../views/NewMainLaningPage/Press`));

import HomePage  from "../../views/HomePage/HomePage";
import Restaurant from "../../views/Restaurant/Restaurant";

const LifeAndADnDInsurance = React.lazy(() => import(`../../views/NewMainLaningPage/LifeAndADnDInsurance`));
const GlobalESGAdvisor = React.lazy(() => import(`../../views/NewMainLaningPage/GlobalESGAdvisor`));
const FinancialServices = React.lazy(() => import(`../../views/NewMainLaningPage/FinancialServices`));

import GigPlatforms from "../../views/NewMainLaningPage/GigPlatforms/index.js";
import FranchiseUnits from "../../views/NewMainLaningPage/FranchiseUnits/index.js";*/

const Disclosures = React.lazy(() => import(`../../views/NewMainLaningPage/Disclosures`));

//const BookADemo = React.lazy(() => import(`../../views/NewMainLaningPage/BookADemo/BookADemo`));

const PrivacyPolicy = React.lazy(() => import(`../../views/NewMainLaningPage/PrivacyPolicy/PrivacyPolicy`));
const TermsOfService = React.lazy(() => import(`../../views/NewMainLaningPage/TermsOfService/TermsOfService`));

const TermsConditionsPartner = React.lazy(() => import(`../../views/NewMainLaningPage/TermsConditionsPartner/TermsConditionsPartner`));
const PaymentSuccess = React.lazy(() => import(`../../views/NewMainLaningPage/PaymentSuccess/PaymentSuccess`));
const CreditBuilder = React.lazy(() => import(`../../views/NewMainLaningPage/CbActivate/CbActivate`));

const CbOnboarding = React.lazy(() => import(`../../views/NewMainLaningPage/CbOnboarding/CbOnboarding`));
const TaxFiling = React.lazy(() => import(`../../views/NewMainLaningPage/CbActivate/TaxFiling`));
const GenerateLink = React.lazy(() => import("../../views/NewMainLaningPage/GenerateLink/GenerateLink"))

import Sweepstake from '../../views/Sweepstake'
import { GetEnvVar } from '../AppConfig';

/* version 2 routes start here */
import RouteV2 from '../../RouteV2';
import HomePageV2  from "../../views/V2/HomePage/HomePage";
import HomePageV2New  from "../../views/V2/HomePageNew/HomePageNew";
import CarePageV2  from "../../views/V2/CarePage/CarePage";

import EmbeddedCare  from "../../views/V2/EmbeddedCare/index.js";
import DollarStorePage  from "../../views/V2/DollarStorePage/DollarStorePage.js";

import SocialImpactPlatform from '../../views/V2/SocialImpactPlatform/SocialImpactPlatform.js';
import RewardsPage  from "../../views/V2/RewardsPage/RewardsPage.js";

import AboutUs from '../../views/V2/AboutUs/AboutUs.js';
import PrivacyAndSecurity from '../../views/V2/PrivacyAndSecurity/PrivacyAndSecurity.js';
import PressV2 from '../../views/V2/Press/Press.js';
import ContactUsV2 from '../../views/V2/ContactUs/ContactUs.js';
//const ContactUsV2 = React.lazy(() => import('../../views/V2/ContactUs/ContactUs.js'));
import BookADemo from '../../views/V2/BookADemo/BookADemo.js';
import Financial from '../../views/V2/Financial/Financial.js';
import EmployersPage from '../../views/V2/EmployersPage/EmployersPage';
import WorkPlatForm from '../../views/V2/WorkPlatForm/WorkPlatForm.js';
import CaseStudy from '../../views/V2/CaseStudies/CaseStudies.js';
import PartnerCaseStudy from '../../views/V2/PartnerCaseStudy/PartnerCaseStudy.js';
import WhitePapers from '../../views/V2/WhitePapers/WhitePapers.js';

const MainContainer = () => {
  return <Routes>
    {/* <Route path="/v1" element={<HomePage/>} /> 
    <Route path={`/alerts/:alertid`} element={(<AlertDetail/>)} />
    <Route path="/partner" element={(<Enterprise/>)} />
    <Route path="/marketing" element={(<MarketingPage/>)} /> */}
    <Route path={`/insurance/:insuranceToken`} element={(<PolicyDocument/>)} />
    <Route path={`/membership/:membershipToken`} element={(<MemberShip/>)} />
    <Route path={`/membershipguide`} element={(<MemberShipGuide/>)} />
    <Route path={`/join`} element={(<Join/>)} />
    {GetEnvVar('NODE_ENV')  !== 'production' ? <Route path={`/validateparams`} element={(<ValidateParams/>)} /> : null}
    {/* <Route path={`/life/:arcId/:callReferenceCode/:formnumber`} element={(<LifePDFListing/>)} />
    <Route path={`/life/:arcId/:callReferenceCode`} element={(<LifePDFListing/>)} />
    <Route path="/lifeinsurance/payment" element={(<LifeInsurancePayment/>)} />
    <Route path={`/felife/:profileid`} element={(<FeLifePDFListing/>)} />
    <Route path={`/redirect`} element={<Redirect/>MarketingPage} /> 
    <Route path="/avibra-enterprise" element={(<Enterprise/>)} />
    <Route path="/about" element={(<About/>)} />
    <Route path="/membership-plans" element={(<MembershipPlans/>)} />
    <Route path="/no-cost-insurance" element={(<NoCostInsurance/>)} />
    <Route path="/dollar-benefits-store" element={(<DollarBenefitStore/>)} />
    <Route path="/well-being-advisor" element={(<VibrantLife/>)} />
    <Route path="/contact-us-v1" element={(<ContactUs/>)} /> */}
    <Route path= {`/disclosures`} element={(<Disclosures/>)} />
    <Route path= {`/sweepstakes`} element={<Sweepstake/>} />
    {/* <Route path="/press-v1" element={(<Press/>)} /> 
    <Route path="/home" element={(<HomePage/>)} />
    <Route path="/hourly-workforce" element={(<Restaurant/>)} />
    <Route path="/life-and-adnd-insurance" element={(<LifeAndADnDInsurance/>)} />
    <Route path="/esg-advisor" element={(<GlobalESGAdvisor/>)} />
    <Route path="/financial-services" element={(<FinancialServices/>)} />
    <Route path="/gig-platforms" element={(<GigPlatforms/>)} />
    <Route path="/franchise-units" element={(<FranchiseUnits/>)} />
    <Route path="/book-a-demo" element={(<BookADemo/>)} />*/}
    <Route path="/privacy-policy" element={(<PrivacyPolicy/>)} />
    <Route path="/terms-of-service" element={(<TermsOfService/>)} />
    <Route path="/terms-conditions-partner" element={(<TermsConditionsPartner/>)} />
    <Route path="/payment-success" element={(<PaymentSuccess/>)} />
    <Route path="/cb/activated" element={(<CreditBuilder/>)} />
    <Route path="/cb/onboarding" element={(<CbOnboarding/>)} />
    <Route path="/taxfiling" element={(<TaxFiling/>)} />
    <Route path="/isbaas" element={(<GenerateLink/>)} />
    
    <Route path="/" element={<HomePageV2New/>} />
    <Route path="/home" element={<HomePageV2New/>} />
    <Route path="/embedded-care" element={<EmbeddedCare/>} />
    <Route path="/store" element={<DollarStorePage/>} />
    <Route path="/social-impact" element={<SocialImpactPlatform/>} />
    <Route path="/rewards" element={<RewardsPage/>} />
    <Route path="/about-us" element={<AboutUs/>} />
    <Route path="/privacy-and-security" element={<PrivacyAndSecurity/>} />
    <Route path="/contact-us" element={<ContactUsV2/>} />
    <Route path="/press" element={(<PressV2/>)} />
    <Route path="/book-a-demo" element={(<BookADemo/>)} />
    <Route path="/financial-services" element={(<Financial/>)} />
    <Route path="/care" element={(<CarePageV2/>)} />
    <Route path="/employers" element={(<EmployersPage/>)} />
    <Route path="/work-platforms" element={(<WorkPlatForm/>)} />
    <Route path="/case-studies" element={(<CaseStudy/>)} />
    <Route path="/case-study/:partner" element={(<PartnerCaseStudy/>)} />
    <Route path="/white-papers" element={(<WhitePapers/>)} />

  </Routes>
}

export default MainContainer
