import React from "react";
import "./Beneficiary.css";
import FooterSection from "./FooterSection";

const Beneficiary = (props) => {
  const {pageData: {title, tableTitle, th, td, footer, index}, primary=[], contingent=[], slideNo='' } = props
  console.log("primary & contingent", primary, contingent)
  return (
    <>
      <div className="card-guide">
        <div className="container h-100 p-0">
          <div className="main__color Benefi-ciary">
            <div className="Benefi-ciary__title">
                <h1>{title}</h1>
            </div>
            <div className="beneficiary__main">
                <div className="beneficiary__sub">
                  <p>{tableTitle}</p>
                </div>
                <div class="table-responsive beneficiary__table">
                  <table className="table">
                    <thead>
                      <tr>
                        {th.map(item => {
                          return (
                            <th>{item}</th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {primary?.length > 0 ? 
                      primary?.map((item, index) => {
                        return (
                          <>
                            <tr>
                              <td>{item.name}</td>
                              <td>{item.relation}</td>
                              <td>{item.dob}</td>
                            </tr>
                          </>
                        );
                      }) : contingent?.length > 0 ? 
                      contingent?.map((item, index) => {
                        return (
                          <>
                            <tr>
                              <td>{item.name}</td>
                              <td>{item.relation}</td>
                              <td>{item.dob}</td>
                            </tr>
                          </>
                        );
                      }) : 
                      <>
                      <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                    </> }
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div className="beneficiary__main Contingent_tb">
                <div className="beneficiary__sub">
                  <p>{tableTitle2}</p>
                </div>
                <div class="table-responsive beneficiary__table">
                  <table className="table">
                    <thead>
                      <tr>
                      {th2.map(item => {
                          return (
                            <th>{item}</th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {td2?.map((item, index) => {
                        return (
                          <>
                            <tr>
                              <td>{item.beneficiary}</td>
                              <td>{item.relationship}</td>
                              <td>{item.dob}</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div> */}
              
            <FooterSection slideName={footer} slideNo={slideNo} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Beneficiary;
