import React, { Component } from 'react';
import { partial } from '../../utils/common';

const WithLoader = WrappedComponent => 
    class WithLoaderComponent extends Component {

        state = {
            loading: false
        }

        _modifyLoading = loading =>
            this.setState({
                loading
            })
        
        _stopLoading = partial(this._modifyLoading, false)
        _startLoading = partial(this._modifyLoading, true)

        render () {

            return <WrappedComponent
                stopLoading={this._stopLoading}
                startLoading={this._startLoading}
                loading={this.state.loading}
                {...this.props}
            />

        }

    }

export default WithLoader
