import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
    Container,
    Row,
    Col,
    Spinner
} from 'reactstrap';
import Header from './../../../components/NewDesignV2/Common/Header/Header';
import Footer from './../../../components/NewDesignV2/Common/Footer/Footer';
import SeoMetaTags from '../../../components/NewDesignV2/Common/SeoMetaTags/SeoMetaTags';
import NewDesignV2LandingPageData from "./../../../../constant/NewDesignV2LandingPageData.json";
import NewDesignV2AboutUsPage from "./../../../../constant/NewDesignV2AboutUsPage.json";
import './../../../../styles/NewDesignV2/main-page.css'
import './../../../../styles/NewDesignV2/main-page-responsive.css';
import './AboutUs.css'
import { getFeparamsFilteredApi } from '../../../api/policyDocumentApi';
import { feparamsAction } from '../../../actions/feparamsAction';
import { setLogEvent, setAmplitudeInit } from '../../../utils/helpers';
import OurMission from '../../../components/NewDesignV2/Common/OurMission/OurMission';
import Investors from '../../../components/NewDesignV2/Common/Investors/Investors.js';
import FullWidthBannerImage from "../../../components/NewDesignV2/Common/FullWidthBannerImage/FullWidthBannerImage"
import FullWidthImageBannerHeaderSection from '../../../components/NewDesignV2/FullWidthImageBannerHeaderSection/FullWidthImageBannerHeaderSection';
import AtAvibra from '../AtAvibra/AtAvibra';
import OurBackground from '../OurBackground/OurBackground';

const AboutUs = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [stickyHeaderClass, setStickyHeaderClass] = useState('row topbar');
    const [loader, setLoader] = useState(false)
    const toggleSticky = useCallback(
        () => {

            const scrollY = window.scrollY ? window.scrollY : document.body.scrollTop
            const scrollHeight = (window.innerWidth <= 768) ? 10 : 100
            scrollY >= scrollHeight ? 
                setStickyHeaderClass(`row topbar fixed-header ${(window.innerWidth <= 768) ? '' : 'animated fadeInDown'}`)
                :
                setStickyHeaderClass("row topbar")
        },
        []
    );
    useEffect(()=>{
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
		setLoader(true)
		setTimeout(() => {
		  setLoader(false)	
		}, 300)
	},[])
    useEffect(() => {
        document.getElementById("main-wrapper").focus();
        //getFeparamsFilteredApi
        const {appConfig={}}=props
        if(!Object.keys(appConfig).length) {
        getFeparamsFilteredApi().then(res => {
            const { data = {} } = res;
            props.feparamsAction(data);
            let { amplitudeKey = "" } = data;
            setAmplitudeInit(amplitudeKey);
            setLogEvent("About Us Page loaded", {
                currentUrl: window.location.href,
            })
        })
            .catch(err => {
                console.log(err);
            });
        }else setLogEvent("About Us Page loaded", {
            currentUrl: window.location.href,
        })    
        const handleScroll = () => {
            toggleSticky();
        };

        if (window.addEventListener) {
            window.addEventListener("scroll", handleScroll, { capture: true });
        } else {
            window.attachEvent("onscroll", handleScroll);
        }

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

    }, [])

    const toggle = () => setIsOpen(!isOpen);

    const { mainSection,footerSection, seoMetaData={},socialPlatfomHeaderContent={},avibraCareContent={},customerVoicesContent={},atAvibraContent={},ourBackgroundContent={} } = NewDesignV2LandingPageData
    const { download_href } = mainSection.data.headerSection;
    const { mainPageAppDownLoadLink = download_href } = props.appConfig;

    const {ourMission={},investors={},bannerImageHeaderSection={},aboutPageBannerImageData={}} = NewDesignV2AboutUsPage


    return (<>{loader?<div style={{ textAlign: 'center', marginTop: '20px'}}> 
	   <Spinner style={{ width: '2rem', height: '2rem',color:'#787878',marginBottom:'25px' }} /></div>:
        <Container fluid className="main__container gig-platforms" id="main-wrapper">
            <SeoMetaTags seoData={seoMetaData.aboutPage} />
			<Row className={`topbar ${stickyHeaderClass}`}>
                <Col className="p-0">
                    <Header toggle={toggle} isOpen={isOpen} content={mainSection.data.headerSection} downloadLink={mainPageAppDownLoadLink} />
                </Col>
            </Row>
            <Row>
                <Col className="p-0">
                    <FullWidthImageBannerHeaderSection content={bannerImageHeaderSection}/>
                </Col>
            </Row>
            <Row>
                <Col className="section-area">
                    <OurMission data={ourMission} />
                </Col>
            </Row>
            <Row>
                <Col className="p-0 section-area-p-0 world-class-investors-outer-container">
                    <Investors pageData={investors}/>
                    <div className='light-purple-bg'>
                    <FullWidthBannerImage bannerImageData={aboutPageBannerImageData}/>
                    </div>
                </Col>
            </Row>
            {/* <Row>
                <Col className="p-0 at-avibra-outer-container">
                    <AtAvibra content={atAvibraContent}/>
                </Col>
            </Row> */}
            <Row>
                <Col className="p-0 our-background-outer-container">
                    <OurBackground content={ourBackgroundContent}/>
                </Col>
            </Row>
            <Row>
                <Col className="p-0">
                    <Footer content={footerSection} />
                </Col>
            </Row>
        </Container>}</>

    );
}
const mapStateToProps = state => {
    const { appConfig } = state.feparamsReducer;
    return { appConfig };
}
export default connect(mapStateToProps, { feparamsAction })(AboutUs);
