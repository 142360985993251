import React from "react";
import "./WelcomMember.css";
import FooterSection from "./FooterSection";
import { getImageUrl} from "../../utils/helpers";

const RenewalProcess = ({pageData: {title, description1, description2, image, footer, index}, slideNo=''}) => {
  return (
    <>
      <div className="card-guide">
        <div className="container h-100 p-0">
          <div className="main__color welcome-avibra">
                <div className="row align-items-center h-100">
                    <div className="col-md-9">
                        <div className="welcome__title">
                            <h1 dangerouslySetInnerHTML = {{__html:(title)}}></h1>
                        </div>
                        <div className="main__welcome__content">
                            <p>{description1}</p>
                            <p>{description2}</p>
                        </div>
                        
                    </div>
                    <div className="">
                            <div className="right__side__img" style={{right:'7%'}}>
                                <img src={getImageUrl(image)} alt=""  className="img-fluid"/>
                            </div>
                    </div>
                </div>
                <FooterSection slideName={footer} slideNo={slideNo} />
          </div>
        </div>
      </div>
    </>
  );
};

export default RenewalProcess;
