import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import "./CustomerStoriesGrid.css"
import { useNavigate } from "react-router";

const CustomerStoriesGrid = (props) => {

  const { content = {} } = props
  const { tabData = [], partnerData = {} } = content
  const [activeTab, setActiveTab] = useState('all')
  const [storyData, setStoryData] = useState([])
  let filteredTabData =[]
  filteredTabData = tabData.filter(tab => {
    let validPartners = partnerData[tab.key]?.filter(partner => !partner.primaryKey) || [];
    return validPartners.length > 1;
  });
  const navigate = useNavigate();


  useEffect(() => {
    if (activeTab == 'all' && Object.keys(partnerData).length) {
      let mergedArray = []
      mergedArray = Object.values(partnerData).flat();
      setStoryData(mergedArray)

    } else {
      if (Object.keys(partnerData).length && partnerData[activeTab])
        setStoryData(partnerData[activeTab])
    }
  }, [activeTab])

  const onClickHandler=(item)=>{
    let url=`/case-study/${item.partnerKey}`
    navigate({pathname:url})
  }

  return (
    <Container className="py-5 customer-stories-grid">
      {Object.keys(filteredTabData).length>1 && <div className="customer-stories-grid-tabs">
        <button onClick={() => setActiveTab('all')} className={`filter-btn ${activeTab == 'all' ? 'active' : ''}`}>All</button>
        {filteredTabData.map((item) =>
          <button onClick={() => setActiveTab(item.key)} key={item.key} className={`filter-btn ${activeTab == item.key ? 'active' : ''}`}>{item.label}</button>
        )}
      </div>}
      
      <Row className="story-grid-items">
        {storyData.map((story, index) => {
          return !story?.primaryKey?<Col key={index} md={6} sm={6} xs={12} lg={4} className="mb-4">
            <div className="story-card">
              <div className="story-image">
                <img src={story.cardImage} className="img-fluid story-card-image" />
                <div className="overlay">
                  <img className="img-fluid company-name" src={story.logo} />
                </div>
              </div>

              <div className="story-content">
                <p className="tag-line">{story.partnerName}</p>
                <p className="story-title">{story.title}</p>
                <div className="read-more" onClick={()=>onClickHandler(story)}>{story.ctaText}</div>
              </div>
            </div>
          </Col>:null
        }) }
      </Row>
    </Container>
  );
};

export default CustomerStoriesGrid;
