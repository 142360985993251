import React from "react";
import { getImageUrl } from "../../utils/helpers";
import "./ADDBenefits.css";
import FooterSection from "./FooterSection";
const ADDBenefits = (props) => {
  //console.log(title , "=>titlte");
  const { pageData={},overviewDescData={}, footerText='',slideNo='' } = props;
  const {heading='', icon=''} = pageData;
  const {subtitle=''} = overviewDescData;
  console.log("ADDBenefits overviewDescription ===>",  subtitle, pageData )
  return (
    <>
      <div className="card-guide">
        <div className="container h-100 p-0">
          <div className="main__color add-Benefits">
            <div className="row">
              <div className="col-lg-8">
                <div className="benefit-o-top-area">
                  <div className="benefit-o-top-icon-image">
                      <img src={getImageUrl(icon)} className="img-fluid"/>
                  </div>
                  <div className="benefit-o-top-heading-area">
                    {heading && <div className="benefit-o-top-heading">{heading}</div>}
                  </div>
                </div>
                <div className="benefit-o">
                  <div className="benefit-o-heading">
                    <div className="benefit-o-title">Benefit Overview</div>
                  </div>
                  <div className="benefit-o-details" dangerouslySetInnerHTML = {{__html:(subtitle)}}></div>
                </div>
              </div>
            </div>
            {/* <div className="pattorn">
                  {innerDataImg && <img src={innerDataImg}></img>}
            </div> */}
            <div className="pattorn">
                  <img src={getImageUrl("https://images.avibra.com/newhome/membership-doc-pattern.png")} />
            </div>
            <FooterSection slideName={footerText} slideNo={slideNo} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ADDBenefits;
