import React from "react";
import { Container, Row, Col } from "reactstrap";
import './PartnerCaseStudyHeader.css';
import plusIcon from "../../../../images/new-plus-icon.png"

const PartnerCaseStudyHeader = (props) => {

   const {content={}}=props
   const {logo='',title='',gridData=[],imageWeb='',imageTablet='',imageMobile='',secondPartnerlogo=''}=content

  return (
    <Container className="partner-case-study-header-container-outer">
      <div className="partner-case-study-header-container">
      <div className="parter-case-stud-header-wrp">
      <div className="parter-case-study-logo-wrp">
        <img className="parter-case-study-logo" src={logo}></img> 
        {secondPartnerlogo && <img className="parter-case-study-plus" src={plusIcon} />}
        {secondPartnerlogo &&<img className="parter-case-study-logo" src={secondPartnerlogo}></img>}
      </div>
      <h3 className="parter-case-study-title">{title}</h3>
      </div>
      

      <Row className="parter-case-study-items">
        {gridData.map((stat, index) => (
          <Col key={index} md={3} className="parter-case-study-item">
            <h4 className="parter-case-study-item-title">{stat.value}</h4>
            <p className="parter-case-study-item-desc">{stat.description}</p>
          </Col>
        ))}
      </Row>

      <Row className="parter-case-study-header-img-outer">
        <Col className="parter-case-study-header-img-inner parter-case-study-header-web">
          <img src={imageWeb}  className="img-fluid parter-case-study-header-img" />
        </Col>
        <Col className="parter-case-study-header-img-inner parter-case-study-header-tablet">
          <img src={imageTablet ? imageTablet : imageWeb}  className="img-fluid parter-case-study-header-img" />
        </Col>
        <Col className="parter-case-study-header-img-inner parter-case-study-header-mobile">
          <img src={imageMobile ? imageMobile : imageWeb}  className="img-fluid parter-case-study-header-img" />
        </Col>
      </Row>
      </div>
    </Container>
  );
};

export default PartnerCaseStudyHeader;
