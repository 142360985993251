import React from "react";
import BigUseCaseCard from "../Common/BigUseCaseCard/BigUseCaseCard";
import { Link } from 'react-router';
import "./CareUseCases.css"

const CareUseCases = ({
  content: { topHeading='',title='', description='', component, data, button, link },bgColor="#F5F2FC", hideBgImage=false
}) => {
  return (
    <div className={`care_use_cases_area ${hideBgImage?' no_bg_img': ''}`} style={{backgroundColor:bgColor}}>
      <div className="container">
        <div className='heading-area-v2 center'>
            {topHeading && <div className='top-small-heading-v2'>{topHeading}</div>}
            {title && <div className='heading-v2 font-56' dangerouslySetInnerHTML={ { __html: (title) } }></div>}
            {description && <p className='sub-heading-v2'>{description}</p>}
        </div>
        <div className="row align-items-center">
          {data.map((item, index) => (
            <div key={'big-resources-card-' + index} className="col-lg-6 col-md-6 col-sm-6">
              <BigUseCaseCard  data={item}  /> 
            </div>
          ))}
        </div>
        {button ?
          <div className="bottom__button mt-3">
            <Link to={link}>{button}</Link>
          </div>
          : null}
      </div>
    </div>
  );
};

export default CareUseCases;
