import React from 'react';
import "./OurDollarBenefitsCard.css";
import 'react-lazy-load-image-component/src/effects/opacity.css';

const OurDollarBenefitsCard = ({
    data = {}, keyIndex=''
}) => {
    const { title = "", insurances = [] } = data
    return (
      
            <div className="col-lg-4 col-md-6 mb-3">
                <div className={`avibra_dollar_benefit_card_box card-id-${keyIndex+1}`}>
                    <div className="benefit_card_title">
                        {title}
                    </div>
                    {insurances.map((e, i) => (
                        <ul className="card_detail_list">
                            <li>
                                
                                <span className="card_detail">{e.insurance}</span> <span className="card_detail_cost"> {e.cost}</span>
                            </li>
                        </ul>
                    ))}
                </div>
            </div>
        
    )

};

export default OurDollarBenefitsCard;
