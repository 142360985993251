import React from 'react'
import "./AvibraProvides.css"

const AvibraProvides = (props) => {
    const { content = {} } = props
    const { tagLine = '', heading = '', data = [] } = content

    return (
        <div className='container'>
            <div className='avibra-provides-container container'>
            <div className='avibra-provides-tag'>{tagLine}</div>
            <div className='avibra-provides-heading' dangerouslySetInnerHTML={ { __html: (heading) } } />
            <div className='avibra-provides-items'>
                {data && data.length > 0 && data.map((item, index) => {
                    return <div className={`avibra-provides-item item-${(index+1)}`} key={index}>
                        <div className='avibra-provides-title'>{item.title}</div>
                        <div className='avibra-provides-desc'>{item.desc}</div>
                        {item.ctaText && <div className='avibra-provides-btn-wrp'>
                            <a className='avibra-provides-btn' href={item.ctaLink}>{item.ctaText}</a>
                        </div>}
                        <div className='avibra-provides-img-wrp'>
                            <div className='img-desktop-v2'>
                                <img src={item.image} className='img-fluid'/>
                            </div>
                            <div className='img-tablet-v2'>
                                <img src={item.imageTablet ? item.imageTablet : item.image} className='img-fluid'/>
                            </div>
                            <div className='img-mobile-v2'>
                                <img src={item.imageMobile} className='img-fluid'/>
                            </div>  
                        </div>

                    </div>
                })}

            </div>


        </div>
        </div>
       
    )
}

export default AvibraProvides