import React, { useState, useEffect, useCallback } from 'react'
import {
    Container,
    Row,
    Col,
    Spinner
} from 'reactstrap';
import { connect } from 'react-redux';
import { setLogEvent, setAmplitudeInit } from '../../../utils/helpers';
import Header from '../../../components/NewDesignV2/Common/Header/Header';
import Footer from '../../../components/NewDesignV2/Common/Footer/Footer';
import NewDesignV2LandingPageData from "../../../../constant/NewDesignV2LandingPageData.json";
import NewDesignV2CaseStudy from "../../../../constant/NewDesignV2CaseStudy.json";
import PartnerCaseStudyData from "../../../../constant/PartnerCaseStudyData.json";
import '../../../../styles/NewDesignV2/main-page.css'
import '../../../../styles/NewDesignV2/main-page-responsive.css';
import "./PartnerCaseStudy.css"
import { getFeparamsFilteredApi } from '../../../api/policyDocumentApi';
import { feparamsAction,UpdateFeparamsInfo,getFeparamsSelectedApi  } from '../../../actions/feparamsAction';
import SeoMetaTags from '../../../components/NewDesign/Common/SeoMetaTags/SeoMetaTags';
import PartnerCaseStudyHeader from '../../../components/NewDesignV2/PartnerCaseStudyHeader/PartnerCaseStudyHeader';
import PartnerWithAvibra from '../PartnerWithAvibra/PartnerWithAvibra';
import AboutCompany from '../AboutCompany/AboutCompany';
import CuriousHow from '../CuriousHow/CuriousHow';
import { useParams } from 'react-router';

const PartnerCaseStudy = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [loader, setLoader] = useState(false)
    const [stickyHeaderClass, setStickyHeaderClass] = useState('row topbar');
    const [activePartnerData, setActivePartnerData] = useState({})
    const params = useParams();

    const { partner = "" } = params;


     useEffect(()=>{
        if(!props?.mainSiteCaseStudies)
            getFeparamsSelectedApi(['mainSiteCaseStudies']).then(res => {
                const { data = {} } = res;
                props.UpdateFeparamsInfo({ prop:'mainSiteCaseStudies', value: data.mainSiteCaseStudies });
            })
            .catch(err => {
                    console.log(err);
            });
       },[])

    useEffect(()=>{
        // if (PartnerCaseStudyData.hasOwnProperty(partner)) {
        //     setActivePartnerData(PartnerCaseStudyData[partner])
        // }
    let mainSiteCaseStudies = props?.mainSiteCaseStudies  ? JSON.parse(unescape(props.mainSiteCaseStudies )) : {};
    console.log('mainSiteCaseStudies', mainSiteCaseStudies)   
   if(Object.keys(mainSiteCaseStudies).length ){
        let {partnerData=[]}=mainSiteCaseStudies?.customerStoriesGridData
        if(Object.keys(partnerData).length){
        let mergedArray =[]
        mergedArray=Object.values(partnerData).flat();
        let primaryData= mergedArray.find((item)=>item.partnerKey==partner)
        console.log('mergedArray', mergedArray)
        setActivePartnerData(primaryData?primaryData.details:{})
        }
    }

    },[ props?.mainSiteCaseStudies ])

    const toggleSticky = useCallback(
        () => {

            const scrollY = window.scrollY ? window.scrollY : document.body.scrollTop
            const scrollHeight = (window.innerWidth <= 768) ? 1 : 100
            scrollY >= scrollHeight ?
                setStickyHeaderClass(`row topbar fixed-header ${(window.innerWidth <= 768) ? '' : 'animated fadeInDown'}`)
                :
                setStickyHeaderClass("row topbar")
        },
        []
    );

    useEffect(() => {
        window.scrollTo(0, 0);
        setLoader(true)
        setTimeout(() => {
            setLoader(false)
        }, 50)
    }, [])
    useEffect(() => {
        const { appConfig = {} } = props
        if (!Object.keys(appConfig).length) {
            getFeparamsFilteredApi().then(res => {
                const { data = {} } = res;
                props.feparamsAction(data);
                let { amplitudeKey = "" } = data;
                setAmplitudeInit(amplitudeKey);
                setLogEvent("Partner Case Study Page loaded", {
                    currentUrl: window.location.href,
                })
            })
                .catch(err => {
                    console.log(err);
                });
        } else setLogEvent("Partner Case Study Page loaded", {
            currentUrl: window.location.href,
        })
        const handleScroll = () => {
            toggleSticky();
        };

        if (window.addEventListener) {
            window.addEventListener("scroll", handleScroll, { capture: true });
        } else {
            window.attachEvent("onscroll", handleScroll);
        }

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [])

    const toggle = () => setIsOpen(!isOpen);
    const { mainSection, footerSection, seoMetaData = {} } = NewDesignV2LandingPageData
    const { download_href } = mainSection.data.headerSection;
    const { headerSection = {}, partnerWithAvibraData = {}, CompanyData = {}, CuriousHowData = {} } = activePartnerData
    const { mainPageAppDownLoadLink = download_href } = props.appConfig;

    return (<> {loader ? <div style={{ textAlign: 'center', marginTop: '20px' }}> <Spinner style={{ width: '2rem', height: '2rem', color: '#787878', marginBottom: '25px' }} /></div> : <Container fluid className="main__container " id="main-wrapper">
        <SeoMetaTags seoData={seoMetaData.homePage} />
        <Row className={`header-menu-topbar-v2  topbar ${stickyHeaderClass}`}>
            <Col className="p-0">
                <Header toggle={toggle} isOpen={isOpen} content={mainSection.data.headerSection} downloadLink={mainPageAppDownLoadLink} extraClassName="light-purple-navbar2w" />
            </Col>
        </Row>
        <Row>
            <Col className="section-area p-0 home-page-header-section-v3 partner-case-study-header-section">
                <PartnerCaseStudyHeader content={headerSection} />
            </Col>
        </Row>

        <Row>
            <Col className='p-0'>
                <PartnerWithAvibra content={partnerWithAvibraData} />
            </Col>
        </Row>
        <Row>
            <Col className='p-0'>
                <AboutCompany content={CompanyData} />
            </Col>
        </Row>
        <Row>
            <Col className='p-0'>
                <CuriousHow content={CuriousHowData} />
            </Col>
        </Row>
        <Row>
            <Col className="p-0">
                <Footer content={footerSection} />
            </Col>
        </Row>
    </Container>}

    </>


    );
}
const mapStateToProps = state => {
    const { appConfig,mainSiteCaseStudies } = state.feparamsReducer;
    return { appConfig,mainSiteCaseStudies };
}

export default connect(mapStateToProps, { feparamsAction,UpdateFeparamsInfo,getFeparamsSelectedApi  })(PartnerCaseStudy);
