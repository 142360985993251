export const SET_PARTNER_DATA = 'SET_PARTNER_DATA';

export const SET_FEPARAMS_DATA = 'SET_FEPARAMS_DATA';

export const SET_PARTNER_ACCESS_TOKEN = 'SET_PARTNER_ACCESS_TOKEN';

export const SET_PARTNER_MARKETPLACE_INBOX = 'SET_PARTNER_MARKETPLACE_INBOX';
export const SET_PARTNER_MARKETPLACE_DETAILS_DATA = "SET_PARTNER_MARKETPLACE_DETAILS_DATA";
export const MARKETPLACE_SHOW_LOADER = "MARKETPLACE_SHOW_LOADER";
export const MARKETPLACE_HIDE_LOADER = "MARKETPLACE_HIDE_LOADER";

export const UNAUTHORIZED_PARTNER = "UNAUTHORIZED_PARTNER";

//LifeInsurance
export const UPDATE_LIFEINSURANCE_INFO = "update _lifeinsurance_info";
export const UPDATE_LIFE_RECOMMENDATIONS = "update_life_recommendations";
export const SHOW_LIFE_WAIT_LOADER = "show_life_wait_loader";
export const HIDE_LIFE_WAIT_LOADER = "hide_life_wait_loader";
export const SET_MAINCARD_DATA_LIST = "SET_MAINCARD_DATA_LIST";
export const UPDATE_PARTNERDATA_INFO = "UPDATE_PARTNERDATA_INFO"

export const UPDATE_FEPARAMS_INFO='UPDATE_FEPARAMS_INFO';