import axios from "axios" 
import {
  CognitoUserPool,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js"
import AWS from "aws-sdk";
import moment from "moment"
import { HashValue, getAge, setLogEvent } from "../utils/helpers";
import { API_URL, POOL_DATA } from "../../constant/constant";

let cognitoUser = null
let sessionToken = ""
let credentials = {}

const userPool = new CognitoUserPool(POOL_DATA)

export const checkIfEmailExistsApi = async email =>
  axios.post(`${API_URL}/profiles/email/available`, {
    email: `${await HashValue(email)}@avibra.com`
  })

export const checkIfPhoneNumberExistsApi = async data =>
  axios.post(`${API_URL}/profiles/contactnumber/available`, data)

export const createUserProfileApi = userInfo =>
  axios.post(`${API_URL}/profiles/create`, userInfo)

export const waitlistUpdateUserProfileApi = userInfo => 
  axios.post(`${API_URL}/waitlist/update`, userInfo)

export const waitlistUpdateStatusApi = status => 
  axios.post(`${API_URL}/waitlist/updatewaitstatusbyid`, status)


  export const signUpUserWithAws = (
  { fullname, email, password }
) => {
  let promise = new Promise(async (resolve, reject) => {
    let attributeList = []
    let hashEmail = `${await HashValue(email)}@avibra.com`
    let hashName = await HashValue(fullname)
    let dataName = {
      Name: "name",
      Value: hashName
    }

    let dataEmail = {
      Name: "email",
      Value: hashEmail
    }

    attributeList.push(new CognitoUserAttribute(dataName))
    attributeList.push(new CognitoUserAttribute(dataEmail))
    userPool.signUp(hashEmail, password, attributeList, null, function(
      err,
      result
    ) {
      if (err) {
        reject(err)
        return
      } else {
        cognitoUser = result.user
        resolve(result)
      }
    })
  })

  return promise
}

export const sendDownloadLink = (number,appLinkUrl) => {
  let payLoad = {
    phonenumber: number,
    message: window.encodeURIComponent(appLinkUrl)
  }

  sendSmsLinkApi(payLoad)
    .then(res => {})
    .catch(err => {})
}

export const sendSmsLinkApi = data => {
  const { phonenumber = "", message = "" } = data
  return axios.get(`${API_URL}/notification/send/smsmessage`, {
    params: {
      phonenumber,
      message
    }
  })
}
export const createDepUserAliases = data => {
  return axios.post(`${API_URL}/profiles/createDepUserAliases`,  data)
}
export const ProcessProfileData = async profile => {
  let dob = profile.dob;
  if(dob && !(moment(dob, 'YYYY-MM-DD', true).isValid())){
      dob = moment(dob.replace(/\s/g, ""), "MM/DD/YYYY").format(
      "YYYY-MM-DD"
    )
  }
  let profileInfo = {
    email: `${await HashValue(profile.email)}@avibra.com`,
    fullname: profile.fullname,
    dob: dob,
    age: getAge(dob),
    gender:
      typeof profile.gender === "object" ? [profile.gender[0]] : profile.gender,
    zipcode: profile.zipcode,
    id: "1"
  }
  return profileInfo
}

export const getRegistrationBotApi = (identity = '') => axios.post(`${API_URL}/profiles/regbotsetup`, { identity  })

export const updateUserUnderwriting = (data) => axios.post(`${API_URL}/profiles/underwriting`, data)

export const verifyCodeApi = (data) => axios.post(`${API_URL}/profiles/code/verify`, data)

export const sendCodeApi = (data) => axios.post(`${API_URL}/profiles/code/creatensend`, data)

export const updateContactApi = (data) => axios.post(`${API_URL}/profiles/update/contact`, data)

export const getFeParamsTextApi = () => axios.get(`${API_URL}/feparams`)

export const sendRefererInfoApi = (refererInfo) => axios.post(`${API_URL}/analytic/incentives/invitee`, refererInfo)
export const aggregateCardsApi = (payload) => axios.post(`${API_URL}/analytic/incentives/aggregate`, payload)

export const uploadColumnDataApi = (columnData) => axios.post(`${API_URL}/profiles/update/columndata`, columnData)

export const updateUserCredentialsForGoogle = (idToken) => {
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.POOL_IDENTITY,
    Logins: {
        'accounts.google.com': idToken
    }
  })
  onSocialLoginCheckUser()
}

const setAccessToken = (credentials) => {
  sessionToken = credentials.sessionToken
  credentials = {
      SecretKey: credentials.secretAccessKey,
      AccessKeyId: credentials.accessKeyId
  };
}


const onSocialLoginCheckUser = () => {
  AWS.config.credentials.get((_) => {
      var sessionToken = AWS.config.credentials.sessionToken
      setAccessToken(AWS.config.credentials)
      if (sessionToken) {
        setLogEvent('User token Exchanged')
      } else {
        setLogEvent('User  token Empty')
      }
  })
}

export const acceptTncAPI = profileId => axios.post(`${API_URL}/profiles/tnc/${profileId}`)
  
export const createUserAliasAndSetPhoneProfileIdApi = data => {
  return axios.post(`${API_URL}/profiles/createAliasUserAndSetPhoneProfileId`, data)
}

export const sendNewSmsLinkApi = data => {
  return axios.post(`${API_URL}/message/send`, data)
}


export const sendDownloadMessage = data => {
  return axios.post(`${API_URL}/message/download`, data)
}