import React, { Component } from 'react'
import ThemeContext from './ThemeContext';

class ThemeContextProvider extends Component {

    state = {
        theme: {
            backgroundColor:'transparent',
            cardContainerBgColor:'#f8f8f8',
            cardColor:'#fff',
            textColor: '#000'
        }
    };

    componentDidMount () {

    }

    render() {
        return (
            <ThemeContext.Provider
                value={{...this.state}}
            >
                {this.props.children}
            </ThemeContext.Provider>
        );
    }
}

export default ThemeContextProvider