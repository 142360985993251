// import "./RestaurantFirstSection.css"
import React,{useState,useEffect} from 'react'
import "./CareProvidesSection.css"
import {
    Container,
    Row,
    Col, Collapse, Button,NavLink
} from 'reactstrap';
// import NavLink from 'reactstrap/lib/NavLink';
import Slider from 'react-slick';

const CareProvidesSection = ({ providerSectionData }) => {
    const [current, setCurrent] = useState(0);
    const [collapse, setCollapse] = useState('');
    // const {title, subTitle, faqs=[]} = content;

    const {topHeading='',heading='',subHeading='',btnCta='',btnLink='',btnCtaTablet='',btnCtaMobile='',detailHeading='',faqs=[],bannerImages='',bottomBtnCta,bottomBtnLink,applyCss=false,statsData=[]}=providerSectionData
    const {desktop='',tablet='',mobile=''} = bannerImages;
    const [faq,setFaq] = useState([]);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        centerMode: true,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: false,
              infinite: false,
            },
          },
        ]
      };
    useEffect(() => {
        setFaq(faqs);
      }, [])
      const toggle = (e) => {
        let event = e.target.dataset.event;
        setCollapse( collapse === Number(event) ? null : Number(event) );
      }
      console.log('faq====>',collapse, faq)
  return (
    <section className={`care-provides ${applyCss?'add-background':''}`}>
        <div className='care-provides-heading'>
            <div className='container'>
                <div className='heading-area-v2'>
                    <div className='top-small-heading-v2'>{topHeading}</div>
                    <div className='heading-v2' dangerouslySetInnerHTML={ { __html: (heading) } }></div>
                    {/* <h2 dangerouslySetInnerHTML={ { __html: (heading) } }></h2> */}
                    {subHeading && <p className='sub-heading-v2'>{subHeading}</p>}
                    {btnCta && <a className='learn_more learn_more_for_desktop' href={btnLink}>{btnCta}</a>}
                    {btnCtaTablet && <a className='learn_more learn_more_for_tablet' href={btnLink}>{btnCtaTablet}</a>}
                </div>
                <div className='whyRestaurants'>
                    <div className='row'>
                        <div className='col-lg-5 col-md-12 col-sm-12'>
                            {desktop ? <>
                            <div className='care_provides_img_desktop'>
                                <img src={desktop} className='img-fluid'></img>
                            </div>
                            <div className='care_provides_img_tablet'>
                                <img src={tablet ? tablet : desktop} className='img-fluid'></img>
                            </div>
                            <div className='care_provides_img_mobile'>
                                <img src={mobile ? mobile : desktop} className='img-fluid'></img>
                            </div>
                            </> : null}
                        </div>
                        <div className='col-lg-7 col-md-12 col-sm-12'>
                            <div className='restaurants_details'>
                                {detailHeading ? <div className='r_details_heading'>
                                    <h3 dangerouslySetInnerHTML={ { __html: (detailHeading) } }></h3>
                                </div> : null}
                                <div className="faqs__area pt-0 care-provides">
                                    <div className="faqs__area-list-cont">
                                        <Row>
                                            <Col className="col-md-12 col-lg-12 col-xl-12">
                                            <div className="row faqs__row justify-content-center">
                                                {faq?.map((faq, index) => (
                                                <><div key={index} className={`faqs__row__item ${index==0?'first':''}`} data-event={index} onClick={toggle} id={"toggler"+index}>
                                                    <div className={`faq__que ${collapse === index ? 'fontBold' : 'fontNormal'}`} data-event={index} >
                                                    <span dangerouslySetInnerHTML={{__html: (faq.title)}} data-event={index}></span>
                                                    <span className="faq__que__icon" data-event={index}>{collapse === index ? <div className={`faq__plus__icon ${applyCss?'faq__plus__icon_bg':''}` }  data-event={index}><i class="fa fa-minus" aria-hidden="true" data-event={index}></i></div> : <div className={`faq__plus__icon ${applyCss?'faq__plus__icon_bg':''}` } data-event={index}><i class="fa fa-plus" aria-hidden="true" data-event={index}></i></div> }</span>
                                                    </div>
                                                    <Collapse isOpen={collapse === index}>
                                                        <div className="faq__ans" dangerouslySetInnerHTML={{__html: (faq.description)}}/>
                                                    </Collapse>
                                                </div>
                                                <div className='faq-divider-line'></div>
                                                </>
                                                ))}
                                            </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                {bottomBtnCta && <div className='mordernWay_head mordernWay_bottom'>
                  <a className='learn_more' href={bottomBtnLink}>{bottomBtnCta}</a>
                </div>}
            </div> 
        </div>
    </section>
  )
}

export default CareProvidesSection