import React from "react";

const BenefitsCard = ({
  data: {
    title,
  },
}) => {
  return (
    <>
      <div>
        <div className="card__beni">
          <div className="ico__beni">
          <svg width={55} height={55} viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <rect x="0.276367" y="0.101562" width="54.0674" height="54.0674" rx="27.0337" fill="#E0F6EC" />
          <path d="M15 28L23.5 36.5L39.5 18" stroke="#4E887A" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="54.0674" height="54.0674" fill="white" transform="translate(0.276367 0.101562)" />
          </clipPath>
        </defs>
      </svg>
          </div>
          <div className="benifits__item">
            {title}
          </div>
        </div>
      </div>
    </>
  );
};

export default BenefitsCard;
