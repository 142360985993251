import axios from "axios";
import { SET_FEPARAMS_DATA,UPDATE_FEPARAMS_INFO } from "../types";

export function feparamsAction (data) {
    return {
        type: SET_FEPARAMS_DATA,
        payload: data
    }
}

export const UpdateFeparamsInfo = ({ prop, value}) => {
    return {
        type: UPDATE_FEPARAMS_INFO,
        payload: {prop, value}
    }
}

export const getFeparamsSelectedApi = (payload) => axios.post(`/feparams/filtered`,payload)
