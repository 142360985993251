import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

const SeoMetaTags = (props) => {
    const { title='Avibra', description='', keywords='' } = props?.seoData
    //console.log("SeoMetaTags seoData ===>", props.seoData)
    return <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords}/>
    </Helmet>

}

export default SeoMetaTags