import axios from 'axios'
import { apiGet,apiPost } from "../utils/api"

import { API_URL } from "../../constant/constant"

export const postPartner = (requestUrl, data, headers={}) =>  apiPost(`${API_URL}${requestUrl}`, data, headers);

export const getMarketPlacePartnerApi  = (data, headers={}) =>  apiPost(`${API_URL}/marketplace/external/productTypes`, data, headers);

export const getMarketPlacePartnerPolicyDetailsApi = (policyid, headers={}) => {
    return apiGet(`${API_URL}/marketplace/policy/${policyid}`,{},headers)
}
export const getMarketPlaceNewsDetailApi = (url, data, headers={}) => {
    return apiPost(`${API_URL}${url}`, data, headers={})
}

export const apiGetPartner = partnerKey => axios.get(`/lite/${partnerKey}`)
export const apiGetPartnerWithCampaignName = (partnerKey,campaignName) => { 
    if(campaignName)
        return axios.get(`/lite/${partnerKey}/${campaignName}`)
    else    
         return axios.get(`/lite/${partnerKey}`)
}


//export const apiGetPartnerExternalExists = externalKey => axios.get(`${API_URL}/external/${externalKey}`)

export const apiGetPartnerExternalExists = (externalKey, partnerProfileId) => axios.post(`/user/exists`,{externalID:externalKey,invitedBy:partnerProfileId})


export const GetPartnerRegBot = (requestUrl,headers) =>  apiGet(`${API_URL}/${requestUrl}`, '', headers);
