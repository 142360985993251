import React from "react";
import "./HowItWorksCard.css"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const HowItWorksCard = ({
  data: {
    image,
    title,
    index,
    subtitle
  },
}) => {
  return (
    <>
      <div className="how_it_work_box">
        <div className="how_it_work_image">
        <LazyLoadImage src={image} alt={title.replace(/<[^>]*>?/gm, '')} className="img-fluid" effect="opacity" />
        </div>
        <div className="how_it_work_detail">
          <h3>{title}</h3>
          <p>{subtitle}</p>
        </div>
      </div>
    </>
  );
};

export default HowItWorksCard;
