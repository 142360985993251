import moment from "moment";
import React from "react";
import "./MembershipDetails.css";
import FooterSection from "./FooterSection";
import { getImageUrl} from "../../utils/helpers";

const MembershipDetails = (props) => {
 const {pageData: {title, image, data, footer, index}, rightPolicyData=[], slideNo=''} = props

  
  return (
    <>
      <div className="card-guide">
        <div className="container h-100 p-0">
          <div className="main__color member-details">
            <div className="details__main__guide">
              <div className="left__side">
                <div className="left__title">
                  <h1 dangerouslySetInnerHTML = {{__html:(title)}}>
                  </h1>
                </div>
                <div className="box__table">
                  {/* <div className="main__table">
                    {rightData?.map((item, index) => {
                      return (
                        <>
                          <div className="main__title__box">
                            <div className="left__side__tile">{item.title}</div>
                            <div className="right__side__tile">
                              {item.desc}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div> */}
                  {
                    rightPolicyData?.map((item , index)=>{
                      if(!item.desc){
                        return null;
                      }
                      return(
                          <table class="table" key={`rightPolicyData-${index}`}>
                            <tbody>
                              <tr>
                                <td>{item.title}</td>
                                <td>{item.desc}</td>
                              </tr>
                            </tbody>
                          </table>
                      )
                    })
                  }
                
                </div>
              </div>
              <div className="right__side">
                <img
                  src={getImageUrl(image)}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <FooterSection slideName={footer} slideNo={slideNo} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MembershipDetails;
