import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import "./BigResourcesCard.css"

const BigResourcesCard = ({ data: { title, description, image, button,bgColor,tags=[],showTags="" } }) => {
    return (
        <div className="main__resource__card left__side" style={{backgroundColor:bgColor ? bgColor : '#F7FCFB'}}>
            <div className="img__resource">
                <LazyLoadImage
                    className="d-block img-fluid"
                    src={image}
                    alt={title.replace(/<[^>]*>?/gm, '')}
                    effect="opacity"
                />
            </div>
            <div className="section__content">
                <h3>{title}</h3>
                <p className="resource__description">
                   {description}
                </p>
                {button ? <a href="#">{button}</a> : null }
                {(showTags=='true' && tags && tags.length) ? <div style={{marginTop:'36px',marginBottom:'20px'}}>
                {tags.map((tag, i) => (
                  tag.name &&  <span className = {`commit-button`} key={`tag-${i}`} style={{color:tag.textColor || '#00CF9A', backgroundColor:tag.bgColor || '#E8FCF7', marginRight:'10px',padding:'7px 10px',borderRadius:'20px'}}>{tag.name}</span>
                ))}
                </div> : null }
            </div>
        </div>
    );
};

export default BigResourcesCard;
