import React, { useEffect, useState } from 'react'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Container,
    Modal, 
    ModalHeader,
    ModalBody,
	Row,
	Col
} from 'reactstrap';
import { Link } from 'react-router';
import GetStartedButton from '../GetStartedButton/GetStartedButton'
import { setLogEvent } from '../../../../utils/helpers';
import avibraLogo from '../../../../../images/avibra-logo.svg';
import avibraWhiteLogo from '../../../../../images/avibra-white-logo.svg'
//import { HashLink } from 'react-router-hash-link';
import { GetEnvVar } from "../../../../utils/AppConfig";
import "./Header.css"

let workerLoginLink = 'https://benefits.avibra.com/?_partnerkey_=dobs'
if (GetEnvVar('NODE_ENV')  === "development" || GetEnvVar('NODE_ENV')  === "preprod") {
    workerLoginLink = "https://benefits-staging.avibra.com/?_partnerkey_=dobs";
}
const Header = ({ toggle, isOpen, content, downloadLink, darkMode='',hideGetStarted=false,showContactUs=false, CustomGetStartedButton }) => {
    const { headerLinks, ctaButton={}, ctaButtonContact={}} = content
    const [addClassMenu, setClassMenu] = useState(false); 
    const [modal, setModal] = useState(false);

    // let url = window.location.pathname
    // console.log("url", url)

    const toggleMeeting = () => setModal(!modal);
    const download_href = downloadLink;
    const pagePath = window.location.pathname;
    useEffect(() => {
		if(window.location.pathname === '/partner'){
            setClassMenu(true);
		}
	}, [])
    const clickedContactUs = () =>{
        setLogEvent("User Clicked On Contact Us Button");
        toggleMeeting();
        // document.getElementsByClassName("join-learn-more")[0].scrollIntoView({behavior: "smooth"})
        // setTimeout(() => {
        //     isOpen ? toggle() : null;
        // }, 500)
    }
    const onWorkerLoginClick = ()=>{
        setLogEvent("User Clicked On Member Login Button");
    }
    const onBookADemoClick = ()=>{
        setLogEvent("User Clicked On Book A Demo Button");
    }
    const onDownloadClick = ()=>{
        setLogEvent("User Clicked On Get Started Button");
    }
    let getLink = (link, mobile=false) => {
        return link.href.includes("http") ? 
        (<a href={link.href} target="_blank" className="nav-link one-item">{link.title}</a>)
        : 
        <Link to={link.href} className={`${pagePath==link.href && !mobile ? 'nav-link one-item selected' : `nav-link one-item`}`}>{link.title}
        {/* {(pagePath==link.href && !mobile) ? <div className="active-menu"></div> : ''} */}
        </Link>
    }
    //console.log("content ==>", ctaButton)
    return <div className="header-section container ">
        <Row>
            <Col className="mobile-nav-menu">
        <div className={`landing-header-new ${darkMode}`}>
        <Navbar light expand="md">
           {pagePath!='/disclosures' ? <Link  className="logo-link" to='/'><img src={(darkMode=='dark-mode') ? avibraWhiteLogo : avibraLogo}  alt="Avibra logo"/></Link>
            :<div className="logo-link"><img src={(darkMode=='dark-mode') ? avibraWhiteLogo : avibraLogo}  alt="Avibra logo"/></div>
           }
            {pagePath!='/disclosures' && <div className={`navbar-btn d-flex align-items-center`}>
            {showContactUs ? 
                    <NavLink className={`${ctaButtonContact.headerStyleName}  download-btn contact-us`}  style={{cursor:'pointer'}} onClick={()=>clickedContactUs()}>
                            <span className="download-link">{ctaButtonContact.text}</span>
                    </NavLink>
                    : 
                    <NavLink className={`${ctaButton.headerStyleName} mt-0 download-btn d-md-none `}>
                    <a href={ctaButton.action} target="_blank" className="download-link" onClick={()=>onDownloadClick()}>
                        {ctaButton.text}
                    </a>
                    </NavLink>
             }
               
               
             <NavbarToggler onClick={toggle} className={darkMode}/>
             </div> }
           {pagePath!='/disclosures' && <Collapse navbar className="desktop__menu">
                <Nav className={addClassMenu ? "mr-auto header-links-container header-link-right" : "mr-auto header-links-container "} navbar>
                    {
                        headerLinks.map((link, index) => {
                            if(link.dropdownMenu && link.dropdownMenu.length > 0){
                                return (
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>
                                            {link.title}
                                        </DropdownToggle>
                                        <DropdownMenu left>
                                            {
                                                link.dropdownMenu.map((ddItem, index) => {
                                                    return <NavItem key={index} className="dropdown-item">
                                                            <Link to={ddItem.href} className="nav-link">{ddItem.title}</Link>
                                                        </NavItem>
                                                })
                                            }
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    )
                            } else{
                                return <NavItem key={index}>
                                    {/* {link.type == 'hashLink' ? 
                                    <HashLink smooth to={link.href} className="nav-link"
                                        scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'end' })}>
                                        {link.title}
                                    </HashLink>
                                    :
                                    getLink(link) 
                                    } */}
                                    {getLink(link)}
                                </NavItem>
                            }
                        })
                    }
                    <NavItem style={{marginLeft:'20px'}}>
                        <GetStartedButton 
                            text="Member Login"
                            link={workerLoginLink}
                            onClick={()=>onWorkerLoginClick()}
                            bgColor={'#F5F2FC'}
                            textColor={"#292C4E"}
                            textTransform={"capitalize"}
                        />
                    </NavItem>
                    {!hideGetStarted ? 
                    <NavItem style={{marginLeft:'20px'}}>
                        {/* <GetStartedButton 
                            text="BOOK A DEMO"
                            link={'/book-a-demo'}
                            onClick={()=>onBookADemoClick()}
                            bgColor={'#774AD9'}
                        /> */}
                        <a href={'/book-a-demo'}>
                        <button className='demo-btn' onClick={()=>onBookADemoClick()}>
                            BOOK A DEMO
                        </button>
                        </a>
                    </NavItem>
                    : null }
                     {CustomGetStartedButton ? 
                    <NavItem style={{marginLeft:'20px'}}>
                        {/* <GetStartedButton 
                            text="BOOK A DEMO"
                            link={'/book-a-demo'}
                            onClick={()=>onBookADemoClick()}
                            bgColor={'#774AD9'}
                        /> */}
                        <a href={'/book-a-demo'}>
                        <button className='demo-btn' onClick={()=>onBookADemoClick()}>
                            BOOK A DEMO
                        </button>
                        </a>
                    </NavItem>
                    : null }
                    {showContactUs ? 
                    <NavItem>
                        <NavLink className={`${ctaButtonContact.headerStyleName}  download-btn`} style={{cursor:'pointer'}} onClick={()=>clickedContactUs()}><span className="download-link">{ctaButtonContact.text}</span></NavLink>
                    </NavItem>
                    : null }
                </Nav>
            </Collapse>}
            <Modal isOpen={isOpen} toggle={toggle} className="header-burger-old header-burger-menu-popup-modal">
                <ModalHeader toggle={toggle}>
                    <Link to="/" className="logo-link"><img src={avibraLogo} alt="Avibra logo" style={{height:'30px'}} /></Link>
                </ModalHeader>
                <ModalBody>
                <Nav className={addClassMenu ? "mr-auto header-links-container header-link-right" : "mr-auto header-links-container"} navbar>
                    {
                        headerLinks.map((link, index) => {
                            if(link.dropdownMenu && link.dropdownMenu.length > 0){
                                return (
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>
                                            {link.title}
                                        </DropdownToggle>
                                        <DropdownMenu center>
                                            {
                                                link.dropdownMenu.map((ddItem, index) => {
                                                    return <NavItem key={index} className="dropdown-item">
                                                            <Link to={ddItem.href} className="nav-link">{ddItem.title}</Link>
                                                        </NavItem>
                                                })
                                            }
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    )
                            } else{
                            return <NavItem key={index}>
                                {/* {link.type == 'hashLink' ? 
                                    <HashLink smooth to={link.href} className="nav-link"
                                        scroll={el => { el.scrollIntoView({ behavior: 'instant', block: 'end' }); toggle(); }}>
                                        {link.title}
                                    </HashLink>
                                    :
                                    getLink(link, true) 
                                    }*/}
                                {getLink(link, true)}
                            </NavItem>
                            }
                        })
                    }
                    {!hideGetStarted ? <>
                    <NavItem className={"download-nav-mobile btn-nav-menu"}>
                        <GetStartedButton 
                            text="Member Login"
                            link={workerLoginLink}
                            onClick={()=>onWorkerLoginClick()}
                            bgColor={'#fff'}
                            textColor={"#121213"}
                            textTransform={"capitalize"}
                        />
                    </NavItem>
                    <NavItem className={"download-nav-mobile btn-nav-menu"}>
                        {/* <GetStartedButton 
                            text="BOOK A DEMO"
                            link={'/book-a-demo'}
                            onClick={()=>onBookADemoClick()}
                            className={`fullwidth`}
                            bgColor={'#774AD9'}
                        /> */}
                        <a href={'/book-a-demo'}>
                        <button className='demo-btn' onClick={()=>onBookADemoClick()}>
                            BOOK A DEMO
                        </button>
                        </a>
                    </NavItem> 
                    
                    </>  : null}
                    {showContactUs ? 
                    <NavItem>
                        <NavLink className={`${ctaButtonContact.headerStyleName}  download-btn`} style={{cursor:'pointer'}} onClick={()=>clickedContactUs()}><span className="download-link">{ctaButtonContact.text}</span></NavLink>
                    </NavItem> : null }
                </Nav>
                {CustomGetStartedButton ? <div className="mobile__get__start__btn">
                        {/* <GetStartedButton 
                            text="BOOK A DEMO"
                            link={'/book-a-demo'}
                            onClick={()=>onBookADemoClick()}
                            bgColor={'#774AD9'}
                        /> */}
                        <a href={'/book-a-demo'}>
                        <button className='demo-btn' onClick={()=>onBookADemoClick()}>
                            BOOK A DEMO
                        </button>
                        </a>
                </div> : null}
                
                </ModalBody>
            </Modal>
        </Navbar>
    </div>
        </Col>
            </Row>
        </div>
}

export default Header