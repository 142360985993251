import { SET_FEPARAMS_DATA,UPDATE_FEPARAMS_INFO } from "../types";

const INITIAL_STATE = {
    appConfig: {}
}

export default (state = INITIAL_STATE, action) => {
    console.log('action', action)
    switch(action.type){
        case UPDATE_FEPARAMS_INFO:
            return {  ...state , [action.payload.prop]: action.payload.value  };
        case SET_FEPARAMS_DATA:
            return { ...state , appConfig: action.payload }
        default:
            return state;
    }
}