import React from 'react';
import "./PressReleasesItem.css"; 

const PressReleasesItem = ({
    data = {},keyIndex=''
}) => {
    const { title = "", date = "", link = "" } = data
    //console.log("PressReleasesItem ===>", data)
    return (

        <div key={keyIndex} className={`press-slider-item ${keyIndex}`} >
            <div >
                <div className={`press-plan-item ${keyIndex}`}>
                    <div className="press-plan-title"><a href={link} target="_blank">{title}</a></div>
                    <div className="press-plan-date">{date}</div>
                    <div className="press-plan-link">
                        <a href={link} target="_blank"><i class="fa fa-arrow-right" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default PressReleasesItem;
