import React from "react";
import "./PricingCard.css";

const PricingCard = ({ data: { title, description, advantages, dollar } }) => {
  return (
    <div className="pricing__card__box">
      <div className="pricing__title">{title}</div>

      <div className="pricing__content">{description}</div>
      <div className="advantages__map">
        {advantages.map((e) => (
          <div className="advantages__map__element">{e}</div>
        ))}
      </div>
      <div className="pricing__main">{dollar}</div>
    </div>
  );
};

export default PricingCard;
