import React from 'react';
import "./UserBlock.css";
const UserBlock = ({data = {}}) => {
    
    const {imageUrl ="", title = "", description=""} = data;

    return(
    <div className="user-block-container">
        <div className="user-block-image-container" style={ {backgroundImage: `url(${imageUrl})`}}>
        </div>
        <div className="user-block-content">
            <div className="user-block-title">
                {title}
            </div>
            <div  className="user-block-description">
                {description}
            </div>
        </div>
    </div>
)};

export default UserBlock;
