import React, { useState, useEffect, useCallback } from 'react'
import {
    Container,
    Row,
    Col,
    Spinner
} from 'reactstrap';
import { connect } from 'react-redux';
import { setLogEvent, setAmplitudeInit } from '../../../utils/helpers';
import Header from '../../../components/NewDesignV2/Common/Header/Header';
import Footer from '../../../components/NewDesignV2/Common/Footer/Footer';
import NewDesignV2LandingPageData from "../../../../constant/NewDesignV2LandingPageData.json";
import '../../../../styles/NewDesignV2/main-page.css'
import '../../../../styles/NewDesignV2/main-page-responsive.css';
import "./WhitePapers.css"
import { getFeparamsFilteredApi } from '../../../api/policyDocumentApi';
import { feparamsAction,UpdateFeparamsInfo,getFeparamsSelectedApi } from '../../../actions/feparamsAction';
import SeoMetaTags from '../../../components/NewDesign/Common/SeoMetaTags/SeoMetaTags';
import WhitePapersCard from '../../../components/NewDesignV2/Common/WhitePapersCard/WhitePapersCard';

const WhitePapers = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [loader, setLoader] = useState(false)
    const [stickyHeaderClass, setStickyHeaderClass] = useState('row topbar');
    const toggleSticky = useCallback(
        () => {

            const scrollY = window.scrollY ? window.scrollY : document.body.scrollTop
            const scrollHeight = (window.innerWidth <= 768) ? 1 : 100
            scrollY >= scrollHeight ?
                setStickyHeaderClass(`row topbar fixed-header ${(window.innerWidth <= 768) ? '' : 'animated fadeInDown'}`)
                :
                setStickyHeaderClass("row topbar")
        },
        []
    );

    useEffect(() => {
        window.scrollTo(0, 0);
        setLoader(true)
        setTimeout(() => {
            setLoader(false)
        }, 50)
    }, [])

  
    useEffect(() => {
        const { appConfig = {} } = props
        if (!Object.keys(appConfig).length) {
            getFeparamsFilteredApi().then(res => {
                const { data = {} } = res;
                props.feparamsAction(data);
                let { amplitudeKey = "" } = data;
                setAmplitudeInit(amplitudeKey);
                setLogEvent("White Papers Page loaded", {
                    currentUrl: window.location.href,
                })
            })
                .catch(err => {
                    console.log(err);
                });
        } else setLogEvent("White Papers Page loaded", {
            currentUrl: window.location.href,
        })
        const handleScroll = () => {
            toggleSticky();
        };

        if (window.addEventListener) {
            window.addEventListener("scroll", handleScroll, { capture: true });
        } else {
            window.attachEvent("onscroll", handleScroll);
        }

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [])

    const toggle = () => setIsOpen(!isOpen);
    const { mainSection, footerSection,seoMetaData = {}} = NewDesignV2LandingPageData
    const { download_href } = mainSection.data.headerSection;

    const { mainPageAppDownLoadLink = download_href,mainSiteWhitePaperData } = props.appConfig;
    // const {mainSiteCaseStudies}=props
    let mainSiteWhitePaperDataList = mainSiteWhitePaperData  ? JSON.parse(unescape(mainSiteWhitePaperData )) : {};
    console.log('mainSiteWhitePaperData',mainSiteWhitePaperDataList)
   
   return (<> {loader  || Object.keys(mainSiteWhitePaperDataList).length==0? <div style={{ textAlign: 'center', marginTop: '20px' }}> <Spinner style={{ width: '2rem', height: '2rem', color: '#787878', marginBottom: '25px' }} /></div> : <Container fluid className="main__container " id="main-wrapper">
        <SeoMetaTags seoData={seoMetaData.whitePaperPage} />
        <Row className={`header-menu-topbar-v2 topbar ${stickyHeaderClass}`}>
            <Col className="p-0">
                <Header toggle={toggle} isOpen={isOpen} content={mainSection.data.headerSection} downloadLink={mainPageAppDownLoadLink} />
            </Col>
        </Row>
        <Row >
            <Col className="p-0 white-papers-card-page light-purple-bg">
                <WhitePapersCard content={mainSiteWhitePaperDataList} />
            </Col>
        </Row>
        <Row>
            <Col className="p-0">
                <Footer content={footerSection} />
            </Col>
        </Row>
    </Container>}

    </>


    );
}
const mapStateToProps = state => {
    const { appConfig,mainSiteCaseStudies } = state.feparamsReducer;
    return { appConfig,mainSiteCaseStudies };
}

export default connect(mapStateToProps, { feparamsAction,UpdateFeparamsInfo,getFeparamsSelectedApi })(WhitePapers);
