import React from 'react'
import "./WhoWeServe.css"
const WhoWeServe = (props) => {
    const { content = {} } = props
    const { tagLine = '', heading = '',description='', data = [] } = content
    return (
        <div className='container'>
           <div className='who-we-serve-container'>
            {tagLine && <div className='who-we-serve-tag'>{tagLine}</div>}
            {heading && <div className='who-we-serve-title'>{heading}</div>}
            {description && <div className='who-we-serve-description'>{description}</div>}
            {/* <div className='who-we-serve-line'></div> */}
            <div className='who-we-serve-items'>
                {data && data.length > 0 && data.map((item, index) => {
                    return <div className='who-we-serve-item' key={index}>
                        <div className='who-we-serve-left-part'>
                        {item?.image ? <div className='who-we-serve-img-wrp'>
                            <img src={item.image} className='img-fluid'/>
                        </div> : null}
                        <div className='who-we-serve-content'>
                            <div className='who-we-serve-subheading'>{item.title}</div>
                            <div className='who-we-serve-desc'>{item.desc}</div>
                        </div>
                        </div>
                        <div className='who-we-serve-right-part'>
                            <div className='who-we-serve-learn-more-btn'>
                                <a href={item.linkUrl}>{item.linkCta}</a>
                            </div>
                            {item?.partnerLogos && item.partnerLogos.length > 0 ?
                            <div class="who-we-serve-partner-logo-items">
                                {item.partnerLogos.map((plogo, ind) => {
                                    return <img class="who-we-serve-logo-item" src={plogo} />
                                })}
                            </div> : null}
                        </div>
                    </div>
                })}


            </div>
        </div>
        </div>
    )
}

export default WhoWeServe