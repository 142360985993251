import React, { useEffect } from 'react';
import "./LovedBy.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const LovedBy = ({lovedByData}) => {

    if(lovedByData && lovedByData.data && lovedByData.data.length)
    return (
        <div className={`${lovedByData?.addClass} loved-by `}>
            {lovedByData.heading && <div className="loved-by-heading">{lovedByData.heading}</div>}
            <div className="loved-by-items">
            {
            lovedByData && lovedByData.data.map((item, index) => {
                return  <div key={index} className={`loved-by-item item-${index+1}`}>
                            <LazyLoadImage src={item.image} alt={item.name} class="img-fluid" effect="opacity"/>
                        </div>
            })
            }
            </div>
        </div>
    )

    return (<div className="loved-by">
    <div className="loved-by-heading"></div>
    <div className="loved-by-items">
      <div key={1} className={`loved-by-item item-1`}></div>
      <div key={2} className={`loved-by-item item-2`}></div>
      <div key={3} className={`loved-by-item item-3`}></div>
      <div key={4} className={`loved-by-item item-4`}></div>
    </div>
</div>)
}

export default LovedBy;
