import React, { useRef, useEffect,useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { setLogEvent } from '../../../utils/helpers';
import StarRating from '../Common/StarRating/StarRating';
import GetStartedButton from "../Common/GetStartedButton/GetStartedButton";
import "./HomeHeaderSectionNew.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
//import { AnimateOnChange } from 'react-animation';
import {Animated} from "react-animated-css";
import awardsIcon from "../../../../images/newDesign/awards_icon.png"


import _ from 'lodash';
const HomeHeaderSectionNew = (props) => {
    const { content = {}, appLinkUrl = "/", target = "_blank"} = props
    const graph = useRef(null);
    const [headingText, setHeadingText] = useState('');
    const [isVisible, setIsVisible] = useState(true);
    const [emailId, setEmailId] = useState('');
    const { avibraRating = {},awardMessage='',mainDescription='',applyStyle='',leftHeaderMobileAppImg='',rightHeaderMobileAppImg='',mobileHeaderMobileAppImg='' } = content;
    useEffect(() => {
        rotateSliderText()
    }, []);
    const onDownloadClick = () => {
        setLogEvent("User Clicked On Get Started Button");
    }
    const submitEmailForm = () => {
        setLogEvent("User Clicked On Email Form Learn More Button",{"email":emailId});
        if(emailId)
        window.location = `/book-a-demo?email=${emailId}`;
        else
        window.location = `/book-a-demo`
    }
    const rotateSliderText = (count=0) => {
        if(content?.slidingTitle){
            const terms = content.slidingTitle;
            count = (count >= terms.length) ? 0 : count;
            setHeadingText(terms[count]?terms[count]:terms[0])
            setIsVisible(true)
            //console.log("rotateSliderText ==>", count,"<=>",count, terms[count] , terms.length);
            setTimeout(()=>{
                setIsVisible(false)
            }, 2850);
            setTimeout(()=>{
                rotateSliderText(count+1)
            }, 3000);
        }
    }

    let renderedHeading = ""
    //console.log('content===>', content)
    if (content.title instanceof Array) {
        renderedHeading = content.title.map((heading, index) =>
            <div dangerouslySetInnerHTML={{ __html: (heading) }}></div>
        )
        //console.log('content===>if', content)
    } else {
        renderedHeading = <span dangerouslySetInnerHTML={{ __html: (content.title) }}></span>
        //console.log('content===>else', content)
    }
    let description = content.subTitle;
    let descriptionPart1 = '';
    let descriptionPart2 = '';
    const descriptionArray = description.split("#@#");
    if (description.includes("#@#")) {
        descriptionPart1 = descriptionArray[0];
        descriptionPart2 = descriptionArray[1];
    } else {
        descriptionPart1 = description;
    }
    const headerDesktopImg = content.backgroundImg.desktop;
    // const headerRightImgMobile = content.backgroundImg.mobile;
    const headerDesktopLeftBg = '';//content.backgroundImg.desktopLeftBg;
    return (
        <div className={`main-header-section ${applyStyle?'new-header-style':''} home-header-section-v2`}>
            <div className={`main-header-content ${target}target-old`}>
                <div className="main-header-desc-v2">
                    <div class="landing-container">
                        <div className={`header_area header_area_new header-section container`}>     {/* container-fluid` style={{ backgroundImage: `url(${headerRightImgDesktop})` }}*/}
                            <Row>   {/*style={{ backgroundImage: `url(${headerDesktopLeftBg})` }}*/}
                                <Col className="image-lower1" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <div className='home-header-center-heading-dara'>
                                    <div className="left" style={{ paddingLeft: '0px' }}>
                                        <div className="main-header-text center">
                                            {/* {awardMessage ? <div className='award-message-area'><img src={awardsIcon} className='award-icon'/> {awardMessage}</div> : ''} */}
                                            <div class="main-header-hero-heading center">
                                                <div class="hero-text-big hero-text-gradient">CARE</div>
                                                <div className='hero-heading-text-area'>
                                                <div class="hero-text-small">
                                                    <div>Comprehensive</div> <div>Assistance</div> and <div>Reward</div> <div>Ecosystem</div>
                                                </div>
                                                <div style={{clear:'both'}}></div>
                                                <div className="title">Built For &nbsp;
                                                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={isVisible}>
                                                    <span dangerouslySetInnerHTML={{ __html: (headingText) }}></span>
                                                </Animated>
                                                </div>
                                                <div style={{clear:'both'}}></div>
                                                <div className="sub-title">
                                                    <div dangerouslySetInnerHTML={{ __html: (descriptionPart1) }}></div>
                                                </div>
                                                <div style={{clear:'both'}}></div>
                                                {mainDescription ? <div className="main_second_header_description" dangerouslySetInnerHTML={{ __html: (mainDescription) }}></div> : null}
                                                <div style={{clear:'both'}}></div>
                                                {/* <div className="header-get-button-and-rating">
                                                {(content.buttonText) ? <div className="header-claim-btn-cont1">
                                                    <GetStartedButton
                                                        bgColor={'#774AD9'}
                                                        onClick={() => onDownloadClick()}
                                                        text={content.buttonText}
                                                        link={appLinkUrl}
                                                        target={target}
                                                        padding={'15px 45px'}
                                                    />
                                                </div> : null}
                                                </div> */}
                                                </div>
                                            </div>
                                            <div style={{clear:'both'}}></div>
                                            {/* <div className="title">
                                                {renderedHeading}
                                                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={isVisible}>
                                                    <span dangerouslySetInnerHTML={{ __html: (headingText) }}></span>
                                                </Animated>
                                            </div> */}
                                            
                                            <div className={`main__header__inner ${content?.addClass}`}>
                                            {/* <div className="header-get-button-and-rating">
                                                {(content.buttonText) ? <div className="header-claim-btn-cont1">
                                                    <GetStartedButton
                                                        bgColor={'#FD7350'}
                                                        onClick={() => onDownloadClick()}
                                                        text={content.buttonText}
                                                        link={appLinkUrl}
                                                        target={target}
                                                        padding={'15px 45px'}
                                                    />
                                                </div> : null}
                                            </div> */}
                                            {/* {avibraRating.hideRating ? '' : <div className="avibra-rating-left-align">
                                                    <div className="avibra-rating-heading">{avibraRating.heading}</div>
                                                    <div className="avibra-app-avg-rating">
                                                        <StarRating rating={avibraRating.avgRating ? parseFloat(avibraRating.avgRating) : 5} />
                                                    </div>
                                            </div>} */}
                                            </div>
                                            <div style={{ clear: 'both' }}></div>
                                            {/* <div className="main_second_header_description" dangerouslySetInnerHTML={{ __html: (descriptionPart2) }}></div> */}
                                        </div>
                                    </div>
                                    </div>
                                </Col>
                                {/* <Col className="landing-habbits1" xs="12" sm="12" md="12" lg="6" xl="6">
                                    <div className="main-header-new-right-img" style={{ position: 'relative' }}>
                                        <div>
                                            {headerDesktopImg ? <LazyLoadImage src={headerDesktopImg} alt={''} effect="opacity" className="img-fluid" /> : null}
                                        </div>
                                    </div>
                                </Col> */}
                            </Row>
                        </div>
                    </div>
                    {  leftHeaderMobileAppImg ?
                        <div className="header-left-bg-vector-image d-flex justify-content-start align-items-end">
                        <div className={"image"}>
                            <LazyLoadImage src={leftHeaderMobileAppImg} effect="opacity" className='img-fluid'/>
                        </div>
                        </div>
                        : null}
                    {  rightHeaderMobileAppImg ?
                        <div className="header-right-bg-vector-image d-flex justify-content-start align-items-end">
                        <div className={"image"}>
                            <LazyLoadImage src={rightHeaderMobileAppImg} effect="opacity" className='img-fluid'/>
                        </div>
                        </div>
                        : null}
                    {mobileHeaderMobileAppImg ?
                        <div className="home-header-mobile-view-image-v2">
                            <LazyLoadImage src={mobileHeaderMobileAppImg} effect="opacity" className='img-fluid w-100'/>
                        </div>
                    : null }
                </div>
            </div>
        </div>
    )
}

export default HomeHeaderSectionNew