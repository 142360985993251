import React from "react";
import "./ClaimInformation.css";
import FooterSection from "./FooterSection";
import { customTextRender , getImageUrl} from "../../utils/helpers";
import processString from 'react-process-string';

const ClaimInformation = ({claimInfoData={}, certificateDocs=[], footerText='',slideNo='' }) => {
  const {title='', subtitle='',propertyData={}} = claimInfoData
  const property = (propertyData && propertyData.dataType) ? propertyData[propertyData.dataType] : {};
  const renderParsedText = (unprocessedText)=>{
    let config = [{
        regex: /{(.*?)}/gim,
        fn: (key, result) => customTextRender(key,result)
    }];
    let processed = processString(config)(unprocessedText);
    return processed
  }
  console.log("ClaimInformation claimInfoData===>", claimInfoData, certificateDocs)
  let certificateDocsList = []
  if(certificateDocs && certificateDocs.length){

    certificateDocs.map((certificateCard,index)=> {

      if(certificateCard.propertyData && certificateCard.propertyData.dataType) {
        certificateDocsList[index] = certificateCard.propertyData[certificateCard.propertyData.dataType];
        console.log("certificateCardProperty ===>", certificateDocsList)
      }
    })
    
  }
  return (
    <>
    {(title || subtitle || (propertyData && propertyData?.dataType && propertyData[propertyData?.dataType])) ? 
      <div className="slide__pdf claim_information">
      <div className="card-guide">
        <div className="container h-100 p-0">
          <div className="main__color ClaimInformation">
                <div class="col-md-12-bg-img-right">
                  <div class="info__image right_area">
                    <img src={getImageUrl('https://images.avibra.com/newhome/membership-doc-info-details.png')} alt="rightImg"  className="img-fluid" />
                  </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <div className="ClaimInformation__Benefits__title" style={{ marginRight: "-50px" }}>
                            <h3>{title=='Claims' ? 'Claim Information' : title}</h3>
                            {property ?
                            <div key={`claiminfo-keyinfo`} style={{ marginTop:10,paddingVertical:10 }}>
                              {property["Email Headline"] ? <div className="dobs_dd_claim_card_style">
                                  <div className="mainHeaderStyle">
                                      <div>
                                          {property["Email Headline"]}
                                      </div>
                                  </div>
                              </div> : null}
                              {property["Email Description"] ? <div style={{ paddingHorizontal: 22, paddingTop: 20 }}>
                                  <div className="ClaimInformation__benefits__desc"  style={{whiteSpace: "pre-line"}}>{ renderParsedText( property["Email Description"]) }</div>
                              </div> : null}
                            </div> : subtitle ? <>
                            <div className="ClaimInformation__benefits__desc" dangerouslySetInnerHTML = {{__html:(subtitle.replace(new RegExp('\r?\n','g'), '<br />'))}}></div>
                            </>: null}
                            
                            {/*<div>
                            {property ?  
                              <div key={`claiminfo`} style={{ backgroundColor: 'white',borderRadius:20,padding:'15px' }}>
                                  {property["Email Headline"] ? <div className="dobs_dd_claim_card_style">
                                      <div style={{}}>
                                          <div className="TextParse" style={{}}>
                                              {property["Email Headline"]?(property["Email Headline"]):""}
                                          </div>
                                      </div>
                                  </div> : null}
                                  {property["Email Description"] ? <div style={{ paddingHorizontal: 22, paddingTop: 20 }}>
                                          <div style={{ color: "#292C4E", fontSize: '14px', lineHeight: '20px'}}>{property["Email Description"]}</div>
                                      </div> : null}
                              </div>: null}
                            </div>*/}
                            
                            {/*<div className="pdf__content">
                                <h1 className="mt-5">{Secondtitle}</h1>  
                                <div className="inner__pdf__content">
                                    <div className="icon__main">
                                        <img src={pdfOne} alt="" className="img-fluid" />
                                    </div>
                                    <div className="inner__pdf__link">
                                        <a href={pdfOneLink} target="_blank" >https://www.avibra.com/AD&DRider.pdf</a>
                                    </div>
                                </div>
                          </div>*/}
                            {certificateDocsList && certificateDocsList.length ?
                              <div className="pdf__content">
                                <h3 className="mt-5">Legal Documents</h3> 
                                {certificateDocsList && certificateDocsList.length && certificateDocsList.map((certificateDoc, index)=>{
                                  return(<div className="inner__pdf__content" key={'legal-doc-'+index}>
                                      <span className="icon__main">
                                          <img src={getImageUrl('https://images.avibra.com/newhome/membership-doc-file-pdf-one.png')} alt="" className="img-fluid" />
                                      </span>
                                      <span className="inner__pdf__link">
                                          <a href={certificateDoc[0]['downloadLink']} target="_blank" >{certificateDoc[0]['downloadLink']}</a>
                                      </span>
                                  </div>)
                                })
                              }
                              </div>
                            : null}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="info__image">
                            &nbsp;{/*<img src={getImageUrl('https://images.avibra.com/newhome/membership-doc-info-details.png')} alt="rightImg"  className="img-fluid" />*/}
                        </div>
                    </div>
                </div>
                <FooterSection slideName={footerText} slideNo={slideNo} />
          </div>
        </div>
      </div>
      </div>
      : null}
    </>
  );
};

export default ClaimInformation;
