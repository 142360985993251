import React from 'react'
import { getImageUrl } from '../../utils/helpers';
import "./BenefitsHighlight.css"
import FooterSection from "./FooterSection";

function BenefitsHighlight({ highlightsData={}, providerInfoData={},companyInfoData={},footerText='',slideNo='' }) {
  const {title='', subtitle='', propertyData = ''} = highlightsData
  let list = [];
  let companyInfo = '';
  if(propertyData && propertyData.dataType)
    list = propertyData[propertyData.dataType];
  if(companyInfoData.propertyData && companyInfoData.propertyData.dataType)
    companyInfo = companyInfoData.propertyData[companyInfoData.propertyData.dataType];

  let providerLogo = ''
  if(providerInfoData && providerInfoData.topLeftImg){
    providerLogo = (providerInfoData && providerInfoData.topLeftImg) ? providerInfoData.topLeftImg : '';
  }
    console.log("BenefitsHighlight highlightsData providerInfoData ==>", providerInfoData,companyInfoData )
    const renderList = (lists) => {
      return(
          <div>
          {
              lists && lists.map((item, index) => {
              return (
                  <div key={`list${index}`} className={lists && lists.length > 10 ? 'half_width_list' : 'full_width_list'  }>
                      <div style={{ flexDirection: 'row', paddingRight: 20 }}>
                          <div>
                          <span style={styles.bulleted} className="dd_lighlights_bulleted">
                              ✓
                          </span>
                          <span>{item}</span>
                          </div>
                      </div>
                  </div>
              )
          })
          }
          </div>
      )
  }
  

    return (
      <>
        <div className="card-guide">
        <div className="container h-100 p-0">
          <div className="main__color Benefits-Highlight">
            
          <div className="highlight-main-page">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="highlight-section">
                                <div className="highlight-heading">
                                    <h3>{highlightsData?.title || ''}</h3>
                                </div>
                                <div className="highlight-section-details">
                                {highlightsData?.subtitle && <div className="highlight-details" dangerouslySetInnerHTML={{__html:(highlightsData?.subtitle)}}></div>}
                                {list && renderList(list)}
                                </div>
                            </div>
                            <div className="provider__info__card">
                                {((companyInfo && companyInfo.logo) || providerLogo) ?
                                <div className="provider__img__area">
                                  {companyInfo && companyInfo.logo ?
                                    <img src={getImageUrl(companyInfo?.logo)} alt={companyInfo?.name} className="img-fluid"/>
                                  : providerLogo ? 
                                    <img src={getImageUrl(providerLogo)} alt={''} className="img-fluid" /> : null}
                                </div> : null}
                                <div className="provider__detail__area">
                                  <div className="provider__title">{providerInfoData?.title}</div>
                                  <div className="provider__subtitle">{providerInfoData?.subtitle}</div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <FooterSection slideName={footerText} slideNo={slideNo} />
          </div>
        </div>
      </div>
      </>
    )
}

export default BenefitsHighlight
const styles = {
    bulleted: {
        width:'18px',
        height:'18px',
        borderRadius:'15px',
        backgroundColor:"#FD7350",
        justifyContent: "center",
        alignItems: "center",
        marginRight:'15px',
        marginLeft:'0px',
        marginTop:'5px',
        padding:'1px',
        textAlign:'center',
        fontSize:'12px',
        display:'inline-block',
        color:'#fff'
    }
}