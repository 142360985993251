import React from "react";
import "./BenefitsStore.css";
import FooterSection from "./FooterSection.js"
import { getImageUrl } from '../../utils/helpers';

const BenefitsStore = (props) => {
    const { addonsPlanList=[] ,title='',desc='',slideNo='', footNote=""} = props;
    console.log("BenefitsStore addonsPlanList ===>", addonsPlanList)

    return (
    <>
         <div className="card-guide">
          <div className="container h-100 p-0">
            <div className="main__color Benefits-Store">
                <div className="Benefits-Store-Title">
                  <h1  dangerouslySetInnerHTML = {{__html:(title)}}></h1>
                  <p>{desc}</p>
                  <p className="TextParse">*{footNote}</p>
                </div>
                <div className="card__area">
                    <div className="row">
                        {
                            addonsPlanList && addonsPlanList?.map((item,index)=>{
                                return(
                                    <>
                                        <div className="col-md-4" style={{marginBottom:'15px'}}>
                                            <div className="card__store">
                                                {item.icon ?<div className="membership_doc_card__img">
                                                    <img src={getImageUrl(item.icon)} alt="" className="img-fluid" />
                                                </div> : null}
                                                <div className="card__title">
                                                    <h4>{item?.heading}</h4>
                                                    <p>{item.description || "Emergency cash for you or your loved ones after you’re in a major accident."}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="card__bottom__img mt-4">
                    <img src={getImageUrl("https://images.avibra.com/newhome/membership-doc-bg-bottom-benifits.png")} alt="" className="img-fluid"/>
                </div>
                <FooterSection slideName={title} slideNo={slideNo} />
            </div>
          </div>
      </div>
    </>
  );
};

export default BenefitsStore;
