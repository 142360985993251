import { createStore, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk';
import promise from 'redux-promise';
import allReducers from "../reducers/rootReducer";
import {createLogger} from 'redux-logger';
import { GetEnvVar } from '../utils/AppConfig';

const updatedApplyMiddleware = (GetEnvVar('NODE_ENV') == "development") || (GetEnvVar('NODE_ENV') == "preprod") ? applyMiddleware(thunk, promise, createLogger()) : applyMiddleware(thunk, promise)

const store = createStore(
    allReducers,
    updatedApplyMiddleware
);

export default store;