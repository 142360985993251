import React from 'react'
import "./PartnerWithAvibra.css"
import { Container, Row,Col } from 'reactstrap'

const PartnerWithAvibra = ({content={}}) => {

    let {title='',name='',designation=''}=content

  return (
   <Container className='partner-with-avibra-container'>
       { Object.keys(content).length >0 ?<Row className='justify-content-center'>
       <Col >
         <div className='partner-with-avibra-content'>
          <h3 className='partner-with-avibra-title'>{title}</h3>
          <div className='partner-with-avibra-username'>{name}</div>
          <div className='partner-with-avibra-designation'>{designation}</div>
       </div>
         </Col>
       </Row>:null}
    </Container>
  )
}

export default PartnerWithAvibra