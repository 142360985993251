import React from 'react'
import "./GrowthThrough.css"
const GrowthThrough = (props) => {
    const { content = {} } = props
    const { tagLine = '', heading = '', data = [] } = content
    return (
        <div className='container'>
           <div className='growth-through-container'>
            <div className='growth-through-tag'>{tagLine}</div>
            <div className='growth-through-title'>{heading}</div>
            <div className='growth-through-line'></div>
            <div className='growth-through-items'>
                {data && data.length > 0 && data.map((item, index) => {
                    return <div className='growth-through-item' key={index}>
                        <div className='growth-through-img-wrp'>
                            <img src={item.image} className='img-fluid'/>
                        </div>
                        <div className='growth-through-content'>
                            <div className='growth-through-subheading'>{item.title}</div>
                            <div className='growth-through-desc'>{item.desc}</div>
                        </div>
                    </div>
                })}


            </div>
        </div>
        </div>
        
    )
}

export default GrowthThrough