import axios from 'axios'
//import Config from 'Config'
import qs from 'query-string'
import { errorHandler } from '../api/policyDocumentApi'
import { GetEnvVar } from './AppConfig'

export const SetApiKey= (API_KEY = "testkey") => {
    axios.defaults.headers.common['x-api-key'] =  API_KEY;
}



export const SetBaseURL = (baseUrl) => {
    axios.defaults.baseURL = baseUrl || GetEnvVar('BASE_URL')
}

SetBaseURL(GetEnvVar('API_URL'))
SetApiKey(GetEnvVar('API_KEY'))

export function getHeaders() {
    const headers = {
        'Content-Type': 'application/json',
    }

    return headers
}

export function apiReq(endPoint, data, method, headers, requestOptions = {}, shouldRefreshToken = true) {
    return new Promise((resolve, reject) => {
        headers = {
            ...getHeaders(),
            ...headers,
        }

        if (method === 'get' || method === 'delete') {
            data = {
                ...requestOptions,
                params: data,
                headers,
                data: {},
            }
            data.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'repeat' })
        }
        
        axios[method](endPoint, data, { headers })
            .then((result) => {
                const { data } = result

                if (data.status === false) {
                    return reject(data)
                }

                return resolve(data)
            })
            .catch(error => {
                errorHandler(error)
                return reject(error)
            })
    })
}

export function apiGet(endPoint, data, headers = {}, requestOptions) {
    return apiReq(endPoint, data, 'get', headers, requestOptions)
}

export function apiPost(endPoint, data, headers = {}, requestOptions) {
    return apiReq(endPoint, data, 'post', headers, requestOptions)
}

export function apiPatch(endPoint, data, headers = {}, requestOptions) {
    return apiReq(endPoint, data, 'patch', headers, requestOptions)
}

export function apiPut(endPoint, data, headers = {}, requestOptions) {
    return apiReq(endPoint, data, 'put', headers, requestOptions)
}