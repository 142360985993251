import React from 'react'
import "./FullWidthHeaderSection.css"
const FullWidthHeaderSection = (props) => {
    const { content = {} } = props
    const { tagLine,heading = '',description='',bannerImage={}} = content;
    const {desktop='',mobile='',tablet=''} = bannerImage

    return (
        <div className='container'>
            <div className='full-width-header-container'>
                
                {tagLine && <div className='full-width-header-tagline'>{tagLine}</div>}
                {heading && <div className='full-width-header-heading' dangerouslySetInnerHTML={{ __html: (heading) }} />}
                {description && <div className='full-width-header-description'>{description}</div>}
                
                {desktop ? <div className='full-width-header-image-wrp full-width-header-img-desktop'>
                    <img src={desktop} className='img-fluid desktop-img'/>
                </div> : null}
                {tablet ? <div className='full-width-header-image-wrp full-width-header-img-tablet'>
                    <img src={tablet} className='img-fluid tablet-img'/>
                </div> : null}
                {mobile ? <div className='full-width-header-image-wrp full-width-header-img-mobile'>
                    <img src={mobile} className='img-fluid mobile-img'/>
                </div> : null}

            </div>
        </div>
    )
}

export default FullWidthHeaderSection