import React from "react";
import { getImageUrl } from "../../utils/helpers";
import "./CardMain1.css";
import FooterSection from "./FooterSection";

const CardMain1 = (props) => {
  const { addonsPlanList=[], footerText='', slideNo='' } = props;
  return (
    <>
      <div className="card-guide">
        <div className="container h-100 p-0">
          <div className="main__color Card-Main-One">
            <div className="card__area">
            <div className="row">
              <div className={addonsPlanList.length <= 4 ? 'col-md-8' : 'col-md-12'}>
                
                  <div className="row">
                {
                  addonsPlanList && addonsPlanList?.map((item,index)=>{
                    return(
                      <div className={addonsPlanList.length <= 4 ? 'col-md-6' : 'col-md-4'} style={{marginBottom:'15px'}}>
                          <div className="card__store">
                              {item.icon ?<div className="membership_doc_card__img">
                                  <img src={getImageUrl(item.icon)} alt="" className="img-fluid" />
                              </div> : null}
                              <div className="card__title">
                                  <h4>{item?.heading}</h4>
                                  <p>{item.description || "Emergency cash for you or your loved ones after you’re in a major accident."}</p>
                              </div>
                          </div>
                      </div>
                    )
                  })
                }
                  </div>
                
              </div>
              {addonsPlanList.length <= 4 ?
              <div className="col-md-4">
                  <div className="right_side__img">
                      <img src={getImageUrl("https://images.avibra.com/newhome/membership-doc-pattern-card.png")} alt="" className="img-fluid"/>
                  </div>
              </div> : null}
            </div>
            </div>
            {addonsPlanList.length > 4 ?
            <div className="card__bottom__img mt-4">
              <img src={getImageUrl("https://images.avibra.com/newhome/membership-doc-bg-bottom-benifits.png")} alt="" className="img-fluid" />
            </div>: null}
            <FooterSection slideName={footerText} slideNo={slideNo} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CardMain1;
