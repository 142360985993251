import React from "react";
import "./Livemembership.css";
import FooterSection from "./FooterSection";
import { getImageUrl} from "../../utils/helpers";

const Livemembership = ({pageData: {title, description, image, footer, index},slideNo=''}) => {
  return (
    <>
      <div className="card-guide">
        <div className="container h-100 p-0">
          <div className="main__color live-member">
               
                <div className="row align-items-center h-100">
                    <div className="welcome__title Livemembership">
                        <h1 dangerouslySetInnerHTML = {{__html:(title)}}></h1>
                    </div>
                    <div className="col-md-6">
                        <div className="main__welcome__content" dangerouslySetInnerHTML = {{__html:(description)}}></div>
                    </div>
                    <div>
                        <div className="right__side__img">
                            <img src={getImageUrl(image)} alt=""  className="img-fluid"/>
                        </div>
                    </div>
                </div>
                <FooterSection slideName={footer} slideNo={slideNo} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Livemembership;
