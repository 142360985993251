import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import "./WhitePapersCard.css"
import { useNavigate } from "react-router";

const WhitePapersCard = (props) => {
  const [modal, setModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const {content={}}=props
  const {data=[]}=content
  const {heading,description}=content
  const toggle = () => {
    setModal(!modal);
    setPdfUrl('');
  }
  const openPdfModal = (url='') => {
    const gdViewUrl= 'https://docs.google.com/viewer?embedded=true&url='+url
    setPdfUrl(gdViewUrl);
    setModal(true);
  }
  return (
    <div className="container main-paper-study">
      <div className="main-paper-study-header-wrp">
          <h2>{heading}</h2>
          <p>
            {description}
          </p>
      </div>
      {data && data.length>0 && data.map((item,index)=>{
            return <Row className="justify-content-center main-paper-study-card" key={`wp-card-item-${index+1}`}>
        <Col lg={11} md={11} sm={12}>
          <div className="main-paper-study-card-outer" key={index}>
            <Row className="align-items-center main-paper-study-card-inner">
              <Col md={12} className="main-paper-study-card-inner-left">
                <h3 className="main-paper-card-heading">{item.title}</h3>
                <p className="main-paper-card-desc">{item.description}</p>
                <div className="main-paper-card-btn">
                  <div className="main-paper-right-arrow" title="View PDF" onClick={()=>openPdfModal(item.pdfLink)}>
                    <i className="fa fa-eye" aria-hidden="true"></i>
                  </div>
                  <a href={item.pdfLink} target="_blank" title="Download PDF" download>
                    <div className="main-paper-right-arrow">
                      <i className="fa fa-download" aria-hidden="true"></i>
                    </div>
                  </a>
                </div>
              </Col>
            </Row>
          </div>
          {modal && pdfUrl ? <Modal isOpen={modal} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
              <iframe src={pdfUrl} style={{border:'none',width:'100%',height:(window.innerHeight-150)}}></iframe>
            </ModalBody>
            </Modal> : null}
        </Col>
      </Row>
    })}
    </div>
  );
};

export default WhitePapersCard;
