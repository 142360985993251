import React, { useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import "./StorePackages.css";
import purpleCheckCircle from '../../../../../images/newDesignV2/purple-check-circle.png'

const GridCardView = ({ storePackages }) => {
    const { storePackagesArray = [], storeCategory = [], applyStyle = false, purpleBg=false } = storePackages
    const [isOpen, setIsOpen] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState('core-store')
    const tabButtonClicked = (index, selected) => {
        setIsOpen(index)
        setSelectedCategory(selected)
    }
    return (
            <>
                {/* <div className="store_packages_tab_button_area_outer">
                <div className="store_packages_tab_button_area">
                    {storeCategory.length && storeCategory.map((data, index) => {
                        return <div onClick={() => tabButtonClicked(index, data.key)} className={`store_packages_tab_button ${index == isOpen ? ' active' : ''} ${applyStyle ? 'selected-tab' : ''}`}>{data.lable}</div>
                    })}
                </div>
                </div> */}
                <div className='store-package-type'>
                    {storePackagesArray.length && storePackagesArray.map((data, index) => {
                        if(data?.storeType && selectedCategory == data?.storeType)
                        return <div className='store-package-section' key={index} >
                                <div className='store-package-heading-area'>
                                    <div className='store-package-heading'>{data.heading}</div>
                                    <div className='store-package-description'>{data.description}</div>
                                </div>
                                <div className='row p-lr-50'>
                                {data?.packages?.length && data.packages.map((pack, pindex) => {
                                    return (<div className='col-lg-4 col-md-12 col-sm-12' key={`package-${pindex}`}>
                                        <div className='store-package-area '>
                                            <div className='store-package-item-heading'>{pack?.title}</div>
                                                <div className='store-package-list'>
                                                {pack?.items?.length && pack.items.map((item, iindex) => {
                                                    return <div className='package-item' key={`package-item-${iindex}`}><img src={purpleCheckCircle} width={'30'}/> {item}</div>
                                                }) }
                                                </div>
                                            </div>
                                        </div>)
                                    })
                                }
                                </div>
                        </div>
                    })}
                </div>
            </>
        )
}

export default GridCardView