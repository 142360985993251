import React,{useState} from 'react'
import "./EasyLaunch.css"
import minusIcon from "../../../../images/minus-icon.png"
import plusIcon from "../../../../images/plus-icon.png"
import checkmark from "../../../../images/check.svg"



const EasyLaunch = (props) => {
    const { content = {} } = props
    const { tagLine = '', heading = '',imageWeb,imageTablet,imageMobile, data = [] } = content
    const [openSections, setOpenSections] = useState({section0:true});
    const [cardImage, setCardImage] = useState({web:imageWeb,tablet:imageTablet,mobile:imageMobile})
      const handleToggle = (section,index) => {
        setOpenSections(prevState => ({
          [section]: !prevState[section]
        }));
         setCardImage({web:data[index].imageWeb,tablet:data[index].imageTablet,mobile:data[index].imageMobile})
      };
console.log('openSections', openSections)
    return (
        <div className='container'>
            <div className='easy-launch-container'>
            <div className='easy-launch-subheading'>{tagLine}</div>
            <div className='easy-launch-desc' dangerouslySetInnerHTML={{ __html: (heading) }} />
            <div className='easy-launch-items'>
                <div className='easy-launch-card-wrp'>
                    {data && data.length > 0 && data.map((item, index) => {
                        return <div className='easy-launch-card' key={index}>
                            <div className='easy-launch-card-left'>
                                <div className='easy-launch-card-icon' onClick={() => handleToggle('section'+index,index)}>
                                    <img src={openSections['section'+index] ? minusIcon :plusIcon}/>
                                </div>
                                <div className='easy-launch-card-title'>{item.title}</div>
                            </div>
                            <div className='easy-launch-card-right'>
                                <div className='easy-launch-card-tagbar'>
                                    <img src={checkmark}/>
                                    <div className='easy-launch-tagname'>{item.tagLine}</div>
                                </div>
                                {openSections['section'+index] && <div className='easy-launch-card-desc'>{item.desc}</div>}
                            </div>
                        </div>
                    })}
                </div>
                <div className='easy-launch-img-wrp easy-launch-img-desktop'>
                   <img src={cardImage.web} className="img-fluid"/>
                </div>
                <div className='easy-launch-img-wrp easy-launch-img-tablet'>
                   <img src={cardImage.tablet} className="img-fluid"/>
                </div><div className='easy-launch-img-wrp easy-launch-img-mobile'>
                   <img src={cardImage.mobile} className="img-fluid"/>
                </div>
            </div>

        </div>
        </div>
       
    )
}

export default EasyLaunch