import React from 'react';
import Pillar from './Pillar/Pillar';
import Jupiter from './Jupiter/Jupiter';
import MicroJupiter from './MicroJupiter/index.js';
import UserBlock from './UserBlock/index.js';
import HowItWorksCard from './HowItWorksCard/HowItWorksCard';
import InternalHowItWorksCard from './InternalHowItWorksCard/InternalHowItWorksCard';
import BenefitsCard from './BenefitsCard/BenefitsCard';
import InfoCard from './InfoCard/InfoCard';
import ResourcesCard from './ResourcesCard/ResourcesCard';
import BigResourcesCard from './BigResourcesCard/BigResourcesCard';
import PricingCard from './PricingCard/PricingCard';
import OurDollarBenefitsCard from './OurDollarBenefitsCard/OurDollarBenefitsCard';
import UserCard from '../UserCard/UserCard';
import PressReleasesItem from '../PressReleasesItem/PressReleasesItem';


const RenderCommonComponent = ({
    componentName = "", data = "", componentConfig = {}, keyIndex=""
}) => {
    console.log("component name", componentName)
    const GetComponentToRender = ({ componentName }) => {


        switch (componentName) {
            case "Jupiter":
                return <Jupiter data={data} />
            case "Pillar":
                return <Pillar data={data} />
            case "howItWorksCard":
                return <HowItWorksCard data={data} />
            case "internalHowItWorksCard":
                return <InternalHowItWorksCard data={data} />
            case "benefitsCard":
                return <BenefitsCard data={data} />
            case "miniJupiter":
                return <MicroJupiter data={data} componentConfig={componentConfig} />
            case "userBlock":
                return <UserBlock data={data} componentConfig={componentConfig} />
            case "infoCard":
                return <InfoCard data={data} componentConfig={componentConfig} />   
            case "resourceCard":
                return <ResourcesCard  data={data} componentConfig={componentConfig} />         
            case "bigResourceCard":
                return <BigResourcesCard  data={data} componentConfig={componentConfig} />             
            case "pricingCard":
                return <PricingCard  data={data} componentConfig={componentConfig} />                    
            case "ourDollarBenefitsCard":
                return <OurDollarBenefitsCard data={data} keyIndex={keyIndex} componentConfig={componentConfig} />    
            case "userCard":
                return <UserCard  data={data} keyIndex={keyIndex}  componentConfig={componentConfig} />
            case "pressReleasesItem":
                return <PressReleasesItem data={data} keyIndex={keyIndex}  />  
            default:
                return <div>No Component to render</div>
        }
    }

    return (
        <GetComponentToRender componentName={componentName} />
    )
};

export default RenderCommonComponent;
