import React,{useState,useEffect} from 'react'
import "./LearningMoreForm.css"
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { useForm } from 'react-hubspot'
import { inputValidator,newInputValidator } from '../../../../utils/helpers';
//import Config from "Config"
import ReCAPTCHA from "react-google-recaptcha";
import { GetEnvVar } from '../../../../utils/AppConfig';
import Select from 'react-select';

const errorMsgs = {
  "firstname":"Please enter a valid first name",
  "lastname":"Please enter a valid last name",
  "email": "Please enter a valid email address",
  "phone": "Please enter a valid phone number",
  "company": "Please enter a valid company name",
  "platformInterested" : "Please select platform from the above",
  "how_did_you_hear_about_us_" : "Please select any option from the above",
  "which_best_describes_your_organization_" : "Please select any option from the above",
  "how_many_hourly_or_gig_workers_do_you_employ_" : "Please select any option from the above",
  "anything_else_you_would_like_us_to_know_":"This field is required",
  "captcha":"Please select checkbox",
  }

const platformInterestedOptions=[
  { value: "Financial Services", label: "Financial Services" },
  { value: "Work Platforms", label: "Work Platforms" },
  { value: "Employers", label: "Employers" }
]
const how_did_you_hear_about_us_Options=[
  { value: "Event/Conference", label: "Event/Conference" },
  { value: "Search Engine (Google/Bing)", label: "Search Engine (Google/Bing)" },
  { value: "Email", label: "Email" },
  { value: "Article/Newsletter", label: "Article/Newsletter" },
  { value: "Saw an ad", label: "Saw an ad" },
  { value: "LinkedIn", label: "LinkedIn" },
  { value: "Referred by someone", label: "Referred by someone" },
  { value: "Other (please explain)", label: "Other (please explain)" },
]

const which_best_describes_your_organization_Options=[
  { value: "Gig or work platform", label: "Gig or work platform" },
  { value: "Fintech", label: "Fintech" },
  { value: "Employer of hourly workers", label: "Employer of hourly workers" },
  { value: "Benefits broker", label: "Benefits broker" },
]

const how_many_hourly_or_gig_workers_do_you_employ_Options=[
  { value: "0-300", label: "0-300" },
  { value: "300-1000", label: "300-1000" },
  { value: "1000-5000", label: "1000-5000" },
  { value: "5,000+", label: "5,000+" }
]

// const platformInterestedOptions=[
//   { value: "Financial Services", label: "Financial Services" },
//   { value: "Work Platforms", label: "Work Platforms" },
//   { value: "Employers", label: "Employers" }
// ]
const LearningMoreForm = ({LearningMoreData,fromBookADemo=false,appConfig={}}) => {
  //const {title,rightVectorImage='',leftVectorImage='',bannerImage="", mobileImage="" , TabImage=""} = content
  const {heading,btnCta,btnLink}=LearningMoreData
  const [modal, setModal] = useState(false);
  const formToggle = () => setModal(!modal);
  const [formData, setFormData] = useState({firstname:"",lastname:"",email:"",phone:"",company:"",anything_else_you_would_like_us_to_know_:"",how_did_you_hear_about_us_:"",which_best_describes_your_organization_:"",how_many_hourly_or_gig_workers_do_you_employ_:""})
  const [formError, setFormError] = useState({})
  const [showThanksMsg, setShowThanksMsg] = useState(false);
  const { data, isLoading, isError, handleSubmit,form } = useForm({
    portalId:  "20464330",
    formId: "c248011c-f607-4ef9-b0b6-00b8dde1bd40",
  })
  const recaptchaRef = React.createRef();
 
  useEffect(()=>{
    if(data){
      const {status}=data
      if(status==200)
      setShowThanksMsg(true)
    }
  },data)
  // console.log('dataaaa', data,isError,handleSubmit,form)
  const isEmailAllowed = (emailInput) => {
    let isEmailValid = true;
    let employerEmailExcludedDomain = appConfig.employerEmailExcludedDomain 
    ? JSON.parse(unescape(appConfig.employerEmailExcludedDomain ))
    : [];

    const emailDomain = emailInput.toLowerCase().split('@')[1].split('.')[0];
    
    const isEmailExists=employerEmailExcludedDomain.length>0 && employerEmailExcludedDomain.includes(emailDomain) ;
    if (isEmailExists) {
      isEmailValid = false
    } else {
      isEmailValid = true;
    }
    return isEmailValid
  }
  
  const submitHandler=(e)=>{
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();
    let isFormValid;
    let formErrors={};
    let hangoutButton = document.getElementById("formSubmit1");
    if(!recaptchaValue){
      formErrors['captcha']=errorMsgs['captcha']
    }
    for(let item in formData){
     
     if(item=='company'||item=='firstname' || item=='lastname'){
     
        if(!formData[item] || !(/^[A-Za-z\s]+$/.test(formData[item]))){
          formErrors[item]=errorMsgs[item]
        }
      }
      else{
        isFormValid= inputValidator(formData[item],item)
        if(!isFormValid){
          formErrors[item]=errorMsgs[item]
        }
        if(item=='email' && !formErrors[item]){
          if(!isEmailAllowed(formData[item]))
            formErrors[item]='Enter a valid company email address';
        }
        if(item=='anything_else_you_would_like_us_to_know_' && formErrors[item]){
          delete formErrors[item]
        }
      }
      
    }
    setFormError(formErrors)

    if(Object.keys(formErrors).length==0){
        hangoutButton.click(); // this will trigger the click event
    }
  }
  const onCaptchaChange = (value)=> {
    //console.log("formError 1 ===>",formError)
    if(value){
      let formErrorsData = JSON.parse(JSON.stringify(formError));
      delete formErrorsData['captcha'];
      setFormError(formErrorsData)
    }
  }
  return (
      <div className="learning-form-area">
            {!showThanksMsg ?
            <>
            {heading ? fromBookADemo ? <h1 className='form-heading'>{heading}</h1> : <div className="membership-plans-title">{heading}</div> : null}
            <div className='learning-form'>
              <form onSubmit={handleSubmit}>
              <div className='row'>
                <div className={`${fromBookADemo?'col-lg-6 col-md-6':'col-lg-6 col-md-6'} learning_input_left`}>
                  <div className='learning_input '>
                    <input type="text" placeholder="first name" name="firstname" value={formData.firstname} onChange={(e)=>{setFormData({...formData,firstname:e.target.value.replace(/^\s+/g, "")});setFormError({...formError,firstname:''})} }></input>
                    <p className='error-msg'>{formError.firstname}</p>
                  </div>
                </div>
                <div className={`${fromBookADemo?'col-lg-6 col-md-6':'col-lg-6 col-md-6'} learning_input_right`}>
                  <div className='learning_input'>
                    <input type="text" placeholder="last name" name="lastname" value={formData.lastname} onChange={(e)=>{setFormData({...formData,lastname:e.target.value.replace(/^\s+/g, "")});setFormError({...formError,lastname:''})}}></input>
                    <p className='error-msg'>{formError.lastname}</p>
                  </div>
                </div>
                
                
                <div className={`${fromBookADemo?'col-lg-6 col-md-6':'col-lg-6 col-md-6'} learning_input_left`}>
                <div className='learning_input email'>
                    <input className="learning_input_email" type="text" placeholder="Email" name="email" value={formData.email} onChange={(e)=>{setFormData({...formData,email:e.target.value.replace(/^\s+/g, "")});setFormError({...formError,email:''})}}></input>
                    <p className='error-msg'>{formError.email}</p>
                  </div>
                </div>
                <div className={`${fromBookADemo?'col-lg-6 col-md-6':'col-lg-6 col-md-6'} learning_input_right`}>
                <div className='learning_input'>
                    <input type="tel" placeholder="Phone" name='phone' value={formData.phone} onChange={(e)=>{setFormData({...formData,phone:e.target.value.replace(/^\s+/g, "")});setFormError({...formError,phone:''})}}></input>
                    <p className='error-msg'>{formError.phone}</p>
                  </div>
                </div>
                <div className='col-lg-12 col-md-12'>
                  <div className='learning_input'>
                    <input type="text" placeholder="Company" name='company' value={formData.company} onChange={(e)=>{setFormData({...formData,company:e.target.value.replace(/^\s+/g, "")});setFormError({...formError,company:''})}}></input>
                    <p className='error-msg'>{formError.company}</p>
                  </div>
                </div>
                <div className='col-lg-12 col-md-12'>
                  <div className='learning_input platform-select'>
                    <div className='select-dropdown-area'>
                        <select required name="how_did_you_hear_about_us_" className="select-common-dropdown" value={formData.how_did_you_hear_about_us_} 
                          onChange={(e)=>{
                            setFormData({...formData, how_did_you_hear_about_us_:e.target.value});
                            setFormError({...formError, how_did_you_hear_about_us_:''})
                          }} 
                        >
                      <option value="" selected disabled >HOW DID YOU HEAR ABOUT US?</option>
                      {Object.keys(how_did_you_hear_about_us_Options).length && how_did_you_hear_about_us_Options.map((item, index) => {
                        return <option key={index} value={item.value} selected={item.value == formData.how_did_you_hear_about_us_ ? true : false}>{item.label}</option>
                      })}
                    </select> 
                  </div>
                  <p className='error-msg'>{formError.how_did_you_hear_about_us_}</p>
                  </div>
                </div>
                <div className='col-lg-12 col-md-12'>
                  <div className='learning_input platform-select'>
                    <div className='select-dropdown-area'>
                        <select required name="which_best_describes_your_organization_" className="select-common-dropdown" value={formData.which_best_describes_your_organization_} 
                          onChange={(e)=>{
                            setFormData({...formData, which_best_describes_your_organization_:e.target.value});
                            setFormError({...formError, which_best_describes_your_organization_:''})
                          }} 
                        >
                      <option value="" selected disabled >WHICH BEST DESCRIBES YOUR ORGANIZATION?</option>
                      {Object.keys(which_best_describes_your_organization_Options).length && which_best_describes_your_organization_Options.map((item, index) => {
                        return <option key={index} value={item.value} selected={item.value == formData.which_best_describes_your_organization_ ? true : false}>{item.label}</option>
                      })}
                    </select> 
                  </div>
                  <p className='error-msg'>{formError.which_best_describes_your_organization_}</p>
                  </div>
                </div>
                <div className='col-lg-12 col-md-12'>
                  <div className='learning_input platform-select'>
                    <div className='select-dropdown-area'>
                        <select required name="how_many_hourly_or_gig_workers_do_you_employ_" className="select-common-dropdown" value={formData.how_many_hourly_or_gig_workers_do_you_employ_} 
                          onChange={(e)=>{
                            setFormData({...formData, how_many_hourly_or_gig_workers_do_you_employ_:e.target.value});
                            setFormError({...formError, how_many_hourly_or_gig_workers_do_you_employ_:''})
                          }} 
                        >
                      <option value="" selected disabled >HOW MANY HOURLY OR GIG WORKERS DO YOU EMPLOY?</option>
                      {Object.keys(how_many_hourly_or_gig_workers_do_you_employ_Options).length && how_many_hourly_or_gig_workers_do_you_employ_Options.map((item, index) => {
                        return <option key={index} value={item.value} selected={item.value == formData.how_many_hourly_or_gig_workers_do_you_employ_ ? true : false}>{item.label}</option>
                      })}
                    </select> 
                  </div>
                  <p className='error-msg'>{formError.how_many_hourly_or_gig_workers_do_you_employ_}</p>
                  </div>
                </div>
                <div className='col-lg-12 col-md-12'>
                  <div className='learning_input'>
                    <textarea type="text" rows={1} placeholder="ANYTHING ELSE YOU WOULD LIKE US TO KNOW?" name='anything_else_you_would_like_us_to_know_' value={formData.anything_else_you_would_like_us_to_know_} onChange={(e) => { setFormData({ ...formData, anything_else_you_would_like_us_to_know_: e.target.value.replace(/^\s+/g, "") }); setFormError({ ...formError, anything_else_you_would_like_us_to_know_: '' }) }}></textarea>
                    <p className='error-msg'>{formError.anything_else_you_would_like_us_to_know_}</p>
                  </div>
                </div>
                {/* <div className='col-lg-12 col-md-12'>
                  <div className='learning_input platform-select'> */}
                    
                    {/* <select className="form-control" id="exampleFormControlSelect1" name='platformInterested' onChange={(e)=>{setFormData({...formData,platformInterested:e.target.value});setFormError({...formError,platformInterested:''})}} >
                      <option value="" disabled selected>Please Select Platform</option>
                      {Object.keys(platformInterestedOptions).length && platformInterestedOptions.map((item, index) => {
                        return <option key={index} value={item} selected={item == formData.platformInterested ? true : false}>{item}</option>
                      })}
                    </select>
                    <span className='platform-select-icon'><i className="fas fa-angle-down"></i></span> */}
                        {/* <Select
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder={'Platform Interested'}
                            name="platformInterested"
                            options={platformInterestedOptions}
                            value={formData.platformInterested}
                            onChange={(e)=>{setFormData({...formData,platformInterested:e});setFormError({...formError,platformInterested:''})}}
                          /> */}
                    {/* <div className='select-dropdown-area'>
                        <select required name="platformInterested" className="select-common-dropdown" value={formData.platformInterested} 
                          onChange={(e)=>{
                            setFormData({...formData, platformInterested:e.target.value});
                            setFormError({...formError, platformInterested:''})
                          }} 
                        >
                      <option value="" selected disabled >PLATFORM INTERESTED</option>
                      {Object.keys(platformInterestedOptions).length && platformInterestedOptions.map((item, index) => {
                        return <option key={index} value={item.value} selected={item.value == formData.platformInterested ? true : false}>{item.label}</option>
                      })}
                    </select> 
                  </div> */}
                  {/* <p className='error-msg'>{formError.platformInterested}</p> */}
                  {/* </div>
                </div> */}
                {GetEnvVar('GOOGLE_CAPTCHA_KEY') && <div className='col-lg-12'>
                  <div style={{textAlign:'center', margin:'0 auto', width:'305px' }}>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={GetEnvVar('GOOGLE_CAPTCHA_KEY') ? GetEnvVar('GOOGLE_CAPTCHA_KEY') :''}
                      onChange={onCaptchaChange}
                    />
                    <p className='error-msg'>{formError.captcha}</p>
                  </div>
                </div>}
                <div className='col-lg-12'>
                  <div className='submit_button'>
                    <button onClick={submitHandler}>{btnCta}</button>
                    <button type='submit' id="formSubmit1" >{btnCta}</button>
                  </div>
                </div>
              </div>
                </form>
            </div>
            </>
            :
            <div className='learning-form-thanks-box'>
              <div className='learning-form-thanks-area'>
                <div className="learning-form-thanks-vector-img">
                  <img src="https://images.avibra.com/siteui/red_pattern.png" />
                </div>
                <div className='learning-form-thanks-heading'>Thank you! <br/>We're glad you're interested in learning more.</div>
                <div className='learning-form-thanks-description'>
                  We'll reach out to you shortly to schedule a demo.
                </div>
              </div>
            </div>
            }       
      </div>
  )
}

export default LearningMoreForm