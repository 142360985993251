import React, { useState, useEffect } from 'react'
import {
    Container,
    Row,
    Col, Collapse, Button
} from 'reactstrap';
import "./SecurityCards.css";


const SecurityCards = ({ content }) => {
  const {heading='',subHeading='',items=[]} = content

  return (
      <div className="privacy-security-items-container">
        <Container>
          <div className='row'>
              <div className='col-mb-12 col-lg-12 center'>
                <div class="heading-area-v2 center">
                    <div class="heading-v2 font-size-32">{heading}</div>
                    <div className='sub-heading-v2'>{subHeading}</div>
                </div>
              </div>
          </div>
          <div className='row privacy-security-items-row'>
          {items.length ? items?.map((item, index) => {
              return <div className='col-sm-12 col-md-6 col-lg-4' key={`item-privacy-${index}`}>
                  <div className="privacy-security-card">
                      <div className='privacy-security-icon'>
                        <img src={item.icon} />
                      </div>
                      <div className="privacy-security-headline">
                          {item.title}
                      </div>
                      <div className="privacy-security-description" dangerouslySetInnerHTML={{__html: (item.description)}}/>
                  </div>
              </div>
          }) : null
          }
          </div>
        </Container>
      </div>
  );
};

export default SecurityCards;
