import React, { useRef, useEffect,useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { setLogEvent } from '../../../utils/helpers';
import StarRating from '../Common/StarRating/StarRating';
import GetStartedButton from "../Common/GetStartedButton/GetStartedButton";
import "./PageHeaderSection.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import {Animated} from "react-animated-css";
import HeaderRightImgMobile from "../../../../images/newDesign/home-mobile.png"
import awardsIcon from "../../../../images/newDesign/awards_icon.png"


import _ from 'lodash';
const PageHeaderSection = ({ content = {}, appLinkUrl = "/", target = "_blank",extraClassName2='' }) => {

    const graph = useRef(null);
    const [headingText, setHeadingText] = useState('');
    //const [count, setCount] = useState(0);
    const [isVisible, setIsVisible] = useState(true);
    const [emailId, setEmailId] = useState('');
    const { lovedBy = {}, avibraRating = {},awardMessage='',applyStyle, extraClassName='' } = content;
    useEffect(() => {
        rotateSliderText()
    }, []);
    const onDownloadClick = () => {
        setLogEvent("User Clicked On Get Started Button");
    }
    const submitEmailForm = () => {
        setLogEvent("User Clicked On Email Form Learn More Button",{"email":emailId});
        if(emailId)
        window.location = `/book-a-demo?email=${emailId}`;
        else
        window.location = `/book-a-demo`
    }
    const rotateSliderText = (count=0) => {
        if(content?.slidingTitle){
            const terms = content.slidingTitle;
            count = (count >= terms.length) ? 0 : count;
            setHeadingText(terms[count]?terms[count]:terms[0])
            setIsVisible(true)
            //console.log("rotateSliderText ==>", count,"<=>",count, terms[count] , terms.length);
            setTimeout(()=>{
                setIsVisible(false)
            }, 2850);
            setTimeout(()=>{
                rotateSliderText(count+1)
            }, 3000);
        }
    }

    let renderedHeading = ""
    //console.log('content===>', content)
    if (content.title instanceof Array) {
        renderedHeading = content.title.map((heading, index) =>
            <div dangerouslySetInnerHTML={{ __html: (heading) }}></div>
        )
        //console.log('content===>if', content)
    } else {
        renderedHeading = <div dangerouslySetInnerHTML={{ __html: (content.title) }}></div>
        //console.log('content===>else', content)
    }
    let description = content.subTitle;
    let descriptionPart1 = '';
    let descriptionPart2 = '';
    const descriptionArray = description.split("#@#");
    if (description.includes("#@#")) {
        descriptionPart1 = descriptionArray[0];
        descriptionPart2 = descriptionArray[1];
    } else {
        descriptionPart1 = description;
    }
    const headerImgDesktop = content.backgroundImg.desktop;
    const headerImgTablet = content.backgroundImg.tablet;
    const headerImgMobile = content.backgroundImg.mobile;
    // const headerRightImgMobile = content.backgroundImg.mobile;
    const headerDesktopLeftBg = '';//content.backgroundImg.desktopLeftBg;
    return (
        <div className={`main-header-section ${applyStyle?'new-header-style':''} ${extraClassName} ${extraClassName2}`}>
            <div className={`main-header-content ${target}target-old`}>
                <div className="main-header-desc">
                    <div class="landing-container">
                        <div className={`header_area_old header_area_new header-section container`}>     {/* container-fluid` style={{ backgroundImage: `url(${headerRightImgDesktop})` }}*/}
                            <Row className={'header-row'}>   {/*style={{ backgroundImage: `url(${headerDesktopLeftBg})` }}*/}
                                <Col className="image-lower1" xs="12" sm="12" md="6" lg="5" xl="5">
                                    <div className="left" style={{ paddingLeft: '0px' }}>
                                        <div className="main-header-text">
                                            {awardMessage ? <div className='award-message-area'><img src={awardsIcon} className='award-icon'/> {awardMessage}</div> : ''}
                                            <div style={{clear:'both'}}></div>
                                            <div className="heading-v2">
                                                {renderedHeading}
                                            <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={isVisible}>
                                                <div dangerouslySetInnerHTML={{ __html: (headingText) }}></div>
                                            </Animated>
                                            </div>
                                            <div className="sub-title-v2">
                                                <div dangerouslySetInnerHTML={{ __html: (descriptionPart1) }}></div>
                                            </div>
                                            <div className={`main__header__inner ${content?.addClass}`}>
                                            <div className="header-get-button-and-rating">
                                                {content.inputType ?<div className="header-claim-btn-cont">
                                                    <input type="text" placeholder='email' value={emailId} onChange={e => setEmailId(e.target.value)}/>
                                                    <button className='header-claim-btn-cont' onClick={()=>{submitEmailForm()}}>{content.buttonText}</button>
                                                </div>:
                                                (content.buttonText) ? <div className="header-claim-btn-cont">
                                               
                                                    <GetStartedButton
                                                        bgColor={'#774AD9'}
                                                        onClick={() => onDownloadClick()}
                                                        text={content.buttonText}
                                                        link={appLinkUrl}
                                                        target={target}
                                                        padding={'15px 45px'}
                                                    />
                                                </div> : null}
                                               
                                            </div>
                                            {/* {avibraRating.hideRating ? '' : <div className="avibra-rating-left-align">
                                                    <div className="avibra-rating-heading">{avibraRating.heading}</div>
                                                    <div className="avibra-app-avg-rating">
                                                        <StarRating rating={avibraRating.avgRating ? parseFloat(avibraRating.avgRating) : 5} />
                                                    </div>
                                            </div>} */}
                                            </div>
                                            <div style={{ clear: 'both' }}></div>
                                            <div className="main_second_header_description" dangerouslySetInnerHTML={{ __html: (descriptionPart2) }}></div>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="landing-habbits1 page-header-section-img-col" xs="12" sm="12" md="6" lg="7" xl="7">
                                    <div className="main-header-new-right-img" style={{ position: 'relative' }}>
                                       <div className='img-desktop-v2'>
                                            <LazyLoadImage src={headerImgDesktop} alt={''} effect="opacity" className="img-fluid" />
                                        </div>
                                        <div className='img-tablet-v2'>
                                            <LazyLoadImage src={headerImgTablet ? headerImgTablet : headerImgDesktop} alt={''} effect="opacity" className="img-fluid" />
                                        </div>
                                        <div className='img-mobile-v2'>
                                            <LazyLoadImage src={headerImgMobile ? headerImgMobile : headerImgDesktop} alt={''} effect="opacity" className="img-fluid" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageHeaderSection