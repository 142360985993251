import React, { Component,useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col, Spinner } from 'reactstrap';
import queryString from "query-string"
import WithLoader from '../components/HOC/WithLoader'
import { changeHeaderTheme } from '../actions/Header'
import "../../styles/Join/join.css"
import "../../styles/Join/join-responsive.css"
import "../../styles/Animation/home.css"
import { customTextRender, setAmplitudeInit, setAmplitudeUserId, setLogEvent,initBranchSdk } from '../utils/helpers'
import { sendSmsLinkApi , createDepUserAliases, createUserAliasAndSetPhoneProfileIdApi, sendNewSmsLinkApi, sendDownloadMessage} from '../api/auth'
import processString from 'react-process-string';
import landingPage from '../../constant/landingPage.json';
import MaskedInput from 'react-text-mask'
import avibraLogo from '../../images/avibra-logo.svg';
//import avibraAppHeaderImg from '../../images/new-header-app-img.png';
//import * as QRCode from 'easyqrcodejs';
import Header from '../components/NewDesign/Common/Header/Header';
import Footer from '../components/NewDesign/Common/Footer/Footer';
import SeoMetaTags from '../components/NewDesign/Common/SeoMetaTags/SeoMetaTags';
import NewDesignLandingPageData from "../../constant/NewDesignLandingPageData.json";
import { getFeparamsFilteredApi } from '../api/policyDocumentApi';
import {QRCodeCanvas} from 'qrcode.react';
const sectionsWithAnimation = [
    'joinSectionAnimation',
]

const pageData = {
    "description":"Avibra is a free app that provides benefits for everyone. You can start by earning insurance coverage at no cost to you through our Well-Being Advisor. Then explore all of our $1/week benefits in our Dollar Benefits Store. Our hope is to provide everyone with affordable, accessible coverage while improving your health, finances, career, relationships and community in the process.",
    "disclaimer":"Avibra is an affinity based membership program that has a defined mission and purpose. It is not an insurance company, but does, however, provide certain insurance benefits to its members as part of the overall package of membership benefits program made available to the membership. Only individuals between ages 18 through and including 62 and who are residents of one of the 50 U.S. states or the District of Columbia are eligible for insurance coverage and other benefits, subject to state availability, issue limitations and contractual terms and conditions, any of which may change at any time and without notice. For terms and conditions visit {link|https://www.avibra.com|www.avibra.com}"
}

//class Join extends Component {
const Join = (props) => {
    
    var downloadLink = landingPage.headerSection.download_href; 
    const [number, setNumber] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [sucessMsg, setSucessMsg] = useState("")
    const [appDownloadLink, setAppDownloadLink] = useState(downloadLink)
    const [stickyHeaderClass, setStickyHeaderClass] = useState("row topbar")
    const [isOpen, setIsOpen] = useState("")
    const [hideSMSOnJoinPage, setHideSMSOnJoinPage] = useState("true")
    const [feparmasApiCalled, setFeparmasApiCalled] = useState("")
    const [renderQRCode, setRenderQRCode] = useState(false)
    // constructor(props){
    //     super(props);
    //     var downloadLink = landingPage.headerSection.download_href; 
       
    //     this.state = {
    //         number: '',
    //         errorMsg: '',
    //         sucessMsg:'',
    //         appDownloadLink:downloadLink,
    //         stickyHeaderClass:'row topbar',
    //         isOpen:false,
    //         hideSMSOnJoinPage:'',
    //         feparmasApiCalled:false
    //     }
    //     this.handleScroll = this.handleScroll.bind(this);
    //     this.toggle = this.toggle.bind(this);
    //     this.qrcode = React.createRef();
    // }
    useEffect(() => {
        pageLoadData();
        props.dispatch(changeHeaderTheme(''));
    },[])

    useEffect(() => {
        window.addEventListener("scroll", addWhiteTheme, false);
        
        if (window.addEventListener) {
            window.addEventListener("scroll", handleScroll, false);
        } else {
            window.attachEvent("onscroll", handleScroll);
        }
    });
    useEffect(() => {
        return () => {
            window.removeEventListener("scroll", addWhiteTheme, false);
            window.removeEventListener("scroll", handleScroll, false);
        };
    }, []);
    
    

    const generateQrCode = (downloadLink) => {
        var options = {
            text: downloadLink,
            width:140,
            height:140
        }
        console.log("generateQrCode ==>", options)
        //new QRCode( this.qrcode.current, options);
    }
    const generateBranchLink = () =>{
        let downloadLink = landingPage.headerSection.download_href;
        initBranchSdk();
        branch.data(function(err, data) {
            console.log("generateBranchLink ==>", data)
            if(err){
                setLogEvent("Init Branch Error", {error: JSON.stringify(err)})
            }
            else{
                let qsparam = queryString.parse(window.location.search.toLowerCase())

                if(qsparam && qsparam.guestid && qsparam.guestid != ''){
                    setAmplitudeUserId(qsparam.guestid);
                }
                downloadLink = (data.data_parsed && data.data_parsed['~referring_link']) ? data.data_parsed['~referring_link']  : landingPage.headerSection.download_href;
                setLogEvent("Join Page loaded", {downloadLink: downloadLink});
                setAppDownloadLink(downloadLink)
                setRenderQRCode(true)
            }
        });
    }
    const pageLoadData =() =>{
        getFeparamsFilteredApi().then((res)=>{
            const {data = {}} = res;
            const {amplitudeKey = "", hideSMSOnJoinPage=''} = data;
            setHideSMSOnJoinPage(hideSMSOnJoinPage)
            setTimeout(()=>{
                setFeparmasApiCalled(true)
            },1000)
            // this.setState({hideSMSOnJoinPage:hideSMSOnJoinPage},()=>{
            //     this.setState({feparmasApiCalled:true})
            // })
            setAmplitudeInit(amplitudeKey);
            generateBranchLink();
            // let downloadLink = landingPage.headerSection.download_href;
            // initBranchSdk();
            // branch.data(function(err, data) {
            //     if(err){
            //         setLogEvent("Init Branch Error", {error: JSON.stringify(err)})
            //     }
            //     else{
            //         let qsparam = queryString.parse(window.location.search.toLowerCase())

            //         if(qsparam && qsparam.guestid && qsparam.guestid != ''){
            //             setAmplitudeUserId(qsparam.guestid);
            //         }
            //         downloadLink = (data.data_parsed && data.data_parsed['~referring_link']) ? data.data_parsed['~referring_link']  : landingPage.headerSection.download_href;
            //         setLogEvent("Join Page loaded", {downloadLink: downloadLink});
            //         setAppDownloadLink(downloadLink)
            //     }
            // });
            //this.setState({appDownloadLink:downloadLink})
            //this.generateQrCode(downloadLink)
        }).catch((err)=>{
            console.log(err);
            generateBranchLink();
            //generateQrCode(appDownloadLink)
        })
    }
    const logBrazeEvent = (phoneNumber) =>{
        //if(phoneNumber && phoneNumber.length == 17) 
            //let tempPhoneNumber = phoneNumber.replaceAll(/\s/g,'').replace("(", "").replace(")", "").replace("-", "").replace(" ", "");
            let sendData = [
                {
                    "aliasLabel": "phone",
                    "aliasName": phoneNumber.trim()
                }
            ]
            createDepUserAliases(sendData).then(res => {
                console.log("createDepUserAliases Response::", res)
            }).catch(err => {
                console.log("createDepUserAliases Error::", err)
            })
    }
    
    const handleInput = event => {
        //this.setState({ number: event.target.value, errorMsg: '', sucessMsg:'' });
        setNumber(event.target.value);
        setErrorMsg('');
        setSucessMsg('')
        
    }

    const captureOnSendText = ()=>{
        // var actionname = 'phonecapture';
        // var action = {
        //     'rev': '',
        //     'prod': '',
        //     'id': '',
        //     'promo': ''
        // };
     
        // var session = {
        //     'user': ''
        // };
        
        // var u = (("https:" == document.location.protocol) ? "https://collector-11003.tvsquared.com/" : "http://collector-11003.tvsquared.com/");
        // _tvq.push(['setSiteId', "TV-8181909063-1"]);
        // _tvq.push(['setTrackerUrl', u + 'tv2track.php']);
        // _tvq.push([function() {
        //     this.setCustomVariable(5, 'session', JSON2.stringify(session), 'visit')
        // }]);
        // _tvq.push([function() {
        //     this.setCustomVariable(5, actionname, JSON2.stringify(action), 'page')
        // }]);
        // _tvq.push(['trackPageView']);
        // var d = document,
        //     g = d.createElement('script'),
        //     s = d.getElementsByTagName('script')[0];
        // g.type = 'text/javascript';
        // g.defer = true;
        // g.async = true;
        // g.src = u + 'tv2track.js';
        // s.parentNode.insertBefore(g, s);
    }

    const SendText = () => {
        setLogEvent("User Used Phone to Download App");
        let qsparam = queryString.parse(window.location.search.toLowerCase())
        props.startLoading();
        captureOnSendText();
        let phoneNumber = number.replace(/[-() ]/g,'');
        let appDownloadLink = appDownloadLink;
        //this.logBrazeEvent(phoneNumber);
        let payLoadFirst = {
            phoneNumber: phoneNumber
          }
        if(qsparam && qsparam.guestid && qsparam.guestid != ''){
            payLoadFirst = {...payLoadFirst , ...{profileId:qsparam.guestid}}
        }
        console.log('createUserAliasAndSetPhoneProfileIdApi payLoadFirst ===>', payLoadFirst )
        createUserAliasAndSetPhoneProfileIdApi(payLoadFirst).then(res => {
            if(res.data=='OK' || res.data == 'ok'){
                let payLoad = {
                    phoneNumber: phoneNumber,
                    message:appDownloadLink
                  }
                setTimeout(() => {
                    //sendSmsLinkApi(payLoad).then(res => {
                    sendDownloadMessage(payLoad).then(res =>{
                        setLogEvent("SMS Sent Success");
                        setSucessMsg("SMS Sent");
                        setNumber('')
                        // this.setState({
                        //     sucessMsg: "SMS Sent", number: ''
                        // });
                        props.stopLoading()
                      })
                      .catch(err => {
                        setLogEvent("SMS Sent Failed");
                        setErrorMsg("Not able to send message");
                        setNumber('')
                        // this.setState({
                        //     errorMsg: "Not able to send message", number: ''
                        // })
                        props.stopLoading()
                      })
                }, 2000);
            }
        })
        .catch(err => {
          props.stopLoading()
        })
        // window.branch.sendSMS(phoneNumber, {}, {}, (err, link) => {
        //     props.stopLoading()
        //     if (err) {
        //         this.setState({
        //             errorMsg: "Not able to send message"
        //         })
        //         props.stopLoading()
        //     }
        //     else {
        //         this.setState({
        //             sucessMsg: "SMS Sent"
        //         })
        //     }
        //     console.log({ err }, link, "link")
        //     this.setState({
        //         number: ''
        //     })
        // })
    }

    const toggle = () => {   
        //this.setState({isOpen: !this.state.isOpen});
        setIsOpen(!isOpen)
    }
    const handleScroll = () => {
        const scrollY = window.scrollY ? window.scrollY : document.documentElement.scrollTop;
        const scrollHeight = (window.innerWidth <= 768) ? 10 : 100
		scrollY >= scrollHeight ? 
        setStickyHeaderClass("row topbar fixed-header animated fadeInDown")
        :
        setStickyHeaderClass("row topbar");
    };
    // UNSAFE_componentWillMount(){
    //     this.pageLoadData()
    // }
    // componentDidMount() {
    //     props.dispatch(changeHeaderTheme(''));
    //     window.addEventListener("scroll", this.addWhiteTheme, false);
        
    //     if (window.addEventListener) {
    //         window.addEventListener("scroll", this.handleScroll, false);
    //     } else {
    //         window.attachEvent("onscroll", this.handleScroll);
    //     }
    //     // var options = {
    //     //     text: this.state.appDownloadLink,
    //     //     width:140,
    //     //     height:140
    //     // }
    //     // new QRCode( this.qrcode.current, options);
    // }
    // componentWillUnmount() {
    //     window.removeEventListener("scroll", this.addWhiteTheme, false);
    //     window.removeEventListener("scroll", this.handleScroll, false);
    // }

    const addAnimation = () => {

        sectionsWithAnimation.forEach(
            section => {

                let element = this[section]
                let offset = element.getBoundingClientRect().top
                if (offset < 300) {
                    this[section].classList.add('animation')
                }

            }
        )
    }

    const addWhiteTheme = () => {

        var scroll = window.scrollY || document.documentElement.scrollTop

        if (scroll > 100) {

            props.dispatch(changeHeaderTheme('headerWhiteArya'))

        } else {

            props.dispatch(changeHeaderTheme(''))
        }
        //this.addAnimation()
    }

    const renderJoinSectionInfo1 = () => {
        return (
            <div className="renderJoinSectionInfo1">
                <img className="img-fluid img-cover-old1 joinSlideFromLeft-old1" alt={'join mobile app image'} src={'https://images.avibra.com/newhome/join-page-right-app-img.png'} />
            </div>
        )
    }

    const renderProcessDisclaimer =(text) => {
            let config = [{
                regex: /{(.*?)}/gim,
                fn: (key, result) => customTextRender(key,result)
            }];
            let processed = processString(config)(text);
            return (<span>{processed}</span>)
    }

    const renderJoinSectionInfo2 = () => {
        //const {hideSMSOnJoinPage=''} = this.state;
        const {
            loading
        } = props
        let qrCodeColor = "#292C4E";
        const ua = window.navigator.userAgent;
        if (ua.indexOf("MSIE ") != -1|| !!navigator.userAgent.match(/Trident.*rv\:11\./))
            qrCodeColor = "#292C4E";
        //const { number, errorMsg, sucessMsg, appDownloadLink } = this.state
        return (
            <div className="renderJoinSectionInfo2">
                {(hideSMSOnJoinPage=='true' || hideSMSOnJoinPage==true) ? null :
                <>
                <div className="text-desc joinFadeInDown" >
                    <span className="qr-code-heading">Receive a one-time text message to download the app</span>
                </div>
                <div className="input-section joinFadeInDownContent">
                    <div className="label_join_phone_number">Phone number</div>
                    <div className="input-con">
                        {/* <input type="text" className="number-input" disabled={loading} name="number" maxLength="17" value={number} onChange={this.handleInput} placeholder="+1 (000) 000-0000" /> */}
                        <MaskedInput
                            mask={['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            className="number-input"
                            placeholder="+1 (000) 000-0000"
                            guide={false}
                            id="my-input-id"
                            onBlur={() => {}}
                            onChange={handleInput}
                            disabled={loading}
                            keepCharPositions={true}
                            value={number}
                        />
                    </div>
                    {/*<div className="border-bottom"></div>*/}
                    {
                        sucessMsg ?
                            <span style={{ color: '#774ad9' }}>{sucessMsg} </span>
                            : null
                    }
                    {
                        errorMsg ?
                            <span style={{ color: 'red' }}>{errorMsg} </span>
                            : null
                    }
                    {
                       number && number.length == 17 ? <input type="submit" className="text-me" disabled={loading} value={loading ? "SENDING...." : "TEXT ME LINK"} onClick={()=>SendText()} /> : null

                    }
                    {/*<span className="separator">
                        <h2><span>OR</span></h2>
                    </span>*/}
                    
                    {/*<div className="desc">
                        <p>{pageData.description || ''}</p>
                    </div>
                    <div className="disclaimer">
                        {this.renderProcessDisclaimer(pageData.disclaimer)}
                    </div>*/}
                </div>
                </>}
                <Row >
                    <div className="col-sm-12" style={{marginTop:20}}>
                        <div className="text-desc joinFadeInDown">
                            <div className="qr-code-heading">{(hideSMSOnJoinPage=='true' || hideSMSOnJoinPage==true)?null:<span>Or</span>} Scan the QR-Code to download the app</div>
                        </div>
                    </div>
                </Row>
                <Row>
                    <div className="col-sm-12">
                        <div className="qrCodeContainer">
                            {/*<QRCode value={appDownloadLink} bgColor={qrCodeColor} fgColor={'#FCF8EF'} />*/}
                            {/* <div ref={this.qrcode}></div> */}
                            <QRCodeCanvas value={appDownloadLink} size={150}/>
                        </div>
                    </div>
                    <div className="desc">
                        <p>Open your phone camera, hover over the QR code until it comes into focus and a link pops up, and then follow the prompt.</p>
                    </div>
                </Row>
            </div>
        )
    }
    
    
        //console.log(props, 'props')
        const {mainSection, footerSection,seoMetaData={}} = NewDesignLandingPageData
        //const {feparmasApiCalled=false}= this.state;
        return (
            <section className="joinSection newJoinSectionPage" >
			    <SeoMetaTags seoData={seoMetaData.joinPage} />
                {/*<div className="joinSectionContent">
                    <div className="joininfo1">
                        {this.renderJoinSectionInfo1()}
                    </div>
                    <div className="joininfo2">
                        {this.renderJoinSectionInfo2()}
                    </div>
                </div>*/}
                {/*<Row className={`topbar ${this.state.stickyHeaderClass}`}>
                    <Col className="p-0">
                        <Header toggle={this.toggle} isOpen={this.state.isOpen} content={mainSection.data.headerSection} downloadLink={this.state.appDownloadLink} />
                    </Col>
                </Row>*/}
                <section className="newJoinFirstSection-old">
                <Container className="join__main_content_area">
                    <Row>
                        <Col className="p-0">
                            <div className="landing-header join-page">
                                <nav className="navbar navbar-expand-md">
                                    <div className="header-styling" style={{margin: '0px'}}>
                                        <img src={avibraLogo} height="35"/>
                                    </div>
                                </nav>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="6" lg="6" xl="6" className="p-0">
                            {renderQRCode ? 
                            <div className="joininfo2-new" >
                                {renderJoinSectionInfo2()}
                            </div>
                            :
                            <div className="full-width loader-container" style={{display:'flex',justifyContent:'center',margin:'50px 10px'}}>
                                <Spinner style={{width:'25px',height:'25px',color:'#333'}} color={"#333"} height={25} width={25} />
                            </div>
                            }
                        </Col>
                        <Col sm="12" md="6" lg="6" xl="6" style={{overflow: 'hidden'}}>
                            <div className="joininfo1-new">
                                {renderJoinSectionInfo1()}
                            </div>
                        </Col>
                    </Row>
                </Container>
                </section>
                <div style={{clear:'both'}}></div>
                    <div className="partner-footer">
                        <div className="new-join-footer-cont">
                            <div className="container p-0">
                                <Row>
                                    <Col sm="12" md="6" lg="6" xl="6">
                                        <div className="desc">
                                            <p>{pageData.description || ''}</p>
                                        </div>
                                    </Col>
                                    <Col sm="12" md="6" lg="6" xl="6" >
                                        <div className="new-footer-cont-area" >
                                            <div className="p-0 new-footer-cont-section-1 row">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <div className="disclaimer">
                                                        {renderProcessDisclaimer(pageData.disclaimer)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <Footer content={footerSection} hideFooterLink={true} />
            </section>
        );
    
}

const JoinWithLoader = WithLoader(Join)

export default connect(state => state)(JoinWithLoader)