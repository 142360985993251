import React from "react";
import "./ResourcesCard.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const ResourcesCard = ({ data: { title, description, image, button } }) => {
  return (
    <div className="main__resource__card">
      <div className="img__resource">
        <LazyLoadImage src={image} alt={title} className="img-fluid" effect="opacity"/>
      </div>
      <div className="section__content">
        <h3>{title}</h3>
        <p>{description}</p>
        <a href="#">{button}</a>
      </div>
    </div>
  );
};

export default ResourcesCard;
