import React from 'react'
import "./OurBackground.css"

const OurBackground = (props) => {
    const { content = {} } = props
    const { heading = '', desc = '', data = [] } = content

    return (
        <div className='container'>
            <div className='our-background-container'>
                <div className='our-background-header-wrp'>
                    <div className='our-background-heading' dangerouslySetInnerHTML={{ __html: (heading) }} />
                    <div className='our-background-desc' dangerouslySetInnerHTML={{ __html: (desc) }} />
                </div>
                <div className='our-background-cards'>
                    {data && data.length > 0 && data.map((item, index) => {
                        return <div className={`our-background-card item-${(index+1)}`} key={index}>
                            <img src={item} />
                        </div>
                    })}
                </div>
            </div>
        </div>

    )
}

export default OurBackground