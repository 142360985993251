export function partial(fn,...presetArgs) {
    return function partiallyApplied(...laterArgs){
      return fn( ...presetArgs, ...laterArgs );
    };
}

export const actionCreator = type => 
    payload => ({
        type,
        payload
    })

export const formatDollar = (num) => {
    var p = num.toFixed(2).split(".");
    return p[0].split("").reverse().reduce(function(acc, num, i, orig) {
        return  num=="-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
    }, "") + "." + p[1];
}
