import { SHOW_LIFE_WAIT_LOADER, HIDE_LIFE_WAIT_LOADER, UPDATE_LIFEINSURANCE_INFO,UPDATE_LIFE_RECOMMENDATIONS } from "../types"
import _ from 'lodash';

const INITIAL_STATE = { userInfo:{},lifeLoading:false, lifeQuoteDetails:{}, lifeLiteQuote:{}, lifeQuote:{},lifeLiteQuoteRecommendations:{}, lifeQuoteOptions:{}, selectedPlan:{},isQuoteValueUpdate:true, makeLifeDecisionData:{},lifeBeneficiaryInfo:{},beneficairyFinalSaveDone:false,makeLifeDecisionDataError:false, initalRedirect:'', lifeWaitLoader:false, makeLifeDecisionDataAction:'', lifeErrorData:{}, backFromLifeBot:false,bothBotSubmited:false }

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_LIFEINSURANCE_INFO:
            return { ...state , [action.payload.prop]: action.payload.value}
        case UPDATE_LIFE_RECOMMENDATIONS:
                var lifeLiteQuoteRecommendations = state.lifeLiteQuoteRecommendations;
                lifeLiteQuoteRecommendations[action.payload.index] = action.payload.value;
                state.lifeLiteQuoteRecommendations = _.cloneDeep(lifeLiteQuoteRecommendations)
                return {...state};
        case SHOW_LIFE_WAIT_LOADER:
            return { ...state, lifeLoading: true}
        case HIDE_LIFE_WAIT_LOADER:
            return { ...state, lifeLoading: false}
        default:
            return state
    }
}