import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
    Container,
    Row,
    Col,
    Spinner
} from 'reactstrap';
import Header from './../../../components/NewDesignV2/Common/Header/Header';
import Footer from './../../../components/NewDesignV2/Common/Footer/Footer';
import SeoMetaTags from '../../../components/NewDesignV2/Common/SeoMetaTags/SeoMetaTags';
import NewDesignV2LandingPageData from "./../../../../constant/NewDesignV2LandingPageData.json";
import NewDesignV2PrivacyAndSecurity from "./../../../../constant/NewDesignV2PrivacyAndSecurity.json";
import './../../../../styles/NewDesignV2/main-page.css'
import './../../../../styles/NewDesignV2/main-page-responsive.css';
import './PrivacyAndSecurity.css'
import { getFeparamsFilteredApi } from '../../../api/policyDocumentApi';
import { feparamsAction } from '../../../actions/feparamsAction';
import { setLogEvent, setAmplitudeInit } from '../../../utils/helpers';
import PageHeaderSection from '../../../components/NewDesignV2/PageHeaderSection/PageHeaderSection';
import Faqs from '../../../components/NewDesignV2/Common/Faqs/Faqs.js';
import SecurityCards from '../../../components/NewDesignV2/Common/SecurityCards/SecurityCards.js';

const PrivacyAndSecurity = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [stickyHeaderClass, setStickyHeaderClass] = useState('row topbar');
    const [loader, setLoader] = useState(false)
    const toggleSticky = useCallback(
        () => {

            const scrollY = window.scrollY ? window.scrollY : document.body.scrollTop
            const scrollHeight = (window.innerWidth <= 768) ? 10 : 100
            scrollY >= scrollHeight ? 
                setStickyHeaderClass(`row topbar fixed-header ${(window.innerWidth <= 768) ? '' : 'animated fadeInDown'}`)
                :
                setStickyHeaderClass("row topbar")
        },
        []
    );
    useEffect(()=>{
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
		setLoader(true)
		setTimeout(() => {
		  setLoader(false)	
		}, 300)
	},[])
    useEffect(() => {
        document.getElementById("main-wrapper").focus();
        //getFeparamsFilteredApi
        const {appConfig={}}=props
        if(!Object.keys(appConfig).length) {
        getFeparamsFilteredApi().then(res => {
            const { data = {} } = res;
            props.feparamsAction(data);
            let { amplitudeKey = "" } = data;
            setAmplitudeInit(amplitudeKey);
            setLogEvent("Gig Platforms Page loaded", {
                currentUrl: window.location.href,
            })
        })
            .catch(err => {
                console.log(err);
            });
        }else setLogEvent("Gig Platforms Page loaded", {
            currentUrl: window.location.href,
        })    
        const handleScroll = () => {
            toggleSticky();
        };

        if (window.addEventListener) {
            window.addEventListener("scroll", handleScroll, { capture: true });
        } else {
            window.attachEvent("onscroll", handleScroll);
        }

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

    }, [])

    const toggle = () => setIsOpen(!isOpen);

    const { mainSection,footerSection, seoMetaData={} } = NewDesignV2LandingPageData
    const { download_href } = mainSection.data.headerSection;
    const { mainPageAppDownLoadLink = download_href } = props.appConfig;

    const { securityHeaderSection={},footerHeadingData={},protectingYourInformation={},faqsQuestions={} } = NewDesignV2PrivacyAndSecurity
    

    return (<>{loader?<div style={{ textAlign: 'center', marginTop: '20px'}}> 
	   <Spinner style={{ width: '2rem', height: '2rem',color:'#787878',marginBottom:'25px' }} /></div>:
        <Container fluid className="main__container rewards-page" id="main-wrapper">
            <SeoMetaTags seoData={seoMetaData.privacyAndSecurity} />
			<Row className={`topbar ${stickyHeaderClass}`}>
                <Col className="p-0">
                    <Header toggle={toggle} isOpen={isOpen} content={mainSection.data.headerSection} downloadLink={mainPageAppDownLoadLink} />
                </Col>
            </Row>
            <Row>
                <Col className="section-area p-0 privacy-and-security-header light-purple-bg">
                    <PageHeaderSection content={securityHeaderSection} appLinkUrl={'/book-a-demo'} target="" />
                    <div class="container"><div class="divider-line-full-width m-t-60"></div></div>
                </Col>
            </Row>
            <Row>
                <Col className="section-area privacy-security-card-wrapper light-purple-bg">
                    <SecurityCards content={protectingYourInformation}/>
                </Col>
            </Row>
            {/* <Row>
                <Col className="p-0">
                    <div id='faqs'>
                        <Faqs content={faqsQuestions} quesSeeMoreCount={6}/>
                    </div>
                </Col>
            </Row> */}
            <Row>
                <Col className="p-0">
                    <Footer content={footerSection} footerHeadingData={footerHeadingData}/>
                </Col>
            </Row>
        </Container>}</>

    );
}
const mapStateToProps = state => {
    const { appConfig } = state.feparamsReducer;
    return { appConfig };
}
export default connect(mapStateToProps, { feparamsAction })(PrivacyAndSecurity);
