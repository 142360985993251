//import Config from 'Config'
import moment from 'moment'
import { formatDollar } from '../js/utils/helpers'
import { GetEnvVar } from '../js/utils/AppConfig'

export const dummyPartnerName = "stashtest"

export const POOL_DATA = {
    UserPoolId: GetEnvVar("UserPoolId"),
    ClientId: GetEnvVar("ClientId")
}

export const TrackingId = "UA-126746588-1"

export const API_URL = GetEnvVar("API_URL")

export const IRE_ID = 21508

export const IRE_ACCESS_TOKEN = "A2335310-6aff-4ee9-b84b-fabd9a88107e1"

export const REG_URL = GetEnvVar("REG_URL")

export const currentYear = moment().format('YYYY')

export const sms_api_url = `${API_URL}/notification/send/smsmessage`

export const reviewList = [
    {
        title: "*****",
        platform: "Google Store",
        desc:
            '"I am absolutely in love with this app and how things are done! Honestly so much better than anything else I have ever used!"'
    },
    {
        title: "*****",
        platform: "Apple Store",
        desc:
            '"The idea of rewarding micro life insurance for good habits is hands down biggest take away from this app"'
    },
    {
        title: "*****",
        platform: "Twitter",
        desc:
            '"This app promotes your spiritual, physical and mental well-being"'
    }
]

export const isAvibraDomain = partnername => {
    return partnername == "www" || partnername == "avibra.com" || partnername == "avibra" || partnername == ""
}

export const getSecondSectionList = (partnername) => {
    return [
        {
            title: "Instant",
            //icon: require("../images/comment-arrow.svg"),
            desc: "90 seconds (and a can-do attitude) gets you the tools you need to improve your well-being, manage risks and earn insurance coverage"
                
        },
        {
            title: "Learn",
            //icon: require("../images/contact-icon.svg"),
            desc:
                "Be the best version of yourself through our science-backed wellness content."
        },
        {
            title: "Earn",
            //icon: require("../images/checkbox-marked.svg"),
            desc: "Grow your coverage through rewards for your good habits."
        }
    ]
}

export const wellBeingList = [
    {
        icon:
            "https://v.fastcdn.co/t/9f388803/8dc6f99d/1572969970-45164665-63x57x63x63x0x6-buddhist-yoga-pose.png",
        head: "Guided Meditation",
        foot: "positively impact mental and physical health"
    },
    {
        icon:
            "https://v.fastcdn.co/t/9f388803/8dc6f99d/1572969971-45164725-50x50-thank-you.png",
        head: "Gratitude Journal",
        foot: "rediscover great things in life"
    },
    {
        icon:
            "https://v.fastcdn.co/t/9f388803/8dc6f99d/1572969972-45164805-50x50-test-quiz.png",
        head: "Quizzes",
        foot: "make it more fun to learn about different topics"
    },
    {
        icon:
            "https://v.fastcdn.co/t/9f388803/8dc6f99d/1572969973-45164925-50x50-lotus-flower.png",
        head: "Well-Being Videos",
        foot: "improve self-awareness"
    },
    {
        icon:
            "https://v.fastcdn.co/t/9f388803/8dc6f99d/1572969973-45164965-50x50-motivation.png",
        head: "Life Lessons Learned",
        foot: "inspire both professionally and personally"
    },
    {
        icon:
            "https://v.fastcdn.co/t/9f388803/8dc6f99d/1572969974-45164990-50x50-elemental-tip.png",
        head: "10 Tip Series",
        foot: "improve health, relationships and finances"
    },
    {
        icon:
            "https://v.fastcdn.co/t/9f388803/8dc6f99d/1572969975-45168635-50x50-group.png",
        head: "Social Impact",
        foot: "protect community through good habits"
    },
    {
        icon:
            "https://v.fastcdn.co/t/9f388803/8dc6f99d/1572969976-45165360-50x50-brain.png",
        head: "Brain Workouts",
        foot: "boost brain activity and improve memory"
    },
    {
        icon:
            "https://v.fastcdn.co/t/9f388803/8dc6f99d/1572969977-45165415-50x50-yoga.png",
        head: "Laughter Yoga",
        foot: "increase happiness, strengthen immune system"
    },
    {
        icon:
            "https://v.fastcdn.co/t/9f388803/8dc6f99d/1572969977-45168490-50x50-brain-and-head.png",
        head: "AI Well-Being Adviser",
        foot: "unlock good habits from wearables & daily purchases"
    },
    {
        icon:
            "https://v.fastcdn.co/t/9f388803/8dc6f99d/1572969978-45183755-50x50-spiritual.png",
        head: "Spirituality",
        foot: "unlock inner positive energy"
    },
    {
        icon:
            "https://v.fastcdn.co/t/9f388803/8dc6f99d/1572969979-45165030-50x50-global.png",
        head: "Protect the Earth",
        foot: "plant trees with your good habits"
    }
]

export const googleAppLink = "https://play.google.com/store/apps/details?id=com.avibra"

export const appleAppLink = "https://apps.apple.com/us/app/avibra/id1449843334"

export const fbPageLink = "https://www.facebook.com/avibralife/"

export const instaPageLink = "https://www.instagram.com/avibra/"

export const twitterPageLink = "https://twitter.com/AvibraLife"
export const tikTokLink = "https://www.tiktok.com/@avibra_app"
export const linkedInLink = "https://www.linkedin.com/company/avibra/"

//"https://vm.tiktok.com/ZM8WpJmdU/" 

export const privacyLink = "https://resources.avibra.com/Avibra_PrivacyPolicy.pdf"

export const blogLink = "https://blog.avibra.com/"

export const licensesLink = "https://resources.avibra.com/Licenses_Avibra_Insurance_Services_LLC.xlsx"

export const termsLink = "https://resources.avibra.com/Avibra_TermsofService.pdf"

export const removeIcon = `<?xml version="1.0"?>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 298.667 298.667" style="enable-background:new 0 0 298.667 298.667;" xml:space="preserve" width="20px" height="20px" class=""><g><g>
	<g>
		<rect y="128" width="298.667" height="42.667" data-original="#000000" class="active-path" data-old_color="#000000" fill="#FF0000"></rect>
	</g>
</g></g> </svg>`

export const checkedIcon = `<?xml version="1.0"?>
<svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" enable-background="new 0 0 515.556 515.556" height="20px" viewBox="0 0 515.556 515.556" width="20px"><g><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#008000"/></g> </svg>
`

export const avibraLogo = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="135px" height="135px" viewBox="0 0 135 135" enable-background="new 0 0 135 135" xml:space="preserve">  <image id="image0" width="135" height="135" x="0" y="0"
    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIcAAACHCAYAAAA850oKAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAe
UUlEQVR42u19e5hU1ZXvb+2qftF0A60E4/sB45XmTduiGF84kjDGUQlGY+beyahJrtFJJmbixDzE
fJN81ziaMSZzrzeJxIQ7kTYaZ26iSdS0BmPkKQINeJsGjE9QQYSGqu6qve4f5+y91z51Guimq05T
XcsPq7rqnFN7r/07673XASpUoQpVqEIVqlCFKlQyGtN174nMTEmPYyiRSnoAQ4V60fOvDZv/ZUHS
4xhKVAEHgNHb7p7OUJcz6PaFzBWehFRhBICeXr4DzASiiXdt+e7VSY9nqNCwB8eorntaCPgoM8AM
EOMbC7gtlfS4hgINe3DkwHcwACICgaAZp/9285ufSHpcQ4GGNTgat949C4x5AAFMYAAEQo70Ny7g
9nTS40uahjU48jm6g5m9z5gZ0Bi/onPt3yQ9vqRp2IKjYfN3ZwN8SaBMKLA3QAAIIAKIvj6T769K
epxJ0rAFhwZ/M5AZATBAITAAgAFmPmVjV/Zvkx5nkjQswTFy6z3nM/NFVlJY6cH2PQCA8bVmbqtO
erxJ0bAEB+f4m4TAAOVAnwTSIwSKkSjQOPGVzTuuS3q8SdGwA0d9171zGHSekxBOnRCZ1AoBIVg0
cNv4zsdrkh53EjTswEGcF1IDkEBghn1vDVPNx7/O2z6d9LgT4VXSAyglNXb+69w89G+CSHnooYSv
lh2hceq8F4Cg3hxbXXfqtlM+lUl6DqWkYSU5csx3OGAEiDBGKNhJEITAMLaHZv7g9p7Mf096/KWm
YSM5Gru++1d5jV9Zt1Wqj0CEiL9ho6WGTUS0fdRIdeobx35mX9JzKRUNG8mR07jDqg+hMiwwjOQI
j5HGKjOgNY/bvRefS3oepaRhAY6Gznv/GoyZgTcSTNm6q8L4ZO/zQOV4kVPGP47d0TYy6fmUisoe
HMxMmnmh9UZChSGNT4MKgvJViQmOuWuN3bfrvZuTnlOpqOzB0dB575UMTCNpU8T8k+k3U9thpEkA
FAUGgTW+dPTbP25Iel6loLIGBzMTFC0M3sPzRqz/GjE+AxNE2e9I2uzBgU373s1/Pum5lYLK2lsZ
2XnvVZp5CRCJfJJQK4IFJu6BsAjdSQ2RnAvA815NVePJu067anfScywmla3kYGbi0Nbw7wHhvgJw
9RwOBIGQMSCJSI7gnNHZ3J4vJD3HYlPZgmPklvvmMOgMAMaAgLUt2EydYGwRmba3/4WSwoTZPbtF
47PlXi1WtuCAxvXBGwJIeTEMAGCwXXTrrjozxLmxRNYGYXENZj5m+f/bdmnS0ywmlSU4Gt64/2hm
vsIZnwgAIjKxVjIY8jwTWw3mPBejXvxI6g1Jz7WYVJbg0N3ZuQBVO+MTQmqQJzGM1LCqo0CSFNor
9nzNl5y8dVFt0vMtFpUlOAA13cudRELhhvzYh0y2wQOLdw2WcKH0jmxqUtKzLRoXkx5AUYj1NKMW
wOwtuLUhmDypYaVHpJ7U2iZSvYQqihmA5mlJT7dYVJ7gINUsg162LoNcziQ8EDKvYutJ2VclFALN
2ijmfAJIcXPS0y0WlacrxqgxKoIiqgSIqg8uKPixQPHS+85ssdKGCFqrsi0hLE9wACRD4rI21PM8
QEbzwKtCB3xgmMiojLQCABPIi6+XF5UrOIQh6WmBwHYgd/fLFH34NTwvxwTCwqPstUieX55UnjYH
01uAH8iykkEuNonPvJiIe+/vZZF2B0w85K2kp1ssKk9wAGu9egwZGbXJNXjxDlttbsiG0pWfxBX1
pQwGMa1NerLForIEB4Ne6qseIzA4TUg8kq2N2aJgwOCMVmebAITqNL2U9HyLRWUJDpWqegFAbD2G
kxrCnTWqhJwBa78l34iVe16I1fb3Jnxia9LzLRofkx5AMehLp13/DBG9ak0IkXWVd79HMsjFIn0v
9rYYFeNiH1hMRGVrk5YlOBYSabD6aV/1GI58e8PZEvKIwtON15Ki9E+SnmsxqSzBAQBVKSwiolyf
9RiQsQs/8eY8k7BulN035nqk1J/2Trx6fdLzLCaVLTh2T/hMFzHd11c9RuDOutC5kRDSK5Gg8GIi
SjFY/UPScyw2lS04AKDu6NTtYLzZVz0GidC4Hz31o6U2JuJiIA/sb75mWdLzKzaVNTjeGXvdHkbq
Zlij0VchENIBMBlbmaXlUJW4cxSpN6rS9E9Jz60UVNbgAID9Z1z3iCK6rY96DCtJmEWUFCbMQeIw
Aojep5Sat+f0T7yT9LxKQWUPDgDoPv26/0FE/xaNhHr1HCJX4lovkKzl6E1BXdl9+tVlG/SK0rAA
BwB8+fS/u5mUuguAK/+L81OFhHFejtqZStFHu5uveTrpeZSSyjbd3BeNeHnR5dD4idYY1WcDFwEQ
RbRCU+2CzMT5ryQ99lLTsJEchvad/qnH0lXpFiL1B7CfM3EbqQkA8kS46xRUnTscgQEMQ8lhiJmp
fuPi65j5OwDGBHUcgaohVisV4Ybu5mvXJD3OJGnYgsNQ/ZZ/H6cz+rtgvoZBe4noa7ee8Yn7FhLp
pMdWoSFC9R2L5zRt/vkJSY+jQhWqUIUqVKEKVahCFapQSMPGlWVmatrycKPuRVMv9zYRU5OGbjrr
jOMeeYYuzMljGzvaxueIJ2miXSlgp1bYedQI7HzthKv2Jz2PUlJZguMCbk+v6HxjEud1q2Y+C6BW
Ap3BmlPR7sWjjj5q5PZj5nbL80esa7tOg38EAOYh1UHRMe1RoNUatFwpXg5UL9/f/NE/Jz3fYlHZ
7HhrfLWtKdeduYoZH39h46tnEaMu2Aergs1NAFwBz4FrgnV4bHC+aD0JNDBwPlifr5kA7kXt2kff
AtTTnKLFl0287MmHifJJ82Kw6IiWHOM7H695PbfjUmJ8UjPNI0I1ZDkf3FYCQuE+WYDQ1DSy/o1j
P+r1M6/tWPJ3nMePTbWY3MiESNpfNnghSm1n4OcpqMX7ply6Kmn+HC4dkeAY09U2KtvbfQvn6WYQ
Rkczq/E77H1QAACDONN8dSq6vaBu/cNXs+afI5qtDcsEC64rtlCyK2Rer4A79k259JEjdfvCEZWV
Pf7Vtrr6TT/5cja7dwtrfJ2JR9sv+6rH8CrOlf0uVDHdcQtHSu1xWyBlzalo2iJT+5EOQQDAzJPy
jIdr1/56Zc1Lv/5w0rwbCB0R4LiA29MjNiy68d293V15re9kUFNBFZc4Pli7YGpyt70r9QrPY4pt
MstMu2U1mLepOqqaZD0q/E5B4XkzWPMTNWt+/Ye6Fx+fnTQv+0NDHhyjOn9y2vJNW59j8A/A+KDX
o8tTJ5F6jHB3vHeXe/tjAQBdcb9JxF3GbrG1pESR/bX24IL+YdHSw+Bv+pAmWlqz5ol7xnd2HhEN
X4Y0OOo3Lvrbnpxew4yzZF8vJy38x23ZG7tgE5Pbs2LqNggKTOrluN/dN3H+myC1R25kMmrK2Rp+
XzH7s/DVmZRqzEys+R/+vLdrWXXHUxOT5u/BaEiCY9Qr/2dM3cYH2jTrRcw88oD9Mbxd7/AavnG0
v4bY8ggAxNzR1xiIqUOe7zwS1xvdlqHa/h5OYvhgEioqaJg7FVm9qubF3w3ph/sMOXA0di2a0LMv
8yKABYfSH8OCAKJJi2yVIDcuSbsAgFJ4tq9xEPBs4YZrv0G+6+8RKqHohilzJSAAjjc2rtWav1+9
+smHZvLKIflY9CEFjvpND07JZfVSZpzk98cQO87kXSgbLHi75M2HwuWMLJYi9e7eiQv63GagiX7v
Li57lbrrRW2MuOa3norz/CILoI+vf3HXY8e/+nxd0vyP0pABR13nolnM+WcYGFfYH8M0XTF3vdlP
Et6NgG9sioUINkMj0neUwIzfHCj+MHb0uKWA2iNZFJUa0sYwu+XMmAFyMQ+vd5hodxl+qBnz3n57
/2+aOl9oTHodJA0JcNS//KM5yPFTzDzm0PpjwC2E+X802OU9nkvkR8KQeIroZwca02snnLOfgEds
q7iCSvXojv3CprderCXiAjvPx8yVz9uzZ9/TDRuXHZX0ehhKHBx1Lz/YqrX6FYB6Ix0O3h/D718u
F8lIlOjd6rVsIvXmvOb5Tx2cO/RTz+5g8TveOMQhkbF6ABJ/S3vFhvuZWzL7un87VFRMouCo37ro
GNL5RwHU9rs/RoHB584PjRR7Hb+jIMBM9x1Kgiwz6a/bweolt43SfddnM33IcUTzL257pZN8Bc34
Z+7Ykf1hkutiKDFwNHNbtc7wIwwcZ4xOINofgz3m2TssNucRngdYEe/bLeZL9d6I2rofHDKDFH/L
2kBMVnq4fqRuXO7pTrIXiL9B25wk5+PiInbu19asfPaWpNbGzj2pH966ae99AM4ZYH8MuFyJO0c+
A9aByg+xk6K7d06Y9/6hjvPWyZc9AtA6m8zrw1gmG5U145GvZtymKb/51pc0UhJp8J01Lz73l0mt
D5AQOEZseOB6Znw6+mTGwv4YEcNOBrtC8h7MZyOVLv4he9kTqa7ja+ru6s9YFxJpAn2OpWcj7R3z
rDjAhtm97yGlljtddiW08xA3BEApndMP1a7+40lJrBGQADjqt/z7OBDuDv6ShuSB+mM4N9GPWJIA
hDneXK/wOCb+3OYJ87L9HXNm6qVLFdOD0bvb2RUCnKJJndchWT5Lzhs7efaHM04BAE2a+XulXiND
JQeHzuy/i4FGoN/9MUKShmV41xo3VwSf/MayAIi+nZ30sd8OdNyjKH0TM62V+RIv6GbH74PSBsfE
3HypEVdMFF6XCKz1ZTUrnptX6nUynC4ZNbz803Nz+dxS89PRFLe5a0je+ZGaTxgGxtogVn1EcyL/
kZk8/4rDLbqp3fD0SZztWcHgsYgC2GNlofciv2cRM6FY4zoSDSbVdeKoDzRvnjCh31LvcKhkkmMB
t6VyOv8DaaEfej2G5ap3l3p6HYV3YjBBta4xddQnB6MaKzNxziuKcCUR9UBILdkpyKoYMydyRnUg
RcgG48w5Vh154X8HLK35tD/vfvvLpVorQyUDx682dd8IxpSB1WOE0cSYegopeYhUYNTCYEi9w1V0
2dvNF+4drHnsn/aR54jUjSYq6uwbEUuJMaptfgiISMLw/DA2Y20TmGMsj75Su3p1SY3TkoBj3Fs/
rQfwNWDg9Ri2pgKw9RgywGXiIs5uUdtS6dRFmTOu2DbY88lMnftjSqkbAcoFI5Xlh7JDoW9fWM8E
QiIal8pIDs/N9UoP6nQ+841SrJehkoBj706+CYwPSMu8f/UYAbui9Rh+rsIBSYGWpuvqWrvPuHxd
seaUnTr3fyqVugSEnWYMBgQyPuMBhQyoZYW8iOpG5mWfF+M8uv9as3LlaaVYs4CPRaaxO9pGauIv
9V2PAZtf6LsewwHHjxGw1d9Ctfxo4uST5+ydMO/tYs8tM+3idkqnWwm0AQgMYYqAnSL2UTBT5SSF
jd6aoJ4fGPNvBqR1Plcy6VF0cOx5u/vvwXx03/UYDhCF9Rgy6OhHHQuyoqB9rNRNmcnzb1hFLb2l
YmB28pyu+oaGswH6hbsB2PPEfMnmx0Cs6hDXtEEzch6deV4MA9fWLFv2F6WYW1HB0dS5uFER3TLw
egyTfIOfEodw/4Lj/oPSNDE76cpDzpkMJu2cMOv9nhkXL1BK/RVAW0yI3ElD+OUDUl1GYjvWFYc7
3gInsK9Smqgk0qOo4NiXy31BM5r8egxp2Ts50K96DNhQ+5NIq/OzUz52+VDo+JedftHjJzaeOJFI
3ahUaptXShjOybq+VGhvyfpTL69UECika6pXrTqj2PMpWhBs9NZFo7P7sQ1Mo/yH4MQFjmTU0x3l
7ij5HjtJqSWUT/1435QrhuyWwwu4Pf38ajUfwH8D4xJmpAIf2wT2ZP5HqFbJjxBIBBTwi5iW9M46
8+pizqFo4Bix6cFvssbX5RZFmfOIPh4rTJmK9LtljmamtYrwe1Lq6VNYP9XRfFVPMZky2FTfsfyY
nkz2IwS6CMwXMuO42Igq+a49pPo0PNMhf0ixoqopPWdOLdozX4oGjtqOB28jQmO85lL+e60BpUBM
OWbeQ6A9zKm3UopePqZ6xOaBJMuGMo1au3RMT079l1we4xVhTJ5Vg2Ie6QyUCH8kaQCKAA0QsDR7
Vsuvk55PhSpUoQo5KmlWthQ0tqN9ZCaXOa4XvceBcRyp1NHMXAemOiauA1MtmJQKPKf3MtM/clv0
GjUd7ePR0/NFK9JZ5UC0nzTv14T9xKqbCW+lQK9Dp1+fOLP2zVLGVkpFRyw4LuD29PKOPdO15mkE
nszAZGiapAEv4CZzNWbKoqb09p4Z874ZvfZCZvXtNU+uBVNzXM+PgusSacXYysA6AOuA1DpVVb0y
M/WsrUnz6XDoiAHHQmZ158ZHWzmvLoTG+UyYDcZIzx0Wgado2Z2LJdig286R9aNP2TlhVmw9afXq
pz4G5ocdm/pfj0GgVwE8w0g9m0rxU5kZsxOPxfSHhjQ4LuD29Ir1uy7Ma30lSF3BzOP8hfIXQ+Zd
ooGjaM6CQV/tmf7hb/f128xMNS8+vQbAFD+sL+sxTEQmDO5xZGzhq43dkFrNoEcVVT3S09KyKWn+
HoyGJDhGdvyiuVfj0wR8kpmazFDjgkHxVVeRnXJhlbit8wC901hde8rB6jyqX3zqcmj8snCh/XoL
mUn2JFgMUJwqwnpFqR/WVtf+bPeUKbuS5nkcDRlwzOSVVR3rt1xNUJ/RzLOjYWUPCCJIxvI4iOgr
Rc9354DUrT3TP/ydg42Jmalm9e9XMjADsdIHQnLFACHy+9GxhZ/vJ9DDSKX+rbelZUg9jjRxcBz/
alvdu++nrgfzP2rNJwSDEpuIAC8R56kSwFsct9EoLjwf3smkdowmnLp9qt97tC+qWdV+KYP/r8t7
mFaVkWo2M5YCUMTZPfAAIsbbnqbUtzKtM4bEs+QSA8fJW9trt+99+2YAt2jGOO9utCrEDzF7uYgY
G8PPVURUUJjTIFK3ZKddck9/xlq1qn05GGcWsM3mSdz4vGOkxwQg3g6KSJwgK/uCSqVuz7ZM+11S
6yNGVFqq61hyFTPdyRonxz58T9ZwRJko9btY/LgK8AIVw3hr7NjGU1874Zx+tamuWdX+YQY94eU5
xLUdUIPvrA3k5Up8NeRtwgY8teTArp5QnL6l5+zJG5NYp5KCY8SGh2awVt/TjNmyVlLWeaDgznJD
9aWGn+G1PTEi7qUHEKbPZ2fMHdAmoepVzz7Pms+OA3NsPUa4z9erY4lzfftQkwJUOWb6Xw21o77+
3vRT3ivlepWkhnR85+M1I9Yt+bbOq2Va82xXiS0roSJlczEiuv/9MTzJ8foHx1T/74HOgRTfPlj1
GIUqCKK4mBDpT5Imwk17Mrs7apatu7QU6yW4Xlyq62hrZcYiZp4Yy6RQHpsda3Jo0VoH7y60TI+W
AAjmuwgmCHRTdvolh1UpVrXyD0vBfG5QWnB49RgFm5dExyBpM0n1FDLqZ/UN6S+839y8s9hrVzTJ
wcxUu27JbazxPOsAGF4MwjDKqgMWUJXFxZCMgTvC7Wnx+2PIiu5Q9TBeHV993GH3vEhBfcOBTrkx
SvsDdhdtwfj9ToQxHpi4XvA+pj+Jxt90v59fm16xvugNb4siORo72pqymn8G8DzPi4jc+YVb/+JD
4d75UZsiKqrlPpDwWqRSn81Ou/j+wZhb1cql7WBcAETC6HGxFrvYhftqpcTwqt9i1Sc84zd8zZFS
/5Sb1Xx3MdYQKILkGLHhoRm9wGoC5gm96fpjSNdO9MWSzUtg+ebXkdq8iT0/rj+GDzICNk2aOvqB
wZpfmlO3ghTLhQ8H46sUcgvPMqwubS1rnwTnRXfgm4iuvUl8YKWh8S/p5zf8cmzHjpGDvY7AIIOj
dsOS87SmZ7TWJ8XHHEIy9oPd4uZLj9h9sIg7312YDeMj/TEoRTcNZjp9/5nnLIcOHrdhbYSI6Jd9
RQoMa2EneSoT8PqTIHKduP4kDEAzX77rvXefHLX2lTGDuZbAIIJjxIYl88D0GzAaPAZ4Fn1EBUSl
BRwD4rr09Ls/hqKHM1MvGfRoY62q+QoIuzwpAEACoW+vSthbns3ix3gs2MPXA/UnYcKs7r37nql/
Ycu4wZznoICjrmPJVTrPj7HWdW7nme9BeAEgoS48qcFOt8oYgVFNUo87oeG7wDa0TdSdUlVfHExm
GdrT0vKOgvpqVGpIFWqNb2M8SfEXsUMG2p/E7fRnMNOUrO5ZWrey68TBmudhg2PEhoeuZ+afA1QV
rZr2ffqIZS/vpoI7UOha44pSzPkCRJC2R/Bu4f6pF742WIyK0ldmzrqfCCt8CVg4D+tlkS/lfPkB
z/YoVDnmn/+8GJ8HADMmZDO552qWdQ3KjrjD8lbqOtq+qLW+2/M+JOoh0I8oA0MS+QmOOa/Pegzp
lUTOJ9B/ZqZffHmxn5BUu3r1Sblcz0oCjo6do2dAmgxy8L3nvcluh96c3DPqPFBQjPcnI8KkdqSQ
nts7+5Q1hzO/AUuO2nUPfZUNMCKqRCbQpKFIBTaGH+CRNka0HsNz68y5bM43nxAItLFhRN2gNGs5
GGVmzHglTakFzMh5dlAUGN74/X++26ssHw/Yn0TMTPKKSAV8Zv5AnnPtVS9snnk48xuQ5Khbv2Q+
gx4GM8XmMsRSAZF6DPuj5rsB1GMINzAgUwhMu0lVn5mdfn7noCPhAFS1fPnfM/O9vsRwc/akYkHU
U/CMQxUkH1cqOgw4/kUCit4N5m4eInqtNqVaumedun0g8+q35Kjf2DZZMz/o59OFPWCHKIxIITF8
j4IcD8PPveRaRO9Swd0jVZTKEalrSg0MAOhtbf0eQT2AqGpg511YqShqPwoiuoDHG+eq+/aFExxR
ew4eeFjj+EyOfzFzJQ/okR39AkdjR1tTPqcfI1C9GXe0P0YAkIgIjcYwImFlGe2MThaCaQFTHbNc
0AlZSqn52RlznigJGmLoq60tN5BSP7IfWFUSNmsxdoewFcymcOuleGrXD5QFJ/t2mXvuLXtgk/xn
xrlrMlsGlIk+ZHAs4LZUj+aHGDgV8FHv3LZwUizWUE7U+6jQxvD6W0QCSS4v4RumILVXpVLzslMv
+s9iLv7BaCGR7m1tuYGI7rHGZRT4EYMdwuX1vBOhRqP9SaSbK9fAecqF/Gemz6b++MoN/Z3TIYPj
V+v5Tmb+S1N34ffHEGCRfr63mCiQGiw+l66qfUSnYKovSo24xq6UUhdnps75fZHWvN/Ue1bLLaTU
7XEhfnfXF/YnoUN8XoxUv85DBGyzPRGVtt2CGGDW308/v+2c/szlkAzS2o6HroWmxcGiuMUvqMT2
Y/8xP1NYGeWsbWecRt2zwmsSQLQeTB/vmXHxhpKu/iFS1fI1n9Ka7wOjvuCp1t684+tdfXXsXuOq
zA6J/8F3b9WkUi37zznh9UOZw0ElR8OmttOh1Q+dfvcDUc7ilhOCnYizO3xD1AHAsQswEU7EGl2O
ran7jh2dPnOoAgMAelunLUoTzVCElZYfYgbR/JHnAh/keTEeMGIkdyz/gzU4pievlyxkPiSNcUDJ
wcw0oqNtqdaY7aSFs75ZXqIAtUKXWvsAvuSIuHJRN1iKzVDdbAerTyVpePaXZq7kqpdya+8A6FYw
VHx/EsMYJRBEnt1yIP4bo9Y7P5b/BNYMUurz+kMnHNRIPSA4atYv+Rwxvh9VIaWuxwBULzF+WEU1
C/fOOK/oXQKLQella88G03dY87kFMRq7GSe+NuRA/O+zkt0zZKP8V3urU2pSZvaxrxxozH2Co66j
7URmdLAOn+val94s0HXyshE3NnZiItxuTGwXWdQAFitVuzAz9fytSS/wYFDqTx0fIeZvMTA9epP5
1WwBmZ16iOW/EtJVLqXokBTD//CU3/F5J8w90Fj7Bsf6tjateUHUYJIRzFiJAT9qF1U/BbkWEV62
12DKgOiXYPXPQ9muGCgxM6X/tOFjRPgSa7T6/As2TQU1tVwgGQrySoK3UZ5G+W9WwKY7Ump+/txj
H+1rnLHgqNvwyCydy/0pVmLI8LX8W0xOBmvigOMMMEjgaEXqWQCLR9SP+kVfu9/LjWqWbfyLfJ4+
yczXAnSq+Vx6JZLHfkYaEU/HpBHMVQ7K/87p9cc2r2qh2GKoeHCsb3uOGbO9IIxQH9TH587OUM6Y
soMV3gYRGMTEvJmJlhPoBUU1jxUzxX4kUPr5zeeA85eA0MqMVoCO8rO1OAT+y2qxA/A/vC4pdbM+
74PfjxtPATjq1radDcL9ujB3AQVAF/jj/qXcph4ATHkF2sWEXQS1k5l3gukdEL9UqxpW7J7yoV1J
L8hQppo/dp7GpFpz4PFKUxODm0CqCZqbmKghOEpIDe2H160qkfktVp6aV6B3ptWPm9uX9KhQhSpU
oQpVqEIVKjb9fzsmXuuCcbOqAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTAzLTA0VDEwOjU1OjIz
KzAwOjAwAkc3TAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wMy0wNFQxMDo1NToyMyswMDowMHMa
j/AAAAAASUVORK5CYII=" />
</svg>`

export const policyDocHtml = (policy = {}, renderLogo, renderBeneficiaryTable, renderInsuranceCoverageTable, waitingPeriod) => `<!DOCTYPE html>
<html>
<head>
    <title>Policy Document</title>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <meta http-equiv="Content-Style-Type" content="text/css" />
    <style type="text/css">
        body {
            background: rgb(204,204,204);
            font-size: 11pt;
            font-family: 'Arial', 'Helvetica', sans-serif;
            font-style: normal;
            font-weight: normal;
            color: #000000;
            text-decoration: none;
                
        }
        section {
            background: white;
            display: block;
            margin: 0 auto;
            margin-bottom: 0.5cm;
            widows: 2;
            orphans: 2;
            padding: 1cm;
        }
        section[size="A4"] {  
            width: 21cm;
            height: 29.7cm;
            font-size: 13.3333px;
            
        }
        section[size="A4"][layout="landscape"] {
            width: 29.7cm;
            height: 21cm;
            
        }
        page[size="A3"] {
            width: 29.7cm;
            height: 42cm;
            
        }
        page[size="A3"][layout="landscape"] {
            width: 42cm;
            height: 29.7cm;  
        }
        page[size="A5"] {
            width: 14.8cm;
            height: 21cm;
        }
        page[size="A5"][layout="landscape"] {
            width: 21cm;
            height: 14.8cm;  
        }
        span.bold
        {
            font-family: 'Arial', 'Helvetica', sans-serif;
            font-weight: bold;
        }
        span.boldLabel
        {
            font-size: 10pt;
            font-family: 'Arial', 'Helvetica', sans-serif;
            font-weight: bold;
        }
        span.normalText
        {
            font-size: 10pt;
            font-family: 'Arial', 'Helvetica', sans-serif;
        }
        span.boldText
        {
            font-size: 10pt;
            font-family: 'Arial', 'Helvetica', sans-serif;
            font-weight: bold;
        }
        .label{ 
            position: relative;
            display: inline;
            width:30%;
            float:left;
        }
        .value{ 
            position: relative;
            display: inline;
            width:70%;
            border-bottom:1px solid black;
            float:left;
        }
        .logo{
            width:100px;
            height:100px;
        }
        .divTable{
            display: table;
            width: 100%;
        }
        .divTableRow {
            display: table-row;
        }
        .divTableHeading {
            background-color: #EEE;
            display: table-header-group;
        }
        .divTableCell, .divTableHead {
            border: 1px solid #999999;
            display: table-cell;
            padding: 3px 10px;
            overflow-wrap: break-word;
            word-wrap: break-word;
            max-width:300px;
        }
        .divTableHeading {
            background-color: #EEE;
            display: table-header-group;
            font-weight: bold;
        }
        .divTableFoot {
            background-color: #EEE;
            display: table-footer-group;
            font-weight: bold;
        }
        .divTableBody {
            font-family: 'Arial', 'Helvetica', sans-serif;
            display: table-row-group;
        }
        .pCenter{
            text-align: center;
            widows: 2;
            orphans: 2;
            margin: 0px 0px 0px 0px;
        }
        .pLeft{
            widows: 2;
            orphans: 2;
            margin: 0px 0px 0px 0px;
        }
        .pJustify{
            text-align: justify;
            text-justify: inter-word;
            text-align-last: auto;
            widows: 2;
            orphans: 2;
            margin: 0px 0px 0px 0px;
        }
        .alignCenter{
            text-align: center;
        }
        span.heading
        {
            font-size: 13pt;
            font-family: 'Arial', 'Helvetica', sans-serif;
            font-weight: bold;
        }
        span.Theading{
            font-size: 10pt;
            font-family: 'Arial', 'Helvetica', sans-serif;
            font-weight: bold;
        }
        span.super
        {
            font-size: 6pt;
            font-family: 'Arial', 'Helvetica', sans-serif;
            vertical-align: super;
        }
        .list0 {text-indent: 0px; padding: 0; margin: 0 0 0 48px; list-style-position: outside; list-style-type: decimal;}
        .list1 {text-indent: -24px; padding: 0; margin: 0 0 0 48px; list-style-position: outside; list-style-type: lower-alpha;}
        .list2 {text-indent: -12px; padding: 0; margin: 0 0 0 48px; list-style-position: outside; list-style-type: lower-roman;}
        .list3 {text-indent: -24px; padding: 0; margin: 0 0 0 48px; list-style-position: outside; list-style-type: decimal;}
        .list4 {text-indent: 0px; padding: 0; margin: 0 0 0 48px; list-style-position: outside; list-style-type: disc;}
        .list5 {text-indent: -24px; padding: 0; margin: 0 0 0 48px; list-style-position: outside; list-style-type: circle;}
        .list6 {text-indent: -24px; padding: 0; margin: 0 0 0 48px; list-style-position: outside; list-style-type: square;}
        .list7 {text-indent: -24px; padding: 0; margin: 0 0 0 48px; list-style-position: outside; list-style-type: disc;}
        @media print {
            body, page {
            margin: 0;
            box-shadow: 0;
            -webkit-print-color-adjust:exact, background: #ffffff
            }
            @page{margin:0}
            html{margin:0}
            section[size="A4"]{ margin: 0px auto;
            border:0px solid black;}
        }
        .logoContainer{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .avibraLogo{
            width:auto;
            height:50px;
        }
        .partnerLogo{
            width:auto;
            height:50px;
        }
        .plus{
            font-size: 20pt;
            text-align: center;
            font-weight: 500;
            margin-left: 10px; 
            margin-right: 10px; 
        }

        @media screen and (max-width: 768px) {
            section[size="A4"] {
                width: 100%;
                height: 100%;
            }

            .policy-document {
                right: 15px;
                font-size: 16px;
                height: 40px;
                width: 40px;
            }

            section {
                padding: 0.4cm
            }

            #Layer_1 {
                width: 50px;
                height: 50px;
            }

            span.heading {
                font-size: 10pt;
            }

            .pJustify {
                word-wrap: break-word;
            }

            .divTableCell, .divTableHead {
                padding: 3px 5px;
            }
        }
        
    </style>
</html>
</head>
<body>
<div id="A4">
<section size="A4" id="section1">
    ${renderLogo()}
    <p class="pCenter"><span class="alignCenter">www.avibra.com</span></p>
    <p class="pCenter"><br/></p>
    <p class= "pCenter"><span class="heading">SCHEDULE OF BENEFITS</span></p>
    <p class="pCenter"><br/></p>
    <p class="pCenter"><br/></p>
    <p class="pCenter"><br/></p>
    <div class="label">
            <span class ="boldLabel">Insured Member:</span>
        </div>
        <div class="value"> 
            <span class ="boldLabel">${policy.profilenam || "&nbsp;"}</span>
        </div>
        <p class="pCenter"><br/></p>
        <p class="pCenter"><br/></p>
        <div class="label">
        <span class ="boldLabel">Member Date of Birth: </span>
        </div>
        <div class="value"> 
            <span class ="boldLabel">${moment(policy.dob).format('MM/DD/YYYY') || "&nbsp;"}</span>
        </div>
        <p class="pCenter"><br/></p>
        <p class="pCenter"><br/></p>
        <div class="label">
            <span class ="boldLabel">Policy Number: </span>
        </div>
        <div class="value"> 
            <span class ="boldLabel">${policy.policynumber || "&nbsp;"}</span>
        </div>
        <p class="pCenter"><br/></p>
        <p class="pCenter"><br/></p>
        <div class="label">
            <span class ="boldLabel">Effective Date: </span>
        </div>
        <div class="value"> 
            <span class ="boldLabel">${moment(policy.issuedate).format('MM/DD/YYYY') || "&nbsp;"}</span>
        </div>
        <p class="pCenter"><br/></p>
        <p class="pCenter"><br/></p>
        <div class="label">
            <span class ="boldLabel">Benefit Amount: </span>
        </div>
        <div class="value"> 
            <span class ="boldLabel">$${formatDollar(policy.earnedamount) || "0"}</span>
            <span >(as ${moment(new Date()).format('MM/DD/YYYY')})</span>
        </div>
        <p class="pCenter"><br/></p>
        <p class="pCenter"><br/></p>
        <p class="pCenter"><br/></p>
        <p class="pCenter"> <span class ="boldLabel">Beneficiary Information</span></p>
        <p class="pCenter"><br/></p>
        <div class="divTable">
            <div class="divTableHeading">
                <div class="divTableCell divTableHead"><span class ="Theading">Sl No.</span></div>
                <div class="divTableCell divTableHead"><span class ="Theading" >Name</span></div>
                <div class="divTableCell divTableHead"><span class ="Theading">DOB</span></div>
                <div class="divTableCell divTableHead"><span class ="Theading">SSN</span></div>
                <div class="divTableCell divTableHead"><span class ="Theading">Percentage</span></div>
            </div>
            <div class="divTableBody">${renderBeneficiaryTable()}</div>
        </div>
        <p class="pCenter"><br/></p>'
        <p class="pCenter"><br/></p>
        <p class="pCenter"><br/></p>
        <p class="pCenter"> <span class ="boldLabel">Insurance Coverage</span></p>
        <p class="pCenter"><br/></p>
        <div class="divTable">
        <div class="divTableHeading">
            <div class="divTableCell divTableHead"><span class ="Theading"></span></div>
            <div class="divTableCell divTableHead"><span class ="Theading" >Core (max coverage)</span></div>
            <div class="divTableCell divTableHead"><span class ="Theading">Plus (max coverage)</span></div>
        </div>
        <div class="divTableBody">${renderInsuranceCoverageTable()}</div>
         </div> 
         <p class="pCenter"><br/></p>
        <p class ="pLeft"><span class ="boldLabel">${AppConfig.insurancedisclamer || ''}</span></p>
        <p class="pCenter"><br/></p>
                <!--<p class ="pLeft"><span class ="boldLabel">*Benefits terminate at age ${AppConfig.terminationage}span></p>-->
        <p class="pCenter">&nbsp;</p>
</section> 
${policySecondHtml(waitingPeriod)}
</div>
</body>
</html>`


export const AppConfig = {
    "insurancepage2link": "https://www.avibra.com/insurance_document.html",
    "insurancedisclamer": "*If you had a pre-existing medical condition of cancer, heart disease, stroke, HIV at the time of your app registration or membership reactivation then your life insurance coverage maximum benefit will not exceed $3,000;",
    "terminationage": "55",
    "insurancecoveragetable": "[{\"name\":\"*Life Insurance\",\"core\":\"coremax\",\"plus\":\"plusmaxcap\"},{\"name\":\"AD&D\",\"core\":\"adndmax\",\"plus\":\"adndmax\"}]"
}


export const policySecondHtml = (waitingPeriod) => `
    <section size="A4" id="section2">
        <p class="pCenter"><span class="heading">SCHEDULE OF BENEFITS</span></p>
        <p class="pLeft">
            To make it easier for you, Avibra have simplified all your membership benefits, exclusion clauses, eligibility criteria and more into a single document.
            Life insurance is underwritten by Amalgamated Life Insurance Company. THIS IS NOT A CERTIFICATE OF INSURANCE issued by Amalgamated Life Insurance Company.                 
        </p>
        <p class="pCenter"><span class="heading">FRAUD NOTICE</span></p>
        <p class="pCenter"><span class="boldText">Any person who knowingly and with intent to injure, defraud, or deceive any insurer files a statement of claim or an application containing any false, incomplete, or misleading information is guilty of a felony of the third degree.</span></p>
        <p class="pCenter"><span class="heading">ELIGIBLE MEMBERS</span></p>
        <p class="pLeft"><span class="boldText">You</span><span class="normalText"> are eligible if:</span></p>
        <ol class="list0">
            <li value="1" style="margin-left: 0px" class="pLeft"><span class="boldText">You</span><span class="normalText"> are registered on the [</span><span class="boldText">Avibra App]</span><span class="normalText">;</span></li>
            <li value="2" style="margin-left: 0px" class="pLeft"><span class="boldText">You </span><span class="normalText">are between the ages of [18 and 54];</span></li>
            <li value="3" style="margin-left: 0px" class="pLeft"><span class="boldText">You</span><span class="normalText"> are a United States citizen or a non-citizen with a green card, working visa or student visa. </span></li>
            <li value="3" style="margin-left: 0px" class="pLeft"><span class="normalText">There is a </span><span class="boldText">${waitingPeriod} day waiting period</span><span class="normalText"> from date of your app registration or reactivation of your expired membership before life insurance becomes active and eligible benefits will be paid.</span></li>
        </ol>
        <p class="pCenter"><span class="heading">TERMINATION OF INSURANCE</span></p>
        <p class="pJustify"><span class="normalText">The insurance will terminate on the earliest of the following dates:</span></p>
        <ul class="list4">
            <li style="margin-left: 0px" class="pLeft"><span class="boldText">Your</span><span class="normalText"> death;</span></li>
            <li style="margin-left: 0px" class="pLeft"><span class="boldText">You</span><span class="normalText"> have the core (free) membership plan and you have not used app to improve your overall well-being or are inactive in the Avibra App for more than [30] days (for members with a plus membership, this condition does not apply)
                </span>
            </li>
            <li style="margin-left: 0px" class="pLeft"><span class="normalText">Your 55th birthday; or</span></li>
            <li style="margin-left: 0px" class="pLeft"><span class="boldText">You</span><span class="normalText"> have requested to terminate the coverage; or</span></li>
            <li style="margin-left: 0px" class="pLeft"><span class="normalText">Within thirty (30) days after the Group Policy has been terminated. </span></li>
        </ul>
        <p class="pCenter"><span class="heading">POLICY CONVERSION</span></p>
        <p class="pJustify"><span class="normalText">The life insurance benefit bundled with your core and plus membership is effective until you turn </span><span class="boldText">55</span>.<span class="normalText"> At that point, you will have the option to convert into a lifelong coverage based on your needs and insurance underwriter policy conversion guidelines which is separately covered outside core and plus membership. If you're interested in learning more about the policies available after you turn </span><span class="boldText">55</span><span class="normalText">, contact us directly at </span><span class="boldText">hello@avibra.com</span></p>
        <p class="pJustify"><span class="boldText">MISSTATEMENT OF AGE</span></p>
        <p class="pJustify"><span class="normalText">No insurance will be provided if </span><span class="boldText">your </span><span class="normalText">true age would render </span><span class="boldText">you</span><span class="normalText"> ineligible for coverage.  If </span><span class="boldText">your</span><span class="normalText"> true age was not stated in the [</span><span class="boldText">Avibra App</span><span class="normalText">] or in the Schedule, insurance will not be provided for a period longer or an amount greater than if </span><span class="boldText">your</span><span class="normalText"> true age had been stated in the </span><span class="boldText">[Avibra App]</span><span class="normalText"> or in the Schedule. In the event of a claim, if it is determined that </span><span class="boldText">you</span><span class="normalText"> were ineligible for coverage and </span><span class="boldText">your</span><span class="normalText"> true age was correctly stated on the [</span><span class="boldText">Avibra App</span><span class="normalText">] or in the Schedule, </span><span class="boldText">We</span><span class="normalText"> cannot deny or change the amount of coverage or benefit.</span></p>
        <p class="pLeft"><span class="boldText">EXCLUSIONS</span></p>
        <ul class="list4">
            <li style="margin-left: 0px" class="normalText"><span class="normalText">If </span><span class="boldText">you </span><span class="normalText">commit suicide while sane or insane within two (2) years of your effective date recorded in Avibra App; or
                </span>
            </li>
            <li style="margin-left: 0px" class="normalText"><span class="normalText">[Death as a result, directly or indirectly, of war, declared or undeclared, or of any act or hazard of such war. This exclusion does not apply to innocent victims; or]</span></li>
            <li style="margin-left: 0px" class="normalText"><span class="normalText">[Death as a result of a specified hazardous occupation or occupations; or]</span></li>
            <li style="margin-left: 0px" class="normalText"><span class="normalText">[Death while you are a resident outside of the continental United States or Canada.]</span></li>
        </ul>
        <p class="pLeft"><span class="boldText">SETTLEMENT</span></p>
        <ul class="list4">
            <li style="margin-left: 0px" class="normalText"><span class="normalText">If </span><span class="boldText">you </span><span class="normalText">are diagnosed with medical condition of cancer, heart disease, stroke, HIV in last 10 years at time of member registration/re-activation then your life insurance maximum death benefit will not exceed $3,000; and
                </span>
            </li>
            <li style="margin-left: 0px" class="normalText"><span class="normalText">Payment of the Death Benefit will be made to the Beneficiary; and</span></li>
            <li style="margin-left: 0px" class="normalText"><span class="normalText">A </span><span class="boldText">Death Benefit</span><span class="normalText"> will be paid in a single lump sum payment.</span></li>
        </ul>
        <p class="pLeft"><span class="boldText">CLAIMS</span></p>
        <p class="pJustify"><span class="normalText">Beneficiaries will need to email claims@avibra.com to initiate the claim process. Avibra will verify deceased member credentials for active core/plus membership before deceased death date and on successful membership verification will connect the beneficiary directly to the insurance carrier to start the steps required to successfully process the claim.</span></p>
        <p class="pCenter"><span class="heading">MEMBER CERTIFICATE</span></p>
        <p class="pJustify"><span class="normalText">Member Certificate that is part of this schedule document is always available for download from https://resources.avibra.com/Amalgamated+Member+Certificate.pdf</span></p>
        <p class="pCenter"><br></p>
    </section>
`

export const cityData = {
    "AZ": {
        "full_name": "Arizona",
        "nick_name":"Arizonan"
    },
    "CO": {
        "full_name":"Colorado",
        "nick_name": "Coloradan"
    },
    "DE": {
        "full_name": "Delaware",
        "nick_name":"Delawarean"
    },
    "FL": {
        "full_name": "Florida",
        "nick_name":"Floridian"

    },
    "GA": {
        "full_name": "Georgia",
        "nick_name":"Georgian"
    },
    "HI": {
        "full_name":"Hawaii",
        "nick_name":"Hawaii resident"
    },
    "ID": {
        "full_name":"Idaho",
        "nick_name":"Idahoan"
    },
    "IL": {
        "full_name":"Illinois",
        "nick_name":"Illinoisan"
    },
    "IN": {
        "full_name":"Indiana",
        "nick_name":"Hoosier"
    },
    "IA": {
        "full_name":"Iowa",
        "nick_name":"Iowan"
    },
    "KS": {
        "full_name":"Kansas",
        "nick_name":"Kansan"
    },
    "KY": {
        "full_name":"Kentucky",
        "nick_name":"Kentuckian"
    },
    "LA": {
        "full_name":"Louisiana",
        "nick_name":"Louisianian"
    },
    "MD": {
        "full_name":"Maryland",
        "nick_name":"Marylander"
    },
    "ME": {
        "full_name":"Maine",
        "nick_name":"Mainer"
    },
    "MA": {
        "full_name":"Massachusetts",
        "nick_name":"Massachusettsan"
    },
    "MN": {
        "full_name":"Minnesotan",
        "nick_name":"Minnesotan"
    },
    "MI": {
        "full_name":"Missouri",
        "nick_name":"Missourian"
    },
    "MS": {
        "full_name":"Mississippi",
        "nick_name":"Mississippian"
        
    },
    "MO": {
        "full_name":"Montana",
        "nick_name":"Montanan"
    },
    "MT": {
        "full_name":"Massachusetts",
        "nick_name":"Massachusettsan"
    },
    "NC": {
        "full_name":"North Carolina",
        "nick_name":"North Carolinian"
    },
    "NE": {
        "full_name":"Nebraska",
        "nick_name":"Nebraskan"
    },
    "NV": {
        "full_name":"Nevada",
        "nick_name":"Nevadan"
    },
    "NH": {
        "full_name":"New Hampshire",
        "nick_name":"New Hampshirite"
    },
    "NJ": {
        "full_name":"New Jersey",
        "nick_name":"New Jerseyan"
    },
    "NY": {
        "full_name":"New York",
        "nick_name":"New Yorker"
    },
    "ND": {
        "full_name":"North Dakota",
        "nick_name":"North Dakotan"
    },
    "NM": {
        "full_name":"New Mexico",
        "nick_name":"New Mexican"
    },
    "OH": {
        "full_name":"Ohio",
        "nick_name":"Ohioan"
        
    },
    "OK": {
        "full_name":"Oklahoma",
        "nick_name":"Oklahoman"
    },
    "OR": {
        "full_name":"Oregon",
        "nick_name":"Oregonian"
    },
    "PA": {
        "full_name":"Pennsylvania",
        "nick_name":"Pennsylvania"
    },
    "RI": {
        "full_name":"Rhode Island",
        "nick_name":"Rhode Islander"
    },
    "SC": {
        "full_name":"South Carolina",
        "nick_name":"South Carolinian"
    },
    "SD": {
        "full_name":"South Dakota",
        "nick_name":"South Dakotan"
    },
    "TN": {
        "full_name":"Tennessee",
        "nick_name":"Tennessean"
        
    },
    "TX": {
        "full_name":"Texas",
        "nick_name":"Texan"
    },
    "UT": {
        "full_name":"Utah",
        "nick_name":"Utahn"
    },
    "WI": {
        "full_name":"Wisconsin",
        "nick_name":"Wisconsinite"
    },
    "VA": {
        "full_name":"Virginia",
        "nick_name":"Virginian"
        
    },
    "VT": {
        "full_name":"Vermont",
        "nick_name":"Vermonter"
    },
    "WA": {
        "full_name":"washington",
        "nick_name":"Washingtonian"
    },
    "WV": {
        "full_name":"West Virginia",
        "nick_name":"West Virginian"
    },
    "WY": {
        "full_name":"Wisconsin",
        "nick_name":"Wyomingite"
    },
    "CA": {
        "full_name":"California",
        "nick_name":"Californian"
    },
    "CT": {
        "full_name":"Connecticut",
        "nick_name":"Connecticuter"
    },
    "AK": {
        "full_name":"Alaska",
        "nick_name":"Alaskan"
    },
    "AR": {
        "full_name":"Arkansas",
        "nick_name":"Arkansan"
    },
    "AL": {
        "full_name":"Alabama",
        "nick_name":"Alabamian"
    }
}

export const MEETING_LINK = GetEnvVar("MEETING_LINK");

export const FEPARAM_KEYS = [
    "mainPageAppDownLoadLink",
    "inputvalidator",
    "amplitudeKey",
    "hideSMSOnJoinPage",
    "employerEmailExcludedDomain",
    "financialTestimonial",
    "workPlatformTestimonial",
    "employersTestimonial",
    "mainSiteWhitePaperData"
    ]  