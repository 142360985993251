import React from 'react'
import "./EnhancesGrowth.css"

const EnhancesGrowth = (props) => {
    const { content = {} } = props
    const { tagLine = '', heading = '',imageWeb='',imageMobile='',imageTablet=''} = content
  return (
    <div className='container'>
         <div className='enhance-growth-container'>
        <div className='enhance-growth-items'>
            <div className='enhance-growth-content'>
            <div className='enhance-growth-subheading'>{tagLine}</div>
            <div className='enhance-growth-desc'dangerouslySetInnerHTML={ { __html: (heading) } } />
            </div>
            <div className='enhance-growth-image-wrp enhance-growth-image-wrp-desktop'>
              <img src={imageWeb} className='img-fluid' />
            </div>
            <div className='enhance-growth-image-wrp enhance-growth-image-wrp-tablet'>
              <img src={imageTablet} className='img-fluid'/>
            </div>
            <div className='enhance-growth-image-wrp enhance-growth-image-wrp-mobile'>
              <img src={imageMobile} className='img-fluid'/>
            </div>
        </div>
    </div>
    </div>
   
  )
}

export default EnhancesGrowth