import React from 'react'
import { Container, Row,Col } from 'reactstrap'
import "./AboutCompany.css"

const AboutCompany = (props) => {
    const { content = {} } = props
    const { aboutComapnyData = {}, solutionData = {}, resultsData = {},readyToExperience={} } = content
    const { aboutListData = [] } = aboutComapnyData
    const { solutionListData = [] } = solutionData

    console.log('solutionListData', solutionListData)
    return (
        <Container className='about-company-container'>
            <Row className='justify-content-center'>
                    <div className='about-company-sepration-line'></div>
                <Col lg={8} md={11} sm={12} className='about-company-container-column'>
                    <div className='about-company-content'>
                        <div className='about-company-section'>
                            <h3 className='about-company-title'>{aboutComapnyData.title}</h3>
                            <p className='about-company-upper-desc' dangerouslySetInnerHTML={{ __html: (aboutComapnyData.upperDescription) }}></p>
                            <div className='about-company-items'>
                                {aboutListData.length > 0 && aboutListData.map((item, index) => {
                                    return <div className='about-company-item' key={index}>
                                        <div className='about-company-checkmark'>
                                            <i className='fas fa-check'></i>
                                        </div>
                                        <div className='about-company-item-desc' dangerouslySetInnerHTML={{ __html: (item) }}></div>
                                    </div>
                                })}

                            </div>
                            <p className='about-company-bottom-desc' dangerouslySetInnerHTML={{ __html: (aboutComapnyData.bottomDescription) }}></p>
                        </div>
                    </div>

                    <div className='the-solution-content'>
                        <div className='the-solution-section'>
                            <h3 className='the-solution-title'>{solutionData.title}</h3>
                            <p className='the-solution-upper-desc' dangerouslySetInnerHTML={{ __html: (solutionData.upperDescription) }}></p>
                            <div className='the-solution-items'>
                                {solutionListData.length > 0 && solutionListData.map((item, index) => {
                                    return <div className='the-solution-item' key={index}>
                                        <div className='the-solution-checkmark'></div>
                                        <div className='the-solution-item-desc' dangerouslySetInnerHTML={{ __html: (item) }}></div>
                                    </div>
                                })}

                            </div>
                        </div>
                    </div>

                    <div className='the-results-content'>
                        <div className='the-results-section'>
                            <h3 className='the-results-title'>{resultsData.title}</h3>
                            <p className='the-results-upper-desc' dangerouslySetInnerHTML={{ __html: (resultsData.upperDescription) }}></p>
                            <div className='the-results-content-wrp'>
                                <div className='the-results-comma'>“</div>
                                <h3 className='the-results-item-desc'>{resultsData.subTitle}</h3>
                               {resultsData.userName && <p className='the-results-item-username'>{resultsData.userName}</p>}
                            </div>
                            <p className='the-results-bottom-desc' dangerouslySetInnerHTML={{ __html: (resultsData.bottomDescription) }}></p>

                        </div>
                    </div>

                    {Object.keys(readyToExperience).length>0 && <div className='the-solution-content'>
                        <div className='the-solution-section'>
                            <h3 className='the-solution-title'>{readyToExperience.title}</h3>
                            <p className='the-solution-upper-desc' dangerouslySetInnerHTML={{ __html: (readyToExperience.upperDescription) }}></p>
                        </div>
                    </div>}
                </Col>
            </Row>

        </Container>
    )
}

export default AboutCompany