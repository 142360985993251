import React, { lazy, Suspense } from "react"
//import Config from "Config"
import { Route, BrowserRouter } from "react-router"
// import GoogleAnalytics from "../js/components/GoogleAnalytics"
import ScrollTop from "./components/ScrollTop"
import { Spinner } from "reactstrap"
import { initBrazeSdk } from "./utils/helpers"
import { GetEnvVar } from "./utils/AppConfig"
//import { initBranchSdk,initBrazeSdk } from "./utils/helpers"
//initBranchSdk();
import MainContainer from "./utils/Components/MainContainer";
import { Helmet, HelmetProvider } from 'react-helmet-async';
const getMainRoute = () => {
  switch (GetEnvVar('BUILD_FOR')) {
    case "main":{
      initBrazeSdk();
      return <MainContainer/>
    }
    default:
      return <MainContainer/>
  }
}
class MainApp extends React.Component {
  render() {
    return (
      <HelmetProvider>
      <BrowserRouter>
        <ScrollTop>
          <div className="route-container">
            {/* {GoogleAnalytics.init() && <GoogleAnalytics.RouteTracker />} */}
            <Suspense
              fallback={
                <div className="text-center full-width invalidLink-container">
                  <Spinner
                    style={{width:'50px',height:'50px',color:'#333'}}
                    color={"#333"}
                    height={50}
                    width={50}
                  />
                </div>
              }
            >
              {/* <Switch>
                <Route path={`/`} component={getMainRoute()} />
              </Switch>
              <Route path={`/unauthorized`} component={Unauthorized} /> */}
              {getMainRoute()}
              </Suspense>
          </div>
        </ScrollTop>
      </BrowserRouter>
      </HelmetProvider>
    )
  }
}

export default MainApp
