import React, { Component } from "react"

import { connect } from 'react-redux'
import { getPartnerName,isEmpty } from "../../js/utils/helpers";
import { getPartnerData } from "../../js/utils/partnerData";
import { partnerDataAction, setPartnerAccessToken, setUnauthorizedPartner } from "../../js/actions/partnerDataAction";
import { feparamsAction } from "../../js/actions/feparamsAction";
import { dummyPartnerName } from "../../constant/constant";
import { getFeparamsApi } from "../../js/api/policyDocumentApi";

const PartnerContainer = WrappedComponent => {
    class ComponentToRender extends Component {
        componentDidMount() {
            let partnerName = getPartnerName()
            const { partnerReducer = {} } = this.props;
            //console.log("partnerName ====>>>",partnerName)
            
            getPartnerData(partnerName)
                .then(partnerData => {
                    this.props.dispatch(partnerDataAction(partnerData));

                    if (!partnerData.partnerResponse) {
                        this.props.dispatch(setUnauthorizedPartner(true));
                        return;
                    }

                    const partnerProfileId = partnerData.partnerResponse.partnerProfileID;//"3009763d-685e-4051-abfe-c56496a96f4c2";
                    const clientId = partnerData.clientId;//"35hhvfd4hrt4vv2k2ps5hvl8v4";
                    if(partnerProfileId == '' || clientId == '')
                        this.props.dispatch(setUnauthorizedPartner(true));
                    if(partnerProfileId && clientId){
                        this.props.dispatch(setPartnerAccessToken({
                            "partnerprofileid" : partnerProfileId,
                            "clientid" : clientId,
                        }));
                    }
                })
                .catch(error => {
                    //console.log(error)
                })
             getFeparamsApi().then(res => this.props.dispatch(feparamsAction(res.data))).catch(error => {
                 //console.log(error)
                })

        }
        UNSAFE_componentWillReceiveProps(nextProps){
            //console.log("nextProps partnerDetails --->",nextProps.partnerDetails);
            if( !isEmpty(nextProps.partnerDetails) ){
                if(!(nextProps.partnerDetails && nextProps.partnerDetails.partnerName !== ''))
                    this.props.dispatch(setUnauthorizedPartner(true));
            }
        }
        render() {
            const { partnerReducer = {}, feparamsReducer } = this.props
            const { partnerDetails = {} } = partnerReducer
            const { appConfig = {} } = feparamsReducer
            return <WrappedComponent
                partnerData={partnerDetails}
                appConfig={appConfig}
                {...this.props}
            />
        }
    }
    let ComponentWithPartner = connect(
        ({ dispatch, partnerReducer, feparamsReducer }) => ({ dispatch, partnerReducer, feparamsReducer })
    )(ComponentToRender)
    return ComponentWithPartner
}
export default PartnerContainer