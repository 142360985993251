import React, { useState, useEffect, useCallback } from 'react'
import {
	Container,
	Row,
	Col,Form, FormGroup, Label, Input,Button,Spinner
} from 'reactstrap';
import Header from './../../../components/NewDesignV2/Common/Header/Header';
import Footer from './../../../components/NewDesignV2/Common/Footer/Footer';
import SeoMetaTags from '../../../components/NewDesignV2/Common/SeoMetaTags/SeoMetaTags';
import './../../../../styles/NewDesignV2/main-page.css'
import './../../../../styles/NewDesignV2/main-page-responsive.css';
import NewDesignV2LandingPageData from "./../../../../constant/NewDesignV2LandingPageData.json";
import { setLogEvent, setAmplitudeInit } from '../../../utils/helpers';

import { connect } from 'react-redux';
import { getFeparamsFilteredApi } from '../../../api/policyDocumentApi';
import { feparamsAction } from '../../../actions/feparamsAction';
import './ContactUs.css'
const ContactUs = (props) => {
	const [isOpen, setIsOpen] = useState(false); 
	//const [selectedMyHabitsIndex, setSelectedMyHabitsIndex] = useState(0)
	const [stickyHeaderClass, setStickyHeaderClass] = useState('row topbar');
	const [loader, setLoader] = useState(false)
	const toggleSticky = useCallback(
        () => {

            const scrollY = window.scrollY ? window.scrollY : document.body.scrollTop
			const scrollHeight = (window.innerWidth <= 768) ? 10 : 100
			scrollY >= scrollHeight ? 
				setStickyHeaderClass(`row topbar fixed-header ${(window.innerWidth <= 768) ? '' : 'animated fadeInDown'}`)
                :
                setStickyHeaderClass("row topbar")
        },
        []
    );
	useEffect(()=>{
		window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
		setLoader(true)
		setTimeout(() => {
		  setLoader(false)	
		}, 300)
	},[])
    useEffect(() => {
        document.getElementById("main-wrapper").focus();
		const {appConfig={}}=props
		if(!Object.keys(appConfig).length) {
		getFeparamsFilteredApi().then(res => {
            const { data = {} } = res;
            props.feparamsAction(data);
            let { amplitudeKey = "" } = data;
            setAmplitudeInit(amplitudeKey);
            setLogEvent("Contact Us Page loaded", {
                currentUrl: window.location.href,
            })
        }).catch(err => {
            console.log(err);
        });
	   }else setLogEvent("Contact Us Page loaded", {
		currentUrl: window.location.href,
	 })
		const handleScroll = () => {
            toggleSticky();
        };

        if (window.addEventListener) {
            window.addEventListener("scroll", handleScroll, { capture: true });
        } else {
            window.attachEvent("onscroll", handleScroll);
        }

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
	}, [])

	const toggle = () => setIsOpen(!isOpen);
	

    const { mainSection={},contactSection={}, footerSection={}, seoMetaData={} } = NewDesignV2LandingPageData
    
    const { download_href } = mainSection.data.headerSection;
	const { mainPageAppDownLoadLink = download_href } = props.appConfig;

	const addressData = (address, index) => {
		switch (address.type) {
			case "email":
				return(
					<div className={`address_feild_new address-item-${index+1}`} key={index}>
						<div className="address_label_new">{address.label}</div>
						<div className="address_value_new"><a href={`mailto:${address.value}`}>{address.value}</a></div>
					</div>
				);
			case "website":
				return(
					<div className={`address_feild_new address-item-${index+1}`} key={index}>
						<div className="address_label_new">{address.label}</div>
						<div className="address_value_new"><a target="_blank" href={`https://${address.value}`}>{address.value}</a></div>
					</div>
				);
			default:
				return(
					<div className={`address_feild_new address-item-${index+1}`} key={index}>
						<div className="address_label_new">{address.label}</div>
						<div className="address_value_new">{address.value}</div>
					</div>
				);
		  }
	}

	return (<>{loader?<div style={{ textAlign: 'center', marginTop: '20px'}}> 
	    <Spinner style={{ width: '2rem', height: '2rem',color:'#787878',marginBottom:'25px' }} /></div>:
		<Container fluid className="main__container " id="main-wrapper">
			<SeoMetaTags seoData={seoMetaData.contactPage} />
            <Row className={`topbar ${stickyHeaderClass}`}>
                <Col className="p-0">
                    <Header toggle={toggle} isOpen={isOpen} content={mainSection.data.headerSection} downloadLink={mainPageAppDownLoadLink} />
                </Col>
            </Row>
			<Row className="contact_us_page_wrap">
				<Col className="p-0">
				<Container className="contact_new_area">
					{/* <div className="contact_new_heading_area">
						<div className="contact_heading_leaf_vector_img">
							<img src="https://images.avibra.com/newhome/contact-us-leaf-1.png"/>
						</div>
					<h1 className="contact_new_heading">{contactSection.heading}</h1>
					{contactSection.subHeading && <p className="contact_new_subheading">{contactSection.subHeading}</p> }
					</div> */}
					<Row className="contact_container">
						<Col sm="12" md="7" lg="7" xl="7" className="contact_new_left">
						<div className='contact_new_v2_left_area'>
							<h1 className="contact_new_heading">{contactSection.heading}</h1>
							{contactSection.subHeading && <div className='contact_new_subheading' dangerouslySetInnerHTML={{ __html: (contactSection.subHeading) }} />}
							<div className="contact_address">
								{
									contactSection.addressData.map((data, index) => {
										return addressData(data, index);
									})
								}
							</div>
						</div>
						</Col>
						<Col sm="12" md="5" lg="5" xl="5" className="contact_new_right">
							<div className="contact_map_new">
								{contactSection.rightImage && <img src={contactSection.rightImage} alt={contactSection.heading} className="img-fluid"/>}
								{/* <img src="https://fastly.picsum.photos/id/866/500/600.jpg?hmac=Ap3CJUF90vuFwe655sk6AOdZqXSdEQ3bxHTUa14nrx4" alt={contactSection.heading} className="img-fluid" /> */}
							</div>
						</Col>
					</Row>
					
				</Container>
				</Col>
			</Row>

			<Row>
                <Col className="p-0">
                    <Footer content={footerSection} />
                </Col>
            </Row>
        </Container>}</>

	);
}

const mapStateToProps = state => {
    const { appConfig } = state.feparamsReducer;
    return { appConfig };
}
export default connect(mapStateToProps, { feparamsAction })(ContactUs);