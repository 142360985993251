import { combineReducers } from 'redux';
import partnerReducer from './partnerReducer';
import feparamsReducer from './feparamsReducer';
import lifeInsuranceReducer from './lifeInsuranceReducer';

const allReducers = combineReducers({
    partnerReducer,
    feparamsReducer,
    lifeInsuranceReducer
});

export default allReducers