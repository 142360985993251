import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { getViewport } from '../../../../utils/helpers';
import RenderCommonComponent from '../../../NewDesign/Common';
import "./PressReleases.css"

const Regular_Slide_Width = 840;
const Mobile_Slide_Width = 325;
const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));


const PressReleases = ({
    pageData = {}
}) => {

    const [currentSlide, setCurrentSlide] = useState(0);
    const screenSize = window.matchMedia("(max-width: 768px)")
    const screenSize1024 = window.matchMedia("(max-width: 1024px)")
    const screenSize700 = window.matchMedia("(max-width: 700px)")
    
    const [disablePrevBtnSlide, setDisablePrevBtnSlide] = useState(window.innerWidth >= 1024 ? true : false);
    const [disableNextBtnSlide, setDisableNextBtnSlide] = useState(false);

    const [viewPortWidth] = getViewport();

    let param = window.location.pathname

    useEffect(() => {
        const slider = document.querySelector('.parent');
        let mouseDown = false;
        let startX, scrollLeft;

        let startDragging = function (e) {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        };
        let stopDragging = function (event) {
            mouseDown = false;
        };

        slider.addEventListener('mousemove', (e) => {
            e.preventDefault();
            if (!mouseDown) { return; }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
        });

        // Add the event listeners
        slider.addEventListener('mousedown', startDragging, false);
        slider.addEventListener('mouseup', stopDragging, false);
        slider.addEventListener('mouseleave', stopDragging, false);
    }, [])


    const currentSlidePercentage = screenSize.matches ? (Mobile_Slide_Width + 45) / viewPortWidth * 100 : (Regular_Slide_Width + ((pageData.isCustomerStory || screenSize1024.matches) ? 120 : 250)) / viewPortWidth * 100;

    console.log("currentSlidePercentage", currentSlidePercentage)

    const next = () => setCurrentSlide((prevState) => {
        return prevState + 1;
    });

    const prev = () => setCurrentSlide((prevState) => {
        return prevState - 1;
    });

    const updateCurrentSlide = (index) => {
        if (currentSlide !== index) {
            setCurrentSlide(index)
        }
    }

    
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={`${disableNextBtnSlide ? 'slick-disabled' : ''} ${className}`}
            style={{ ...style, display: "block" }}
            onClick={disableNextBtnSlide?null:onClick}
          >
              {'>'}
            </div>
        );
      }

      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
         <>
             <div
            className={`${disablePrevBtnSlide ? 'slick-disabled' : ''} ${className}`}
            style={{ ...style, display: "block" }}
            onClick={disablePrevBtnSlide?null:onClick}
          >
              {'<'}
        </div>
         </>
        );
      }

    const settings = {
        infinite: false,
        // slidesToShow: 3.5,
        slidesToScroll: 1,
        // autoplay: true,
        // speed: 2000,
        // autoplaySpeed: 2000,
        // cssEase: "linear",
        // slidesToScroll: slidesToScroll,
        slidesToShow: 2, 
        afterChange: function(index) {
            //console.log(`Slider Changed to: ${index + 1}, ${pageData.data.length} , ${window.innerWidth} background: #222; color: #bada55`);
            let currentIndex = (index+1);
            let totalItem = pageData.data.length;
            setDisablePrevBtnSlide(false);
            setDisableNextBtnSlide(false);
            if(window.innerWidth >= 1024){
                if(currentIndex == 1)
                    setDisablePrevBtnSlide(true);
                else    
                if((totalItem - currentIndex) == 1)
                    setDisableNextBtnSlide(true);
            }
        },
        //   centerPadding: '60px',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                adaptiveHeight: true,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '40px',
              }
            }
        ],
    };


    return (
        <div className={"section-container our-press-news-container"}>
            <div className="customers-say-wrp">
                <div className="container">
                    <div className="our-press-stories-title">
                        <h1 dangerouslySetInnerHTML={ { __html: (pageData.title) } }></h1>
                    </div>
                

                <div className="section-content our-press-stories-content ">
                    <Slider {...settings}>
                    {
                            pageData.data.map((value, index) => {
                                return (
                                    <RenderCommonComponent
                                        componentName={pageData.component}
                                        data={{ ...value, isCustomerStory: pageData.isCustomerStory }} keyIndex={`item-${((index%2) + 1)} `}
                                    />
                                )
                            })
                        }
                    </Slider>
                    
                    <div className="parent"></div>    
                </div>
                </div>
            </div>
        </div>
    )

};

export default PressReleases;