import { SHOW_LIFE_WAIT_LOADER, HIDE_LIFE_WAIT_LOADER, UPDATE_LIFEINSURANCE_INFO, UPDATE_LIFE_RECOMMENDATIONS } from "../types"

import axios from "axios";
//import { Actions } from "react-native-router-flux";
import store from '../store/store';
import {  getResetLifeInsuranceState,getApiCall,postApiCall } from '../api/lifeInsuranceApi';
export const UpdateLifeInsuranceInfo = ({ prop, value }) => {
    return {
        type: UPDATE_LIFEINSURANCE_INFO,
        payload: { prop, value }
    }
}


export const GetLifeQuoteDetails = (data)=>{

    return (dispatch)=>{
        dispatch({ type: SHOW_LIFE_WAIT_LOADER });
        //console.log("Data ===>", data)
        axios.post('lifeinsurance/getquote/details', data)
        .then(function (response) {
            dispatch({ type: HIDE_LIFE_WAIT_LOADER });
            console.log("GetLifeQuoteDetails response--->",response.data);
            dispatch({
                type: UPDATE_LIFEINSURANCE_INFO,
                payload: { prop: 'lifeQuoteDetails', value: response.data }
            });
            dispatch({
                type: UPDATE_LIFEINSURANCE_INFO,
                payload: { prop: 'lifeLiteQuote', value: response.data }
            });
            const userInfo = response.data.details.filter( i => ['info'].includes( i.property ))[0];
            if(userInfo){
                dispatch({
                    type: UPDATE_LIFEINSURANCE_INFO,
                    payload: { prop: 'lifeUserInfo', value: userInfo.propertyData.data }
                });
            }
            

            const selectedUserLifeInurancePlan = {
                profileId:data.profileId,
                coverage: response.data.coverage ? response.data.coverage : data.coverage ? data.coverage : response.data.coverage,
                term: response.data.term ? response.data.term : data.term ? data.term : response.data.term,
                payout: response.data.payout ? response.data.payout : data.payout ? data.payout : response.data.payout,
            };
            dispatch({
                type: UPDATE_LIFEINSURANCE_INFO,
                payload: { prop: 'selectedPlan', value: selectedUserLifeInurancePlan }
            });
            //AsyncStorage.setItem("selectedUserLifeInurancePlan", JSON.stringify(selectedUserLifeInurancePlan));
        }).catch(function (error) {
            dispatch({ type: HIDE_LIFE_WAIT_LOADER });
        })
    }

}

export const GetLifeQuoteLite = (data)=>{
    return (dispatch)=>{
        dispatch({ type: SHOW_LIFE_WAIT_LOADER });
        axios.post('lifeinsurance/getquote/lite', data)
        .then(function (response) {
            dispatch({ type: HIDE_LIFE_WAIT_LOADER });
            console.log("GetLifeQuoteLite response---->",response.data);
            dispatch({
                type: UPDATE_LIFEINSURANCE_INFO,
                payload: { prop: 'lifeLiteQuote', value: response.data }
            });
            dispatch({
                type: UPDATE_LIFEINSURANCE_INFO,
                payload: { prop: 'selectedPlan', value: {
                    profileId:data.profileId,
                    coverage: response.data.coverage ? response.data.coverage : data.coverage ? data.coverage : response.data.coverage,
                    term: response.data.term ? response.data.term : data.term ? data.term : response.data.term,
                    payout: response.data.payout ? response.data.payout : data.payout ? data.payout : response.data.payout,
                } }
            });
        }).catch(function (error) {
            dispatch({ type: HIDE_LIFE_WAIT_LOADER });
        })
    }
}

export const GetRecommendationsLifeQuoteLite = (data,index)=>{
    return (dispatch)=>{
        dispatch({
            type: UPDATE_LIFE_RECOMMENDATIONS,
            payload: { prop: 'lifeLiteQuoteRecommendations',index:index, value:  {loading: true} }
        });
        axios.post('lifeinsurance/getquote/lite', data)
        .then(function (response) {
            console.log("GetRecommendationsLifeQuoteLite response---->",response.data);
            dispatch({
                type: UPDATE_LIFE_RECOMMENDATIONS,
                payload: { prop: 'lifeLiteQuoteRecommendations',index:index, value: response.data }
            });
        }).catch(function (error) {
            dispatch({
                type: UPDATE_LIFE_RECOMMENDATIONS,
                payload: { prop: 'lifeLiteQuoteRecommendations',index:index, value: {failed: true} }
            });
        })
    }
}

export const GetRecommendationsOptions = (data,index)=>{
    console.log("GetRecommendationsOptions ===>",data)
    return (dispatch)=>{
        /*const resData = {"payouts":["monthly","lumpSum"],"coverages":[1000000,900000,800000,700000,600000,500000,400000,300000,200000,100000],"terms":[10,15,20],"paymentTypes":{"Pinless Debit":"DP","VISA":"VI","Mastercard":"MC","Discover":"DI","American Express":"AX","Japan Credit Bureau":"JC","Diners Club":"DC"}};
        dispatch({
            type: UPDATE_LIFEINSURANCE_INFO,
            payload: { prop: 'lifeQuoteOptions', value: resData }
        });*/
        axios.get('lifeinsurance/options')
        .then(function (response) {
            console.log("lifeQuoteOptions response ===>",response)
            dispatch({
                type: UPDATE_LIFEINSURANCE_INFO,
                payload: { prop: 'lifeQuoteOptions', value: response.data }
            });
        }).catch(function (error) {
            // dispatch({
            //     type: UPDATE_LIFE_RECOMMENDATIONS,
            //     payload: { prop: 'lifeLiteQuoteRecommendations',index:index, value: {failed: true} }
            // });
            console.log("GetRecommendationsOptions error ===>", error);
        })
    }
}
export const GetUserInitialLifeInsuranceState = (profileid)=>{
    return (dispatch)=>{
        dispatch({ type: SHOW_LIFE_WAIT_LOADER });
        axios.get(`lifeinsurance/state/${profileid}`)
        .then(function (response) {
            dispatch(UpdateLifeInsuranceInfo({prop:'initalRedirect', value: response.data.redirectTo}))
            dispatch({ type: HIDE_LIFE_WAIT_LOADER });
            //dispatch(GetUserLifeInsuranceState(profileid))
        }).catch(function (error) {

            dispatch({ type: HIDE_LIFE_WAIT_LOADER });
            //CustomException(error)
        })
    }
}

export const BuyLifeQuoteDetails = (data)=>{
    console.log("BuyLifeQuoteDetails ===>",data)
    return (dispatch)=>{
        dispatch({ type: SHOW_LIFE_WAIT_LOADER });
        
        axios.post('/lifeinsurance/buy', data)
        .then(function (response) {
            dispatch({ type: HIDE_LIFE_WAIT_LOADER });
            dispatch(GetUserLifeInsuranceState(data.profileId))
        }).catch(function (error) {
            dispatch({ type: HIDE_LIFE_WAIT_LOADER });
            //Actions.error();
            //CustomException(error)
            console.log("BuyLifeQuoteDetails error ===>", error);
        })
    }

}

export const acceptOffer = (profileid)=>{
    return (dispatch)=>{
        dispatch({ type: SHOW_LIFE_WAIT_LOADER });
        axios.get(`/lifeinsurance/acceptOffer/${profileid}`)
        .then(function (response) {
            if(response.data && response.data.header == 'Error' ){
                dispatch({
                    type:UPDATE_LIFEINSURANCE_INFO,
                    payload: {prop:"lifeErrorData", value: response.data}
                });
                dispatch({
                    type:UPDATE_LIFEINSURANCE_INFO,
                    payload: {prop:"makeLifeDecisionDataError", value: true}
                });
            }
            dispatch(EmptyMakeLifeDecisionData());
            dispatch(GetUserLifeInsuranceState(profileid))
            dispatch({ type: HIDE_LIFE_WAIT_LOADER });       
        }).catch(function (error) {
            dispatch({ type: HIDE_LIFE_WAIT_LOADER });
        })
    }
}
export const getPremium = (profileid)=>{
    return (dispatch)=>{
        dispatch({ type: SHOW_LIFE_WAIT_LOADER });
        axios.get(`/lifeinsurance/getPremium/${profileid}`)
        .then(function (response) {
            console.log("getPremium called ====>", response, profileid)
            if(response.data && response.data.header == 'Error' ){
                dispatch({
                    type:UPDATE_LIFEINSURANCE_INFO,
                    payload: {prop:"lifeErrorData", value: response.data}
                });
                dispatch({
                    type:UPDATE_LIFEINSURANCE_INFO,
                    payload: {prop:"makeLifeDecisionDataError", value: true}
                });
            }
            dispatch({ type: HIDE_LIFE_WAIT_LOADER });
            dispatch(GetUserLifeInsuranceState(profileid))
        }).catch(function (error) {
            dispatch({ type: HIDE_LIFE_WAIT_LOADER });
            //Actions.lifeInsuranceError({error: error.response.data});
            console.log("getPremium Action Error",error)
        })
    }
}

export const getPolicyNumber = (profileid)=>{
    return (dispatch)=>{
        dispatch({ type: SHOW_LIFE_WAIT_LOADER });
        axios.get(`/lifeinsurance/getPolicyNumber/${profileid}`)
        .then(function (response) {
            console.log("getPolicyNumber called ====>",response)
            if(response.data && response.data.header == 'Error' ){
                dispatch({
                    type:UPDATE_LIFEINSURANCE_INFO,
                    payload: {prop:"lifeErrorData", value: response.data}
                });
                dispatch({
                    type:UPDATE_LIFEINSURANCE_INFO,
                    payload: {prop:"makeLifeDecisionDataError", value: true}
                });
            }
            dispatch({ type: HIDE_LIFE_WAIT_LOADER });
            dispatch(GetUserLifeInsuranceState(profileid))
        }).catch(function (error) {
            dispatch({ type: HIDE_LIFE_WAIT_LOADER });
            console.log("getPolicyNumber Api Error ===>",error)
        })
    }
}

export const submitPayment = (data)=>{
    return (dispatch)=>{
        dispatch({ type: SHOW_LIFE_WAIT_LOADER });
        axios.get(`/lifeinsurance/submitPayment`,data)
        .then(function (response) {
            if(response.data && response.data.header == 'Error' ){
                dispatch({
                    type:UPDATE_LIFEINSURANCE_INFO,
                    payload: {prop:"lifeErrorData", value: response.data}
                });
                dispatch({
                    type:UPDATE_LIFEINSURANCE_INFO,
                    payload: {prop:"makeLifeDecisionDataError", value: true}
                });
            }
            dispatch({ type: HIDE_LIFE_WAIT_LOADER });
            dispatch(GetUserLifeInsuranceState(data.profileId))
        }).catch(function (error) {
            dispatch({ type: HIDE_LIFE_WAIT_LOADER });
        })
    }
}

export const GetUserLifeInsuranceState = (profileid, fromBot)=>{
    return (dispatch)=>{
        dispatch({ type: SHOW_LIFE_WAIT_LOADER });
        axios.get(`lifeinsurance/state/${profileid}`)
        .then(function (response) {
            //console.log("lifeinsurance/state/"+profileid)
            console.log("GetUserLifeInsuranceState response --->",profileid,response.data);
            dispatch(UpdateLifeInsuranceInfo({prop:'initalRedirect', value: response.data.redirectTo}))
            const lifeDecisionData = {lifeData: response.data, response:response, fromBot:fromBot}
            dispatch({ type: HIDE_LIFE_WAIT_LOADER });
            dispatch({
                type: UPDATE_LIFEINSURANCE_INFO,
                payload: { prop: 'makeLifeDecisionData', value: lifeDecisionData }
            });
            dispatch({
                type:UPDATE_LIFEINSURANCE_INFO,
                payload: {prop:"backFromLifeBot", value: false}
            });
            let errorFlag = false;
            if(response.error || (response.data && response.data.error)) {
                errorFlag = ( response.data.error &&  Object.keys(response.data.error).length > 0 ) ? true : false;
                dispatch({
                    type:UPDATE_LIFEINSURANCE_INFO,
                    payload: {prop:"makeLifeDecisionDataError", value: true}
                });
            } else {
                MakeLifeDecision(response.data,profileid,dispatch,response,fromBot)
            }         
        }).catch(function (error) {
            console.log("GetUserLifeInsuranceState error --->",error);
            dispatch({ type: HIDE_LIFE_WAIT_LOADER });
        })
    }
}

let lifeWaitLoader = false;
const MakeLifeDecision = (lifeData={},profileid,dispatch,response,fromBot)=>{
    console.log("MakeLifeDecision ====>", lifeData.redirectTo, " profileid ====>",profileid )
    if(lifeWaitLoader && lifeData.redirectTo.toLowerCase() !== 'longpoll'){
        //Actions.pop();
        lifeWaitLoader = false;
        dispatch({
            type:UPDATE_LIFEINSURANCE_INFO,
            payload: {prop:"lifeWaitLoader", value: false}
        });
    }
    switch (lifeData.redirectTo.toLowerCase()) {
        //error/longPoll/finalQuote/payment
        case 'longpoll':
            //Actions.waitLoader();
            let waitms = lifeData.data.uiWaitTimeSeconds ? lifeData.data.uiWaitTimeSeconds*1000 : 3000;
            setTimeout(() => {
                lifeWaitLoader = true;
                dispatch({
                    type:UPDATE_LIFEINSURANCE_INFO,
                    payload: {prop:"lifeWaitLoader", value: true}
                });
                dispatch(GetUserLifeInsuranceState(profileid))
            }, waitms);
            //write long poll logic , call GetUserLifeInsuranceState from here
            break;
        case 'declined':
            dispatch({
                type:UPDATE_LIFEINSURANCE_INFO,
                payload: {prop:"makeLifeDecisionDataError", value: true}
            });
            dispatch({
                type:UPDATE_LIFEINSURANCE_INFO,
                payload: {prop:"lifeWaitLoader", value: false}
            });
            break;
        case 'finalquote':
            dispatch({
                type:UPDATE_LIFEINSURANCE_INFO,
                payload: {prop:"makeLifeDecisionDataAction", value: 'finalquote'}
            });
            break;
        /*case 'databot':
            //Actions.push('lifeDataBot',{ data: lifeData.data, isEarned: false, index: null,carouselIndex:null, doNotPop: true , waitLoader: true})
            dispatch({
                type:UPDATE_LIFEINSURANCE_INFO,
                payload: {prop:"makeLifeDecisionDataAction", value: 'databot'}
            });
            break;
        case 'finalquote':
            dispatch({
                type:UPDATE_LIFEINSURANCE_INFO,
                payload: {prop:"makeLifeDecisionDataAction", value: 'finalquote'}
            });
            //if(fromBot)
                //Actions.replace('lifeFinalQuote',{actionResponse:response.data.data});
            //else
                //Actions.lifeFinalQuote({actionResponse:response.data.data});
            break;
        case 'payment':
            dispatch({
                type:UPDATE_LIFEINSURANCE_INFO,
                payload: {prop:"makeLifeDecisionDataAction", value: 'payment'}
            });
            break;
        case 'beneficiary':
            dispatch({
                type:UPDATE_LIFEINSURANCE_INFO,
                payload: {prop:"makeLifeDecisionDataAction", value: 'beneficiary'}
            });
            break;
        case 'complete':
            //Actions.lifeCongrats();
            dispatch({
                type:UPDATE_LIFEINSURANCE_INFO,
                payload: {prop:"makeLifeDecisionDataAction", value: 'congrats'}
            });
            break;*/
        default:
            break;
    }
    if(lifeData.error && lifeData.error !== null){
        setTimeout(() => { 
            dispatch({
                type:UPDATE_LIFEINSURANCE_INFO,
                payload: {prop:"makeLifeDecisionDataError", value: true}
            });
            dispatch({
                type:UPDATE_LIFEINSURANCE_INFO,
                payload: {prop:"lifeWaitLoader", value: false}
            });
        }, 1200);
    }
    /*if(lifeData.error && lifeData.error !== null){
        setTimeout(() => {   
            //Actions.lifeInsuranceError({error: lifeData.error, data: lifeData.data, profileid:profileid, dispatch, reload: () => {},redirectTo:lifeData.redirectTo});
            dispatch({
                type:UPDATE_LIFEINSURANCE_INFO,
                payload: {prop:"makeLifeDecisionDataAction", value: 'error'}
            });
            dispatch({
                type:UPDATE_LIFEINSURANCE_INFO,
                payload: {prop:"lifeErrorData", value: {error: lifeData.error, data: lifeData.data ,profileid:profileid, redirectTo:lifeData.redirectTo} }
            });
        }, 1200);
    }*/
}


export const GetLifeBeneficiary = (data) => {
    let beneficiaryInfo = store.getState().lifeInsuranceReducer.lifeBeneficiaryInfo.length>0 ? store.getState().lifeInsuranceReducer.lifeBeneficiaryInfo : [];
    return(dispatch)=>{
        dispatch({ type: SHOW_LIFE_WAIT_LOADER })
        
        dispatch({
            type:UPDATE_LIFEINSURANCE_INFO,
            payload: {prop:"lifeBeneficiaryInfo", value:beneficiaryInfo}
        });
        dispatch({ type: HIDE_LIFE_WAIT_LOADER });
    }
}

export const OnAddLifeBeneficiaryInfo = (data,index) => {
    let beneficiaryInfo = store.getState().lifeInsuranceReducer.lifeBeneficiaryInfo ? store.getState().lifeInsuranceReducer.lifeBeneficiaryInfo : [];
    if(Object.keys(beneficiaryInfo).length>0) 
        beneficiaryInfo[Object.keys(beneficiaryInfo).length] = data; 
    else
        beneficiaryInfo[0] = data;  
    
    const beneficiaryInfoArray = _.values(beneficiaryInfo);   
    return async (dispatch)=>{
        dispatch({
            type:UPDATE_LIFEINSURANCE_INFO,
            payload: {prop:"lifeBeneficiaryInfo", value: beneficiaryInfoArray}
        });
    }
}
export const UpdateLifeBeneficaiaryInfo = (data,index) => {
    let beneficiaryInfo = store.getState().lifeInsuranceReducer.lifeBeneficiaryInfo ? store.getState().lifeInsuranceReducer.lifeBeneficiaryInfo : [];
    if(index>0) 
        beneficiaryInfo[index] = data; 
    else
        beneficiaryInfo[0] = data;  
    
    const beneficiaryInfoArray = _.values(beneficiaryInfo);  
    return async(dispatch)=>{
        dispatch({
            type:UPDATE_LIFEINSURANCE_INFO,
            payload: {prop:"lifeBeneficiaryInfo", value: beneficiaryInfoArray}
        });
    }
}
export const DeleteLifeBeneficairyInfo = (data,index) => {
    let beneficiaryInfo = store.getState().lifeInsuranceReducer.lifeBeneficiaryInfo ? store.getState().lifeInsuranceReducer.lifeBeneficiaryInfo : [];
    let updatedbeneficiaryInfo = beneficiaryInfo.filter(item => item.id !== data.id);
    let beneficiaryInfoArray = _.values(updatedbeneficiaryInfo);
    return async(dispatch)=>{
        dispatch({
            type:UPDATE_LIFEINSURANCE_INFO,
            payload: {prop:"lifeBeneficiaryInfo", value: beneficiaryInfoArray}
        });
    }
}
export const ResetLifeBeneficairyInfo = () => {
    let beneficiaryInfo =  [];
    return async(dispatch)=>{
        dispatch({
            type:UPDATE_LIFEINSURANCE_INFO,
            payload: {prop:"lifeBeneficiaryInfo", value: beneficiaryInfo}
        });
    }
}

export const FinalSaveLifeBeneficiaryData = (data,index) => {
    let beneficiaryInfo = store.getState().lifeInsuranceReducer.lifeBeneficiaryInfo ? store.getState().lifeInsuranceReducer.lifeBeneficiaryInfo : [];
    const beneficiaryInfoArray = _.values(beneficiaryInfo);
    const submitData = {
        "profileId" : data.profileId,
        "beneficiaries" : beneficiaryInfoArray
    }
    
    //console.log("FinalSaveLifeBeneficiaryData submitData --->", submitData);
    return async (dispatch)=>{
        //Object.assign({},beneficiaryInfo) 
        let errorFlag = false;
        axios.post('/lifeinsurance/updatebeneficiary', submitData)
        .then(function (response) {
            //LogEvent("User Life Beneficary Save");
            errorFlag = ( response.data && response.data.error &&  Object.keys(response.data.error).length > 0 ) ? true : false;
            console.log("FinalSaveLifeBeneficiaryData response data --->", response.data, " ----> error flag" ,errorFlag)
            dispatch(EmptyMakeLifeDecisionData("lifeBot"));
            dispatch({
                type:UPDATE_LIFEINSURANCE_INFO,
                payload: {prop:"makeLifeDecisionDataError", value: errorFlag}
            });
            dispatch({
                type:UPDATE_LIFEINSURANCE_INFO,
                payload: {prop:"beneficairyFinalSaveDone", value: true}
            });
        })
        .catch(function (err) {
            //Actions.error();
            //CustomException(err);
            console.log('Error ---> FinalSaveLifeBeneficiaryData api', err);
            dispatch({ type: HIDE_LIFE_WAIT_LOADER });
        });
    }
}

export const BackLifeInsuranceError = () => {
    return async(dispatch)=>{
        dispatch({
            type:UPDATE_LIFEINSURANCE_INFO,
            payload: {prop:"makeLifeDecisionDataError", value: false}
        });
        dispatch({
            type:UPDATE_LIFEINSURANCE_INFO,
            payload: {prop:"beneficairyFinalSaveDone", value: false}
        });
        dispatch({
            type:UPDATE_LIFEINSURANCE_INFO,
            payload: {prop:"lifeWaitLoader", value: false}
        });
        dispatch({
            type:UPDATE_LIFEINSURANCE_INFO,
            payload: {prop:"bothBotSubmited", value: false}
        });
        dispatch({
            type:UPDATE_LIFEINSURANCE_INFO,
            payload: {prop:"lifeErrorData", value: {}}
        });
        dispatch({
            type:UPDATE_LIFEINSURANCE_INFO,
            payload: {prop:"makeLifeDecisionDataAction", value: ''}
        });
    }
}

export const ResetLifeInsuranceState = (data)=>{
    console.log('ResetLifeInsuranceState ===>',data);
    return (dispatch)=>{
        dispatch({ type: SHOW_LIFE_WAIT_LOADER });
        postApiCall(`lifeinsurance/state/${data.profileId}`, JSON.stringify("")).then(res => {
            setTimeout(() => {
            console.log("/lifeinsurance/state/"+data.profileId)
            console.log('ResetLifeInsuranceState response ===>', res );
            dispatch({ type: HIDE_LIFE_WAIT_LOADER });
            dispatch(BuyLifeQuoteDetails(data))
            },500);
        })
        .catch(err => {
            console.log('ResetLifeInsuranceState error ===>',error);
            dispatch({ type: HIDE_LIFE_WAIT_LOADER });
        });
    }

}

export const SetLifeInsuranceError = () => {
    return async(dispatch)=>{
        dispatch({
            type:UPDATE_LIFEINSURANCE_INFO,
            payload: {prop:"makeLifeDecisionDataError", value: true}
        });
    }
}
export const EmptyMakeLifeDecisionData = (fromCall='no') => {
    return async(dispatch)=>{
        dispatch({
            type: UPDATE_LIFEINSURANCE_INFO,
            payload: { prop: 'makeLifeDecisionData', value: {} }
        });
        dispatch({
            type:UPDATE_LIFEINSURANCE_INFO,
            payload: {prop:"makeLifeDecisionDataError", value: false}
        });
        if(fromCall == "lifeBot"){
            dispatch({
                type:UPDATE_LIFEINSURANCE_INFO,
                payload: {prop:"backFromLifeBot", value: true}
            });
        }
    }
}

export const GetProfile = (profileid) =>{
    console.log("GetProfile profileid ===>",profileid)
    return async (dispatch)=>{
        axios.get(`profiles/${profileid}`).then(response =>{
            console.log("GetProfile response data", response.data);
            dispatch({
                type: UPDATE_LIFEINSURANCE_INFO,
                payload: { prop: 'userInfo', value: response.data }
            });
        })
        .catch(error =>{
            console.log('GetProfile error ===>',error);
            dispatch({ type: HIDE_LIFE_WAIT_LOADER });
        })
    }
}


export const apiGetPartnerWithCampaignName = (apiPayload,callBack=()=>{}) => { 
   
    let {campaignName='',partnerKey=''}=apiPayload
    let api = ''  

    if(campaignName)
        api=(`lite/${partnerKey}/${campaignName}`)
    else   
       api=(`lite/${partnerKey}`)

        return async(dispatch)=>{
            axios.get(`/${api}`).then(res =>{
                console.log("apiGetPartnerWithCampaignName Res ===>",res)
                callBack(res.data[0] || {})
            }).catch(err=>{
                callBack(err)
                console.log("apiGetPartnerWithCampaignName Error ===>",err)
            })
        }
}

export const getLinkTokenForUser = (data, partnerkey,callBack=()=>{}) => {
      
    return(dispatch)=>{
        
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${btoa(`pp:${partnerkey}:${data.partnerprofileid}`)}`       
        }
        //console.log('getLinkTokenForUser',partnerkey,data,headers )
        axios.post(`/partner/user/linktoken`, data,{headers:headers}).then(response=>{
            if(response.data){
                callBack(response.data)
            }
            console.log("getLinkTokenForUser Res ===>",response)
        }).catch((err)=>{
            callBack()
            console.log('getLinkTokenForUser err', err);
        })
    }
  }