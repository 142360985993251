import React, { useRef, useEffect,useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { setLogEvent } from '../../../utils/helpers';
import StarRating from '../Common/StarRating/StarRating';
import GetStartedButton from "../Common/GetStartedButton/GetStartedButton";
import "./PageHeaderSectionV3.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import {Animated} from "react-animated-css";
import HeaderRightImgMobile from "../../../../images/newDesign/home-mobile.png"
import awardsIcon from "../../../../images/newDesign/awards_icon.png"


import _ from 'lodash';
const PageHeaderSectionV3 = ({ content = {}, appLinkUrl = "/", target = "_blank",extraClassName2='' }) => {

    const graph = useRef(null);
    const [headingText, setHeadingText] = useState('');
    //const [count, setCount] = useState(0);
    const [isVisible, setIsVisible] = useState(true);
    const [emailId, setEmailId] = useState('');
    const [offsetY, setOffsetY] = useState(0);
    const handleScroll = () => {
        setOffsetY(window.scrollY);
    };
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const { tagLine = {}, avibraRating = {},awardMessage='',applyStyle, extraClassName='',showGridStyle=false, gridItemsData=[],bottomRightVectorImg='',bottomLeftVectorImg='',topRightVectorImg='' } = content;
    useEffect(() => {
        rotateSliderText()
    }, []);
    const onDownloadClick = () => {
        setLogEvent("User Clicked On Get Started Button");
    }
    const submitEmailForm = () => {
        setLogEvent("User Clicked On Email Form Learn More Button",{"email":emailId});
        if(emailId)
        window.location = `/book-a-demo?email=${emailId}`;
        else
        window.location = `/book-a-demo`
    }
    const rotateSliderText = (count=0) => {
        if(content?.slidingTitle){
            const terms = content.slidingTitle;
            count = (count >= terms.length) ? 0 : count;
            setHeadingText(terms[count]?terms[count]:terms[0])
            setIsVisible(true)
            //console.log("rotateSliderText ==>", count,"<=>",count, terms[count] , terms.length);
            setTimeout(()=>{
                setIsVisible(false)
            }, 2850);
            setTimeout(()=>{
                rotateSliderText(count+1)
            }, 3000);
        }
    }

    let renderedHeading = ""
    //console.log('content===>', content)
    if (content.title instanceof Array) {
        renderedHeading = content.title.map((heading, index) =>
            <div dangerouslySetInnerHTML={{ __html: (heading) }}></div>
        )
        //console.log('content===>if', content)
    } else {
        renderedHeading = <div dangerouslySetInnerHTML={{ __html: (content.title) }}></div>
        //console.log('content===>else', content)
    }
    let description = content.subTitle;
    let descriptionPart1 = '';
    let descriptionPart2 = '';
    const descriptionArray = description.split("#@#");
    if (description.includes("#@#")) {
        descriptionPart1 = descriptionArray[0];
        descriptionPart2 = descriptionArray[1];
    } else {
        descriptionPart1 = description;
    }
    const headerImgDesktop = content.backgroundImg.desktop;
    const headerImgTablet = content.backgroundImg.tablet;
    const headerImgMobile = content.backgroundImg.mobile;
    // const headerRightImgMobile = content.backgroundImg.mobile;
    const headerDesktopLeftBg = '';//content.backgroundImg.desktopLeftBg;
    return (
        <div className={`main-header-v3-section ${applyStyle?'new-header-style':''} ${extraClassName} ${extraClassName2}`}>
            <div className={`full-width-banner-header-img-area-v2 container ${target}target-old`}>
                {topRightVectorImg && <div className="top-right-vector-img" style={{backgroundImage:`url(${topRightVectorImg})`}}></div>}
                <div className="main-header-desc">
                    <div class="landing-container">
                        <div className={`header_area_old header_area_new header-section container`}>     {/* container-fluid` style={{ backgroundImage: `url(${headerRightImgDesktop})` }}*/}
                            <Row className={'header-row'}>   {/*style={{ backgroundImage: `url(${headerDesktopLeftBg})` }}*/}
                                <Col className="image-lower1 header-v3-left-text-area" xs="12" sm="12" md="12" lg="6" xl="6">
                                    <div className="left" style={{ paddingLeft: '0px' }}>
                                        <div className="main-header-text-align-center">
                                            {awardMessage ? <div className='award-message-area'><img src={awardsIcon} className='award-icon'/> {awardMessage}</div> : ''}
                                            <div style={{clear:'both'}}></div>
                                            {tagLine ? <div className='main-header-tag-line'>{tagLine}</div> : null}
                                            <div className="heading-v2">
                                                {renderedHeading}
                                            <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={isVisible}>
                                                <div dangerouslySetInnerHTML={{ __html: (headingText) }}></div>
                                            </Animated>
                                            </div>
                                            <div className="sub-title-v2">
                                                <div dangerouslySetInnerHTML={{ __html: (descriptionPart1) }}></div>
                                            </div>
                                            <div className={`main__header__inner ${content?.addClass}`}>
                                            <div className="header-get-button-and-rating">
                                                {content.inputType ?<div className="header-claim-btn-cont">
                                                    <input type="text" placeholder='email' value={emailId} onChange={e => setEmailId(e.target.value)}/>
                                                    <button className='header-claim-btn-cont' onClick={()=>{submitEmailForm()}}>{content.buttonText}</button>
                                                </div>:
                                                (content.buttonText) ? <div className="header-claim-btn-cont">
                                               
                                                    <GetStartedButton
                                                        bgColor={'#774AD9'}
                                                        onClick={() => onDownloadClick()}
                                                        text={content.buttonText}
                                                        link={appLinkUrl}
                                                        target={target}
                                                        padding={'15px 45px'}
                                                    />
                                                </div> : null}
                                                {content?.buttonRightInfoText && <div className="header-claim-btn-cont-right-text">
                                                    {content.buttonRightInfoText}
                                                </div>}
                                            </div>
                                            </div>
                                            <div style={{ clear: 'both' }}></div>
                                            {descriptionPart2 && <div className="main_second_header_description" dangerouslySetInnerHTML={{ __html: (descriptionPart2) }}></div>}
                                        </div>
                                    </div>
                                </Col>
                                <Col className="landing-habbits1 page-header-section-img-col" xs="12" sm="12" md="12" lg="6" xl="6">
                                {showGridStyle==true && gridItemsData.length ?
                                    <div className="main-header-new-grid-area">
                                        {gridItemsData && gridItemsData.length > 0 && gridItemsData.map((items, index) => {
                                        return <div className={`main-header-item-grids items-grid-${index}`}>
                                            {items.length && items.map((item,ind)=>{
                                                return <div className={`main-header-item-grid item-grid-${ind} ${(items.length == (ind+1)) ? 'last' : ''}`}>
                                                {item?.icon ? <div className='main-header-item-grid-img-wrp'>
                                                    <img src={item.icon} className='img-fluid'/>
                                                </div> : null}
                                                <div className='main-header-item-grid-data'>
                                                    <div className='main-header-item-grid-title'>{item.title}</div>
                                                    <div className='main-header-item-grid-description'>{item.description}</div>
                                                </div>
                                            </div>
                                            })}
                                        </div>
                                        })}
                                    </div>
                                    :
                                    <div className="main-header-new-center-img" style={{ position: 'relative' }}>
                                       <div className='img-desktop-v2'>
                                            <LazyLoadImage src={headerImgDesktop} alt={''} className="img-fluid home-header-hero-image" style={{ transform: `translateY(${offsetY * -0.3}px)` }}/>                               
                                        </div>
                                        <div className='img-tablet-v2'>
                                            <LazyLoadImage src={headerImgTablet ? headerImgTablet : headerImgDesktop} alt={''} className="img-fluid home-header-hero-image" style={{ transform: `translateY(${offsetY * -0.3}px)` }} />
                                        </div>
                                        <div className='img-mobile-v2'>
                                            <img src={headerImgMobile ? headerImgMobile : headerImgDesktop} alt={''} className="img-fluid home-header-hero-image" style={{ transform: `translateY(${offsetY * -0.3}px)` }} />
                                        </div>
                                    </div>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                {bottomLeftVectorImg && <div className="bottom-left-vector-img" style={{backgroundImage:`url(${bottomLeftVectorImg})`}}></div> }
                {bottomRightVectorImg && <div className="bottom-right-vector-img" style={{backgroundImage:`url(${bottomRightVectorImg})`}}></div>}
            </div>
        </div>
    )
}

export default PageHeaderSectionV3