import React, { useState, useEffect, useCallback } from 'react'
import {
	Container,
	Row,
	Col,Form, FormGroup, Label, Input,Button
} from 'reactstrap';
import SeoMetaTags from '../../../components/NewDesign/Common/SeoMetaTags/SeoMetaTags';
import './../../../../styles/NewDesignV2/main-page.css'
import './../../../../styles/NewDesignV2/main-page-responsive.css';
import NewDesignLandingPageData from "../../../../constant/NewDesignLandingPageData.json";
import NewDesignLandingPageDataV2 from "../../../../constant/NewDesignV2LandingPageData.json";
import { connect } from 'react-redux';
import { getFeparamsFilteredApi } from '../../../api/policyDocumentApi';
import { feparamsAction } from '../../../actions/feparamsAction';
import { setLogEvent,setAmplitudeInit } from '../../../utils/helpers';
import LearningMoreForm from '../../../components/NewDesignV2/Common/LearningMoreForm/LearningMoreForm.js';
import './BookADemo.css';
import LovedBy from '../../../components/NewDesignV2/Common/LovedBy/LovedBy.js';
import queryString from 'query-string';
import avibraLogo from '../../../../images/avibra-logo.svg';

let qsparam = queryString.parse(window.location.search.toLowerCase());

const BookADemo = (props) => {
	const [isOpen, setIsOpen] = useState(false); 
	//const [selectedMyHabitsIndex, setSelectedMyHabitsIndex] = useState(0)
	const [emailId, setEmailId] = useState((qsparam && qsparam.email)?qsparam.email:'')
	const [stickyHeaderClass, setStickyHeaderClass] = useState('row topbar');
    const toggleSticky = useCallback(
        () => {

            const scrollY = window.scrollY ? window.scrollY : document.body.scrollTop
			const scrollHeight = (window.innerWidth <= 768) ? 10 : 100
			scrollY >= scrollHeight ? 
				setStickyHeaderClass(`row topbar fixed-header ${(window.innerWidth <= 768) ? '' : 'animated fadeInDown'}`)
                :
                setStickyHeaderClass("row topbar")
        },
        []
    );
    useEffect(() => {
        document.getElementById("main-wrapper").focus();
		getFeparamsFilteredApi().then(res => {
            const { data = {} } = res;
            props.feparamsAction(data);
            let { amplitudeKey = "" } = data;
            setAmplitudeInit(amplitudeKey);
            setLogEvent("ContactUs Page loaded", {
                currentUrl: window.location.href,
            })
        }).catch(err => {
            console.log(err);
        });

		const handleScroll = () => {
            toggleSticky();
        };

        if (window.addEventListener) {
            window.addEventListener("scroll", handleScroll, { capture: true });
        } else {
            window.attachEvent("onscroll", handleScroll);
        }

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
	}, [])

	const toggle = () => setIsOpen(!isOpen);
	

    const { mainSection={},bookADemoSection={}, bookADemoFinancialSection={}, footerSection={}, seoMetaData={} } = NewDesignLandingPageData
    const {lovedByForBookADemo={}}=NewDesignLandingPageDataV2
    const { download_href } = mainSection.data.headerSection;
	const { mainPageAppDownLoadLink = download_href } = props.appConfig;
	const learningMoreData = {heading:"Book a Demo",btnCta:"Book"};
	// const bookADemoSectionData = (qsparam && qsparam.from=='financial') ? bookADemoFinancialSection : bookADemoSection;
	const bookADemoSectionData = bookADemoSection;

	return (
		<Container fluid className="book-a-demo" id="main-wrapper">
			<SeoMetaTags seoData={seoMetaData.bookADemoPage} />
            
					<div className="inner">
						<div className="panel panel-left">
							<div className='avibra-logo'>
								<a href="/" alt="Avibra Logo"><img src={avibraLogo} alt='Avibra Logo' /></a>
							</div>
							<div className='book-demo-text-container'>
							<div className='book-demo-heading' dangerouslySetInnerHTML={{ __html: (bookADemoSectionData.title) }} />
							<div className='book-demo-content'>
								{bookADemoSectionData.description}
							</div>
							{/* {bookADemoSectionData.descriptionPoints.length ?
							<div className='book-demo-content-points'>
								<ul>
								{bookADemoSectionData.descriptionPoints.map((data, index) => {
									return <li key={'item-'+index}>{data}</li>
								})}
								</ul>
							</div>
							: null} */}
							</div>
							{bookADemoSectionData.asSeenIn ?
							<div className='as-seen-in-logos-area'>
								<div className='as-seen-in-heading'>{bookADemoSectionData.asSeenIn.heading}</div>
								{/* <div>
									<img src={bookADemoSectionData.asSeenIn.logoImage} alt="logos" />
								</div> */}
								<LovedBy lovedByData={lovedByForBookADemo} />
							</div>
							:null}
						</div>
						<div className="panel panel-right" style={{minHeight:window.innerHeight<=800?800:window.innerHeight}}>
							<div className="panel-content">
							<div class="form">
								<div className='book-a-demo-form' >
									<LearningMoreForm LearningMoreData={learningMoreData} fromBookADemo={true} appConfig={props.appConfig}/>
								</div>
							</div>
							{/* <div class="form">
								<h1 className='form-heading'>Schedule a live demo</h1>
								
								<div className='book-a-demo-form'>
									<div className='row'>
										<div className='col-lg-12'>
											<div className='book-a-demo-input'>
												<input type="text" placeholder="First Name" />
											</div>
										</div>
										<div className='col-lg-12'>
											<div className='book-a-demo-input'>
												<input type="text" placeholder="Last Name" />
											</div>
										</div>
										<div className='col-lg-12'>
											<div className='book-a-demo-input'>
												<input type="email" placeholder="Email" value={emailId} />
											</div>
										</div>
										<div className='col-lg-12'>
											<div className='book-a-demo-input'>
												<input type="number" placeholder="Phone" />
											</div>
										</div>
										<div className='col-lg-12'>
											<div className='book-a-demo-input'>
												<input type="text" placeholder="Company" />
											</div>
										</div>
										<div className='col-lg-12'>
											<div className='submit_button'>
												<button>Contact Me</button>
											</div>
										</div>
									</div>
									</div>
							</div> */}
							</div>
						</div>
					</div> 
        </Container>

	);
}

const mapStateToProps = state => {
    const { appConfig } = state.feparamsReducer;
    return { appConfig };
}
export default connect(mapStateToProps, { feparamsAction })(BookADemo);