import React from 'react'
import { Container } from 'reactstrap'
import "./StorePackages.css"
import GridCardView from './GridCardView'

const StorePackages = ({storePackagesData}) => {
  const {topHeadingTag='',heading='',subHeading='',storePackages={} } = storePackagesData
  return (
    <section className='store-packages-section'>
        <Container>
            {/* <div className='heading-area-v2 center'>
                <div className='top-small-heading-v2 center'>{topHeadingTag}</div>
                <div className={'heading-v2'} dangerouslySetInnerHTML={{ __html: (heading) }}></div>
                {subHeading && <p dangerouslySetInnerHTML={{ __html: (subHeading) }}></p>}
            </div> */}
            <GridCardView storePackages={storePackages}/>
         </Container>
    </section>
  )
}

export default StorePackages