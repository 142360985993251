import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import "./InTheNews.css"; 

const InTheNews = ({
    inTheNewsData = {}
}) => {
    const { newsData=[] } = inTheNewsData
    console.log("InTheNews ===>", inTheNewsData)
    return (
        <Container className="press_new_area">
            <div class="title press-title">
                <span class="circle-parent">
                    <span class="text">{inTheNewsData.title}</span>
                </span>
            </div>
            <Row className="press_container">
                <Col sm="12" md="12" lg="6" xl="6" className="press_new_left">
                    <div className='big_press_news_list'>
                        {
                            (newsData && newsData.length) ?
                                    <div className={`big_news_item big-news-0`} >
                                        <Row style={{margin:0}}>
                                            <Col sm="12" md="6" lg="12" xl="12" className="p-0">
                                            <div className='news_item_img'>
                                                {newsData[0].image ? <a href={newsData[0].link} target="_blank"><img src={newsData[0].image} /></a> : null}
                                            </div>
                                            </Col>
                                            <Col sm="12" md="6" lg="12" xl="12" className='big-news_item-text p-0'>
                                            <div className='news_item_logo'>
                                            {newsData[0].logoImg ? <img src={newsData[0].logoImg} alt={newsData[0].logo} style={{maxWidth:'160px',maxHeight:'40px'}} /> : newsData[0].logo ? newsData[0].logo : null }
                                            </div>
                                            <div className='news_item_title'>
                                                <a href={newsData[0].link} target="_blank">{newsData[0].title}</a>
                                            </div>
                                            </Col>
                                        </Row>
                                    </div>
                            : null
                        }
                    </div>
                </Col>
                <Col sm="12" md="12" lg="6" xl="6" className="press_new_right">
                    <div className="press_news_list">
                        {
                            newsData && newsData.map((news, index) => {
                                if(index==0){ return null; }
                                return <><div key={index} className={`news_item big-news-${(index)} `}>
                                    <Row style={{margin:0}}>
                                        <Col sm="12" md="12" lg="3" xl="3" className="p-0">
                                        <div className='news_item_img' style={{backgroundImage:`url(${news.image})`}}>
                                            {news.image ? <a href={news.link} target="_blank">
                                                {/* <img src={news.image} /> */}
                                                </a> : null}
                                        </div>
                                        </Col>
                                        <Col sm="12" md="12" lg="9" xl="9" className='news_item_right_data p-0'>
                                            <div className='news_item_logo'>
                                                {news.logoImg ? <img src={news.logoImg} alt={news.logo} style={{maxWidth:'125px',maxHeight:'25px'}} /> : news.logo ? news.logo : null }
                                            </div>
                                            <div className='news_item_title'>
                                                <a href={news.link} target="_blank">{news.title}</a>
                                            </div>
                                        </Col>
                                    </Row>
                                    </div>
                                    {(index != 0) && ((index)%2 == 0) ? <div className='mobile-clear'></div> : null}
                                    {(index != 0) && ((index)%4 == 0) ? <div className='ipad-clear'></div> : null}
                                    </>
                            })
                        }
                    </div>
                </Col>
            </Row>
            
        </Container>
    )

};

export default InTheNews;
