import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import "./PartnerVoices.css"
import leftArrow from "./../../../../../images/new-left-arrow.png"

const CustomPrevArrow = ({ className, style, onClick }) => {
    return (
        <div
            className={`${className} partner-voices-arrow prev-arrow`}
            style={{ ...style }}
            onClick={onClick}
        >
            <img src={leftArrow}/>
            {/* <i className="fas fa-arrow-left"></i> */}
        </div>
    );
};
const CustomNextArrow = ({ className, style, onClick }) => {
    return (
        <div
            className={`${className} partner-voices-arrow next-arrow`}
            style={{ ...style }}
            onClick={onClick}
        >
            <img src={leftArrow}/>
            {/* <i className="fas fa-arrow-right"></i> */}
        </div>
    );
};

const PartnerVoices = (props) => {
    const { content = {} } = props
    const { heading = '', desc = '', imageWeb = '', imageTablet = '', imageMobile = '', data = [] } = content
    const settings = {
        dots: false,
        arrows: true,
        accessibility: true,
        infinite: true,
        centerMode: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                },
            },
            {
                // Tablet breakpoint (768px to 1024px)
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    centerMode: false,
                }
            }

        ]
    };


    return (

         content?.data.length>0 ?<div className='container'>
            <div className='partner-voices-container'>
                <div className='partner-voices-header-wrp'>
                    <div className='partner-voices-header'>
                        <div className='partner-voices-heading'>{heading}</div>
                        <div className='partner-voices-desc'>{desc}</div>
                    </div>
                </div>
                <div className='partner-voices-slider-container'>
                    <div className='partner-voices-cards'>
                        <div className='partner-slider-outer'>
                            <Slider {...settings}>
                                {data && data.length > 0 && data.map((item, index) => {
                                    return <div>
                                        <div className='partner-voices-card-outer'>
                                        <div className='partner-voices-card' key={index}>
                                            <div className='partner-voices-card-inner'>
                                                <div className='partner-voices-number'>
                                                  <img src={item.partnerLogo} className="img-fluid" />
                                                </div>
                                                <div className='partner-voices-card-content'>
                                                    <div className='partner-voices-card-desc'>{item.testimonial}</div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='partner-voices-img-wrp partner-voices-img-wrp-desktop'>
                                            <img src={item.rightImage} className="img-fluid" />
                                        </div>
                                        <div className='partner-voices-img-wrp partner-voices-img-wrp-mobile'>
                                            <img src={item.rightImageMobile} className="img-fluid" />
                                        </div>
                                        </div>
                                        
                                    </div>

                                })}

                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>:null


    )

};

export default PartnerVoices;
