import React from "react";
import "./InternalHowItWorksCard.css"

const InternalHowItWorksCard = ({
    data: {
        content,
        title,
        isAboutPage
    },
}) => {
    return (
        <>
           
            <div className={`${isAboutPage ? "avibra_card__area__hows" : "card__area__hows"}`}>
           
                <div className={`${isAboutPage ? "avibra_section__card__title" : "section__card__title"}`}>
                    <h5>{title}</h5>

                </div>
           
                <div className={`${isAboutPage ? "avibra_section__card__content" : "section__card__content"}`}>
                    <div dangerouslySetInnerHTML={ { __html: (content) } }></div>
                </div>

            </div>

        </>
    );
};

export default InternalHowItWorksCard;
