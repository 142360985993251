import queryString from "query-string"
import { REG_URL, isAvibraDomain } from "../../constant/constant"
import { apiGetPartnerWithCampaignName , apiGetPartnerExternalExists  } from '../api/partnerApi'
//import  { Redirect, Link } from 'react-router'
import { getPartnerCustomerID,initBranchSdk } from "./helpers"
let partnerData = { exists: false }

const createdeeplink = ({ partnername, partnerid, externalid, subtextonhomescreen, logourl, qsparam, downloadurl='' }, linkcallback) => {
    var linkData = {
        channel: qsparam && qsparam["utm_medium"] ? qsparam["utm_medium"] : 'cobrand_site',
        stage: qsparam && qsparam["utm_content"] ? qsparam["utm_content"] : 'new_user',
        data: {}
    }

    if (partnername) {
        linkData.source = qsparam && qsparam["utm_source"] ? qsparam["utm_source"] : partnername
    }
    else if (qsparam && qsparam["utm_source"]) {
        linkData.source = qsparam["utm_source"]
    }

    if (partnername) {
        linkData.campaign = qsparam && qsparam["utm_campaign"] ? qsparam["utm_campaign"] : partnername
    }
    else if (qsparam && qsparam["utm_campaign"]) {
        linkData.campaign = qsparam["utm_campaign"]
    }


    if (externalid) {
        if (partnerid)
            linkData["data"]["refeerr"] = partnerid + ":" + externalid
    } else {
        if (partnerid)
            linkData["data"]["refeerr"] = partnerid
    }

    if (subtextonhomescreen) {
        linkData["data"]["subtext_on_homescreen"] = subtextonhomescreen
    }

    if (logourl)
        linkData["data"]["partner_logo_url"] = logourl

    if(!downloadurl){
        branch.data(function(err, data) {
            //console.log("window.branch.data ==>",err, err?.message, data);
            if(err){
                initBranchSdk();
            }
        });
        let deepViewData = {
            'refeerr': linkData.data.refeerr,
            'subtext_on_homescreen': linkData.data.subtext_on_homescreen,
            'partner_logo_url':linkData.data.partner_logo_url,
            'from':linkData.data.from,
            'partnerkey':partnername
          }
        branch.deepview(
            {
              'channel': linkData.channel,
              'campaign' : linkData.campaign,
              'data': deepViewData
            },
            {
              'make_new_link': true,  
              'open_app': true
            }
        );

        window.branch.link(linkData, function (err, link) {
            linkcallback(link)
        });
    }
    else{
        let qsLinkData = {
            'channel': linkData.channel,
            'campaign' : linkData.campaign,
            'refeerr': linkData.data.refeerr,
            'subtext_on_homescreen': linkData.data.subtext_on_homescreen,
            'partner_logo_url':linkData.data.partner_logo_url,
            'from':linkData.data.from,
            'partnerkey':partnername
        }
        linkcallback(`${downloadurl}?${queryString.stringify(qsLinkData)}`)
    }
}

const linkcallback = ({ logourl = "", qsparam = "" }, resolve) => {
    return (link) => {
        let { partnerName = "" } = partnerData
        if (logourl)
        partnerData = {...partnerData, imageUrl: logourl}
            // setImageUrl(logourl)

        let linkUpdated = queryString.stringify(qsparam) !== "" ? link + "?" + queryString.stringify(qsparam) : link
        let downloadLink = linkUpdated
        let regUrl = !qsparam["_partnerkey_"]
                 ? REG_URL+ "?_partnerkey_="+ partnerName 
                 : REG_URL
        if (REG_URL && partnerName !== 'www' && partnerName !== 'avibra.com')
            linkUpdated = queryString.stringify(qsparam) !== "" ? regUrl + (regUrl.includes("?") ? "&" : "?") + queryString.stringify(qsparam) : regUrl
            partnerData = {...partnerData, appLinkUrl: linkUpdated, appDownloadLink: downloadLink}
            partnerData = {...partnerData, pageLoad: false}
        // setAppLinkUrl(linkUpdated)
        // setPageLoad(false)
        resolve((partnerData))
    }
}
function checkAuthenticationData(qsparam) {
    if( qsparam._partnerkey_  &&  qsparam._clientid_ ){ 
        if( (qsparam['_partnerkey_'] == '') && (qsparam['externalid'] == '') )
            return false;
    } else { 
        return false
    }
    return true;
}
export const getPartnerData = (partnername) => {
    return new Promise(
        (resolve, reject) => {
            let qsparam = queryString.parse(window.location.search.toLowerCase())
            let checkPartnerAuth = checkAuthenticationData(qsparam);
            partnerData = {...partnerData, checkPartnerAuth: checkPartnerAuth}
            let clientid = '';
            let externalid ='';

            if (isAvibraDomain(partnername)) {
                //setPageLoad(false)
                partnerData = {...partnerData, pageLoad: false}
                createdeeplink({ partnername: "www",qsparam }, linkcallback({}, resolve))
                return
            }
            
            if (qsparam["_partnerkey_"])
                partnername = qsparam["_partnerkey_"]
                partnerData = {...partnerData, partnerName: partnername}
            if (qsparam["clientid"])
                clientid = qsparam["clientid"]
                partnerData = {...partnerData, clientId: clientid}
            if (qsparam["externalid"])
                externalid = qsparam["externalid"]
                partnerData = {...partnerData, externalId: externalid}
            
            let campaignName = qsparam && qsparam["utm_campaign"] ? qsparam["utm_campaign"] :  undefined
            //setPartnerName(partnername)
            
            apiGetPartnerWithCampaignName(partnername,campaignName).then(response => {
                let { data = [] } = response 
                let { logourl = "", externalidkey, allowmultipleusers = false, partnerid, subtextonhomescreen, partnerTitle, partnerSubText, partnerDuplicateSubText, name, partnerProfileID } = data[0] || {}
                partnerData = {...partnerData, name, partnerResponse: data[0], downloadurl: data[0]?.downloadurl}
                //setPartnerResponse(response[0])

                // if(partnername === "stash" || partnername === "stashtest")
                //   partnerDuplicateSubText= "Please contact <a target='_blank' href='mailto:support@stashinvest.com'>support@stashinvest.com</a> to sign up for Stash."
                if(partnerTitle) {
                    partnerData = {...partnerData, partnerTitle}
                }

                if(partnerSubText) {
                    partnerData = {...partnerData, partnerSubText}
                }

                if (externalidkey && qsparam[externalidkey]) {
                    partnerData = { ...partnerData, referralid : getPartnerCustomerID(partnerid, qsparam[externalidkey])}
                } else {
                    partnerData = { ...partnerData, referralid :  getPartnerCustomerID(partnerid)}
                }

                // partnerTitle && setPartnerTitle(partnerTitle)
                // partnerSubText && setPartnerSubText(partnerSubText)

                if (window.document && logourl !== "")
                    window.document.title = ("Avibra + " + partnername).toUpperCase()

                if (externalidkey && qsparam[externalidkey]) {

                    apiGetPartnerExternalExists(qsparam[externalidkey],  data[0].partnerProfileID).then(response => {
                        let exists  = response.data;
                        if (!exists || allowmultipleusers) {
                            createdeeplink({ partnername, partnerid, externalid: qsparam[externalidkey], subtextonhomescreen, logourl, qsparam, downloadurl: partnerData?.downloadurl }, linkcallback({ logourl, qsparam }, resolve))
                        } else {
                            partnerData = {...partnerData, exists: true}
                            if (partnerDuplicateSubText) {
                                if(partnerSubText) {
                                    partnerData = {...partnerData, partnerSubText:null}
                                }
                                partnerData = {...partnerData, partnerSubText:partnerDuplicateSubText}
                                partnerData = {...partnerData, hideSmsSection:true}
                                // partnerSubText && setPartnerSubText(null)
                                // setPartnerSubText(partnerDuplicateSubText)
                                // setHideSmsSection(true)
                            }
                            else {
                                if(partnerSubText) {
                                    partnerData = {...partnerData, partnerSubText:null}
                                }
                                partnerData = {...partnerData, logourl:""}
                                partnerData = {...partnerData, hideSmsSection:false}
                                // partnerSubText && setPartnerSubText(null)
                                // logourl = ""
                                // setHideSmsSection(false)
                            }
                            createdeeplink({ partnername, partnerid, externalid: qsparam[externalidkey], subtextonhomescreen, logourl, qsparam, downloadurl: partnerData?.downloadurl }, linkcallback({ logourl }, resolve))
                        }
                    }).catch((error) => {
                        createdeeplink({ partnername, partnerid, subtextonhomescreen, logourl, qsparam, downloadurl: partnerData?.downloadurl }, linkcallback({ logourl, qsparam },resolve))
                    })
                } else {
                    createdeeplink({ partnername, partnerid, subtextonhomescreen, logourl, qsparam, downloadurl: partnerData?.downloadurl }, linkcallback({ logourl, qsparam }, resolve))
                }
            })
            .catch((error) => {
                createdeeplink({}, linkcallback({},resolve))
            })
        }
    )
}