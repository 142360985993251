import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import "./GetStartedButton.css";

const GetStartedButton = (props) => { 
  let { 
    text='GET STARTED',
    link='',
    onClick,
    bgColor='#4E887A',
    textColor='#ffffff',
    fontSize='14px',
    fontWeight="normal",
    radius='64px',
    width='auto',
    padding='14px 34px',
    textTransform='uppercase',
    className="",
    target="_blank"
  } = props;
  let { mainPageAppDownLoadLink = link } = props.appConfig;
  mainPageAppDownLoadLink = mainPageAppDownLoadLink.replace(/['"]+/g, '');
  link = link.replace(/['"]+/g, '');
  //console.log("GetStartedButton ===>", mainPageAppDownLoadLink);
  return (
    <a 
      href={ link || mainPageAppDownLoadLink}
      className={`get-started-button ${className}`}
      onClick={onClick}
      target={window.innerWidth >= 769 ?target:''}
    >
      <div 
        style={{
          backgroundColor: bgColor,
          color: textColor,
          borderRadius: radius,
          width: width,
          fontSize:fontSize,
          fontWeight:fontWeight,
          padding:padding,
          textTransform:textTransform
        }}
        >
          {text}
        </div>
    </a>
  );
}
const mapStateToProps = state => {
  const { appConfig } = state.feparamsReducer;
  return { appConfig };
}
export default connect(mapStateToProps, { })(GetStartedButton);
