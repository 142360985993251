import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import "./MemberBenefits.css";
import FooterSection from "./FooterSection";
import { getImageUrl } from '../../utils/helpers';
import cardBgImg from "../../../images/card.png"


const MemberBenefits = (props) => {
    const { pageData={}, benefitsList=[],slideNo=''} = props;
    const [cardArrayList , setCardArrayList] = useState([])
    //console.log("MemberBenefits pageData ===>", benefitsList)

    useEffect(() => {
        let mainCardArrayListTemp = cardArrayList;
        mainCardArrayListTemp[props.mainCardArrayList.productname] = props.mainCardArrayList
        setCardArrayList(mainCardArrayListTemp)
    },[props.mainCardArrayList])
    //console.log("setCardArrayList cardArrayList   ====>", cardArrayList)

    return (
      <>
            <div className="card-guide">
                <div className="container h-100 p-0">
                    <div className="main__color Member-Benefits">
                        <div className="Member__Benefits__title">
                            <h1 dangerouslySetInnerHTML = {{__html:(pageData.title)}}></h1>
                            <p>{pageData.description}</p>
                        </div>
                        <div className="benefits__card">
                            {benefitsList?.length ? 
                            <div className="row">
                            {
                                benefitsList?.map((item , index)=>{
                                    let getCurrentItem = cardArrayList[item.productname];
                                    let itemProperty = '';
                                    if(getCurrentItem){
                                        const { propertyData } = getCurrentItem;
                                        itemProperty = propertyData[propertyData.dataType]
                                        //console.log("MemberBenefits productname ==>", getCurrentItem , itemProperty )
                                    }
                                    return(
                                        <>
                                            <div className="col-md-4 mb-4">
                                            <div className="main__bene__card__content" style={{backgroundImage:`url(${cardBgImg})`}}>
                                                <div className="benefits__title">
                                                    <div className="benefits__card__subtitle">Benefit Card</div>
                                                    <h6>{item.heading}</h6>
                                                </div>
                                                <div className="bottom__content">
                                                {/* {(itemProperty && itemProperty['Effective Date']) ? <div className="left__side">
                                                         <p>Effective Date</p>
                                                        <h6>{itemProperty['Effective Date']}</h6>
                                                    </div>: null} */}
                                                    {(itemProperty && itemProperty['Policy #']) ? <div className="right__side">
                                                        <p>Policy No:</p>
                                                        <h6>{itemProperty['Policy #']}</h6>
                                                    </div>:null}
                                                </div>
                                            </div>
                                            <div className="activate__bottom">
                                                <div className="logo__bottom">
                                                    {getCurrentItem?.topLeftImg ? 
                                                    <img src={getImageUrl(getCurrentItem.topLeftImg)} alt="" style={{width:'auto',height:'20px'}} />
                                                    :
                                                    <img src={getImageUrl(item.icon)} alt="" />
                                                    }
                                                </div>
                                                <div className="button__active">
                                                    {(itemProperty && itemProperty['Status']) ? 
                                                    <button className="btn">{itemProperty['Status']}</button>
                                                    : null }
                                                </div>
                                            </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                                
                            </div>
                            :<h3 className="no_benefits">No Active Benefits</h3>}
                        </div>
                        
                        <FooterSection slideName={pageData.footer} slideNo={slideNo} />
                    </div>
                </div>
            </div>

      </>
    )
}

const mapStateToProps = state => {
    const {mainCardArrayList} = state.partnerReducer;
    return { mainCardArrayList };
}
export default connect(mapStateToProps, { })(MemberBenefits);
