import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import "./RewardsCard.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import checkIcon from "../../../../../images/newDesignV2/green-check-circle.png"

const RewardsCard = ({rewardsData}) => {

    if(rewardsData && rewardsData.data && rewardsData.data.length)
    return (
            <Container>
                <div className={`${rewardsData?.addClass} rewards-cards-area `}>
                <div className='heading-v2' dangerouslySetInnerHTML={{ __html: (rewardsData.heading) }}></div>
                {rewardsData?.data?.length && rewardsData?.data.map((reward, index) => {
                    return <div className="rewards-card-container">
                        <div className="row no-gutters">
                            <div className="col-lg-8 col-md-12 col-sm-12">
                                <div className="rewards-card-body">
                                    <div className='rewards-card-ids'>/{reward.id}</div>
                                    <div className='rewards-heading-desc-area'>
                                    <div className="rewards-card-title">{reward.title}</div>
                                        <div className='rewards-card-items'>
                                            {reward.arrayData && reward.arrayData.length > 0 && reward.arrayData.map((item, index) => {
                                                return <div className='rewards-card-item'>
                                                    <div className='rewards-card-item-icon-wrp'>
                                                        <img src={checkIcon} width="35" />
                                                    </div>
                                                    {item}
                                                </div>
                                            })}
                                        </div>
                                    {/* <div className={'rewards-card-description'} dangerouslySetInnerHTML={{ __html: (reward.description) }}></div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <div className='rewards-right-img-area-desktop'>
                                    <LazyLoadImage src={reward.desktopImage} alt={reward.title} className="img-fluid" effect="opacity"/>
                                </div>
                                <div className='rewards-right-img-area-tablet'>
                                    <LazyLoadImage src={reward.tabletImage ? reward.tabletImage : reward.desktopImage} alt={reward.title} className="img-fluid" effect="opacity"/>
                                </div>
                                <div className='rewards-right-img-area-mobile'>
                                    <LazyLoadImage src={reward.mobileImage ? reward.mobileImage : reward.desktopImage} alt={reward.title} className="img-fluid" effect="opacity"/>
                                </div>
                            </div>
                        </div>
                    </div>
                })}
                </div>
            </Container>
    )
}

export default RewardsCard;
