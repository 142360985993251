import React from 'react';
import "./MicroJupiter.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const MicroJupiter = ({
    data = {}
}) => {
    const { imageUrl, title = "", subTitle = "", description = "", isCustomerStory, image, isAboutPage } = data
    return (

        <div className={`${isAboutPage ? "avibra-mini-jup-container" : "mini-jup-container customers_say"}`}>


            <div className="mini-jup-content">
                <div className="mini-jup-title">
                    {/* {
                        isCustomerStory ?
                            <LazyLoadImage src={require(`./../../../../../images/newDesign/${image}`)} className="img-fluid" effect="opacity" />
                            : title
                    } */}
                </div>
                <p className="mini-jup-description">{description}</p>
            </div>


            {isAboutPage ?
                <div className="leaders-image">
                    {<img className="mini-jup-user-image" alt={title.replace(/<[^>]*>?/gm, '') || ''} src={imageUrl} />}
                </div>
                :
                <div className="main__tile">
                    <div className="mini-jup-image">
                    {imageUrl && <img className="mini-jup-user-image" alt={title.replace(/<[^>]*>?/gm, '') || ''} src={imageUrl} />}
                        </div>
                    <div className="text__start">
                    <span>{title}</span>
                    <p>{subTitle}</p>
                        </div>
                </div>
            }


            {/* <div className="mini-jup-content">
                <div className="mini-jup-title">
                    {
                        isCustomerStory ?
                            <LazyLoadImage src={require(`./../../../../../images/newDesign/${image}`)} className="img-fluid" effect="opacity"/>
                            : title
                    }
                </div>
                <div className="mini-jup-subTitle">{subTitle}</div>
                <p className="mini-jup-description">{description}</p>
            </div> */}




        </div>
    )

};

export default MicroJupiter;
