import React from 'react'
import { Provider } from 'react-redux'

import store from './store/store';
import App from './App';
import ThemeContextProvider from './components/Theme/ThemeContextProvider';


const Routes = () => (
    <ThemeContextProvider>
        <Provider store={store}>
            <App /> 
        </Provider>
    </ThemeContextProvider>
)

export default Routes;