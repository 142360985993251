import React from "react";
import "./ClaimInformation.css";
import FooterSection from "./FooterSection";
import { customTextRender,getImageUrl } from "../../utils/helpers";
import processString from 'react-process-string';

const DisclaimerCard = (props) => {
    const { item,index } = props
    const disclaimerInfoData = item.propertyData[item.propertyData.dataType];
    const renderParsedText = (unprocessedText)=>{
        let config = [{
            regex: /{(.*?)}/gim,
            fn: (key, result) => customTextRender(key,result)
        }];
        let processed = processString(config)(unprocessedText);
        return processed
    }
    return (
        <div key={'disclamerinfo' + index} style={{ marginTop: 10 }}>
            <div className="TextParse">{disclaimerInfoData.text?renderParsedText(disclaimerInfoData.text):""}</div>
            {disclaimerInfoData.url ? <button onPress={() => { window.open(disclaimerInfoData.url, '_black') }}
                block dark transparent>
                <span style={{ paddingRight: 0, fontWeight: '600' }}>{disclaimerInfoData.buttonText || "Learn More"}</span>
                {/*<Image style={{ width: 20, height: 20, marginTop: 2 }} source={Icons.chevronBlack} />*/}
            </button> : null}
        </div>
    )
}

const CertificateAndSources = ({claimInfoData={}, certificateDocs=[], sourcesArticle=[], footerText='',slideNo='' }) => {
  const {title='', subtitle='',propertyData={}} = claimInfoData
  console.log("CertificateAndSources claimInfoData===>", claimInfoData, certificateDocs)
  let certificateDocsList = []
  if(certificateDocs && certificateDocs.length){

    certificateDocs.map((certificateCard,index)=> {
      if(certificateCard?.ishide == true){
        console.log("certificateCard Property ===>", certificateCard)
      }else
      if(certificateCard.propertyData && certificateCard.propertyData.dataType) {
        certificateDocsList[index] = certificateCard.propertyData[certificateCard.propertyData.dataType];
        //console.log("certificateCardProperty ===>", certificateDocsList)
      }
    })
    
  }
  return (
    <>
    {(certificateDocsList && certificateDocsList.length) || (sourcesArticle && sourcesArticle.length) ?
    <div className="slide__pdf claim_information">
      <div className="card-guide -new">
        <div className="container h-100 -1 p-0">
          <div className="main__color _new ClaimInformation">
                <div className="col-md-12-bg-img-right">
                    <div className="info__image right_area">
                        <img src={getImageUrl('https://images.avibra.com/newhome/membership-doc-info-details.png')} alt="rightImg"  className="img-fluid" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="ClaimInformation__Benefits__title">
                            {certificateDocsList && certificateDocsList.length ?
                              <div className="pdf__content_area">
                                <h3>Legal Documents</h3> 
                                {certificateDocsList && certificateDocsList.length && certificateDocsList.map((certificateDoc, index)=>{
                                  if(certificateDoc && certificateDoc[0] && certificateDoc[0]['downloadLink'])
                                  return(<div className="inner__pdf__content" key={'legal-doc-'+index}>
                                      <span className="icon__main">
                                          <img src={getImageUrl('https://images.avibra.com/newhome/membership-doc-file-pdf-one.png')} alt="" className="img-fluid" />
                                      </span>
                                      <span className="inner__pdf__link">
                                          <a href={certificateDoc[0]['downloadLink']} target="_blank" >{certificateDoc[0]['downloadLink']}</a>
                                      </span>
                                  </div>)
                                  else
                                  return null
                                })
                              }
                              </div>
                            : null}

                            {sourcesArticle && sourcesArticle.length ?
                              <div className="sources_article__content_area col-md-12 p-0">
                                <h3 className={`${(certificateDocsList && certificateDocsList.length)? 'mt-3' : ''}`}>{ title || 'Sources'}</h3> 
                                {sourcesArticle && sourcesArticle.length && sourcesArticle.map((article, index)=>{
                                  return(<div className="inner__article__content" key={'article-link-'+index}>
                                      <div className={`inner__article__link ${footerText && footerText.replace("$", "").replace(",", "").replace(/[0-9]/g, '').replace(" ", "").toLowerCase().replace(/\s+/g, '-')}`}>
                                        <DisclaimerCard item={article} index={index} />  
                                      </div>
                                  </div>)
                                })
                              }
                              </div>
                            : null}
                        </div>
                    </div>
                    
                </div>
                <FooterSection slideName={footerText} slideNo={slideNo} />
          </div>
        </div>
      </div>
    </div>
    : null}
    </>
  );
};

export default CertificateAndSources;
