import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import "./CustomerVoices.css"

const CustomPrevArrow = ({ className, style, onClick }) => {
    return (
        <div
            className={`${className} customer-voices-arrow prev-arrow`}
            style={{ ...style }}
            onClick={onClick}
        >
            <i className="fas fa-arrow-left"></i>
        </div>
    );
};
const CustomNextArrow = ({ className, style, onClick }) => {
    return (
        <div
            className={`${className} customer-voices-arrow next-arrow`}
            style={{ ...style }}
            onClick={onClick}
        >
            <i className="fas fa-arrow-right"></i>
        </div>
    );
};

const CustomerVoices = (props) => {
    const { content = {} } = props
    const { heading = '', desc = '', imageWeb='',imageTablet='', imageMobile='', data = [] } = content
    const settings = {
        dots: false,
        arrows: true,
        accessibility: true,
        infinite: false,
        centerMode: false,
        speed: 500,
        slidesToShow: 2.5,
        slidesToScroll: 1,
        nextArrow: <CustomNextArrow />, 
        prevArrow: <CustomPrevArrow />, 
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                },
            },
            {
                // Tablet breakpoint (768px to 1024px)
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    dots: false
                }
            }
    
        ]
    };


    return (

        <div className='container'>
            <div className='customer-voices-container'>
                <div className='customer-voices-header-wrp'>
                    <div className='customer-voices-header'>
                        <div className='customer-voices-heading'>{heading}</div>
                        <div className='customer-voices-desc'>{desc}</div>
                    </div>
                </div>
                <div className='customer-voices-slider-container'>
                    <div className='customer-voices-cards'>
                        <div className='customer-voices-img-wrp customer-voices-img-desktop'>
                            <div className='img-desktop-v2'>
                                <img src={imageWeb} className="img-fluid"/>
                            </div>
                            <div className='img-tablet-v2'>
                                <img src={imageTablet ? imageTablet : imageWeb} className="img-fluid"/>
                            </div>
                            <div className='img-mobile-v2'>
                                <img src={imageMobile ? imageMobile : imageWeb} className="img-fluid"/>
                            </div>
                        </div>
                        <div className='customer-slider-outer'>
                            <Slider {...settings}>
                                {data && data.length > 0 && data.map((item, index) => {
                                    return <div className='customer-voices-card' key={index}>
                                        <div className='customer-voices-card-inner'>
                                            <div className='customer-voices-number'>{item.number}</div>
                                            <div className='customer-voices-card-content'>
                                                <div className='customer-voices-card-desc'>{item.desc}</div>
                                                <div className='customer-voices-date-wrp'>
                                                    <div className='customer-voices-card-name'>{item.name}</div>
                                                    {item.date &&<div className='customer-voices-date'>{item.date}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}

                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )

};

export default CustomerVoices;
