import React from 'react'
import "./SuperCharging.css"

const SuperCharging = (props) => {
    const { content = {},extraClass='' } = props
    const { image1,heading = '', data = [], bannerImage={} } = content
    const {desktop='',tablet='',mobile=''} = bannerImage
    return (
        <div className='container'>
            <div className={`super-charging-container ${extraClass}`}>
            <div className='super-charging-card'>
                <div className='super-charging-card-image'>
                    <div className='img-desktop-v2'>
                        <img src={desktop} className="img-fluid"/>
                    </div>
                    <div className='img-tablet-v2'>
                        <img src={tablet ? tablet : desktop} className="img-fluid"/>
                    </div>
                    <div className='img-mobile-v2'>
                        <img src={mobile ? mobile : desktop} className="img-fluid"/>
                    </div>
                </div>
                <div className='super-charging-card-content'>
                    <div className='super-charging-card-title' dangerouslySetInnerHTML={{ __html: (heading) }} />
                    <div className='super-charging-card-items'>
                        {data && data.length > 0 && data.map((item, index) => {
                            return <div className='super-charging-card-item' key={index}>{item}</div>
                        })}
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default SuperCharging