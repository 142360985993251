import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import "./DobsDrillDownDetails.css";

import ADDBD from './AD&DBenefits';
import BenefitsHighlight from './BenefitsHighlight';
import SampleSaving from './SampleSaving';
import ClaimInformation from './ClaimInformation';
import CertificateAndSources from './CertificateAndSources';
import {getDobsDrillDownDetail} from "../../api/policyDocumentApi"
import {setMainCardDataList} from "../../actions/partnerDataAction";

const DobsDrillDownDetails = (props) => {
    const { requestUrl=[],slideNo='8',listItemData='' } = props;
    //console.log("DobsDrillDownDetails listItemData ===>", listItemData)
    const [loading, setLoading] = useState(true)
    const [requestUrlData, setRequestUrlData] = useState()
    const [highlightsData, setHighlightsData] = useState({})
    const [providerInfoData, setProviderInfoData] = useState({})
    const [claimInfoData, setClaimInfoData] = useState({})
    const [certificateCard, setCertificateCard] = useState([])
    const [companyInfoData, setCompanyInfoData] = useState('')
    const [savingsTableData, setSavingsTableData] = useState('')
    const [overviewDescription, setOverviewDescription] = useState('')
    const [sourcesArticle, setSourcesArticle] = useState([])

    useEffect(() => {
        getDobsDrillDownDetail(requestUrl).then(res => {
            let { data } = res ;
            setRequestUrlData(data)
            setLoading(false)
            renderDrillData(data.details,(data?.productname || listItemData?.name))
          }).catch(_ => setLoading(false))
    }, [])

    const renderDrillData = (properties , productname = '') => {
        console.log("renderDrillData properties productname ===>",productname,"-->", properties)
        let legalDoc = [];
        let legalDisclaimer = [];
        properties && properties.map((item, index) => {
            //console.log("renderDrillData properties item", item)
            if(item.property=='benefithighlight' || item.property=='benefithightlight'){
                setHighlightsData(item)
            }
            if(item.property=='providerinfo'){
                setProviderInfoData(item)
            }
            if(item.property=='claimscardnewhome'){
                setClaimInfoData(item)
            }
            if(item.property=='certificatecard'){
                legalDoc.push(item);
            }
            if(item.property=="companyinfo"){
                setCompanyInfoData(item)
            }
            if(item.property=="savingstabular"){
                setSavingsTableData(item)
            }
            if(item.property=="overviewdescription"){
                setOverviewDescription(item)
            }
            if(item.property=='disclaimer' || item.property=='disclaimer2'){
                legalDisclaimer.push(item);
            }
            if(item.property=="maincard"){
                let timeString = new Date().getTime();
                props.setMainCardDataList({...item, timeString: timeString, productname: productname })
            }
        })
        setCertificateCard(legalDoc)
        setSourcesArticle(legalDisclaimer)
    }
    //console.log("companyInfoData ===>", companyInfoData)
    return (
        <>
            <div className="slide__pdf adandd">
                <ADDBD pageData={listItemData} overviewDescData={overviewDescription} footerText={listItemData?.heading} slideNo={(slideNo)} /> 
            </div>
            <div className="slide__pdf benefits_higlight">
                <BenefitsHighlight highlightsData={highlightsData} providerInfoData={providerInfoData} companyInfoData={companyInfoData} footerText={listItemData?.heading} slideNo={(slideNo+1)}/> 
            </div>
            
            <SampleSaving savingsTableData={savingsTableData} footerText={listItemData?.heading} slideNo={(slideNo+2)}/>
            
            <ClaimInformation  claimInfoData={claimInfoData} footerText={listItemData?.heading} slideNo={(slideNo+3)}/>
            
            <CertificateAndSources  title={'Sources'} certificateDocs={certificateCard} sourcesArticle={sourcesArticle} footerText={listItemData?.heading} slideNo={(slideNo+4)}/>
            
        </>
    );
};


const mapStateToProps = state => {
    const { mainCardArrayList } = state.partnerReducer;
    return { mainCardArrayList };
}

export default connect(mapStateToProps, { setMainCardDataList })(DobsDrillDownDetails);
