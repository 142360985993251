import React from 'react'
import "./FastImpact.css"

const FastImpact = (props) => {
    const { content = {} } = props
    const { tagLine = '', heading = '',data=[]} = content
  return (
    <div className='container'>
         <div className='fast-impact-container'>
            <div className='fast-impact-subheading'>{tagLine}</div>
            <div className='fast-impact-desc'dangerouslySetInnerHTML={ { __html: (heading) } } />
        <div className='fast-impact-cards'>
        {data && data.length > 0 && data.map((item, index) => {
            return  <div className='fast-impact-card' key={index}>
                <div className='fast-impact-card-content'>
                    <div className='fast-impact-card-title'>{item.title}</div>
                    <div className='fast-impact-card-desc'>{item.desc}</div>
                </div>
            <div className='fast-impact-card-bottom-num'>{item.bottomText}</div>
          </div>
        })}
        </div>
    </div>
    </div>
    
  )
}

export default FastImpact