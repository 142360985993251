import React,{useEffect,useState,useRef} from 'react'
import "./VideoContent.css"

const VideoContent = (props) => {
    const { content = {} } = props
    const { videoUrl = '', heading = '', description = '',videoCover=''} = content
    const [showControls, setShowControls] = useState(false);
    const [isPaused, setIsPaused] = useState(true);
    const videoRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (videoRef.current) {
                    if (entry.isIntersecting) {
                        //videoRef.current.play();
                    } else {
                        videoRef.current.pause();
                    }
                }
            },
            { threshold: 0.5 } 
        );

        if (videoRef.current) {
            observer.observe(videoRef.current);
        }

        const onPlay = () => setIsPaused(false);
        const onPause = () => setIsPaused(true);
    
        if (videoRef.current) {
            videoRef.current.addEventListener("play", onPlay);
            videoRef.current.addEventListener("pause", onPause);
        }
    

        return () => {
            if (videoRef.current) {
                observer.unobserve(videoRef.current);
                videoRef.current.removeEventListener("play", onPlay);
                videoRef.current.removeEventListener("pause", onPause);
            }
        };
    }, []);
    
    const handlePlay = () => {
        videoRef.current.play();
        setIsPaused(false);

    }


    return (
        <div className='container'>
            <div className='video-content-container'>
                {heading && <div className='video-content-title'>{heading}</div>}
                {description && <div className='video-content-description'>{description}</div>}
                <div 
                    className='video-content-wrp'
                    onMouseEnter={() => setShowControls(true)}
                    onMouseLeave={() => setShowControls(false)}
                >
                    <video
                        ref={videoRef}
                        width="100%"
                        className="rounded-lg border-2 border-green-500 shadow-lg"
                        playsInline
                        controls={showControls}
                        poster={videoCover}
                    >
                        <source
                            src={videoUrl}
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                    
                    {isPaused ? <div className='play-button-wrapper'>
                    <div className="play-toggle">
                        <div className="play-circle" onClick={()=> handlePlay() } >
                            <i className="play-icon"></i>
                        </div>
                        
                    </div>
                    </div> : null}
                </div>
            </div>
        </div>
    )
}

export default VideoContent