import React from 'react'
import "./FinancialServices.css"

const FinancialServices = (props) => {

    const { content = {} } = props

    return (
        <div className='financial-services-container-outer container'>
            <div className='financial-services-heading'>{content.heading}</div>
            <div className='financial-services-desc'>{content.description}</div>
            <div className='financial-services-cards'>
                {content.data?.length && content.data.map((item) => {
                    return <div className='financial-services-card'>
                        <div className='financial-services-card-number'>{item.number}</div>
                        <div className='financial-services-card-content'>
                            <div className='financial-services-card-title'>{item.title}</div>
                            <div className='financial-services-card-desc'>{item.description}</div>
                        </div>
                    </div>
                })}

            </div>
            <div className='financial-services-img financial-services-img-desktop'>
                <img src={content.imageUrlDesktop} className='img-fluid'/>
            </div>
            <div className='financial-services-img financial-services-img-mobile'>
                <img src={content.imageUrlMobile} className='img-fluid'/>
            </div>
            <div className='financial-services-img financial-services-img-tablet'>
                <img src={content.imageUrlTablet} className='img-fluid'/>
            </div>
        </div>
    )
}

export default FinancialServices