import React, { useRef, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { setLogEvent } from '../../../utils/helpers';
import GetStartedButton from "../Common/GetStartedButton/GetStartedButton";
import "./CaseStudyHeaderSection.css";
import 'react-lazy-load-image-component/src/effects/opacity.css';


import _ from 'lodash';
const CaseStudyHeaderSection = ({ content = {}, appLinkUrl = "/", target = "_blank", extraClassName2 = '' }) => {

   
    const [emailId, setEmailId] = useState('');
    const [offsetY, setOffsetY] = useState(0);
    const handleScroll = () => {
        setOffsetY(window.scrollY);
    };
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const { tagLine = {},applyStyle, title, subTitle, extraClassName = '', gridItemsData = [], bottomRightVectorImg = '', bottomLeftVectorImg = '', topLeftVectorImg = '' } = content;

    const onDownloadClick = () => {
        setLogEvent("User Clicked On Get Started Button");
    }
    const submitEmailForm = () => {
        setLogEvent("User Clicked On Book a Demo Button");
            window.location = `/book-a-demo`
    }




    return (
        <div className={`main-header-v3-section case-study-header-section ${applyStyle ? 'new-header-style' : ''} ${extraClassName} ${extraClassName2}`}>
            <div className={`full-width-banner-header-img-area-v2 container ${target}target-old`}>
                {topLeftVectorImg && <div className="top-left-vector-img" style={{ backgroundImage: `url(${topLeftVectorImg})` }}></div>}
                <div className="main-header-desc">
                    <div class="landing-container">
                        <div className={`header_area_old header_area_new header-section container`}>     {/* container-fluid` style={{ backgroundImage: `url(${headerRightImgDesktop})` }}*/}
                            <Row className={'header-row'}>   {/*style={{ backgroundImage: `url(${headerDesktopLeftBg})` }}*/}
                                <Col className="image-lower1 header-v3-left-text-area" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <div>
                                        <div className="main-header-text-align-center">
                                            <div style={{ clear: 'both' }}></div>
                                            {tagLine ? <div className='main-header-tag-line'>{tagLine}</div> : null}
                                            <div className="heading-v2">
                                                <div dangerouslySetInnerHTML={{ __html: (title) }}></div>
                                            </div>
                                            <div className="sub-title-v2">
                                                <div className='sub-title-v2-inner'>
                                                    {subTitle}
                                                </div>
                                            </div>
                                            <div className={`main__header__inner ${content?.addClass}`}>
                                                <div className="header-get-button-and-rating">
                                                    {content.inputType ? <div className="header-claim-btn-cont">
                                                        <input type="text" placeholder='email' value={emailId} onChange={e => setEmailId(e.target.value)} />
                                                        <button className='header-claim-btn-cont' onClick={() => { submitEmailForm() }}>{content.buttonText}</button>
                                                    </div> :
                                                        (content.buttonText) ? <div className="header-claim-btn-cont">

                                                            <GetStartedButton
                                                                bgColor={'#774AD9'}
                                                                onClick={() => onDownloadClick()}
                                                                text={content.buttonText}
                                                                link={appLinkUrl}
                                                                target={target}
                                                                padding={'15px 45px'}
                                                            />
                                                        </div> : null}

                                                </div>
                                            </div>
                                            <div style={{ clear: 'both' }}></div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='case-study-header-section-grid-item-wrp'>
                                {gridItemsData.map((stat, index) => {
                                    return <Col key={index} md={4} sm={6} xs={12} className="text-center">
                                        <div className=" case-study-header-item">
                                            <h2>{stat.title}</h2>
                                            <p>{stat.description}</p>
                                        </div>
                                    </Col>
                                })}

                            </Row>
                            
                        </div>
                    </div>
                </div>
                {bottomLeftVectorImg && <div className="bottom-left-vector-img" style={{ backgroundImage: `url(${bottomLeftVectorImg})` }}></div>}
                {bottomRightVectorImg && <div className="bottom-right-vector-img" style={{ backgroundImage: `url(${bottomRightVectorImg})` }}></div>}
            </div>
        </div>
    )
}

export default CaseStudyHeaderSection