import React from "react"
import { Row, Col, Table } from "reactstrap"
import moment from "moment"
import abbreviate from "number-abbreviate"
import PieChartWithPaddingAngle from "../../components/Charts/PieChartWithPaddingAngle"
import "../../../styles/AlertDetail/alert-detail.css"
import "../../../styles/AlertDetail/alert-detail-responsiveness.css"
import "../../../styles/PolicyDocument/policy-document.css"
import { removeIcon, checkedIcon } from "../../../constant/constant"
import { Capitalize } from "../../utils/helpers"
import logoPng from "../../../images/logo.png"

const MembershipGuide = ({
  policy,
  beneficiary = [],
  wellbeing = {},
  plusPlanDetails,
  plusplanHeader,
  userPlans,
  userInfo,
  planMeta,
  onClickSave,
  insurance,
  waitingPeriod = "",
  insuranceDisclaimer,
  accidentalInsurance,
  membershipState = "Inactive",
  insuranceCoverage,
  isDollarStore,
  dollarCludData={}
}) => {
  let {
    adnddefaultfacevalue,
    reactivationdate,
    issuedate,
    products,
  } = accidentalInsurance

  let { categoryscores = {} } = wellbeing
  let { graph = '' } = categoryscores
  let { isplus = "", isdollarstore } = userInfo
  let lifeValue = 0;
  let adndValue = 0;
  let ameValue = 0;
  let ciValue = 0;
  const updateMyWellBeing = (graph && graph?.data) ? Object.keys(graph.data).map((keys) => {
    return { name: keys, value: parseFloat(graph.data[keys]) }
  }) : {}

  let planName = isDollarStore ? "Dollar Club" : "Plus"

  const newInsurance = insuranceCoverage && insuranceCoverage.map((data) => {
    if(data.productname === 'life')
    {
      data.productname = 'Life Insurance'
    
    }
    else if(data.productname === 'adnd')
    {
      data.productname = 'AD&D'
    
    }
    else if(data.productname === 'ame')
    {
      data.productname = 'Accidental Medical Expense'
    
    }
    return data
  })

  const newPolicy = products && products.map((data) => {
    
    if(data.productname === 'life')
    {
      lifeValue = data.earnedfacevalue + data.planearnedfacevalue + data.planinitialearnedfacevalue;
    }
    else if(data.productname === 'adnd')
    {
      adndValue = data.earnedfacevalue + data.planearnedfacevalue + data.planinitialearnedfacevalue;
    }
    else if(data.productname === 'ame')
    {
      ameValue = data.earnedfacevalue + data.planearnedfacevalue + data.planinitialearnedfacevalue;
    }
    else if(data.productname === 'ci')
    {
      ciValue = data.earnedfacevalue + data.planearnedfacevalue + data.planinitialearnedfacevalue;
    }
    return data
  })

  
  let rightData = [
    {
      title: "Name",
      desc: policy?.profilenam ? policy.profilenam : '',
    },
    {
      title: "Date of Birth:",
      desc: policy?.dob ? moment(policy.dob).format("MMM D, YYYY") : '',
    },
    {
      title: "Membership ID:",
      desc: userInfo?.customerid,
    },
    {
      title: "Membership Activation Date:",
      desc: moment((reactivationdate !== null ? reactivationdate : issuedate)).format("MMM D, YYYY"),
    },
    {
      title: "Life Insurance Benefit Amount:",
      desc: `$${policy?.earnedamount}`,
    },
    {
      title: "Membership Plan:",
      desc: `${isplus ? Object.keys(dollarCludData).length>0 && dollarCludData?.heading  : "Core"}`,
    },
    {
      title: "Membership Status:",
      desc: `${membershipState} (as of ${moment().format("MMM D, YYYY")})`,
    },
  ]

  if (adndValue) {
    rightData = [
      {
        title: "Name",
        desc: policy?.profilenam ? policy.profilenam : '',
      },
      {
        title: "Date of Birth:",
        desc: policy?.dob ? moment(policy.dob).format("MMM D, YYYY") : '',
      },
      {
        title: "Membership ID:",
        desc: userInfo?.customerid,
      },
      {
        title: "Membership Activation Date:",
        desc: moment((reactivationdate !== null ? reactivationdate : issuedate)).format("MMM D, YYYY"),
      },
      {
        title: "Life Insurance Benefit Amount:",
        desc: `$${lifeValue}`,
      },
      {
        title: "AD&D Insurance Benefit Amount:",
        desc: `$${adndValue}`,
      },
      {
        title: "Accident Medical Benefit Amount:",
        desc: `$${ameValue}`,
      },
      {
        title: "Membership Plan:",
        desc: `${isplus ? Object.keys(dollarCludData).length>0 && dollarCludData?.heading  : "Core"}`,
      },
      {
        title: "Membership Status:",
        desc: `${membershipState} (as of ${moment().format("MMM D, YYYY")})`,
      }
    ]
    if(ciValue){
      let ciElement = {
        title: "Critical Illness Benefit Amount:",
        desc: `$${ciValue}`,
      }
      rightData.splice((rightData.length-2), 0, ciElement);
    }
  }

  let { profilenam = "" } = policy

  const data = {
    memberShipTitle: "Nurture and Protect A VIBRAnt Life",
    avibraUrl: "www.avibra.com",
    pieData: [
      { name: "location", value: 400 },
      { name: "Personal Information", value: 300 },
    ],
    riskColors: ["#ffd463", "#6fe5ad"],
    myWellBeingColors: ["#6fe5ad", "#ffd463", "#6fe5ad", "#ff8a68", "#ffd463"],
    membershipCardData: [
      "Name",
      "Date of Birth",
      "Membership ID",
      "Membership Activation Date",
      "Life Insurance Benefit Amount",
      "Membership Plan",
    ],
    membershipTierLabel1: "Core (Free)",
    membershipTierLabel2: (isDollarStore ? "Dollar Club" : "Plus"),
    memberShipCard: "Membership Card",
    avibraMembershipGuide: "Membership Guide",
    avibraMembershipGuideDesc: `${profilenam}, welcome! Avibra was created to offer advice in all areas of life, including how to improve your finances and physical health, reach career goals, and thrive in relationships and your community. The name Avibra is derived from our motto, ‘Live A VIBRAnt Life‘. Avibra has partnered with the National Alliance of Well-Being Associations (myallwell.org)—a non-profit on a mission to create a well-being economy that’s dedicated to providing information to improve the well-being of people across the country. Your Avibra membership automatically makes you a member of MyAllWell, and your benefits are jointly provided by Avibra and MyAllWell.`,
    beneficiaryTheadList: ["SI No", "Name", "DOB", "SSN", "Percentage"],
    insuranceTheadLabel1: "Core (max coverage)",
    insuranceTheadLabel2: (isDollarStore ? "Dollar Club" : "Plus") + " (max coverage)",
    purposeOfAvibra: {
      title: "Avibra Membership Benefits",
      wellBeing:
        `1. Well-Being Advisor - Check in with your mind & body every Well-being Day for 5 minutes with fun and informative activities. Core members renew their membership each month by staying active in the app. That means not just logging in, but completing at least one task in the app, such as reading an article, watching a video or finishing a quiz at least once a month. ${planName} members renew their membership each month through their monthly fee.  You can also use Well-Being Advisor to track your progress across the five well-being pillars: Physical, Financial, Career, Social and Community.`,
      habit:
        "2. Habit Advisor - Connect your personal sources of data to get information about your own good and bad habits across every dimension of your life.",
      share:
        "3. Risk Advisor - We use advanced machine learning to offer a real-time risk monitoring system so that you can stay aware of and avoid the potential risks and dangers to you, your loved ones and the things you love.",
      myWell:
        "4. My News - This is your daily digest of hand-picked articles that are personalized to your preferences. Topics range from parenting and heart health to the environment and debt.",
      myRisk:
        "5. Benefits Reward Program - Get insurance coverage that grows each week based on your own good habits. This includes something as simple as putting money in a savings account or taking a quiz to learn more about the benefits of vitamin D.",
      insurance:
        "6. Community Well-Being - Improve and protect the lives of others across the country through your good habits.",
    },
    memeberShipEligiblityAndConditions: "Membership Eligibility & Conditions",
    memeberShipEligiblityAndConditionsDesc:"Avibra and MyAllWell is open to everyone and conditions are the same for all members, you do not need to purchase any products or services to become a member.  Membership benefits are out lined both inside the app as well as in the membership guide document. Some key conditions outlined are -",
    memeberShipEligiblityAndConditionsList: [
      "•	Core membership is renewed on monthly basis and can renew their monthly memb ership,   benefits by keeping up positive lifestyle habits. However, they'll lose their membership status and benefits if they f ail to check in at least once a month to improve their well-being by taking actions in the app, including engaging with the different types of content that are geared toward improving their financial, career. social, physical and community well-being.",
      `•	${planName} members not only unlock premium membership benefits but their membership is renewed monthly as long as their payment is active. After a failed payment, they are provided a15 day grace period to restore their payment before they lose their ${planName} membership status.`,
      `•	${planName} members will lose their sign-up bonus if they downgrade to core membership.`,
      `•	${planName}  membership fees are non -refundable and is clearly mentioned during subscription process.`,
      "•	Members who joined through a sponsored program that includes members of our affiliate partners or affinity clubs or association or employers or non-profit organization will lose their sign-up bonus incentives and coverage benefits awarded as part of the initial sign-up if the sponsor terminates your membership or subscription in their system. However, your core or plus membership will still remain active and you will be able to continue with all basic coverage benefits available to all non-sponsored members.",
    ],
    wellBeingAdvisor: "Well-Being Advisor",
    wellBeingAdvisorDesc:
      "We want to help you improve your personal well-being in ways that are fun and interesting to you. We’ve developed content in the app that teaches you more about how to live a happy and healthy life through videos, articles, audio clips and more. Well-Being Advisor can help you track your progress across the five pillars of well-being: Physical, Financial, Career, Social and Community.",
    wellBeingAdvisorSubDesc:
      "It’s so easy to improve your well-being and turn your good habits into insurance coverage by simply engaging with any of the in-app activities.",
    wellBeingAdvisorFootDesc:
      "With Avibra, our goal is to help you be a better version of yourself, converting your good habits into financial support for your loved ones and community if they ever need it. ",
    wellBeingAdvisorList: [
      "1. Guided Meditation - to positively impact your mental and physical health",
      "2. Quizzes - to make learning about different well-being topics more fun (not to judge you for what you already know)",
      "3. Well-Being Videos - improve your knowledge on important topics in health, finances and your career",
      "4. Life Lesson Videos - to inspire you both professionally and personally",
      "5. Brain Workouts - to boost your overall brain activity and improve your memory",
      "6. Gratitude Journal - re-discover the things in life you're grateful for through weekly journaling",
      "7. 10 Tips Series - quick tips to improve your health, relationships and finances",
      "8. Peer-to-Peer Well-Being - your good habits earn insurance coverage for your loved ones, plus you can also anonymously protect another person and their family in a different state each week",
      "9. Thoughtfulness - learn more about an integral part of mental, emotional and physical health",
      "10. Protect the Earth - on a weekly basis, your good habits mean that we plant one tree while sharing information on climate change (we work with the TIST Program, which has planted millions of trees across the globe)",
      "11. Be Kind - get ideas on how to practice little acts of kindness for the people around you to improve your relationships, your own mental health and your connections in your community",
      "12. Social Impact - to turn your good habits into support for human rights",
      "13. Yoga Studio - take some time for a yoga flow with Avibra Yoga Studio",
      "14. Relaxation Music - to help ease your everyday stress and anxiety",
      "15. Disaster Preparedness - Learn how to protect yourself and your family from disasters"
    ],
    habitAnalyzer: "Habit Advisor",
    habitAnalyzerDesc:
      "Habit Advisor helps our members figure out what their good and bad habits are through their own daily activities. We have strict privacy policies and data encryption in place, and we do not sell, rent or lease your personal data. There’s a video available inside the app that explains how we use your data, which you can view before you connect any data sources.",
    habitAnalyzerSubDesc:
      "We built Habit Advisor to allow you to unlock 300+ insights from your own activity, including:",
    habitAnalyzerList: [
      "1. Credit Card/Bank Transactions - We can help you figure out if you’re getting better at saving money, or if you’ve been going grocery shopping more than eating out.",
      "2. Wearables (i.e. Apple Health, Fitbit, Google Fit) - Have you been hitting your step goal lately?",
      "3. LinkedIn - We’ll let you know when you’re making positive steps that may help you advance (or change) your career.",
      "4. Driver’s License - Are you a registered organ donor?",
    ],
    shareCare: "My News",
    shareCareDesc:
      "My News helps you stay up-to-date on some of the recent topics in health, personal finances, parenting, and more. See all the latest stories or pick and choose which topics you want to read up on. The articles are gathered up from different sources around the web, including news sites and in-depth blogs. Hear from experts on the latest trends or read about common myths and misconceptions. Read our full list of topics below.",
    shareCareListLeft: [
      "Quit Smoking",
      "Safe Driving",
      "Alcohol Abuse",
      "Anxiety",
      "Career",
      "Asthma",
      "Cancer",
      "Nutrition",
      "Retirement",
      "Savings",
    ],
    shareCareListRight: [
      "Debt",
      "Depression",
      "Diabetes",
      "Drug Abuse",
      "Heart Attack",
      "High Blood Pressure",
      "High Cholesterol",
      "Sleep",
      "Relationships",
      "Obesity",
    ],
    myWellBeing: "myWell-Being Score & myRisk Score",
    myWellBeingDesc:
      "These unique scores are similar to your credit score, but they benefit our users and not some big corporation or bank. We were frustrated seeing scores that don’t seem to give people much in the way of help, but rather just a rating to lenders to tell them how much interest to charge people for loans and credit cards. So, we built our own system to give you real, clear insights into your own well-being and the risk that exists for you and loved ones. The score changes based on your location and an assessment you take on a monthly, quarterly or annual basis.",
    myWellBeingSubDesc:
      "Your myWell-Being Score is calculated based on 6 elements of your overall well-being:",
    myWellBeingList: [
      "Personal finances",
      "Physical health",
      "Career success",
      "Personal relationships",
      "Community engagement",
    ],
    myWellBeingRiskScore: "Your myRisk Score is calculated based on:",
    myWellBeingRiskScoreList: [
      "Your current location",
      "Your personal information",
    ],
    myWellBeing360: "Risk Advisor",
    myWellBeing360Desc:
      "Risk Advisor offers a deeper dive into the individual risks you face each day, breaking your score down into categories that matter specifically for you. We’ve done tons of research and modeling so that we can provide you with more information on the risks to your life, your loved ones, or the things you love. Our hope is that with this data we will not only help keep you informed, but also help you proactively take steps to prevent a bad outcome.",
    myWellBeing360DescSubDesc:
      "",
    myWellBeing360DescSubDesc2:
      "Risk Advisor comes with the following sub-risk monitoring:",
    myWellBeing360DescSubDescList: [
    ],
    myWellBeing360DescSubDesc2List: [
      "● myLocation risk - This gives you a complete risk profile based on your current location. It’s a powerful feature that allows you to take proactive steps to eliminate potential sources of risk or take extra precaution to protect you and your loved ones. It can also help you learn about the risks you may encounter while travelling to a new location on business or personal trips, vacations, or even where you’re meeting a first date.",
      "● myDwelling risk - Get a complete risk profile for where you live or rent and figure out ways to take extra precautions to protect you and your loved ones. You can also use it to learn about risks in a future home.",
      "● myRadar - Threats can come in many forms. Transportation, health, environmental factors, technology and others can all play a role.",
      "● myHealth - Learn about the likely health risks you may be facing now and in the future based on factors such as your age and habits",
      "● myRide - Learn about the risks you face when you get on the road, whether it’s you driving or someone else."
    ],
    memberShipInsurance: "Membership Insurance Benefits",
    memberShipInsuranceDesc:
      "Members are eligible to receive the minimum levels of micro insurance provided that they qualify for coverage pursuant to the terms and conditions set out in the Insurance Benefits section below. This insurance is intended to educate members on the value of living a healthy lifestyle and the importance that insurance plays in the financial well-being of members and their family.",
    memberShipInsuranceSubDesc:
      "To make it easier for you, all your membership benefits are simplified below, including exclusion clauses, eligibility criteria and more. Your Life and AD&D insurance is underwritten by Amalgamated Life Insurance Company and your Accident Medical insurance is underwritten by Liberty Insurance Underwriters Inc. ",

    memberShipInsuranceSubSubDesc:
      `Insurance bundled with your Core and ${planName} membership is an add-on complimentary insurance benefit that comes with your membership perks and any insurance you purchased separately from our partners through the app will not be affected with below exclusion, clauses & eligibility criteria. .`,  

    scheduleBenefits: "Schedule of Benefits",
    beneficiaryInfo: "Beneficiary Information",
    insuranceCoverage: "Insurance Coverage",
    guaranteedCoverageDesc:
      "Members are guaranteed to earn some amount of coverage through their good habits based on membership eligibility and other conditions.",
    fraudNoticeDesc:
      "Any person who knowingly and with intent to injure, defraud, or deceive any insurer files a statement of claim or an application containing any false, incomplete, or misleading information is guilty of a felony of the third degree.",
    memberShipInsuranceNote: `There is a ${waitingPeriod} day waiting period from the date of your member registration or the reactivation of your expired membership before life insurance, AD&D and Accident Medical insurance becomes active and eligible benefits will be paid.`,
    terminationInsuranceDesc:
      "The insurance will terminate on the earliest of the following dates:",
    terminationList: [
      "● Your death",
      `● You have the Core (free) membership plan and you have not renewed your monthly membership through well-being certification program which could be simply using the app to improve your overall well-being at least once a month or are inactive in the Avibra App for more than [30] days (for members with an upgraded membership, this condition does not apply)`,
      "● Your 55th birthday (for Life Insurance) or your 63rd birthday (for accidental death & dismemberment, accident medical and/or critical illness ) or",
      "● You have requested to terminate the coverage or",
      `● 14 days following a failed payment for an upgraded member if an alternate payment form is not provided.`
    ],
    exclusionsList: [
      "● If you die by suicide while sane or insane within two (2) years of your effective date recorded in Avibra App or",
      "● Death as a result, directly or indirectly, of war, declared or undeclared, or of any act or hazard of such war. This exclusion does not apply to innocent victims or",
      "● Death as a result of a specified hazardous occupation or occupations or",
      "● Death while you are a resident outside of the continental United States or Canada.",
    ],
    settlementList: [
      "● Payment of the Death Benefit will be made to the Beneficiary and",
      "● A Death Benefit will be paid in a single lump sum payment.",
    ],
    policyConversionDesc:
      `The life insurance benefit bundled with your Core and ${planName} membership is effective until you turn 55. At that point, you will have the option to convert into a lifelong coverage based on your needs and the insurance underwriter’s policy conversion guidelines, which are separately covered outside the Core and ${planName} membership. If you’re interested in learning more about the policies available after you turn 55, please contact us directly at hello@avibra.com.`,
    misstatementAgeDesc:
      "No insurance will be provided if your true age would render you ineligible for coverage. If your true age was not stated in the Avibra App or in the Schedule, insurance will not be provided for a period longer or an amount greater than if your true age had been stated in the Avibra App or in the Schedule. In the event of a claim, if it is determined that you were ineligible for coverage and your true age was correctly stated on the Avibra App or in the Schedule, we cannot deny or change the amount of coverage or benefit.",
    preExistingConditionsDesc:
      "If you are diagnosed with a medical condition of cancer, heart disease, stroke, or HIV in the last 10 years at time of member registration/re-activation, then your life insurance maximum death benefit will not exceed $5,000",
    claimLifeAndAdnd: "Life Insurance & AD&D Insurance",
    claimsDesc:
      `Beneficiaries will need to email benefits@avibra.com to initiate the claim process. Our customer success team will verify the deceased member's credentials for their active Core/${planName} membership before the deceased death date. On successful membership verification, the customer success team will connect the beneficiary directly to the insurance carrier to start the steps required to successfully process the claim. Please note that beneficiaries will not be eligible to receive benefits if the deceased member passed away prior to the expiration of the ${waitingPeriod} day waiting period from the date of their member registration or reactivation.`,
    claimAme: "Accident Medical",
    claimsAmeDesc:
      `To file a claim and to get the process started members will need to:`,
    claimsAmeProcess: [
      "1. Download and submit the claims form or call the claims department at 1-800-328-1114 to have a claims form sent to you. You can download the form from the Accident Medical information page in the app or at <a target='_blank' href='https://resources.avibra.com/Liberty_Mutual_Avibra_AME_Form.pdf'>https://resources.avibra.com/Liberty_Mutual_Avibra_AME_Form.pdf</a>.",
      "2. You will be asked to fill out a claim form and provide some additional personal information for verification.",
      "3. Once you receive medical bills and records forward them to our claims department to be reviewed."
    ],
    claimAmeSubDesc: `Please be ready to provide your policy number and membership ID to authenticate your membership credentials. Additionally, accident medical claims for any covered expenses, are paid at 100% of the Usual and Customary charges and will be processed only after you have paid your out-of-pocket health expenses. Please note that members will not be eligible to receive this benefit if their core or plus membership has lapsed.`,
    claimCriticalIllness: "Critical Illness",
    claimCriticalIllnessDesc:
      `To file a claim and to get the process started members will need to:`,
    claimCriticalIllnessProcess: [
      "1. Download and submit the claims form or call the claims department at 1-877-501-2467 to have a claims form sent to you. You can download the form from the Critical Illness information page in the app or at <a target='_blank' href='https://resources.avibra.com/Group_Critical_Illness_Claim_Form_Member_02042021.pdf'>https://resources.avibra.com/Group_Critical_Illness_Claim_Form_Member_02042021.pdf</a>.",
      "2. Your claim will be evaluated based on the terms and conditions of your insurance coverage. If additional information or documentation is needed, Liberty Mutual will contact you. Please review your certificate to see your specific benefits."
    ],
    claimCriticalIllnessSubDesc: `Please be ready to provide your policy number and membership ID to authenticate your membership credentials.`,
    memberCertificateDesc:
      "Your Member Certificate for Life Insurance and Accidental Death & Dismemberment (Pg 14-16) is always available for download from <a target='_blank' href='https://resources.avibra.com/Amalgamated+Member+Certificate.pdf'>https://resources.avibra.com/Amalgamated+Member+Certificate.pdf</a>",
    guaranteedCoverage: "GUARANTEED COVERAGE",
    fraudNotice: "FRAUD NOTICE",
    eligibleMembers: "ELIGIBLE MEMBERS",
    eligibleSubText: "You are eligible for life insurance if:",
    eligibleSubText2: "You are eligible for accidental death & dismemberment, accident medical and/or critical illness insurance if:",
    terminationInsurance: "TERMINATION OF INSURANCE",
    policyConversion: "POLICY CONVERSION",
    misstatementAge: "MISSTATEMENT OF AGE",
    exclusions: "EXCLUSIONS",
    settlement: "SETTLEMENT",
    preExistingConditions: "PRE-EXISTING CONDITIONS",
    claims: "CLAIMS",
    memberCertificate: "MEMBER CERTIFICATE",
    eligibleMembersDescList: [
      "1. You are registered on the Avibra App.",
      "2. You are between the ages of 18 and 54.",
      "3. You are a US citizen or a legal resident/non-resident of United States.",
      "4. You are actively residing in the United States."
    ],
    eligibleMembersDescList2: [
      "1. You are registered on the Avibra App.",
      "2. You are between the ages of 18 and 62.",
      "3. You are a US citizen or a legal resident/non-resident of United States.",
      "4. You are actively residing in the United States."
    ],
    memberShipTiers: "Membership Tiers",
    memberShipTiersDesc:
      `Your membership reflects your individual journey. That could simply start with a Core membership and a goal of learning. This includes access to Well-Being Advisor, Habit Advisor, My News, Community Well-Being and our Benefits Reward Program. For those looking to understand the everyday risks to their health and safety, there’s our ${planName} membership which offers unique premium benefits. You can check those benefits out below.`,
    thankYou: "Thank you",
    thankYouDesc:
      "Thanks for going through all of that with us, we hope you understand a bit more about all the great benefits that come with your membership! If you have any questions, you can always reach out to us at hello@avibra.com. The Avibra team is here to help you make the most out of your membership. We’d also love to hear about any features you’d be excited to see in the future on the Avibra app.",
    membershipInsuranceRewardProgram:"Insurance Reward Program",
    membershipInsuranceRewardProgramDesc:"Whenever you do something that improves your well-being, the app directly rewards you with life insurance coverage. This is real money that you can build up based on only your good habits. Actions that earn you life insurance coverage include engaging with in-app content and completing a good habit that we track though connecting to your personal data. You can continue to earn rewards until you reach the maximum reward amount for your membership tier.",  
    membershipCommunityWellBeing:"Community Well-Being",
    membershipCommunityWellBeingDesc:"Improve and protect the lives of others across the country through your good habits. When you take your responsibility to maintain a good lifestyle seriously, you have a chance to educate other members and protect their family through your good habits.",  
  }

  const titleLabel = (value) => {
    return <label className="title-label">{value}</label>
  }

  const subtitleLabel = (value) => {
    return <label>{value}</label>
  }

  const ListingContent = (content, padding = false) => {
    return (
      <div className="list-content">
        <span>
          <div
            className="description-text"
            style={padding ? { paddingLeft: 0 } : {}}
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
          <br />
        </span>
      </div>
    )
  }

  const Content = (content) => {
    return (
      <span>
        <div className="description-text">{content}</div>
        <br />
      </span>
    )
  }

  const renderText = (key, condition = () => false, fallbackvalue) => {
    let data = userInfo[key]
    let value = key
    if (typeof data == "number") value = `$${abbreviate(data, 1)}`
    else if (key == "adndcoverage") {
      value = `$${abbreviate(userInfo.plusmax - userInfo.plusmaxcap)}`
    }

    if (key == "adndmax") {
      if (condition()) return fallbackvalue
      return `$${abbreviate(adnddefaultfacevalue, 1)}`
    }

    return value == "$NaN" ? "$0" : value
  }
  const getPlusPlanHeading = (plans) => {
    return plans && plans.length>0 && plans.map((plan ,index)=>{
        const name = planMeta[plan].planName;
        const price = "";//planMeta[plan].pricing;
        return <th key={`${index}-planhead`}><span>{name}<div/><span style={{fontWeight:price.toLowerCase() == 'free' ? 'normal':'bold',fontSize:12,color: "#002c3d"}}>{price.toLowerCase()}</span></span></th>
        //return <th key={`${index}-planhead`} >{Capitalize(plan)}<div/> <span style={{fontWeight:plusplanHeader[plan].toLowerCase() == 'free' ? 'normal':'bold',fontSize:12,color: "#002c3d"}}>{plusplanHeader[plan]}</span> </th>
    })
  }
  //console.log("beneficiary ===>", beneficiary)
  let beneficiaryPrimaryArr = []; // beneficiary table data
  let beneficiaryContingentArr = [];
  if(beneficiary.length){
    beneficiaryPrimaryArr = beneficiary.filter((e)=>e.beneficiaryType == 'primary');
    beneficiaryContingentArr = beneficiary.filter((e)=>e.beneficiaryType == 'contingent');
    if(!beneficiaryContingentArr.length){
      beneficiaryPrimaryArr = beneficiary;
    }
  }
  console.log("beneficiary primary", beneficiaryPrimaryArr)
  console.log("beneficiary conti", beneficiaryContingentArr)
  return (
    <div className="alert-content-container">
      <Row className="margin-none reverse">
        <Col lg="8" md="12" className="padding-none">
          <section id="print-pdf">
            <section id="membership2"></section>
            <div className="membership-intro">
              <div className="Details-box">
                <div className="description-text">
                  {data.avibraMembershipGuideDesc}
                </div>
              </div>
            </div>
            <div className="description list-content">
              <label>{data.purposeOfAvibra.title}</label>
            </div>
            <div className="description-text">
              <p>{data.purposeOfAvibra.wellBeing}</p>
            </div>
            <div className="description-text">
              <p>{data.purposeOfAvibra.habit}</p>
            </div>
            <div className="description-text">
              <p>{data.purposeOfAvibra.share}</p>
            </div>
            <div className="description-text">
              <p>{data.purposeOfAvibra.myWell}</p>
            </div>
            <div className="description-text">
              <p>{data.purposeOfAvibra.myRisk}</p>
            </div>
            <div className="description-text">
              <p>{data.purposeOfAvibra.insurance}</p>
            </div>
            <section>
              <div className={`content-left-header`}>
                <legend>{data.memberShipTiers}</legend>
              </div>
              <div className="Details-box">
                <div className="description">
                  {Content(data.memberShipTiersDesc)}
                </div>
              </div>
            </section>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th></th>
                  { userPlans && getPlusPlanHeading(userPlans)} 
                </tr>
              </thead>
              <tbody>
              {plusPlanDetails.map((data, index) => {
                  
                  return (
                    <tr key={index} id="page2el">
                    <th>{data.displayName}</th>
                    {
                        userPlans && userPlans.map((plan,i)=>{
                           return(
                            <th key={`${i}-${index}planmap`} >
                                {data[plan] =="Y" || data[plan] =="N" ?
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html:
                                        data[plan] == "Y" ? checkedIcon : removeIcon,
                                      }}
                                    ></span>
                                    :
                                    renderText(data[plan], true)
                                }
                            </th>
                           ) 
                        })
                    }
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            <section>
              <div className={`content-left-header`}>
                <legend>{data.memberShipInsurance}</legend>
              </div>
              <div className="Details-box">
                <div className="description">
                  {Content(data.memberShipInsuranceDesc)}
                  {Content(data.memberShipInsuranceSubDesc)}
                  {Content(data.memberShipInsuranceSubSubDesc)}
                  <div className="text-center margin-bottom-20">
                    {/* <img src={rewardImg} /> */}
                  </div>
                  {titleLabel(data.scheduleBenefits)}
                  {Content(data.beneficiaryInfo)}
                </div>
              </div>
            </section>
            <div>
            <legend>Primary</legend>
            <Table bordered responsive>
              <thead>
                <tr>
                  {data.beneficiaryTheadList.map((item, index) => (
                    <th key={index}>{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {beneficiaryPrimaryArr.length ? (
                  beneficiaryPrimaryArr.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.name}</td>
                        <td>{data.dob}</td>
                        <td>{data.ssn}</td>
                        <td>{data.allocation}%</td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                )}
              </tbody>
            </Table>
            </div>
            {beneficiaryContingentArr.length ? 
            <div>
            <legend>Contingent</legend>
            <Table bordered responsive>
              <thead>
                <tr>
                  {data.beneficiaryTheadList.map((item, index) => (
                    <th key={index}>{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {beneficiaryContingentArr.length ? (
                  beneficiaryContingentArr.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.name}</td>
                        <td>{data.dob}</td>
                        <td>{data.ssn}</td>
                        <td>{data.allocation}%</td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                )}
              </tbody>
            </Table>
            </div> : null }
            <section>
              <div className="Details-box">
                <div className="description">
                  {/* {Content(data.insuranceCoverage)} */}
                  {/* <Table bordered responsive className="margin-bottom-20">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{data.insuranceTheadLabel1}</th>
                        <th>{data.insuranceTheadLabel2}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {newInsurance && newInsurance.map((ic, index) => {
                        return (
                          <tr key={index}>
                            <th>{ic.productname}</th>
                            <td>
                              {ic.max ? `$${abbreviate(ic.max, 1)}` : 'Not Available'}
                            </td>
                            <td>{`$${abbreviate(ic.plusmax, 1)}`}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table> */}
                  {/* <p>
                    <b>{insuranceDisclaimer}</b>
                  </p> */}
                  <div style={{ paddingBottom: 20, paddingTop: 20 }}>
                    {subtitleLabel(data.guaranteedCoverage)}
                  </div>
                  {Content(data.guaranteedCoverageDesc)}
                  {subtitleLabel(data.fraudNotice)}
                  {Content(data.fraudNoticeDesc)}
                  {subtitleLabel(data.eligibleMembers)}
                  <div className="margin-top-10">
                    <span>{data.eligibleSubText}</span>
                  </div>
                  <div className="list-content-membership">
                    {data.eligibleMembersDescList.map((text, index) => (
                      <p key={index}>{text}</p>
                    ))}
                  </div>
                  <div className="margin-top-10">
                    <span>{data.eligibleSubText2}</span>
                  </div>
                  <div className="list-content-membership">
                    {data.eligibleMembersDescList2.map((text, index) => (
                      <p key={index}>{text}</p>
                    ))}
                  </div>
                  {Content(data.memberShipInsuranceNote)}
                  {subtitleLabel(data.terminationInsurance)}
                  {Content(data.terminationInsuranceDesc)}
                  <div>
                    {data.terminationList.map((data, index) => (
                      <div key={index} className="margin-bottom-20">
                        {data}
                      </div>
                    ))}
                  </div>
                  {/* {subtitleLabel(data.policyConversion)}
                  {Content(data.policyConversionDesc)} */}
                  {subtitleLabel(data.misstatementAge)}
                  {Content(data.misstatementAgeDesc)}
                  <div>
                    {/* {subtitleLabel(data.exclusions)}
                    <div>
                      {data.exclusionsList.map((data, index) => (
                        <div key={index} className="margin-bottom-20">
                          {data}
                        </div>
                      ))}
                    </div> */}
                    {subtitleLabel(data.settlement)}
                    {
                      <div>
                        {data.settlementList.map((data, index) => (
                          <div key={index} className="margin-bottom-20">
                            {data}
                          </div>
                        ))}
                      </div>
                    }
                  </div>
                  {/* {subtitleLabel(data.preExistingConditions)} */}
                  {/* {Content(data.preExistingConditionsDesc)} */}
                  {subtitleLabel(data.claims)}
                  {Content(data.claimLifeAndAdnd)}
                  {Content(data.claimsDesc)}
                  {Content(data.claimAme)}
                  {Content(data.claimsAmeDesc)}
                  {
                      <div>
                        {data.claimsAmeProcess.map((data, index) => (
                          <div key={index} className="margin-bottom-20">
                            <div dangerouslySetInnerHTML={{__html: data }} />
                          </div>
                        ))}
                      </div>
                    }
                  {Content(data.claimAmeSubDesc)} 
                  {Content(data.claimCriticalIllness)}
                  {Content(data.claimCriticalIllnessDesc)}
                  {
                    <div>
                      {data.claimCriticalIllnessProcess.map((data, index) => (
                        <div key={index} className="margin-bottom-20">
                          <div dangerouslySetInnerHTML={{__html: data }} />
                        </div>
                      ))}
                    </div>
                  }
                  {Content(data.claimCriticalIllnessSubDesc)}
                  {subtitleLabel(data.memberCertificate)}
                  {/*Content(data.memberCertificateDesc)*/}
                  <div className="description-text" dangerouslySetInnerHTML={{__html: data.memberCertificateDesc }}/>
                  <br/>
                </div>
              </div>
            </section>
            <section>
              <div className={`content-left-header`}>
                <legend>{data.avibraMembershipGuide}</legend>
              </div>
              <div className="Details-box">
                <div className="description">
                  {titleLabel(data.memeberShipEligiblityAndConditions)}
                  {Content(data.memeberShipEligiblityAndConditionsDesc)}
                  <div className="list-content-membership">
                      {data.memeberShipEligiblityAndConditionsList.map((text, index) => (
                        <p key={index}>{text}</p>
                      ))}
                    </div>
                </div>
              </div>  
              <div className="Details-box">
                <div className="description">
                  {titleLabel(data.wellBeingAdvisor)}
                  {Content(data.wellBeingAdvisorDesc)}
                </div>
              </div>
            </section>
            <section>
              <section id="membership3">
                <div className="Details-box">
                  <div className="description">
                    {Content(data.wellBeingAdvisorSubDesc)}
                    <div className="list-content-membership">
                      {data.wellBeingAdvisorList.map((text, index) => (
                        <p key={index}>{text}</p>
                      ))}
                    </div>
                    {Content(data.wellBeingAdvisorFootDesc)}
                  </div>
                </div>
              </section>
              <div className="Details-box">
                <div className="description">
                  {titleLabel(data.habitAnalyzer)}
                  {Content(data.habitAnalyzerDesc)}
                  {Content(data.habitAnalyzerSubDesc)}
                  <div className="list-content-membership">
                    {data.habitAnalyzerList.map((text, index) => (
                      <p key={index}>{text}</p>
                    ))}
                  </div>
                </div>
              </div>
              <div className="Details-box">
                <div className="description">
                  {titleLabel(data.shareCare)}
                  {Content(data.shareCareDesc)}
                  <div className="share-care-main">
                    <div
                      className="share-care-option share-care"
                      id="share-and-care"
                    >
                      <div className="share-item">
                        {data.shareCareListLeft.map((data, index) => {
                          return <div key={index}>● {data}</div>
                        })}
                      </div>
                      <div className="share-item content-left">
                        {data.shareCareListRight.map((data, index) => {
                          return <div key={index}>● {data}</div>
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="margin-top-20">
                    {titleLabel(data.myWellBeing)}
                  </div>
                  {Content(data.myWellBeingDesc)}
                  {Content(data.myWellBeingSubDesc)}
                  <div className="share-care-main margin-bottom-20">
                    <div className="share-care-option" id="well-being-chart">
                      <div
                        style={
                          wellbeing && wellbeing.categoryscores && wellbeing.categoryscores.graph &&
                          wellbeing.categoryscores.graph.data && wellbeing.categoryscores.graph.data.Financial
                            ? {
                                width: "auto",
                              }
                            : null
                        }
                        className="left-data"
                      >
                        {data.myWellBeingList.map((data, index) => {
                          return <div key={index}>● {data}</div>
                        })}
                      </div>
                      {wellbeing && wellbeing.categoryscores && wellbeing.categoryscores.graph && 
                      wellbeing.categoryscores.graph.data && wellbeing.categoryscores.graph.data.Financial ? (
                        <div className="well-being-chart">
                          <PieChartWithPaddingAngle
                            data={updateMyWellBeing}
                            colors={data.myWellBeingColors}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {Content(data.myWellBeingRiskScore)}
                  <div className="share-care-main">
                    <div className="share-care-option" id="my-risk-chart">
                      <div
                        className="left-data"
                        style={
                          wellbeing && wellbeing.categoryscores && wellbeing.categoryscores.graph &&  
                          wellbeing.categoryscores.graph.data && wellbeing.categoryscores.graph.data.Financial
                            ? {
                                width: "auto",
                              }
                            : null
                        }
                      >
                        {data.myWellBeingRiskScoreList.map((data, index) => {
                          return <div key={index}>● {data}</div>
                        })}
                      </div>
                      {wellbeing && wellbeing.categoryscores && wellbeing.categoryscores.graph &&  
                      wellbeing.categoryscores.graph.data && wellbeing.categoryscores.graph.data.Financial ? (
                        <div className="my-risk-chart">
                          <PieChartWithPaddingAngle
                            data={data.pieData}
                            colors={data.riskColors}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="margin-top-20">
                    {titleLabel(data.myWellBeing360)}
                  </div>
                  {Content(data.myWellBeing360Desc)}
                  {Content(data.myWellBeing360DescSubDesc)}
                  <div className="list-content-membership">
                    {data.myWellBeing360DescSubDescList.map((text, index) => (
                      <p key={index}>{text}</p>
                    ))}
                  </div>
                  {Content(data.myWellBeing360DescSubDesc2)}
                  <div className="list-content-membership">
                    {data.myWellBeing360DescSubDesc2List.map((text, index) => (
                      <p key={index}>{text}</p>
                    ))}
                  </div>
                  <div className="Details-box">
                    <div className="description">
                      {titleLabel(data.membershipInsuranceRewardProgram)}
                      {Content(data.membershipInsuranceRewardProgramDesc)}
                    </div>
                  </div>  
                  <div className="Details-box">
                    <div className="description">
                      {titleLabel(data.membershipCommunityWellBeing)}
                      {Content(data.membershipCommunityWellBeingDesc)}
                    </div>
                  </div>  
                  {titleLabel(data.thankYou)}
                  {Content(data.thankYouDesc)}
                </div>
              </div>
            </section>
          </section>
        </Col>
        <Col lg="4" md="12" className="padding-none">
          <div id="main-side">
            <section id="membership1">
              <div id="logo-print" className="margin-bottom-20">
                <div className="text-center margin-bottom-10">
                  <img
                    src={logoPng}
                    height="135px"
                  />
                </div>
                <div className="text-center">
                  <span>
                    <b>{data.memberShipTitle}</b>
                  </span>
                </div>
                <div className="text-center">
                  <span>
                    <b>{data.avibraUrl}</b>
                  </span>
                </div>
              </div>
              <div className="text-center margin-bottom-20">
                <span>
                  <b>{data.memberShipCard}</b>
                </span>
              </div>
              <div className="alert-right-content mobile" id="membership-info">
                {rightData.map((res, index) => {
                  return (
                    <div key={index} className="content-text">
                      <span className="right-label text-left">
                        {res.title + " "}
                      </span>
                      <span className="right-label-text float-right">
                        {" "}
                        {res.desc}
                      </span>
                    </div>
                  )
                })}
              </div>
            </section>
          </div>
        </Col>
        <div className="policy-document" onClick={onClickSave}>
          <span>
            <i className="fas fa-download"></i>
          </span>
        </div>
      </Row>
    </div>
  )
}

export default MembershipGuide
