import React from 'react'
import checkmark from "../../../../../images/check.svg"
import "./VerticalSupport.css"

const VerticalSupport = (props) => {
  const { content = {} } = props
  return (
    <div className='container'>
      <div className='vertical-support-content'>
        <div className='vertical-support-title'>{content.heading}</div>
        <div className='vertical-support-items'>
          {content.data?.length &&
            content.data.map((item) => <div className='vertical-support-item-wrp'>
              <img src={checkmark} />
              <div className='vertical-support-item'>{item}</div>
            </div>)}
        </div>
      </div>
      </div>
  )
}

export default VerticalSupport

