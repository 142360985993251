import React from 'react'
import "./CuriousHow.css"
import { Container, Row, Col } from 'reactstrap'
import { useNavigate } from 'react-router'

const CuriousHow = ({ content = {} }) => {

  let { title = '', ctaText = '', ctaLink = '', imageWeb = '', imageTablet = '', imageMobile = '' } = content
  const navigate = useNavigate();


  return (
    <Container className='curious-how-container'>
      <Row className='justify-content-center'>
        <Col >
          <div className='curious-how-content'>
            <div className='curious-how-content-inner'>
              <h3 className='curious-how-title'>{title}</h3>
              <div className='curious-how-btn' onClick={()=>navigate({pathname:ctaLink})}>{ctaText}</div>
            </div>

            <div className='curious-how-img curious-how-img-web'>
              <img src={imageWeb} className='img-fluid' />
            </div>
            <div className='curious-how-img curious-how-img-tablet'>
              <img src={imageTablet ? imageTablet : imageWeb} className='img-fluid' />
            </div><div className='curious-how-img curious-how-img-mobile'>
              <img src={imageMobile ? imageMobile : imageWeb} className='img-fluid' />
            </div>
          </div>
        </Col>
      </Row>

    </Container>
  )
}

export default CuriousHow