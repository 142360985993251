import axios from "axios"
//import Config from "Config"
import AWS from "aws-sdk"
import aws4 from "aws4-react-native"
import { API_URL, AppConfig, FEPARAM_KEYS } from "../../constant/constant"
import { setLogEvent } from "../utils/helpers";
import { apiPost, apiGet } from "../utils/api"
import { GetEnvVar } from "../utils/AppConfig"
import {Buffer} from 'buffer';
window.Buffer = window.Buffer || Buffer;

let s3_api_url = "https://lifexartifactbucket.s3.amazonaws.com/test/membership.json"

if (GetEnvVar('NODE_ENV')  === "development") {
  s3_api_url = "https://lifexartifactbucket.s3.amazonaws.com/test/membership.json"
}

let credentials = {}
let sessionToken = ""
var CancelToken = axios.CancelToken;
var source = CancelToken.source();
let failedQueue = []

export const errorHandler = error => {
  setLogEvent("Partner API error", {
    apiUrl: error.config?.url || '',
    requestObject: error.config?.data || {},
    error: error.toString()
  })
  return Promise.reject(error)
}

AWS.config.region = GetEnvVar('REGION')

const getProvider = (signuptype) => {
  switch (signuptype) {
    case "1":
      return 'accounts.google.com';
    case "2":
      return 'graph.facebook.com';
    case "3":
      return GetEnvVar('PROVIDER_NAME');
    case "5":
      return 'appleid.apple.com';
    default:
      return GetEnvVar('PROVIDER_NAME')
  }
}

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (!error) {
      prom.resolve(prom.originalRequest)
    }
  })

  failedQueue = []
}

// const axiosWithInterceptor = axios.create()

axios.interceptors.request.use(
    async config => {
    let originalRequest = config
    if(window.isAwsSignatureSet){
      let awsSignature = await getAWSSignature(originalRequest)
      config.headers["X-Amz-Security-Token"] =  awsSignature.headers["X-Amz-Security-Token"],
      config.headers["X-Amz-Date"] =  awsSignature.headers["X-Amz-Date"],
      config.headers["Authorization"] = awsSignature.headers["Authorization"] 
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

axios.interceptors.response.use(
  response => response,
  errorHandler
)

// const axiosInterceptorWithAmplitude = axios.create()

// axiosInterceptorWithAmplitude.interceptors.response.use(
//   response => response,
//   errorHandler
// )

const setAccessToken = awsCredentials => {
  sessionToken = awsCredentials.sessionToken
  credentials = {
    SecretKey: awsCredentials.secretAccessKey,
    AccessKeyId: awsCredentials.accessKeyId
  }
}

export const getUrlInfo = (href) => {
  var match = href.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
  return match && {
      href: href,
      protocol: match[1],
      host: match[2],
      hostname: match[3],
      port: match[4],
      pathname: match[5],
      search: match[6],
      hash: match[7]
  }
}

const getAWSSignature = config => {
  
  let awsHeader = {};
  if (config.url.indexOf('http') == -1) {
    
    const urlPath =
    config.url.charAt(0) === '/' ? config.url : `/${config.url}`;
    const fullURL = `${config.baseURL}${urlPath.substring(1)}`;
    const urlInfo = getUrlInfo(fullURL);
    const urlMethod = config.method.toUpperCase();
    const options = {
      host : urlInfo.host,
      path: `${urlInfo.pathname}${urlInfo.search}`,
      service: 'execute-api',
      method: urlMethod,
    };
    console.log("getAWSSignature options ",options, config.url)
    if (urlMethod !== 'GET') {
      options.body = config.data ? JSON.stringify(config.data) : '{}';
      // options['content-length'] = options.body.length;
      options.headers = {
        'Content-Type': 'application/json;charset=UTF-8',
      };
    }

    awsHeader = aws4.sign(options, {
      accessKeyId: credentials.AccessKeyId,
      secretAccessKey: credentials.SecretKey,
      sessionToken,
    });

  } else {
    awsHeader = {
      headers: config.headers,
    };
  }
  return awsHeader;
};

export const initializeAWSToken = (token, signuptype) => {
  let Logins = {}
  Logins[getProvider(signuptype)] = token

  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: GetEnvVar('POOL_IDENTITY'),
    Logins
  })

  return new Promise(
    (resolve, reject) => {
      AWS.config.credentials.get(res => {
        setAccessToken(AWS.config.credentials)
        resolve()
      })
    }
  )
}

export const getPolicyDetailsApi = profileid => {
  return axios.get(`/policyadmin/policy/document/${profileid}`)
 }
export const getPolicyDetailsApiWithToken = (profileid, headers) => axios.get(`/policyadmin/policy/documentwithauth/${profileid}`);
// {
//   return apiGet(`${API_URL}/policyadmin/policy/documentwithauth/${profileid}`,'',headers)
//   //return axiosWithInterceptor.get(`${API_URL}/policyadmin/policy/document/${profileid}`)
// }

export const getInsurancePageApi = () =>
axios.get(
    `${AppConfig.insurancepage2link}?timestamp=${new Date().getTime()}`
  )

export const getBeneficiaryApi = data =>
  axios.post(`/profiles/external/beneficiaries`, data)

export const getUserProfile = (profileId) => {
  return axios.get(`/profiles/external/${profileId}`)
}
export const getUserProfileDirect = (profileId,headers) => axios.get(`/profiles/${profileId}`)
// {
//  return apiGet(`${API_URL}/profiles/${profileId}`,'',headers);
//  //return axiosWithInterceptor.get(`${API_URL}/profiles/external/${profileId}`)
//}

export const getAnalyticScoreApi = (profileid) => axios.get(`/analytic/scores/${profileid}`)

export const getFeparamsApi = () => axios.get(`/feparams`)

export const getFeparamsFilteredApi = () => axios.post(`/feparams/filtered`,FEPARAM_KEYS)

export const getValidateParamsApi = (url) => axios.get(`${url}/feparams`)

export const getParamSelectorApi = () => axios.get(`https://lifexartifactbucket.s3.amazonaws.com/EnvSelector.json`)

export const getAccidentalInsurance = payload =>
axios.post(`/policyadmin/external/policy`, payload)

export const getMembershipDetailsApi = () => axios.get(s3_api_url)

export const getExternalProfileExist = payload => 
axios.post(`/profiles/external/exists`, payload)

export const getMarketPlaceInboxApi  = data =>
axios.post(`/marketplace/productTypes`, data)

export const getInsuranceProductDetailsApi = (policyid) => {
  return axios.get(`/marketplace/policy/${policyid}`)
}

export const getNewsDetailApi = (url, data) => {
  return axios.post(`/${url}`, data)
} 

export const getAfficiencyFormsApi = (data) => {
  return axios.post(`/afficiency/getForms`, data)
} 

export const getAsyncAfficiencyFormsApi = (data) => {
  return axios.post(`/lifeinsurance/getForms`, data)
} 

export const getFeFormsApi = (profileid) => {
  return axios.get(`/felifeinsurance/forms/${profileid}`)
} 

export const getSubscriptionMatrixApi = (profileid) => {
  return axios.get(`/profiles/plan/subscriptionmatrix/${profileid}`)
}

export const getDobsPurchases = (profileid) => {
  return axios.get(`/profiles/dollaraddonspurchased/${profileid}`)
} 

export const getAddons = (profileid,basePlanName='dollar',subscribedPlanLevel='',flowType='')=> {
      let data = {"profileId": profileid,"plan":basePlanName,"flowType":flowType};
      return axios.post(`/profiles/plan/addons`,data)
}

export const getDobsDrillDownDetail = (requestUrl) => {
  return axios.get(`${requestUrl}`)
} 

export const getPdfProductProviders = (profileid) => {
  return axios.get(`/profiles/productproviders/${profileid}`)
} 

export const GetDollarClubCardDetails = (profileid) => {
  return axios.get(`/profiles/dollarClubDetails/${profileid}`)
} 
export const getProfileIdFromExternalId = (data, headers={}) =>  axios.post(`/profiles/getProfileByExternalId`, data);

export const getTokenFromLinkToken = (data, headers={}) =>  axios.post(`/partner/user/validate/linktoken`, data);