import axios from 'axios'
import { SET_PARTNER_DATA, SET_PARTNER_ACCESS_TOKEN ,SET_PARTNER_MARKETPLACE_INBOX, SET_PARTNER_MARKETPLACE_DETAILS_DATA, UNAUTHORIZED_PARTNER, MARKETPLACE_SHOW_LOADER, MARKETPLACE_HIDE_LOADER, UPDATE_PARTNERDATA_INFO } from "../types";
import { postPartner, getMarketPlacePartnerApi,getMarketPlacePartnerPolicyDetailsApi} from '../api/partnerApi';

export const UpdatePartnerDataInfo = ({ prop, value }) => {
    return {
        type: UPDATE_PARTNERDATA_INFO,
        payload: { prop, value }
    }
}

export function partnerDataAction (data) {
    return {
        type: SET_PARTNER_DATA,
        payload: data
    }
}
export const setPartnerAccessToken = (data) =>{
    //console.log("setPartnerAccessToken Action called",data );
    return (dispatch) => { 
        postPartner('/user/accesstoken',data).then(response=>{
            if(response.access_token)
                dispatch({type:SET_PARTNER_ACCESS_TOKEN, payload: {'Authorization': 'Bearer '+response.access_token } });
            else
                dispatch({ type: UNAUTHORIZED_PARTNER, payload: true });
          }).catch(err=>{
            //console.log("setPartnerAccessToken Error" ,err);
          })       
    }
}

export const getPartnerMarketPlaceList = (data,headers) =>{
    //console.log("getPartnerMarketPlaceList Action called",data ,"headers", headers);
    return (dispatch) => { 
        dispatch({ type: MARKETPLACE_SHOW_LOADER })
        getMarketPlacePartnerApi(data,headers)
            .then(response => {
            dispatch(
                { type: SET_PARTNER_MARKETPLACE_INBOX, payload: response.inboxitems }
                );
            dispatch({ type: SET_PARTNER_MARKETPLACE_DETAILS_DATA, payload: {} });
            dispatch({ type: MARKETPLACE_HIDE_LOADER });
            })
            .catch(err => {
            dispatch({ type: MARKETPLACE_HIDE_LOADER });
            //console.log("getMarketPlacePartnerApi Error",err);
            })
    }
}
export const getPartnerMarketProductDetails = (policyid, AccessToken, afterFatch) =>{
    return (dispatch) => {
        afterFatch ? dispatch({ type: MARKETPLACE_SHOW_LOADER }) : null;
        getMarketPlacePartnerPolicyDetailsApi(policyid, AccessToken)
        .then(res => {
            dispatch({ type: MARKETPLACE_HIDE_LOADER });
            dispatch({ type: SET_PARTNER_MARKETPLACE_DETAILS_DATA, payload: res });
        })
        .catch(err => {
            dispatch({ type: MARKETPLACE_HIDE_LOADER });
            //console.log("getMarketPlacePartnerPolicyDetailsApi Error",err);
        })
    }

}

export function setUnauthorizedPartner(data) {
    return {
        type: UNAUTHORIZED_PARTNER,
        payload: data
    }
}

export const setMainCardDataList = (data) => {
    return async (dispatch)=>{
        dispatch(UpdatePartnerDataInfo({ prop:'mainCardArrayList', value: data }))
    }
}