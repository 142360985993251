import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
    Container,
    Row,
    Col,
    Spinner
} from 'reactstrap';
import Header from './../../../components/NewDesignV2/Common/Header/Header';
import Footer from './../../../components/NewDesignV2/Common/Footer/Footer';
import SeoMetaTags from '../../../components/NewDesignV2/Common/SeoMetaTags/SeoMetaTags';
import NewDesignV2LandingPageData from "./../../../../constant/NewDesignV2LandingPageData.json";
import NewDesignFinancial from "./../../../../constant/NewDesignV2Financial.json"
import './../../../../styles/NewDesignV2/main-page.css'
import './../../../../styles/NewDesignV2/main-page-responsive.css';

import { getFeparamsFilteredApi } from '../../../api/policyDocumentApi';
import { feparamsAction } from '../../../actions/feparamsAction';
import { setLogEvent, setAmplitudeInit } from '../../../utils/helpers';
import VerticalSupport from '../../../components/NewDesignV2/Common/VerticalSupport/VerticalSupport';
import FinancialServices from '../../../components/NewDesignV2/Common/FinancialServices/FinancialServices';
import UseCases from '../../../components/NewDesignV2/Common/UseCases/UseCases';
import PartnerVoices from '../AvibraCare/PartnerVoices/PartnerVoices';
import NewPageHeaderSection from '../../../components/NewDesignV2/Common/NewPageHeaderSection/NewPageHeaderSection';

const Financial = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [stickyHeaderClass, setStickyHeaderClass] = useState('row topbar');
    const [loader, setLoader] = useState(false)
    let {appConfig={}}=props
    let partnerVoicesContent = appConfig.financialTestimonial 
    ? JSON.parse(unescape(appConfig.financialTestimonial ))
    : {};

    const toggleSticky = useCallback(
        () => {

            const scrollY = window.scrollY ? window.scrollY : document.body.scrollTop
            const scrollHeight = (window.innerWidth <= 768) ? 10 : 100
            scrollY >= scrollHeight ? 
                setStickyHeaderClass(`row topbar fixed-header ${(window.innerWidth <= 768) ? '' : 'animated fadeInDown'}`)
                :
                setStickyHeaderClass("row topbar")
        },
        []
    );
    useEffect(()=>{
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
		setLoader(true)
		setTimeout(() => {
		  setLoader(false)	
		}, 300)
	},[])
    useEffect(() => {
        document.getElementById("main-wrapper").focus();
        //getFeparamsFilteredApi
        const {appConfig={}}=props
        if(!Object.keys(appConfig).length) {
        getFeparamsFilteredApi().then(res => {
            const { data = {} } = res;
            props.feparamsAction(data);
            let { amplitudeKey = "" } = data;
            setAmplitudeInit(amplitudeKey);
            setLogEvent("Social Impact Platform Page loaded", {
                currentUrl: window.location.href,
            })
        })
            .catch(err => {
                console.log(err);
            });
        }else setLogEvent("Social Impact Platform Page loaded", {
            currentUrl: window.location.href,
        })    
        const handleScroll = () => {
            toggleSticky();
        };

        if (window.addEventListener) {
            window.addEventListener("scroll", handleScroll, { capture: true });
        } else {
            window.attachEvent("onscroll", handleScroll);
        }

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

    }, [])

    const toggle = () => setIsOpen(!isOpen);

    const { mainSection,footerSection, seoMetaData={} } = NewDesignV2LandingPageData
    const { financialHeaderSection={},verticalSupportData={},financialServicesContent={},useCasesContent={} }=NewDesignFinancial
    const { download_href } = mainSection.data.headerSection;
    const { mainPageAppDownLoadLink = download_href } = props.appConfig;


    return (<>{loader?<div style={{ textAlign: 'center', marginTop: '20px'}}> 
	   <Spinner style={{ width: '2rem', height: '2rem',color:'#787878',marginBottom:'25px' }} /></div>:
        <Container fluid className="main__container gig-platforms financial-container" id="main-wrapper">
            <SeoMetaTags seoData={seoMetaData.financialServicesPage} />
			<Row className={`topbar ${stickyHeaderClass}`}>
                <Col className="p-0">
                    <Header toggle={toggle} isOpen={isOpen} content={mainSection.data.headerSection} downloadLink={mainPageAppDownLoadLink} />
                </Col>
            </Row>
            <Row>
                <Col className="p-0 financial-page-header-container">
                    <NewPageHeaderSection content={financialHeaderSection} appLinkUrl={'/book-a-demo'} target="" extraClassName2={'financial-header'} />
                </Col>
            </Row>
            <Row>
                <Col className="p-0">
                    <VerticalSupport content={verticalSupportData} />
                </Col>
            </Row>
            <Row>
                <Col className="p-0">
                    <FinancialServices content={financialServicesContent} />
                </Col>
            </Row>
            <Row>
                <Col className="p-0 use-cases-container-outer ">
                    <UseCases content={useCasesContent} />
                </Col>
            </Row>
           {partnerVoicesContent?.data?.length>0 && <Row>
                <Col className="p-0 partner-voices-container-area">
                    <PartnerVoices content={partnerVoicesContent}/>
                </Col>
            </Row>}
            <Row>
                <Col className="p-0">
                    <Footer content={footerSection} />
                </Col>
            </Row>
        </Container>}</>

    );
}
const mapStateToProps = state => {
    const { appConfig } = state.feparamsReducer;
    return { appConfig };
}
export default connect(mapStateToProps, { feparamsAction })(Financial);
