import React, { useState, useEffect, useCallback } from 'react'
import {
	Container,
	Row,
	Col,
	Spinner
} from 'reactstrap';
import { connect } from 'react-redux';
import { setLogEvent,setAmplitudeInit } from '../../../utils/helpers';

import Header from '../../../components/NewDesignV2/Common/Header/Header';
import HomeHeaderSectionNew from '../../../components/NewDesignV2/HomeHeaderSectionNew/HomeHeaderSectionNew';
import CirclularGridView from '../../../components/NewDesign/Common/CirclularGridView/CirclularGridView';
import Footer from '../../../components/NewDesignV2/Common/Footer/Footer';
import FullWidthBannerImage from '../../../components/NewDesignV2/Common/FullWidthBannerImage/FullWidthBannerImage';
import LovedBy from '../../../components/NewDesignV2/Common/LovedBy/LovedBy';
import NewDesignV2LandingPageData from "../../../../constant/NewDesignV2LandingPageData.json";
import '../../../../styles/NewDesignV2/main-page.css'
import '../../../../styles/NewDesignV2/main-page-responsive.css';

import { getFeparamsFilteredApi } from '../../../api/policyDocumentApi';
import { feparamsAction } from '../../../actions/feparamsAction';
import SeoMetaTags from '../../../components/NewDesign/Common/SeoMetaTags/SeoMetaTags';
import PowersWorld from '../PowersWorld/PowersWorld';
import GrowthThrough from '../GrowthThrough/GrowthThrough';
import AvibraProvides from '../AvibraProvides/AvibraProvides';
import EnhancesGrowth from '../EnhancesGrowth/EnhancesGrowth';
import FastImpact from '../FastImpact/FastImpact';
import SuperCharging from '../SuperCharging/SuperCharging';
import EasyLaunch from '../EasyLaunch/EasyLaunch';
import SocialImpact from '../SocialImpact/SocialImpact';
import CustomerVoices from '../AvibraCare/CustomerVoices/CustomerVoices';

import RetentionDrives from '../RetentionDrives/RetentionDrives';
import WhoWeServe from '../WhoWeServe/WhoWeServe';
import PageHeaderSectionV3 from '../../../components/NewDesignV2/PageHeaderSectionV3/PageHeaderSectionV3';
import VideoContent from '../VideoContent/VideoContent';

const NewMainLandingPage = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [loader, setLoader] = useState(false)
    const [stickyHeaderClass, setStickyHeaderClass] = useState('row topbar');
    const toggleSticky = useCallback(
        () => {

		const scrollY = window.scrollY ? window.scrollY : document.body.scrollTop
		const scrollHeight = (window.innerWidth <= 768) ? 1 : 100
		scrollY >= scrollHeight ? 
        setStickyHeaderClass(`row topbar fixed-header ${(window.innerWidth <= 768) ? '' : 'animated fadeInDown'}`) 
        : 
        setStickyHeaderClass("row topbar")
        },
        []
    );

	useEffect(()=>{
		window.scrollTo(0, 0);
		setLoader(true)
		setTimeout(() => {
		  setLoader(false)	
		}, 50)
	},[])
	useEffect(() => {
		//getFeparamsFilteredApi
		const {appConfig={}}=props
		if(!Object.keys(appConfig).length) {
		getFeparamsFilteredApi().then(res=>{
				const {data = {}} = res;
				props.feparamsAction(data);
				let { amplitudeKey = "" } = data;
				setAmplitudeInit(amplitudeKey);
				setLogEvent("Main landing Page loaded", {
					currentUrl: window.location.href,
				})
		})
		.catch(err=>{
			console.log(err);
		});
	  }else setLogEvent("Main landing Page loaded", {
		currentUrl: window.location.href,
	  })
        const handleScroll = () => {
            toggleSticky();
          };

		  if(window.addEventListener){
			window.addEventListener("scroll", handleScroll, { capture: true });
		  }else{
			window.attachEvent("onscroll", handleScroll);
		  }

          return () => {
            window.removeEventListener("scroll", handleScroll);
          };
	}, [])

	const toggle = () => setIsOpen(!isOpen);
    const {mainSection,mainHeaderSection={},mainHeaderSectionNew={}, membershipPlansNew, footerSection, ourStories={}, builtForAllIndustries={},commonQuestions={},seoMetaData={},avibraProvides={},DollarBenefitsStore={},hourlyWorkersContent={},powersWorldContent={},retentionDrivesSuccess={},avibraProvidesContent={},lovedBy={},homePageBannerImageData={},enhancesGrowthContent={},fastImpactContent={},superChargingContent={},easyLaunchContent={},growthThroughCare={},whoWeServe={},homeNewHeaderSection={},VideoContentData={}} = NewDesignV2LandingPageData
    const { download_href } = mainSection.data.headerSection;
	
	const {mainPageAppDownLoadLink = download_href} = props.appConfig;
	return (<> {loader?<div style={{ textAlign: 'center', marginTop: '20px'}}> <Spinner style={{ width: '2rem', height: '2rem',color:'#787878',marginBottom:'25px' }} /></div>:<Container fluid className="main__container " id="main-wrapper">
	<SeoMetaTags seoData={seoMetaData.homePage} />
	<Row className={`header-menu-topbar-v2 light-purple-navbar topbar ${stickyHeaderClass}`}>
		<Col className="p-0">
			<Header toggle={toggle} isOpen={isOpen} content={mainSection.data.headerSection} downloadLink={mainPageAppDownLoadLink} extraClassName="light-purple-navbar"/>
		</Col> 
	</Row>
	<Row>
		<Col className="section-area p-0 home-page-header-section-v3 light-purple-bg">
			<PageHeaderSectionV3 content={homeNewHeaderSection} appLinkUrl={'/book-a-demo'} target="" />
		</Col>
	</Row> 
	<Row>
		<Col className="p-0 who-we-serve-container-outer">
			<WhoWeServe content={whoWeServe}/>
		</Col>
	</Row> 
	<Row>
		<Col className="p-0 video-content-container-outer">
			<VideoContent content={VideoContentData}/>
		</Col>
	</Row> 
	{/* <Row>
		<Col className="p-0 retention-drives-container-outer">
			<RetentionDrives content={retentionDrivesSuccess}/>
		</Col>
	</Row> */}
	<Row>
		<Col className="p-0 social-impact-container-outer">
			<SocialImpact content={growthThroughCare}/>
		</Col>
	</Row>
	<Row>
		<Col className="section-area-bottom light-purple-bg">
		<FullWidthBannerImage bannerImageData={homePageBannerImageData}/>
		<div className="loved-by home-page-loved-by">
			<div className="container p-0">
				<LovedBy lovedByData={lovedBy} />
			</div>
		</div>
		</Col>
	</Row>
	<Row>
		<Col className="p-0">
			<Footer content={footerSection} />
		</Col>
	</Row>
</Container>}
	
	</>
		

	);
}
const mapStateToProps = state =>{
	const {appConfig}  = state.feparamsReducer;
	return { appConfig };
}

export default connect(mapStateToProps, {feparamsAction})(NewMainLandingPage);
